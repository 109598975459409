<script setup lang="ts">
import { computed } from 'vue'
type Props = {
    isFullPage?: boolean
    isLoading: boolean
}

const props = defineProps<Props>()

const modelIsLoading = computed(() => props.isLoading)
</script>

<template>
    <b-loading
        :is-full-page="isFullPage"
        v-model="modelIsLoading"
        :can-cancel="false"
        v-bind="$attrs"></b-loading>
</template>
