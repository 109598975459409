import type { ApiModel } from '@/types/models/ApiModel'

export type ApiState = {
    api?: ApiModel
    apis: ApiModel[]
}

export enum EApiActions {
    FETCH_API = 'fetchApi',
    FETCH_APIS = 'fetchApis',
    CREATE_API = 'createApi',
    UPDATE_API = 'updateApi',
    DELETE_API = 'deleteApi'
}

export enum EApiMutations {
    SET_APIS = 'setApis',
    SET_API = 'setApi',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
