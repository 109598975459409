import i18n from '@/locales/i18n'

export enum EEnv {
    DEVELOPMENT = 'development',
    DEV = 'dev',
    PREPRODUCTION = 'preproduction',
    PRODUCTION = 'production',
    OTHER = 'other'
}

export const EEnvLabels = {
    [EEnv.DEV]: i18n.global.t('enum.env.development'),
    [EEnv.DEVELOPMENT]: i18n.global.t('enum.env.development'),
    [EEnv.PREPRODUCTION]: i18n.global.t('enum.env.preproduction'),
    [EEnv.PRODUCTION]: i18n.global.t('enum.env.production'),
    [EEnv.OTHER]: i18n.global.t('enum.env.other')
}
