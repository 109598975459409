import { AUTH_API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { UserModel } from '@/types/models/UserModel'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { PasswordType } from '../types/PasswordType'

const API_PREFIX = `${AUTH_API_PREFIX}/users`

const userApi = {
    getUser: (id: number) => auth.get(`${API_PREFIX}/${id}`),
    getUsers: (params?: Partial<PaginationType>): Promise<PaginationDataType<UserModel[]>> =>
        auth.get(`${API_PREFIX}${buildQueryUrl(params)}`).then(response => response.data),
    createUser: (userData: UserModel) => auth.post(API_PREFIX, userData),
    updateUser: (id: number, userData: UserModel) => auth.patch(`${API_PREFIX}/${id}`, userData),
    deleteUser: (id: number) => auth.delete(`${API_PREFIX}/${id}`),
    impersonateUser: (id: number) => auth.post(`${API_PREFIX}/${id}/impersonate`),

    changePassword: (passwordData: PasswordType) =>
        auth.patch(`${API_PREFIX}/password`, passwordData)
}

export default userApi
