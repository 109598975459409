import { getItemFromStorage, setItemToStorage } from '@/utils/localStorageUtils'
import { onMounted, ref } from 'vue'
import { ELocalStorage } from '../types/enum/LocalStorageEnum'

export type RecentlyVisitedData = {
    label: string
    to: string
    organization_id: number
}

export const useRecentlyVisited = () => {
    onMounted(() => window.addEventListener('recentlyVisited', e => addToRecentlyVisited(e.detail)))

    const recentlyVisited = ref<RecentlyVisitedData | null>(
        getItemFromStorage(ELocalStorage.RECENTLY)
    )

    const addToRecentlyVisited = (data: RecentlyVisitedData) => {
        setItemToStorage(ELocalStorage.RECENTLY, data)
        recentlyVisited.value = data
    }

    return recentlyVisited
}
