import type { TicketModel } from '@/types/models/TicketModel'

export type TicketState = {
    ticket?: Partial<TicketModel>
    tickets: TicketModel[]
}

export enum ETicketActions {
    FETCH_TICKET = 'fetchTicket',
    FETCH_TICKETS = 'fetchTickets',
    CREATE_TICKET = 'createTicket',
    UPDATE_TICKET = 'updateTicket',
    DELETE_TICKET = 'deleteTicket'
}

export enum ETicketMutations {
    SET_TICKETS = 'setTickets',
    SET_ALL_TICKETS = 'setAllTickets',
    SET_TICKET = 'setTicket',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
