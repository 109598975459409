import {
    ETechnicianDevicesPerm,
    ETechnicianDocumentationsPerm,
    ETechnicianQuestionsPerm,
    ETechnicianTicketsPerm,
    ETechnicianTopicsPerm
} from '@/types/permissions/TechnicianPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import {
    TECHNICIAN_DOCUMENTATIONS_ROUTES,
    TECHNICIAN_INVENTORY_ROUTES,
    TECHNICIAN_QUESTIONS_ROUTES,
    TECHNICIAN_ROUTE,
    TECHNICIAN_TICKETS_ROUTES,
    TECHNICIAN_TOPICS_ROUTES
} from './technicianRoutes'
import {
    ETechnicianDocumentationsRoutesValues,
    ETechnicianInventoryRoutesValues,
    ETechnicianQuestionsRoutesValues,
    ETechnicianRoutesValues,
    ETechnicianTicketsRoutesValues,
    ETechnicianTopicsRoutesValues
} from './TechnicianRoutesEnum'

export const technicianMenu = computed(() => [
    {
        icon: 'screwdriver-wrench',
        label: 'menu.technician.title',
        to: `${TECHNICIAN_ROUTE}`,
        names: ETechnicianRoutesValues,
        permissions:
            userHasPermission(ETechnicianDevicesPerm.TECHNICIAN_DEVICES_VIEW) ||
            userHasPermission(ETechnicianTicketsPerm.TECHNICIAN_TICKETS_VIEW) ||
            userHasPermission(ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_VIEW) ||
            userHasPermission(ETechnicianDocumentationsPerm.TECHNICIAN_DOCUMENTATIONS_VIEW) ||
            userHasPermission(ETechnicianTopicsPerm.TECHNICIAN_TOPICS_VIEW),
        children: [
            {
                icon: 'warehouse',
                label: 'menu.technician.inventory',
                to: TECHNICIAN_INVENTORY_ROUTES.baseUrl,
                permissions: userHasPermission(ETechnicianDevicesPerm.TECHNICIAN_DEVICES_VIEW),
                names: ETechnicianInventoryRoutesValues
            },
            {
                icon: 'list-check',
                label: 'menu.technician.tickets',
                to: TECHNICIAN_TICKETS_ROUTES.baseUrl,
                permissions: userHasPermission(ETechnicianTicketsPerm.TECHNICIAN_TICKETS_VIEW),
                names: ETechnicianTicketsRoutesValues
            },
            {
                icon: 'clipboard-question',
                label: 'menu.technician.questions',
                to: TECHNICIAN_QUESTIONS_ROUTES.baseUrl,
                permissions: userHasPermission(ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_VIEW),
                names: ETechnicianQuestionsRoutesValues
            },
            {
                icon: 'book',
                label: 'menu.technician.documentations',
                to: TECHNICIAN_DOCUMENTATIONS_ROUTES.baseUrl,
                permissions: userHasPermission(
                    ETechnicianDocumentationsPerm.TECHNICIAN_DOCUMENTATIONS_VIEW
                ),
                names: ETechnicianDocumentationsRoutesValues
            },
            {
                icon: 'bars',
                label: 'menu.technician.topics',
                to: TECHNICIAN_TOPICS_ROUTES.baseUrl,
                permissions: userHasPermission(ETechnicianTopicsPerm.TECHNICIAN_TOPICS_VIEW),
                names: ETechnicianTopicsRoutesValues
            }
        ]
    }
])
