export type StatisticsState = {
    detections_count: any
    sessions_count: any
    isLoading: boolean
}

export enum EStatisticsMutations {
    SET_DETECTIONS_COUNT = 'setDetectionsCount',
    SET_SESSIONS_COUNT = 'setSessionsCount',
    SET_LOADING = 'setLoading'
}
