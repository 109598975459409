import i18n from '@/locales/i18n'
import { TICKETS_ROUTES } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import { ETicketsPerm } from '@/types/permissions/TicketsPermissionsEnum'
import TicketCreateView from '@/views/TicketsView/TicketCreateView.vue'
import TicketDetailView from '@/views/TicketsView/TicketDetailView.vue'
import { ref } from 'vue'

export enum ETicketsRoutesNames {
    TICKETS_TABLE = 'ticketsTable',
    TICKETS_DETAIL = 'ticketsDetail',
    TICKETS_CREATE = 'ticketsCreate'
}
export const ETicketsRoutesNamesValues = [
    ETicketsRoutesNames.TICKETS_TABLE,
    ETicketsRoutesNames.TICKETS_DETAIL,
    ETicketsRoutesNames.TICKETS_CREATE
]

const routes: RouteType[] = [
    {
        path: TICKETS_ROUTES.baseUrl,
        name: ETicketsRoutesNames.TICKETS_TABLE,
        component: () => import('@/views/TicketsView/TicketsTableView.vue'),
        meta: {
            title: ref(i18n.global.t('menu.technician.tickets')),
            permission: ETicketsPerm.TICKETS_VIEW
        }
    },
    {
        path: TICKETS_ROUTES.detailUrl,
        name: ETicketsRoutesNames.TICKETS_DETAIL,
        component: TicketDetailView,
        meta: {
            title: ref(i18n.global.t('menu.technician.tickets')),
            permission: ETicketsPerm.TICKETS_VIEW
        }
    },
    {
        path: TICKETS_ROUTES.createUrl,
        name: ETicketsRoutesNames.TICKETS_CREATE,
        component: TicketCreateView,
        meta: {
            title: ref(i18n.global.t('menu.technician.tickets')),
            permission: ETicketsPerm.TICKETS_EDIT
        }
    }
]

export default routes
