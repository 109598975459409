<script setup lang="ts">
import router from '@/router'
import { ADMIN_ORGANIZATIONS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultAddressModel } from '@/types/models/AddressModel'
import { defaultOrganizationModel, type OrganizationModel } from '@/types/models/OrganizationModel'
import AdministrationOrganizationDetailView from '@/views/AdministrationView/Organization/AdministrationOrganizationDetailView.vue'
import { ref } from 'vue'

const submitted = ref(false)
const organizationValue = ref<OrganizationModel>({
    ...defaultOrganizationModel,
    address: { ...defaultAddressModel }
})

const updateOrganizationValue = (value: Partial<OrganizationModel>) =>
    (organizationValue.value = { ...organizationValue.value, ...value })

const createOrganization = () => {
    submitted.value = true
    if (!organizationValue.value.name) return
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_ORGANIZATION}`, {
            ...organizationValue.value,
            ...organizationValue.value.address
        })
        .then(res => router.replace(ADMIN_ORGANIZATIONS_ROUTES.buildUrl(res.data.id)))
}
</script>

<template>
    <AdministrationOrganizationDetailView
        :data="organizationValue"
        :label="$t('common.create')"
        :submitted="submitted"
        :loading="!!store.state.organization.isLoading"
        @click="createOrganization"
        @update="updateOrganizationValue" />
</template>
