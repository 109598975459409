<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import type { UserModel } from '@/types/models/UserModel'
import { computed } from 'vue'

type Props = {
    data: Partial<UserModel>
    submitted?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'change', value: Partial<UserModel>): void }>()

const userData = computed({
    get: () => props.data,
    set: value => emit('change', value)
})

const errors = computed(() => ({
    emailError: props.submitted && !userData.value.email,
    nameError: props.submitted && !userData.value.name
}))
</script>

<template>
    <InformationBaseCard class="full-width" :label="$t('user.detail.informationsTitle')">
        <StackLayout :gap="16" class="full-width center-items input-information-card" isResponsive>
            <InputField
                :label="$t('user.detail.name')"
                v-model="userData.name"
                @change="value => (userData.name = value)"
                :placeholder="$t('user.detail.name')"
                required
                :hasError="errors.nameError"
                class="full-width" />
            <InputField
                :label="$t('user.detail.email')"
                v-model="userData.email"
                @change="value => (userData.email = value)"
                :placeholder="$t('authentication.email')"
                inputType="email"
                required
                class="full-width"
                :hasError="errors.emailError" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.personal-card {
    margin-bottom: 1rem;
}

.input-information-card {
    & .field.is-floating-label .label::before {
        background-color: var(--color-background-secondary);
    }
}

.full-width > .control > .input {
    background-color: var(--color-background-secondary);
}
</style>
