<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import CopyTypography from '@/components/Typography/CopyTypography.vue'
import { generateRandomString } from '@/mockupData/mockupUtils'
import { computed } from 'vue'

const generatedFriendCode = generateRandomString(35)

const friendCode = computed({
    get: () => '',
    set: value => value
})
</script>

<template>
    <StackLayout direction="column" :gap="16" class="full-width">
        <CopyTypography
            :label="$t('organization.organizations.invite.yourFriendCode')"
            :value="generatedFriendCode" />
        <StackLayout direction="row" :gap="8">
            <InputField
                :label="$t('organization.organizations.invite.addFriendCode')"
                :modelValue="friendCode"
                class="full-width add-friend-code" />
            <CreateButton :label="$t('common.add')" />
        </StackLayout>
    </StackLayout>
</template>
