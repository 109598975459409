<script setup lang="ts">
import store from '@/store'
import { type MenuItemType } from '@/types/MenuItem'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
type Props = {
    item: MenuItemType
}
const props = defineProps<Props>()
type Emits = {
    (e: 'click'): void
}
const emit = defineEmits<Emits>()

const route = useRoute()
const me = computed(() => store.state.authentication.me)

const item = computed(() => props.item)
const tag = computed(() => (item.value.to ? 'router-link' : undefined))
const pathIncludesLink = computed(
    () =>
        item.value.names?.includes(route.name?.toString() ?? '') ||
        (item.value.children?.length &&
            !!item.value.children.find(
                children => children.names?.includes(route.name?.toString() ?? '')
            ))
)
const hasPermission = computed(() => {
    if (item.value.permissions === undefined) return true
    if (!Array.isArray(item.value.permissions)) return item.value.permissions
    else if (Array.isArray(item.value.permissions))
        return !!me.value.permissions.find(
            permission => (item.value.permissions as PermissionsEnums[])?.includes(permission)
        )

    return true
})

const handleClick = () => {
    if (!props.item.children) emit('click')
    props.item.click?.()
}
</script>

<template>
    <div v-if="hasPermission" class="is-expanded">
        <b-menu-item
            v-if="item.children?.length"
            :label="
                $slots['label'] ? undefined : item.withoutTranslation ? item.label : $t(item.label)
            "
            :iconPack="item.iconPack"
            :icon="$slots['label'] ? undefined : item.icon"
            :tag="tag"
            :to="item.to ? item.to : undefined"
            :expanded="pathIncludesLink"
            :active="pathIncludesLink"
            @click="handleClick"
            v-bind="$attrs">
            <slot />
            <template #label><slot name="label" /></template>
        </b-menu-item>
        <b-menu-item
            v-else
            :label="
                $slots['label'] ? undefined : item.withoutTranslation ? item.label : $t(item.label)
            "
            :iconPack="item.iconPack"
            :icon="$slots['label'] ? undefined : item.icon"
            :active="pathIncludesLink"
            :tag="tag"
            :to="item.to"
            @click="handleClick"
            v-bind="$attrs">
            <template #label><slot name="label" /></template>
        </b-menu-item>
    </div>
</template>
