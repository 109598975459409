import { DEVICE_PREFIX } from '@/config/api'
import device from '@/helpers/device'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { FilterType } from '@/types/filters/FilterType'
import type { TechnicianTicketModel } from '@/types/models/TechnicianTicketModel'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { AxiosResponse } from 'axios'

const TECHNICIAN_TICKETS_PREFIX = `${DEVICE_PREFIX}/device-config-types`

const technicianApi = {
    // DEVICE CONFIG TYPE
    getTechnicianTicket: (id: number): Promise<AxiosResponse<TechnicianTicketModel>> =>
        device.get(`${TECHNICIAN_TICKETS_PREFIX}/${id}`),
    getTechnicianTickets: (
        params: Partial<PaginationType & FilterType<TechnicianTicketModel>>
    ): Promise<PaginationDataType<TechnicianTicketModel[]>> =>
        device
            .get(`${TECHNICIAN_TICKETS_PREFIX}${buildQueryUrl(params)}`)
            .then(response => response.data),
    createTechnicianTicket: (technicianTicketData: any) =>
        device.post(TECHNICIAN_TICKETS_PREFIX, technicianTicketData),
    updateTechnicianTicket: (
        id: number,
        technicianTicketData: Partial<TechnicianTicketModel>
    ): Promise<AxiosResponse<TechnicianTicketModel>> =>
        device.patch(`${TECHNICIAN_TICKETS_PREFIX}/${id}`, technicianTicketData),
    deleteTechnicianTicket: (id: number) => device.delete(`${TECHNICIAN_TICKETS_PREFIX}/${id}`)
}

export default technicianApi
