import type { ErrorLocales } from '@/types/lang/locales'

export const errorEn: ErrorLocales = {
    forbidden: 'Access forbidden',
    notFound: 'No data found',
    refreshError: 'Impossible to refresh your session',
    serverConnectionFailed: 'Server connection has failed',
    sessionExpired: 'Session expired',
    unknownError: 'An unknown error has occured'
}
