export const apiFr = {
    api: {
        error: {
            createApi: 'Erreur lors de la création du API {name}',
            deleteApi: 'Erreur lors de la suppression du API {name}',
            fetchApi: "Erreur lors de la récupération de l'API",
            fetchApis: 'Erreur lors de la récupération des APIs',
            updateApi: 'Erreur lors de la modification du API {name}'
        },
        success: {
            createApi: 'Création du API {name} effectuée avec succès !',
            deleteApi: 'Suppression du API {name} effectuée avec succès !',
            updateApi: 'Modification(s) du API {name} effectuée(s) avec succès !'
        }
    },
    informationsTitle: 'Informations',
    buttons: {
        createApi: 'Créer une API'
    },
    filters: {
        name: 'Nom',
        expirationDateFrom: 'Expiration après le',
        expirationDateTo: 'Expiration avant le',
        onlyDisabled: 'Uniquement désactivé',
        onlyEnabled: 'Uniquement activé'
    },
    model: {
        active: 'Status',
        apiKey: "Clé d'API",
        expirationDate: "Date d'expiration",
        id: 'ID',
        name: 'Nom'
    }
}
