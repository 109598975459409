<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import store from '@/store'
import { EDetectionActions } from '@/store/detectionStore/DetectionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import type { CheckpointModel } from '../../../types/models/CheckpointModel'
import type { EventModel } from '../../../types/models/EventModel'
import { isNullOrUndefined } from '../../../utils/validateUtils'
import AutocompleteField from '../../Selector/AutocompleteField.vue'
import RadioButton from '../RadioButton.vue'

type Props = {
    sort?: ESortBy
    perPage?: number
    currentPage?: number
    event?: Partial<EventModel>
    checkpointId?: number
    checkpoint?: Partial<CheckpointModel>
    deviceId?: number
    hideEventSelector?: boolean
}
const props = defineProps<Props>()

const loading = ref(false)
const open = ref(false)
const { t } = useI18n()

const selectedEvent = ref<Partial<EventModel> | undefined>(props.event)
const selectedCheckpoint = ref<Partial<CheckpointModel> | undefined>(props.checkpoint)
const selectedFilters = ref<EventCheckpointType | undefined>({
    event_id: props.event?.id,
    checkpoint_id: props.checkpointId
})

const handleOpen = () => {
    open.value = true
}

const handleChangeEvent = (value: EventCheckpointType) =>
    (selectedFilters.value = { ...selectedFilters.value, checkpoint_id: undefined, ...value })

const handleConfirm = () => {
    loading.value = true
    store
        .dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.EXPORT_DETECTIONS}`, {
            params: {
                filters: {
                    event_id: selectedFilters.value?.event_id,
                    checkpoint_id: selectedFilters.value?.checkpoint_id,
                    device_id: props.deviceId
                },
                sort: props.sort ? getSortByKey(props.sort) : undefined,
                per_page: props.perPage,
                page: radioButtonValue.value
            },
            event_name: selectedEvent.value?.name ?? props.event?.name,
            checkpoint_name: selectedCheckpoint.value?.name
        })
        .finally(() => (loading.value = false))
}

const radioButtonValue = ref(props.currentPage)
const radioOptions = computed(() => [
    {
        label: t('detection.export.allDetections'),
        value: -1
    },
    {
        label: t('detection.export.currentPage', { page: props.currentPage }),
        value: props.currentPage
    }
])

watch(
    () => props.event,
    newValue => (selectedFilters.value = { ...selectedFilters.value, event_id: newValue?.id }),
    { immediate: true }
)

const handleSelectEvent = (event?: Partial<EventModel>) => {
    selectedEvent.value = event
    selectedFilters.value = {
        ...selectedFilters.value,
        event_id: event?.id,
        checkpoint_id: undefined
    }
}

const handleSelectCheckpoint = (checkpoint?: CheckpointModel) =>
    (selectedCheckpoint.value = checkpoint)
</script>

<template>
    <CreateButton :loading="loading" :label="$t('common.exportToCsv')" @click="handleOpen" />
    <BaseDialog
        :title="$t('common.exportToCsv')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        :disabled="isNullOrUndefined(selectedFilters?.event_id)"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout direction="column" :gap="8" isResponsive>
                <StackLayout :gap="8" isResponsive>
                    <EventCheckpointAutocomplete
                        :event="selectedEvent"
                        :event_id="selectedFilters?.event_id"
                        :checkpoint_id="selectedFilters?.checkpoint_id"
                        filterAllEvents
                        :hideEventSelector="hideEventSelector"
                        @change="handleChangeEvent"
                        @selectEvent="handleSelectEvent"
                        @selectCheckpoint="handleSelectCheckpoint" />
                    <AutocompleteField
                        :label="$t('detection.export.device')"
                        :options="selectedEvent?.detection_devices"
                        v-if="!deviceId && selectedEvent?.detection_devices?.length"
                        class="full-width" />
                </StackLayout>

                <StackLayout :gap="8" isResponsive>
                    <RadioButton
                        :modelValue="radioButtonValue"
                        :options="radioOptions"
                        @change="radioButtonValue = $event" />
                </StackLayout>
            </StackLayout>
        </template>
    </BaseDialog>
</template>
