<!-- Attachments.vue -->
<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import type { DocumentModel } from '@/types/models/ReplyModel'
import { isDocumentPdf } from '@/utils/fileUtils'
import { ref } from 'vue'
import { EditButton } from '../Buttons'
import TicketFilePreviewDialog from '../TicketComponents/Detail/components/TicketFilePreviewDialog.vue'

type Props = {
    documents: DocumentModel[]
}
defineProps<Props>()

const pdfFile = ref<DocumentModel>()
const imageFile = ref<DocumentModel>()
</script>

<template>
    <StackLayout>
        <StackLayout
            v-for="(document, index) in documents"
            :key="index"
            class="flex-wrap padding-for-img">
            <EditButton
                v-if="isDocumentPdf(document.name)"
                isResponsive
                @click="$emit('openPdf', document)"
                icon="file-pdf"
                :label="document.name" />
            <img
                v-else
                class="icon-img primary-border border-radius cursor-pointer"
                @click="$emit('openImage', document)"
                :src="document.path" />
        </StackLayout>
    </StackLayout>

    <TicketFilePreviewDialog v-if="pdfFile" :file="pdfFile" @close="pdfFile = undefined">
        <iframe width="800" height="800" :src="pdfFile?.path"></iframe>
    </TicketFilePreviewDialog>

    <TicketFilePreviewDialog v-if="imageFile" :file="imageFile" @close="imageFile = undefined">
        <img :src="imageFile?.path" />
    </TicketFilePreviewDialog>
</template>
