<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { EDeviceType } from '@/types/enum/DeviceTypeEnum'
import { type DeviceModel } from '@/types/models/DeviceModel'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { computed, ref } from 'vue'
import NumberInputField from '../Fields/NumberInputField.vue'
import DeviceTypeSelector from '../Selector/components/DeviceTypeSelector.vue'
import OrganizationSelector from '../Selector/components/OrganizationSelector.vue'

const submitted = ref(false)

type Props = {
    organizationId?: number
    deviceData: Partial<DeviceModel>
    loading?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceModel>): void
    (e: 'create'): void
}
const emit = defineEmits<Emits>()

const createDevice = () => {
    submitted.value = true
    if (
        !props.deviceData.name ||
        !props.deviceData.data?.organization_id ||
        !props.deviceData.device_type_id
    )
        return
    emit('create')
    submitted.value = false
}

const errors = computed(() => ({
    nameError: submitted.value && !props.deviceData.name,
    organizationError: submitted.value && !props.deviceData.data?.organization_id,
    deviceTypeError: submitted.value && !props.deviceData.device_type_id,
    imeiSatError:
        submitted.value && deviceTypeHasImeiSAT && !props.deviceData.imei?.[1]?.toString().length
}))
const deviceTypeHasImeiSAT = computed(
    () =>
        props.deviceData.device_type_id === EDeviceType.NANO_SAT ||
        props.deviceData.device_type_id === EDeviceType.MODULE_SAT
)

const selectedOrganization = ref<OrganizationModel>()
const handleSelect = (value?: OrganizationModel) => {
    if (!value) return
    selectedOrganization.value = value
    emit('change', { data: { organization_id: value?.id } })
}
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <CreateButton :loading="loading" :label="$t('common.create')" @click="createDevice" />
        <StackLayout direction="row" :gap="8">
            <InformationBaseCard
                class="full-width input-information-card"
                :label="$t('device.createDevice')">
                <StackLayout direction="column" :gap="16" class="full-width">
                    <StackLayout :gap="8">
                        <InputField
                            :label="$t('device.model.deviceName')"
                            :modelValue="deviceData.name"
                            @change="$emit('change', { name: $event })"
                            :placeholder="$t('device.model.deviceName')"
                            required
                            :hasError="errors.nameError"
                            class="full-width" />
                    </StackLayout>
                    <StackLayout :gap="8">
                        <DeviceTypeSelector
                            :modelValue="deviceData.device_type_id"
                            @select="$emit('change', { device_type_id: $event.id })"
                            :hasError="errors.deviceTypeError"
                            required
                            class="full-width" />
                        <InputField
                            :label="$t('device.model.serialNumber')"
                            :modelValue="deviceData.serial_number"
                            @change="$emit('change', { serial_number: $event })"
                            :placeholder="$t('device.model.serialNumber')"
                            class="full-width" />
                    </StackLayout>
                    <OrganizationSelector
                        v-if="!organizationId"
                        required
                        :hasError="errors.organizationError"
                        :organizationId="selectedOrganization?.id"
                        @select="handleSelect"
                        class="full-width" />

                    <NumberInputField
                        v-if="deviceTypeHasImeiSAT"
                        :label="$t('device.model.imeiSAT')"
                        :modelValue="deviceData?.imei?.[1]"
                        @change="
                            $emit('change', { imei: [deviceData.imei?.[0] ?? undefined, $event] })
                        "
                        :hasError="errors.imeiSatError"
                        required />
                </StackLayout>
            </InformationBaseCard>
        </StackLayout>
    </StackLayout>
</template>
