<!-- MessageList.vue -->
<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import store from '@/store'
import type { ReplyModel } from '@/types/models/ReplyModel'
import { defaultUserModel } from '@/types/models/UserModel'
import { computed } from 'vue'
import MessageReply from './MessageReply.vue'

type Props = {
    replies: ReplyModel[]
}
defineProps<Props>()

const me = computed(() => ({ ...(store.state.authentication.me ?? defaultUserModel) }))
</script>

<template>
    <InformationBaseCard :label="$t('ticket.detail.message')">
        <MessageReply
            v-for="reply in replies"
            :key="reply.id"
            :reply="reply"
            :isMyMessage="reply.user_id === me.id" />
    </InformationBaseCard>
</template>
