export const userFr = {
    actions: {
        attachAction: 'Associer',
        detachAction: 'Dissocier',
        activeAction: "Activer l'utilisateur",
        desactiveAction: "Désactiver l'utilisateur"
    },
    api: {
        error: {
            createUser: "Erreur lors de la création de l'utilisateur {name}",
            deleteUser: "Erreur lors de la suppression de l'utilisateur {name}",
            fetchUser: "Erreur lors de la récupération de l'utilisateur",
            fetchUsers: 'Erreur lors de la récupération des utilisateurs',
            updateUser: "Erreur lors de la modification de l'utilisateur {name}",

            disincarnateUser: 'Erreur lors de la désincarnation',
            impersonate: "Erreur lors de l'incarnation",

            changePassword: 'Erreur lors de la tentative de changement de mot de passe'
        },
        success: {
            createUser: "Création de l'utilisateur {name} effectuée avec succès !",
            deleteUser: "Suppression de l'utilisateur {name} effectuée avec succès !",
            fetchUser: "Récupération de l'utilisateur effectuée avec succès !",
            fetchUsers: 'Récupération des utilisateurs effectuée avec succès !',
            updateUser: "Modification(s) de l'utilisateur {name} effectuée(s) avec succès !",

            disincarnateUser: 'Désincarnation réussie !',
            impersonate: 'Incarnation réussie : {name}',

            changePassword: 'Mot de passe changé avec succès !'
        }
    },
    buttons: {
        resetPassword: 'Envoyer un lien de changement de mot de passe'
    },
    create: {
        existingOrganization: 'Organisation existante',
        newOrganizationName: 'Nom de la nouvelle organisation',
        organizationTitle: 'Organisation'
    },
    detail: {
        create: "Créer l'utilisateur",
        edit: 'Enregistrer les modifications',
        email: 'Email',
        informationsTitle: "Informations de l'utilisateur",
        name: 'Nom complet',
        permissionsTitle: 'Permissions',
        organizationTitle: 'Organisation',
        rolesTitle: 'Rôles',
        validationDate: 'Date de validation du compte',
        notValidatedYet: "L'utilisateur n'a pas encore crée son mot de passe",
        lastLoginDate: 'Dernière connexion',
        noLastLoginDate: "L'utilisateur ne s'est jamais connecté"
    },
    dialog: {
        impersonate: 'Incarner',
        impersonateContent: 'Prendre contrôle du compte ?',
        impersonateTitle: 'Incarner'
    },
    filters: {
        name: 'Nom complet',
        email: 'Email'
    },
    table: {
        createUser: 'Créer un utilisateur',
        email: 'Email',
        id: 'ID',
        name: 'Nom complet',
        noRole: 'Aucun rôle assigné',
        organization: 'Organisation',
        role: 'Rôle'
    },
    title: {
        associateUserTitle: '',
        dissociateUserTitle: '',
        blockUserTitle: '',
        unblockUserAction: ''
    }
}
