import type { RangeType } from '@/types/Range'
import { EKeyCategory } from '@/types/enum/KeyCategoryEnum'
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import type { AvailableLangs } from '../../locales/i18n'

export type DeviceTemplateKeysCreateModel = {
    name: string
    description: AvailableLangs<string>
    translation: AvailableLangs<string>
    type: EPrimitive
    category: EKeyCategory

    is_admin: 0 | 1
    masked: 0 | 1
    read_only: 0 | 1
    is_advanced: 0 | 1

    default?: string | number | boolean | null
    range?: Partial<RangeType>
    excluded?: (number | string | undefined)[]
    values?: (number | string | boolean | undefined)[]
    values_labels?: (string | undefined)[]
    unit?: string
}

export const defaultDeviceTemplateKeysCreateModel: DeviceTemplateKeysCreateModel = {
    name: '',
    description: {
        fr: '',
        en: ''
    },
    translation: {
        fr: '',
        en: ''
    },
    type: EPrimitive.STRING,
    category: EKeyCategory.INFORMATIONS,
    is_admin: 0,
    masked: 0,
    read_only: 0,
    is_advanced: 0
}
