<script setup lang="ts">
import { GridLayout, StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import UserCard from '@/components/User/Card/UserCard.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { UserModel } from '@/types/models/UserModel'
import type { EOrganizationsUsersPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'

type Props = {
    data: UserModel[]
    loading?: boolean
    deletePermission: EOrganizationsUsersPerm
    editPermission: EOrganizationsUsersPerm
    editButtonHref?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'click', data: UserModel): void
    (e: 'delete', data: UserModel): void
}
defineEmits<Emits>()

const gridSize = useGridSize()

const hasEditPermission = computed(
    () => props.editPermission && userHasPermission(props.editPermission)
)
</script>

<template>
    <GridLayout v-if="data?.length" :maxItemPerRow="gridSize" :gap="8" class="relative">
        <LoadingSpinner :isLoading="!!loading" />
        <UserCard
            v-for="(user, key) in data"
            :key="key"
            :data="user"
            @click="hasEditPermission && $emit('click', $event)"
            @delete="$emit('delete', user)"
            :editPermission="editPermission"
            :deletePermission="deletePermission"
            :editButtonHref="editButtonHref" />
    </GridLayout>
    <StackLayout v-else class="align-self-center no-record">
        <LoadingSpinner :isLoading="!!loading" />
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>
