<script setup lang="ts">
import ExportDetectionButton from '@/components/Buttons/components/ExportDetectionButton.vue'
import { FullTableLayout } from '@/components/Layouts'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseTable, TablePagination } from '@/components/Table'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import detectionsApi from '@/services/detection.service'
import { ESortBy, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { defaultPaginationDataType, type PaginationDataType } from '@/types/PaginationType'
import { getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { ref, watch } from 'vue'
import DeviceDetectionsColumns from './DeviceDetectionsColumns.vue'

type Props = {
    noDetectionLink?: boolean
    deviceId?: number
}
const props = defineProps<Props>()

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDetections()
}

const handleChangePageSize = (value: number) => {
    currentEventDetections.value.per_page = value
    currentEventDetections.value.current_page = 1
    getDetections()
}
const handleChangePageNumber = (pageNumber: number) => {
    currentEventDetections.value.current_page = pageNumber
    getDetections()
}
const currentEventDetections = ref<PaginationDataType<DetectionModel[]>>({
    ...defaultPaginationDataType
})
const getDetections = () =>
    detectionsApi
        .getDetections(
            {
                filters: { device_id: props.deviceId },
                sort: getSortByKey(sort.value),
                per_page: getPageSize() ?? currentEventDetections.value.per_page,
                page: currentEventDetections.value.current_page
            },
            true
        )
        .then(res => (currentEventDetections.value = res))

const table = ref()

watch(
    () => props.deviceId,
    (newValue, oldValue) => {
        if (oldValue && newValue !== oldValue) handleChangePageNumber(1)
    },
    { immediate: true }
)
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TitleTypography
            :title="$t('device.detail.detections.pastDetections')"
            class="title-x-large bold" />
        <FullTableLayout
            :sortBy="sort"
            @changeSortBy="handleChangeSortBy"
            :orderOptions="ESortByDate"
            isResponsiveSmallScreen>
            <template #export>
                <ExportDetectionButton
                    :sort="sort"
                    :perPage="currentEventDetections.per_page"
                    :currentPage="currentEventDetections.current_page"
                    :deviceId="deviceId" />
            </template>
            <template #table>
                <BaseTable v-bind:ref="table" :data="currentEventDetections?.data ?? []">
                    <template #columns>
                        <DeviceDetectionsColumns :noDetectionLink="noDetectionLink" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :perPage="currentEventDetections.per_page"
                    :currentPage="currentEventDetections?.current_page"
                    :lastPage="currentEventDetections?.last_page"
                    :total="currentEventDetections?.total"
                    forcePropsCurrentPage
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
