<script setup lang="ts">
import { ESortBy, ESortByAlphabetical, ESortByDate } from '@/types/enum/SortByEnum'
import type {
    AdvancedFiltersEmits,
    AdvancedFiltersProps
} from '@/types/filters/AdvancedFiltersProps'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import SecondaryButton from '../Buttons/SecondaryButton.vue'
import SortByFilter from '../Filters/SortByFilter.vue'
import { StackLayout } from '../Layouts'

const props = defineProps<AdvancedFiltersProps>()
const emit = defineEmits<AdvancedFiltersEmits>()

const orderByOptions = computed(
    () => props.orderOptions ?? [ESortByAlphabetical, ESortByDate].flat()
)
const changeOrderBy = (value: ESortBy) => emit('changeSortBy', value)

const isMobileLayout = ref(isMobile())
isMobileEvent(e => (isMobileLayout.value = e.matches))
</script>

<template>
    <StackLayout
        :direction="keepInRow ? 'row' : 'column'"
        :gap="12"
        :class="`align-self-start ${isMobileLayout ? 'full-width' : ''}`"
        isResponsive>
        <slot v-if="isMobileLayout" />
        <StackLayout
            :direction="keepInRow ? 'row' : 'column'"
            :gap="12"
            :class="isMobileLayout ? 'full-width' : ''"
            isResponsive="row">
            <StackLayout direction="row" :gap="12" class="full-width">
                <slot v-if="!isMobileLayout" />
                <SecondaryButton
                    v-if="showAdvancedFilters"
                    :label="$t('common.advancedFilters')"
                    class="full-width"
                    @click="$emit('openFilters')" />
            </StackLayout>
            <SortByFilter
                v-if="!hideSortBy"
                :sortBy="sortBy"
                :options="orderByOptions"
                @click="changeOrderBy"
                class="full-width" />
        </StackLayout>
    </StackLayout>
</template>
