import { API_PREFIX } from '@/config/api'
import api from '@/helpers/api'
import type { Commit, Dispatch } from 'vuex'
import { EZoneMutations, type ZoneState } from './ZoneStoreTypes'

const getters = {
    zoneList: (state: ZoneState) => state.zones,
    isDeleting: (state: ZoneState) => state.isDeleting
}

const actions = {
    fetchZones: ({ dispatch, commit }: { commit: Commit; dispatch: Dispatch }, params: any) =>
        api.get(`${API_PREFIX}/zones`, { params: params }).then(
            res => commit(EZoneMutations.SET_ZONES, res.data.zones),
            err => dispatch('alert/error', err, { root: true })
        ),
    deleteZone: ({ commit }: { commit: Commit }, id: number) => {
        commit(EZoneMutations.SET_IS_DELETING, true)
        api.delete(`${API_PREFIX}/zones/${id}`)
            .then(res => {
                commit(EZoneMutations.SET_DELETE_ZONE, res.data.zones.id)
                commit(EZoneMutations.SET_IS_DELETING, false)
            })
            .catch(err => {
                commit(EZoneMutations.SET_IS_DELETING, false)
            })
    }
}

const mutations = {
    [EZoneMutations.SET_ZONES]: (state: ZoneState, zones: any) => {
        state.zones = zones
    },
    [EZoneMutations.SET_DELETE_ZONE]: (state: ZoneState, id: number) => {
        state.zone.data.filter((x: { id: number }) => x.id !== id)
    },
    [EZoneMutations.SET_IS_DELETING](state: ZoneState, isDeleting: boolean) {
        state.isDeleting = isDeleting
    }
}

// initial state
const state: ZoneState = {
    zones: [],
    isLoading: false,
    isCreating: false,
    isDeleting: false
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
