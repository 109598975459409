export const technicianFr = {
    documentation: {
        latestUpdates: 'Dernières mises à jour'
    },
    question: {
        table: {
            createQuestion: 'Créer une question'
        },
        model: {
            id: 'ID',
            name: 'Nom'
        }
    },
    ticket: {
        table: {
            createTicket: 'Créer un ticket',
            markAsRead: 'Marquer comme lu'
        },
        model: {
            id: 'ID',
            name: 'Nom',
            title: 'Titre',
            type: 'Type',
            statut: 'Statut',
            severity: 'Gravité',
            endDate: 'Date de fin',
            serialNumber: 'Numéro de série du Ticket',
            topic: 'Thème',
            startDate: 'Date de création'
        },
        detail: {
            information: 'Information',
            description: 'Description',
            reassignement: 'Réaffectation',
            ticketReassignment: 'Réaffectation du ticket',
            selectRecipient: 'Selectionner un destinataire',
            diagnistic: 'Diagnostique',
            resolution: 'Résolution',
            comment: 'Commentaire',
            device: 'Appareil',
            deviceName: "Nom de l'Appareil",
            send: 'Envoyer',
            nameDevice: "Nom de l'appareil",
            reassignenment: 'Réaffectation',
            diagnostic: 'Diagnostique',
            informationUser: 'Information Utilisateur',
            nameUser: 'Nom',
            mailUser: 'Mail',
            type: 'Type',
            action: 'Action',
            serialNumberDevice: "Numéro de série de l'appareil"
        },
        ticket: {
            name: 'Nom Thème',
            topic: 'Thème',
            date: 'Date',
            send: 'Envoyer',
            serialNumber: 'Numéro de série',
            id: 'Id',
            parent_id: 'Parent Id',
            trans: 'Traduction',
            parent_name: 'Nom parent',
            select_parent_id: 'Selectionner le parent Id'
        },
        onglet: {
            information: 'Information',
            message: 'Message'
        },
        message: {
            reply: 'Réponse',
            placeholderMessage: 'envoyer un message ...',
            sendReply: 'Envoyer',
            addFile: 'Ajouter un document',
            attachment: 'Pièce jointe'
        },
        filter: {
            archived: 'Uniquement les archivés',
            createDate: 'Date de création',
            object: 'Objet',
            sortByPeriod: 'Trier par période',
            status: 'Status',
            support: 'Support',
            user: 'Utilisateur affecté'
        },
        topic: {
            addTopic: 'Ajouter un thème',
            nameTopicFr: 'Nom du thème (fr)',
            nameTopicEn: 'Nom du thème (en)',
            placeholderNameTopicFr: 'Entrer le nom du thème (fr)',
            placeholderNameTopicEn: 'Entrer le nom du thème (en)',
            create: 'Créer',
            quit: 'Quitter',
            update: 'Mise à jour',
            updateTopic: 'Mise à jour du thème',
            archived: 'show topics archive',
            object: 'Objet'
        }
    }
}
