<script setup lang="ts">
import { FullTableLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import { ADMIN_USERS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import {
    defaultAdminUserFiltersType,
    type AdminUserFiltersType
} from '@/types/filters/AdminFilters'
import type { UserModel } from '@/types/models/UserModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import HrefCreateButton from '../../../components/Buttons/HrefCreateButton.vue'
import AdminUserFilters from './components/AdminUserFilters.vue'
import AdminUserImpersonateButton from './components/AdminUserImpersonateButton.vue'

const { t } = useI18n()
const router = useRouter()

const columns = {
    id: { field: 'id', label: t('user.table.id') },
    organization_name: { field: 'organization_name', label: t('user.table.organization') },
    email: { field: 'email', label: t('user.table.email') },
    name: { field: 'name', label: t('user.table.name') },
    role: { field: 'roles', label: t('user.table.role') }
}

const data = computed(() => store.state.user)
const filters = ref<AdminUserFiltersType>({ ...defaultAdminUserFiltersType, ...getQueryFilters() })
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getUsers = () =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.FETCH_USERS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const goToUserDetail = (user: UserModel) => router.push(ADMIN_USERS_ROUTES.buildUrl(user.id))
const goToUserCreate = () => router.push(ADMIN_USERS_ROUTES.createUrl)
const deleteUser = (
    user: UserModel // TODO correction
) =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.DELETE_USER}`, user).then(() => getUsers())

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getUsers()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getUsers()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getUsers()
}
const handleChangeFilters = (value: Partial<AdminUserFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getUsers()
}

const table = ref<any>([])
</script>

<template>
    <PermissionWrapper :permission="EAdminPerm.ADMIN_USERS">
        <HrefCreateButton
            :href="ADMIN_USERS_ROUTES.createUrl"
            :label="$t('user.table.createUser')"
            @click="goToUserCreate" />
    </PermissionWrapper>
    <FullTableLayout :sortBy="sort" @changeSortBy="handleChangeSortBy">
        <template #filters>
            <AdminUserFilters :filters="filters" @change="handleChangeFilters" />
        </template>

        <template #table>
            <BaseTable v-bind:ref="table" :data="data.data.users" :loading="data.isLoading">
                <template #columns>
                    <TableColumn field="id" :label="columns['id'].label" />
                    <TableColumn field="name" :label="columns['name'].label" />
                    <TableColumn field="email" :label="columns['email'].label" />
                    <TableColumn
                        field="organization_name"
                        :label="columns['organization_name'].label" />
                    <TableActionColumn
                        titleItemKey="name"
                        :editPermission="EAdminPerm.ADMIN_USERS"
                        :deletePermission="EAdminPerm.ADMIN_USERS"
                        @delete="deleteUser"
                        :editButtonHref="ADMIN_USERS_ROUTES.baseUrl">
                        <template #before="props">
                            <PermissionWrapper :permission="EAdminPerm.ADMIN_USERS_IMPERSONATE">
                                <AdminUserImpersonateButton :user="props" />
                            </PermissionWrapper>
                        </template>
                    </TableActionColumn>
                </template>
            </BaseTable>
        </template>
        <template #pagination>
            <TablePagination
                :perPage="data.per_page"
                :currentPage="data.current_page"
                :lastPage="data.last_page"
                :total="data.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>

        <template #dialogFilters:title></template>
        <template #dialogFilters:content></template>
        <template #dialogFilters:footer></template>
    </FullTableLayout>
</template>
