<script setup lang="ts">
import { EShowMore } from '@/types/enum/ShowMoreEnum'
import { computed, ref } from 'vue'
import TextButton from '../Buttons/TextButton.vue'
import ShowMoreDialog from '../Modals/Dialog/ShowMoreDialog.vue'

type Props = {
    text: string
    fullLength?: boolean

    showMoreType?: EShowMore
}

defineEmits<{ (e: 'click'): void }>()
const props = defineProps<Props>()
const textValue = computed(() =>
    props.showMoreType === EShowMore.FULL_TEXT && showMore.value
        ? props.text
        : props.text.slice(0, 150)
)

const showMore = ref(false)
</script>

<template>
    <span>
        {{ fullLength ? text : textValue }}
        <TextButton
            v-if="!fullLength && text.length > 150"
            :text="`... ${$t('common.showMore')}`"
            @click="showMore = !showMore" />
    </span>
    <ShowMoreDialog
        v-if="showMoreType === EShowMore.DIALOG"
        :open="showMore"
        :text="text"
        @close="showMore = false"
        v-bind="$attrs" />
</template>
