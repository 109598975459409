import { API_PREFIX, MULTIPART_FORMDATA_REQUEST } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { TicketModel } from '../types/models/TicketModel'

const TICKET_API = `${API_PREFIX}/tickets`

const ticketApi = {
    getTicket: (id: number) => auth.get(`${TICKET_API}/${id}`).then(response => response.data),
    getTickets: (params?: Partial<PaginationType>) =>
        auth.get(`${TICKET_API}${buildQueryUrl(params)}`).then(response => response.data),
    createTicket: (ticketData: FormData) =>
        auth
            .post(TICKET_API, ticketData, MULTIPART_FORMDATA_REQUEST)
            .then(response => response.data),
    updateTicket: (id: number, ticketData: Partial<TicketModel>) =>
        auth.put(`${TICKET_API}/${id}`, ticketData).then(response => response.data),
    deleteTicket: (id: number) => auth.delete(`${TICKET_API}/${id}`).then(response => response.data)
}

export default ticketApi
