import type { TicketLocales } from '@/types/lang/locales'

export const ticketEn: TicketLocales = {
    table: {
        id: 'Ticket id',
        object: 'Title',
        description: 'Description',
        topic: 'Topic',
        startDate: 'Start date',
        endDate: 'End date',
        statusId: 'Statut',
        supportId: 'Support',
        severityId: 'Severity',
        topicId: 'Topic',
        action: 'Action',
        markAsRead: 'Mark as read',
        markAsUnread: 'Mark as unread',
        delete: 'Delete',
        serialNumber: 'Serial Number',
        comment: 'Comment',
        device: 'Device',
        status: 'Status',
        severitty: 'Severity'
    },
    filter: {
        deviceType: "Type d'appareil",

        input: 'Input',
        date: 'Date',
        status: 'Status',
        support: 'Support',
        period: 'Sort by period',
        mostRecent: 'Most recent',
        leastRecent: 'Least recent',
        techician: 'Techician',
        commercial: 'Marketing team',
        developer: 'Developer',
        treated: 'Treated',
        notTreated: 'Not treated',
        inProgress: 'In progress'
    },
    model: {
        createDate: 'Create date',
        status: 'status',
        support: 'Support',
        sortByPeriod: 'Sort by period',
        advancedFilter: 'Advenced filter',
        createTicket: 'Create ticket'
    },
    detail: {
        action: 'Action',
        date: 'Date',
        diagnostic: 'Diagnostic',
        file: 'File',
        information: 'Information',
        message: 'Messages',
        modalMessage: 'Open image in new tab',
        name: 'Name',
        reassignment: 'Reassignment',
        reply: 'Reply',
        resolution: 'Resolution',
        selectRecipient: 'Select recipient',
        send: 'Send',
        sendReply: 'Send reply',
        ticketReassignment: 'Ticket reassignment',
        topic: 'Topic'
    },
    create: {
        title: 'Select the theme of your request',
        home: 'Go to Home',
        faq: 'See FAQ',
        selectSeverity: 'Select severity',
        information: "Ticket's information",
        message: 'Message',
        placeholderMessage: 'Add description ...',
        addFile: 'Add picture',
        topic: 'Topic',
        severity: 'Severity',
        seeInformation: 'See Information',
        device: 'Device',
        selectDevice: 'Select a device',
        serialNumber: 'Serial number',
        serialNumberDevice: 'Serial number Device',
        youMustFillCategorie: 'You must fill in the categorie',
        youMustFillDescription: 'You must fill in the description',
        youMustFillTopic: 'You must fill in the topic',
        failCreateTicket: 'Ticket creation failed'
    },
    stepper: {
        category: 'Category',
        description: 'Description',
        device: 'Device',
        title: 'Title',
        topic: 'Topic'
    },
    message: {
        attachment: 'Attachment',
        reply: 'Reply',
        placeholderMessage: 'Send response',
        sendReply: 'Send Reply',
        addFile: 'Add Files'
    },
    tab: {
        information: 'Information',
        messages: 'Messages'
    }
}
