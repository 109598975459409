import type { DeviceModel } from './DeviceModel'

export type DeviceCreateModel = Partial<DeviceModel>

export const defaultCreateDevice: DeviceCreateModel = {
    active: true,
    roles: ['device'],
    api_key_type: 'short',
    data: {}
}
