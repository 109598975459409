export type FiltersState = {
    event_id?: number
}

export enum EFiltersActions {
    SET_FILTER = 'setFilter'
}

export enum EFiltersMutations {
    SET_FILTERS = 'setFilters'
}
