<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import InputField from '@/components/Fields/InputField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import DeviceTypeSelector from '@/components/Selector/components/DeviceTypeSelector.vue'
import deviceConfigApi from '@/services/deviceConfig.service'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { DeviceTypesModel } from '@/types/models/DeviceTypesModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { computed, onMounted, ref } from 'vue'
import AddNewConfigKey from '../../components/AddNewConfigKey.vue'
import DeviceConfigKeysList from '../../components/DeviceConfigKeysList.vue'

type Props = {
    confirmLabel?: string
    data?: Partial<DeviceConfigModel>
    permission?: PermissionsEnums
    hasPermission?: boolean
    loading?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceConfigModel>): void
    (e: 'confirm'): void
}
const emit = defineEmits<Emits>()

const deviceTypes = ref<DeviceTypesModel[]>([])
const getDeviceType = () =>
    deviceConfigApi
        .getDeviceTypes({ per_page: -1 })
        .then(res => (deviceTypes.value = res as DeviceTypesModel[]))
onMounted(() => getDeviceType())

const submitted = ref(false)
const hasErrors = computed(() => ({
    deviceTypeError: submitted.value && !props.data?.name
}))

const handleClick = () => {
    submitted.value = true
    emit('confirm')
}

const handleNewData = (newData: DeviceTemplateKeysModel) => {
    if (!newData) return
    emit('change', {
        config_keys: [...(props.data?.config_keys ?? []), newData],
        template: [...(props.data?.template ?? []), newData.name]
    })
}

const handleRemoveKey = (row: DeviceTemplateKeysModel) =>
    emit('change', {
        config_keys: props.data?.config_keys?.filter(item => item.id !== row.id)
    })
</script>

<template>
    <StackLayout :gap="16" direction="column">
        <InformationBaseCard :label="$t('deviceConfig.template.title.informations')">
            <StackLayout direction="row" :gap="8">
                <PermissionWrapper :permission="permission">
                    <DeviceTypeSelector
                        :modelValue="data?.device_type_id"
                        @select="$emit('change', { device_type_id: $event.id })"
                        :hasError="hasErrors.deviceTypeError"
                        class="fit-content" />
                </PermissionWrapper>
                <InputField
                    :label="$t('deviceConfig.template.model.version')"
                    :modelValue="data?.version"
                    @change="$emit('change', { version: $event })" />
            </StackLayout>
        </InformationBaseCard>
        <InformationBaseCard :label="$t('deviceConfig.template.title.templateKeys')">
            <DeviceConfigKeysList
                :data="data?.config_keys"
                :permission="permission"
                @change="handleNewData"
                @delete="handleRemoveKey" />
        </InformationBaseCard>
        <PermissionWrapper :permission="permission" :hasPermission="hasPermission">
            <CreateButton
                :loading="loading"
                :label="confirmLabel ?? $t('deviceConfig.template.buttons.createTemplate')"
                @click="handleClick" />
        </PermissionWrapper>
        <PermissionWrapper :permission="permission">
            <InformationBaseCard :label="$t('deviceConfig.template.title.newKey')">
                <AddNewConfigKey
                    :loading="loading"
                    @create="handleNewData"
                    :editPermission="permission" />
            </InformationBaseCard>
        </PermissionWrapper>
    </StackLayout>
</template>
