<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { type IconType } from '@/types/components/IconType'
import { computed } from 'vue'
import StackLayout from '../Layouts/StackLayout.vue'
import { TypographyItem } from '../Typography'

type ButtonProps = {
    files?: File[]
    label?: string
    icon?: string | IconType
}

const props = defineProps<ButtonProps>()
type Emits = {
    (e: 'change', files?: File | File[]): void
}
const emit = defineEmits<Emits>()

const localFiles = computed({
    get: () => props.files,
    set: value => emit('change', value)
})
</script>

<template>
    <b-upload v-model="localFiles" v-bind="$attrs">
        <StackLayout class="button is-primary">
            <IconItem icon="upload" />
            <TypographyItem :label="$t('common.upload')" />
        </StackLayout>
    </b-upload>
</template>
