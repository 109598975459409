<script setup lang="ts">
import TooltipItem from '@/components/Tooltip/TooltipItem.vue'
import { EPosition } from '@/types/constants/PositionValues'
import { EStatusLabels } from '@/types/enum/StatusEnum'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { StackLayout } from '../Layouts'
import TypographyWithPrefixText from './TypographyWithPrefixText.vue'

const { t } = useI18n()

const props = defineProps<{
    prefix?: string
    suffix?: string
    label?: string | number | boolean
    tooltip?: string
    boldLabel?: boolean
    undefinedLabel?: string
    required?: boolean
    isResponsive?: boolean
}>()

const labelToStatus = computed(() => {
    if (isNullOrUndefined(props.label)) return props.undefinedLabel ?? t('common.undefined')
    return typeof props.label === 'number' || typeof props.label === 'string'
        ? props.label.toString() ?? t('common.undefined')
        : t(EStatusLabels[props.label ? 1 : 0])
})

const tooltipPosition = ref(isMobile() ? EPosition.TOP : EPosition.RIGHT)
isMobileEvent(e => (tooltipPosition.value = e.matches ? EPosition.TOP : EPosition.RIGHT))
</script>

<template>
    <StackLayout :gap="4" class="typography-prefix-stack" :isResponsive="isResponsive">
        <template v-if="tooltip">
            <slot />

            <span class="prefix-span" v-bind="$attrs">
                <TooltipItem :label="tooltip" class="typography-tooltip" multilined>
                    {{ prefix ? `${prefix}${required ? '*' : ''} :` : '' }}
                </TooltipItem>
                <slot name="edit">
                    <span :class="`${boldLabel ? 'bold' : ''} label-span`">
                        {{ `${labelToStatus}${suffix ?? ''}` }}
                    </span>
                </slot>
            </span>
        </template>
        <TypographyWithPrefixText
            v-else
            :prefix="prefix"
            :required="required"
            :boldLabel="boldLabel"
            :label="labelToStatus"
            :suffix="suffix">
            <slot />
            <template #edit>
                <slot name="edit" />
            </template>
        </TypographyWithPrefixText>
    </StackLayout>
</template>

<style scoped lang="scss">
.typography-prefix-stack {
    & :deep(.text) {
        white-space: nowrap;
    }
}

.prefix-span {
    gap: 4px;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        & .field {
            width: 100%;
        }
    }
}

.label-span {
    white-space: nowrap;
}

.typography-tooltip {
    & :deep(.tooltip-content) {
        left: unset;
        transform: unset;
    }
}
</style>
