import type { DetectionModel } from '@/types/models/DetectionModel'

export type SocketDeviceDetectionModel = {
    device_id: number
    detections: DetectionModel[]
}

export type SocketWrapperModel = {
    connected: boolean
    event_ids?: number[]
    checkpoint_ids?: number[]
    device_ids?: number[]
    maxEventDetections?: number
}

export type SocketCheckpointDetectionModel = {
    checkpoint_id: number
    detections: DetectionModel[]
}

export type SocketEventDetectionModel = {
    event_id: number
    detections: DetectionModel[]
}

export type SocketDetectionModel = {
    device_detections: SocketDeviceDetectionModel[]
    checkpoint_detections: SocketCheckpointDetectionModel[]
    event_detections: SocketEventDetectionModel[]
}

export const defaultSocketDetectionModel = {
    device_detections: [],
    checkpoint_detections: [],
    event_detections: []
}
