import { AUTH_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { ResetPwdModel } from '@/types/models/ResetPwdModel'
import type { UserDataModel, UserModel } from '@/types/models/UserModel'
import type { AxiosResponse } from 'axios'
import TokenService from './token.service'

const authApi = {
    login: (user: any) =>
        auth.post('/login', user).then((response: AxiosResponse<UserDataModel>) => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    location.reload()
                    return undefined
                }
            }
            TokenService.setUser(response.data)
            return response.data
        }),

    me: (): Promise<UserModel> =>
        auth
            .post('/me')
            .then(response => response.data)
            .catch(error => Promise.reject(error)),

    logout: () => TokenService.removeUser(),

    sendResetLink: (email: string) =>
        auth
            .post(`${AUTH_PREFIX}/email`, { email })
            .then(response => response)
            .catch(error => Promise.reject(error)),

    resetPassword: (reset: ResetPwdModel) =>
        auth
            .post(`${AUTH_PREFIX}/reset`, reset)
            .then(response => response)
            .catch(error => Promise.reject(error))
}

export default authApi
