<script setup lang="ts">
import { BaseButton, IconButton } from '@/components/Buttons'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseDialog } from '@/components/Modals/Dialog'
import { TypographyItem } from '@/components/Typography'
import { useIsImpersonate } from '@/hooks/useIsImpersonate'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { EColors } from '@/types/constants/ColorValues'
import type { UserModel } from '@/types/models/UserModel'
import { ref } from 'vue'

type Props = {
    user: UserModel
    withLabel?: boolean
}

const props = defineProps<Props>()

const icon = {
    label: 'people-arrows',
    type: EColors.DARK
}
const open = ref(false)
const openDialog = (value: boolean) => (open.value = value)
const handleImpersonateUser = () => {
    store
        .dispatch(`${EStoreModules.USER}/${EUserActions.IMPERSONATE_USER}`, props.user)
        .then(() => store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.ME}`))
    open.value = false
}

const currentlyImpersonate = useIsImpersonate()
</script>

<template>
    <StackLayout v-if="!currentlyImpersonate">
        <BaseButton
            v-if="withLabel"
            :label="$t('user.dialog.impersonateTitle')"
            :type="EColors.WARNING"
            @click="openDialog(true)"
            v-bind="$attrs" />
        <IconButton
            v-else
            :icon="icon"
            :type="EColors.WARNING"
            @click="openDialog(true)"
            v-bind="$attrs" />
        <BaseDialog
            :open="open"
            :title="$t('user.dialog.impersonateTitle')"
            :confirmLabel="$t('user.dialog.impersonate')"
            @confirm="handleImpersonateUser"
            @close="openDialog(false)">
            <template #content>
                <TypographyItem :label="$t('user.dialog.impersonateContent')" />
            </template>
        </BaseDialog>
    </StackLayout>
</template>
