export const MAX_LENGTH = 20

export const truncateFileName = (fileName: string) =>
    fileName.length > MAX_LENGTH ? `${fileName.substring(0, MAX_LENGTH)}...` : fileName

export const isDocumentPdf = (fileName: string) => fileName.toLowerCase().match(/\.pdf$/)

export const generateFormData = (data: object, documents: File[]) => {
    const formData = new FormData()

    formData.append('data', JSON.stringify(data))
    documents.forEach(file => {
        formData.append('documents[]', file)
    })
    return formData
}
