<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { moment } from '@/locales/langUtils'
import { EVENTS_ROUTES } from '@/router/routes'
import store from '@/store'
import { EEventActions } from '@/store/eventStore/EventStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultEventCreateModel, type EventCreateModel } from '@/types/models/EventCreateModel'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import EventCreateInformations from './Create/EventCreateInformations.vue'

const router = useRouter()
const clientId = computed(() => store.state.authentication.me.id)

const data = ref<EventCreateModel>({ ...defaultEventCreateModel, client_id: clientId.value ?? 0 })
const submitted = ref(false)

const updateData = (value: Partial<EventCreateModel>) => (data.value = { ...data.value, ...value })

const createEvent = () => {
    submitted.value = true
    if (errors.value.nameError || errors.value.startDateError || errors.value.endDateError) return
    store
        .dispatch(`${EStoreModules.EVENT}/${EEventActions.CREATE_EVENT}`, data.value)
        .then(res => (res.id ? router.push(EVENTS_ROUTES.buildUrl(res.id)) : null))
}

const errors = computed(() => ({
    nameError: submitted.value && !data.value.name,
    startDateError: submitted.value && !data.value.start_at,
    endDateError:
        submitted.value &&
        data.value.end_at &&
        moment.value(data.value.end_at).isBefore(data.value.start_at)
}))
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <CreateButton
            :loading="!!store.state.event.isLoading"
            :label="$t('event.createEvent')"
            @click="createEvent" />
        <StackLayout direction="column" :gap="8">
            <EventCreateInformations
                :data="data"
                @update="updateData"
                :submitted="submitted"
                :hasErrors="errors" />
        </StackLayout>
    </StackLayout>
</template>
