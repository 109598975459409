export enum EGuardName {
    API = 'api',
    USER = 'user',
    DEVICE = 'device'
}

export const EGuardNameValues = [EGuardName.API, EGuardName.USER, EGuardName.DEVICE]

export const EGuardNameLabels = {
    [EGuardName.API]: 'api',
    [EGuardName.USER]: 'user',
    [EGuardName.DEVICE]: 'device'
}
