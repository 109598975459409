<script setup lang="ts">
import { IconButton, ToggleDrawerButton } from '@/components/Buttons'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import MenuAccount from '@/components/Menu/MenuAccount.vue'
import MenuList from '@/components/Menu/MenuList.vue'
import { useUnfocus } from '@/hooks/useUnfocus'
import type { IconType } from '@/types/components/IconType'
import { EColors } from '@/types/constants/ColorValues'
import { isLight, isLightEvent, isMobileEvent, isTabletEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import MenuHomeLogo from '../../Menu/MenuHomeLogo.vue'
import SidebarMenuDrawer from '../SidebarMenuDrawer.vue'

const open = ref(false)
isMobileEvent(() => (open.value = false))
isTabletEvent(() => (open.value = false))

const click = () => (open.value = !open.value)

const showDropdown = ref(false)

const handleShowDropDown = (value: boolean) => (showDropdown.value = value)

const isLightType = ref(isLight() ? 'is-light' : 'is-dark')
isLightEvent(e => (isLightType.value = `is-${e.matches ? 'light' : 'dark'}`))

const userButtonIcon: IconType = { label: 'circle-user', type: EColors.LIGHT }

const menuAccountRef = ref<HTMLDivElement | null>(null)
useUnfocus(menuAccountRef, () => (showDropdown.value = false))
</script>

<template>
    <div class="navbar-top relative" v-bind="$attrs">
        <div class="align-start start">
            <ToggleDrawerButton :open="false" @click="click" />
        </div>
        <MenuHomeLogo class="logo" />

        <StackLayout direction="row" :gap="8">
            <div class="align-self-center end">
                <IconButton
                    :icon="userButtonIcon"
                    size="is-medium"
                    :type="EColors.PRIMARY"
                    @click="handleShowDropDown(!showDropdown)" />
                <div v-if="showDropdown" class="menu-account-div" ref="menuAccountRef">
                    <MenuList
                        class="primary-border flex align-items-center color-text menu-account">
                        <MenuAccount showToggles />
                    </MenuList>
                </div>
            </div>
        </StackLayout>
    </div>

    <SidebarMenuDrawer
        :overlay="true"
        :open="open"
        @open="value => (open = value)"
        @click="open = false"
        class="mobile-drawer-">
        <StackLayout class="home-button-stack space-between">
            <div class="align-self-center">
                <ToggleDrawerButton :open="open" @click="click" />
            </div>
            <MenuHomeLogo class="drawer-img logo" />
        </StackLayout>
        <template #footer-item>
            <slot />
        </template>
    </SidebarMenuDrawer>
</template>

<style lang="scss">
.menu-account-div {
    z-index: 10;
    .menu-account {
        z-index: 10;
        color: var(--color-text);

        a {
            padding: 0.8rem;
            min-width: 200px;
            justify-content: center;
        }
    }
}
.menu-div-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.hide-button {
    display: none;
}
.navbar-top {
    z-index: 1200;
    width: 100%;
    display: flex;
    position: fixed;
    background-color: var(--color-background);
    color: var(--color-text);
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid $primary;
}
.justify-center {
    justify-content: center;
}
.logo {
    width: auto;
    height: 50px;
}

.home-button-stack {
    border-bottom: 1px solid $primary;
    padding: 0.5em;
}

.drawer-img {
    img {
        height: 50px;
        width: auto;
    }
}

.menu-account {
    position: fixed;
    right: 10px;
    border-radius: 8px;
    transform: translateY(4px);
    flex-direction: column;
    background-color: var(--color-background);
}

.menu-account li:not(:last-of-type) {
    border-bottom: 1px solid $primary;
    width: 100%;
}

.mobile-menu-locale :deep(.selected-option) {
    height: 100%;
}
</style>
