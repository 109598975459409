<script setup lang="ts">
import DeviceCard from '@/components/DeviceComponents/Card/DeviceCard.vue'
import { GridLayout, StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import HrefWrapper from '@/components/Wrappers/HrefWrapper.vue'
import { useGridSize } from '@/hooks/useGridSize'
import { EGridSize } from '@/types/enum/ViewsUtilsEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'

type Props = {
    data: DeviceModel[]
    statuses: AlertSocketMessageModel[]
    detections?: SocketDeviceDetectionModel[]
    loading?: boolean
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    editPermission?: PermissionsEnums
    editButtonHref?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'click', device: DeviceModel): void
    (e: 'delete', device: DeviceModel): void
}
defineEmits<Emits>()

const gridSize = useGridSize({
    mobile: EGridSize.IS_MOBILE - 1,
    tablet: EGridSize.IS_TABLET - 1,
    desktop: EGridSize.IS_DESKTOP - 1,
    widescreen: EGridSize.IS_WIDESCREEN - 1
})

const editHasPermission = computed(
    () => props.editPermission && userHasPermission(props.editPermission)
)

const getDeviceStatus = (device: DeviceModel) => ({
    battery: device.battery,
    plugged: device.plugged,
    signal: device.signal,
    network_type: device.network_type
})
</script>

<template>
    <GridLayout v-if="data?.length" :maxItemPerRow="gridSize" :gap="8" class="relative">
        <LoadingSpinner :isLoading="!!loading" />
        <HrefWrapper
            v-for="(device, key) in data"
            :key="key"
            :href="`${editButtonHref}/${device.device_id ?? device.id}`"
            :hasPermission="editHasPermission"
            :class="`flex full-height color-text device-card ${
                !editHasPermission ? 'not-clickable' : ''
            }`"
            @click="$emit('click', device)"
            v-bind="$attrs">
            <DeviceCard
                :data="device"
                :status="{
                    status: parseInt(device.status as string),
                    ...getDeviceStatus(device),
                    ...(statuses?.find(item => item.device_id === device.device_id) ?? {})
                }"
                :detections="
                    detections?.find(item => item.device_id === (device.device_id ?? device.id))
                        ?.detections ?? []
                "
                :showNextEvent="showNextEvent"
                :showNextCheckpoint="showNextCheckpoint"
                class="full-width">
                <template #content>
                    <slot name="cardContent" v-bind="device" />
                </template>
            </DeviceCard>
        </HrefWrapper>
    </GridLayout>
    <StackLayout v-else class="align-self-center no-record">
        <LoadingSpinner :isLoading="!!loading" />
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>
