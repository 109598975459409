<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import AdministrationApiDetailView from '@/views/AdministrationView/Api/AdministrationApiDetailView.vue'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const isLoading = computed(() => store.state.api.isLoading)
const apiId = ref(route.params.id.toString())
const submitted = ref(false)

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })

const getApi = () =>
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.FETCH_API}`, apiId.value)
        .then(res => (data.value = res))

onMounted(() => {
    if (typeof apiId.value === 'string') getApi()
})

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const save = () => {
    submitted.value = true
    if (!data.value.name) return
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.UPDATE_API}`, {
            apiId: apiId.value,
            apiData: data.value
        })
        .then(() => (submitted.value = false))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <AdministrationApiDetailView
        :data="data"
        :buttonLabel="$t('common.saveModifications')"
        :editPermission="EAdminPerm.ADMIN_APIS"
        :loading="!!store.state.api.isLoading"
        @click="save"
        @update="updateApi" />
</template>
