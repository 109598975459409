<script setup lang="ts">
import { PrimaryButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import { TypographyItem } from '@/components/Typography'
import topicApi from '@/services/topic.service'
import { type CategoryModel } from '@/types/models/CategoryModel'
import { onMounted, ref } from 'vue'
import DividerItem from '../../Divider/DividerItem.vue'

type Emits = {
    (e: 'select', value: number): void
}
const emit = defineEmits<Emits>()

const categories = ref<CategoryModel[][]>([])
const getTopicsAndAddToList = (id?: number) =>
    topicApi.getTopicsWithoutPagination(id).then(res => {
        const existingLineIndex = categories.value.findIndex(
            currentItem => !!currentItem.find(x => x.id === id)
        )
        categories.value.splice(existingLineIndex + 1, categories.value.length - existingLineIndex)
        categories.value.push(res)
    })

onMounted(() => {
    getTopicsAndAddToList()
})

const selectedCategory = ref()
const handleSelectCategory = (category: CategoryModel) => {
    selectedCategory.value = category
    emit('select', category.id)
}
</script>

<template>
    <StackLayout direction="column" :gap="24" class="flex-wrap">
        <TypographyItem
            class="center-text is-size-4 color-text"
            :label="$t('ticket.create.title')" />
        <StackLayout
            :gap="8"
            v-for="(subCategory, keySelected) in categories"
            direction="row"
            :key="keySelected"
            class="flex-wrap">
            <DividerItem variant="middle" orientation="horizontal" />
            <StackLayout
                :gap="8"
                v-for="(lastRow, keySub) in subCategory"
                :key="keySub"
                direction="column"
                class="flex-wrap">
                <PrimaryButton
                    :label="lastRow.trans"
                    :icon-left="lastRow.icon"
                    @click="handleSelectCategory(lastRow)"
                    :class="`${
                        categories[keySelected + 1]?.find(item => item.parent_id === lastRow.id)
                            ? ''
                            : 'is-outlined'
                    }`" />
            </StackLayout>
        </StackLayout>
    </StackLayout>
</template>
