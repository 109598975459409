<script setup lang="ts">
import type { MenuItemType } from '@/types/MenuItem'
import { computed } from 'vue'
import MenuItem from './MenuItem.vue'
import MenuList from './MenuList.vue'

type Props = {
    items?: MenuItemType[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'click'): void
}
defineEmits<Emits>()
const items = computed(() => props.items)
</script>

<template>
    <b-menu>
        <MenuList>
            <slot name="home-item" />
            <MenuItem
                v-for="(item, key) in items"
                :key="key"
                :item="item"
                @click="$emit('click')"
                class="menu-list-item">
                <MenuItem
                    v-for="(itemChildren, keyChildren) in item.children"
                    :key="keyChildren"
                    :item="itemChildren"
                    @click="$emit('click')"
                    class="children-item" />
            </MenuItem>
            <slot name="footer-item" />
        </MenuList>
    </b-menu>
</template>

<style lang="scss">
.menu-list:first-child {
    border-top: 1px solid $primary;
}

.menu-list-item {
    border-bottom: 1px solid $primary;
}

.children-item {
    margin-left: 0.5rem;
}

.children-item:not(:last-child) {
    li > a {
        border-bottom: 1px solid $primary;
    }
}

.menu-list a {
    display: flex;
    gap: 4px;
    color: var(--color-text);
    align-items: center;
}
</style>
