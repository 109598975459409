<script setup lang="ts">
import { PrimaryButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import type { LinkDevice } from '@/types/components/LinkActions/LinkDevice'
import { ref, watch } from 'vue'
import type { OrganizationModel } from '../../../types/models/OrganizationModel'
import DeviceSelector from '../../Selector/components/DeviceSelector.vue'
import OrganizationSelector from '../../Selector/components/OrganizationSelector.vue'

type Props = {
    title: string
    confirmLabel: string
    organizationId?: number
    deviceId?: number
}
const props = defineProps<Props>()
type Emits = {
    (e: 'confirm', value: Partial<LinkDevice>): void
}
const emit = defineEmits<Emits>()

const isSubmitted = ref(false)
const open = ref(false)

const data = ref<Partial<LinkDevice>>({
    organizationId: props.organizationId,
    deviceId: props.deviceId
})
watch(
    [() => props.deviceId, () => props.organizationId],
    ([newDeviceId, newOrganizationId]) =>
        (data.value = {
            deviceId: newDeviceId ?? data.value.organizationId,
            organizationId: newOrganizationId ?? data.value.organizationId
        }),
    { immediate: true }
)

const confirmAction = () => {
    isSubmitted.value = true
    if (data.value.deviceId === undefined || data.value.organizationId === undefined) return
    emit('confirm', data.value)
    isSubmitted.value = false
    open.value = false
}

const handleSelect = (value?: OrganizationModel) => (data.value.organizationId = value?.id)
</script>

<template>
    <PrimaryButton :label="confirmLabel" @click="open = true" class="full-width" />
    <BaseDialog
        :title="title"
        :open="open"
        @confirm="confirmAction"
        @close="open = false"
        class="dialog-with-dropdown modal-selector-full-width">
        <template #content>
            <StackLayout direction="column" class="full-width" :gap="16">
                <DeviceSelector
                    v-if="!deviceId"
                    :label="$t('organization.model.deviceId')"
                    :selectedDevice="data.deviceId?.toString()"
                    @select="data.deviceId = $event?.device_id ?? $event?.id"
                    class="full-width" />
                <OrganizationSelector
                    v-if="!organizationId"
                    :organizationId="data?.organizationId"
                    @select="handleSelect" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>
