import {
    mdiNetworkStrength2,
    mdiNetworkStrength3,
    mdiNetworkStrength4,
    mdiNetworkStrengthOffOutline,
    mdiNetworkStrengthOutline
} from '@mdi/js'
import { EColors } from '../constants/ColorValues'

export enum ESignalLevel {
    NONE = 0,
    ONE = 1,
    TWO = 2,
    THREE = 3,
    FOUR = 4,
    FIVE = 5
}

export const ESignalLevelIcons = {
    [ESignalLevel.NONE]: { path: mdiNetworkStrengthOffOutline, type: EColors.DANGER },
    [ESignalLevel.ONE]: { path: mdiNetworkStrengthOutline, type: EColors.DANGER },
    [ESignalLevel.TWO]: { path: mdiNetworkStrength2, type: EColors.WARNING },
    [ESignalLevel.THREE]: { path: mdiNetworkStrength3, type: EColors.WARNING },
    [ESignalLevel.FOUR]: { path: mdiNetworkStrength4, type: EColors.SUCCESS },
    [ESignalLevel.FIVE]: { path: mdiNetworkStrength4, type: EColors.SUCCESS }
}
