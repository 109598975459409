import { AUTH_API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { RoleModel } from '@/types/models/RoleModel'
import { buildQueryUrl } from '@/utils/queryUtils'

const API_PREFIX = `${AUTH_API_PREFIX}/roles`

const roleApi = {
    getRole: (id: string) => auth.get(`${API_PREFIX}/${id}`),
    getRoles: (params?: Partial<PaginationType & any>): Promise<PaginationDataType<RoleModel[]>> =>
        auth.get(`${API_PREFIX}${buildQueryUrl(params)}`).then(response => response.data),
    createRole: (roleData: any) => auth.post(API_PREFIX, roleData).then(response => response.data),
    updateRole: (id: string, roleData: any) =>
        auth.patch(`${API_PREFIX}/${id}`, roleData).then(response => response.data),
    deleteRole: (id: string) => auth.delete(`${API_PREFIX}/${id}`).then(response => response.data)
}

export default roleApi
