import i18n from '@/locales/i18n'
import technicianService from '@/services/technician.service'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { TechnicianTicketModel } from '@/types/models/TechnicianTicketModel'
import type { PaginationDataType } from '@/types/PaginationType'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import {
    ETechnicianActions,
    ETechnicianMutations,
    type TechnicianState
} from './TechnicianStoreTypes'

const state: Ref<TechnicianState> = ref({
    tickets: {
        data: [],
        current_page: 1,
        last_page: 0,
        total: 0,
        to: 0,
        from: 0,
        per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
        isLoading: false
    }
})

const getters = {
    getTechniciansTickets: () => state.value.tickets.data
}

const actions = {
    async [ETechnicianActions.FETCH_TECHNICIAN_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        technicianId: number
    ) {
        commit(ETechnicianMutations.SET_LOADING, { isLoading: true, key: 'tickets' })
        return await technicianService
            .getTechnicianTicket(technicianId)
            .then(resp => {
                commit(ETechnicianMutations.SET_TECHNICIAN_TICKET, resp.data)
                setDocumentAndTitle(resp.data.object)
                return resp.data
            })
            .finally(() =>
                commit(ETechnicianMutations.SET_LOADING, { isLoading: false, key: 'tickets' })
            )
    },
    async [ETechnicianActions.FETCH_TECHNICIAN_TICKETS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: any
    ) {
        commit(ETechnicianMutations.SET_LOADING, { isLoading: true, key: 'tickets' })
        return await technicianService
            .getTechnicianTickets(params)
            .then(data => {
                commit(ETechnicianMutations.SET_TECHNICIAN_TICKETS, data.data)
                commit(ETechnicianMutations.SET_TOTAL, data)
                return data.data
            })
            .finally(() =>
                commit(ETechnicianMutations.SET_LOADING, { isLoading: false, key: 'tickets' })
            )
    },
    async [ETechnicianActions.CREATE_TECHNICIAN_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        technicianData: any
    ) {
        commit(ETechnicianMutations.SET_LOADING, { isLoading: true, key: 'tickets' })
        return await technicianService
            .createTechnicianTicket(technicianData)
            .then(resp => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: technicianData.name,
                    key: ETechnicianActions.CREATE_TECHNICIAN_TICKET
                })
                return resp.data
            })
            .finally(() =>
                commit(ETechnicianMutations.SET_LOADING, { isLoading: false, key: 'tickets' })
            )
    },

    async [ETechnicianActions.UPDATE_TECHNICIAN_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { technicianId, data }: { technicianId: number; data: TechnicianTicketModel }
    ) {
        commit(ETechnicianMutations.SET_LOADING, { isLoading: true, key: 'tickets' })
        return await technicianService
            .updateTechnicianTicket(technicianId, data)
            .then(resp => {
                commit(ETechnicianMutations.SET_TECHNICIAN_TICKET, resp.data)
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.object,
                    key: ETechnicianActions.UPDATE_TECHNICIAN_TICKET
                })
                return resp.data
            })
            .finally(() =>
                commit(ETechnicianMutations.SET_LOADING, { isLoading: false, key: 'tickets' })
            )
    },

    async [ETechnicianActions.DELETE_TECHNICIAN_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        technicianTicket: TechnicianTicketModel
    ) {
        commit(ETechnicianMutations.SET_LOADING, { isLoading: true, key: 'tickets' })
        return await technicianService
            .deleteTechnicianTicket(technicianTicket.id)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: technicianTicket.object,
                    key: ETechnicianActions.DELETE_TECHNICIAN_TICKET
                })
                return res
            })
            .finally(() =>
                commit(ETechnicianMutations.SET_LOADING, { isLoading: false, key: 'tickets' })
            )
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`technician.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`technician.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ETechnicianMutations.SET_TECHNICIAN_TICKETS](
        _: TechnicianState,
        tickets: TechnicianTicketModel[]
    ) {
        state.value.tickets.data = tickets
    },

    [ETechnicianMutations.SET_TECHNICIAN_TICKET](
        _: TechnicianState,
        ticket: TechnicianTicketModel
    ) {
        state.value.tickets.ticket = ticket
    },

    [ETechnicianMutations.SET_TOTAL](
        _: TechnicianState,
        { data, key }: { data: PaginationDataType<any>; key: keyof TechnicianState }
    ) {
        state.value[key].current_page = data.current_page
        state.value[key].total = data.total
        state.value[key].last_page = data.last_page
        state.value[key].to = data.to
        state.value[key].from = data.from
    },
    [ETechnicianMutations.SET_LOADING](
        _: TechnicianState,
        { isLoading, key }: { isLoading: boolean; key: keyof TechnicianState }
    ) {
        state.value[key].isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
