import auth from '@//helpers/auth'
import { AUTH_API_PREFIX } from '@/config/api'
import type { PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'

const API_PREFIX = `${AUTH_API_PREFIX}/permissions`

const permissionApi = {
    getPermission: (id: number) => auth.get(`${API_PREFIX}/${id}`).then(response => response.data),
    getPermissions: (params: Partial<PaginationType> & { guard_name: string }) => {
        const { guard_name, ...other } = params
        return auth
            .get(
                `${API_PREFIX}${buildQueryUrl({
                    per_page: -1,
                    ...other,
                    filters: { guard_name }
                })}`
            )
            .then(response => response.data)
    }
}

export default permissionApi
