<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { TIME_FORMAT } from '@/utils/dateUtils'
import moment from 'moment'
import { onBeforeUnmount, ref } from 'vue'

const useCurrentTime = () => {
    const currentTime = ref(moment().format(TIME_FORMAT.value))
    const updateCurrentTime = () => {
        currentTime.value = moment().format(TIME_FORMAT.value)
    }
    const updateTimeInterval = setInterval(updateCurrentTime, 1000)

    onBeforeUnmount(() => {
        clearInterval(updateTimeInterval)
    })
    return {
        currentTime
    }
}

const { currentTime } = useCurrentTime()
</script>

<template>
    <StackLayout direction="column" :gap="4" class="color-text">
        <h3 class="mb-0">
            {{ currentTime }}
        </h3>
        <h4 class="mt-0">
            {{ Intl.DateTimeFormat().resolvedOptions().timeZone }}
        </h4>
    </StackLayout>
</template>
