<script setup lang="ts">
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { computed, onMounted, ref, type InputHTMLAttributes, type PropType } from 'vue'
import CustomField from './CustomField.vue'

const DATEPICKER_WIDTH = 362

const props = defineProps({
    label: { type: String, required: false },
    type: Object as PropType<Colors>,
    placeholder: { type: String },
    modelValue: Date as PropType<Date | null>,
    required: Boolean,
    inputType: String as PropType<InputHTMLAttributes['type']>,
    hasError: Boolean,
    clearable: Boolean,
    errorText: String
})
const emit = defineEmits(['change'])

const localValue = computed({
    get: () => (props.modelValue ? props.modelValue : undefined),
    set: value => emit('change', value)
})

const computedType = computed(() =>
    props.hasError ? EColors.DANGER : props.type ?? EColors.PRIMARY
)

const datepicker = ref()
const dropdownMenuRef = computed(() => datepicker.value?.$el?.children?.[0].children?.[2])

const handleDropdownMenuSide = () => {
    const position =
        DATEPICKER_WIDTH + datepicker.value.$el.getBoundingClientRect().left - window.innerWidth
    dropdownMenuRef.value.style.right = position >= 0 ? 0 : 'unset'
    dropdownMenuRef.value.style.left = position >= 0 ? 'unset' : 0
}

onMounted(() => {
    datepicker.value?.$el?.addEventListener('click', handleDropdownMenuSide)
})
</script>

<template>
    <CustomField
        :label="label ?? ''"
        :message="errorText"
        :type="computedType"
        :required="required">
        <b-datepicker
            ref="datepicker"
            v-model="localValue"
            :placeholder="placeholder ?? label"
            icon="calendar-day"
            class="datepicker-class"
            :icon-right="clearable && localValue ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="localValue = undefined"
            nearby-selectable-month-days
            :first-day-of-week="1"
            :mobile-native="false"
            clearable
            editable
            v-bind="$attrs">
            <slot />
            <!-- <template #trigger>
                <SecondaryButton
                    class="delete secondary-button"
                    @click="$refs?.datepicker?.toggle?.()" />
            </template> -->
        </b-datepicker>
    </CustomField>
</template>

<style scoped lang="scss">
.datepicker {
    &:deep(.datepicker-header) {
        border-bottom: 1px solid $primary;
        & .select {
            & select {
                background-color: var(--color-background-secondary);
                color: var(--color-text);
                & option {
                    color: var(--color-text);
                }
            }
            &.is-empty select {
                color: var(--color-placeholder);
            }
        }
    }
    &:deep(.datepicker-footer) {
        border-top: 1px solid $primary;
    }

    & :deep(.dropdown-content) {
        max-width: v-bind(DATEPICKER_WIDTH);
        background-color: var(--color-background-secondary);
        border: 1px solid $primary;

        &.is-selectable {
            color: var(--color-text);
        }
    }

    & :deep(.datepicker-table) {
        & .datepicker-body {
            & .datepicker-cell {
                &.is-selectable {
                    color: var(--color-text);
                    &:hover:not(.is-selected) {
                        background-color: rgba($primary, 50%);
                        color: var(--color-text);
                    }
                    &.is-first-hovered,
                    &.is-last-hovered {
                        background-color: $primary;
                    }
                    &.is-within-hovered {
                        background-color: rgba($primary, 50%);
                    }
                }
                &.is-unselectable {
                    color: var(--color-background-secondary);
                }
            }
        }
    }
    &:deep(::placeholder) {
        color: var(--color-placeholder);
    }
    & :deep(.datepicker .dropdown-content) {
        background-color: var(--color-background-secondary);
        border: 1px solid $primary;
    }
    & :deep(.dropdown-menu) {
        padding-top: unset;
    }

    & :deep(select) {
        border-color: $primary;
    }
    & :deep(a) {
        border-color: $primary;
    }
    &:deep(.select:not(.is-multiple):not(.is-loading)::after) {
        border-color: $primary;
    }
}

.datepicker-class {
    background-color: var(--color-background-secondary);
    color: var(--color-text);

    &:deep(::placeholder) {
        color: var(--color-placeholder);
    }
    & .datepicker .dropdown-content {
        background-color: var(--color-background-secondary);
        border: 1px solid $primary;
    }
}
</style>
