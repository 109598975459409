<script setup lang="ts">
import { type SelectorObject } from '@/types/components/SelectorObject'
import { type Colors } from '@/types/constants/ColorValues'
import { computed, type PropType } from 'vue'
import CustomField from '../Fields/CustomField.vue'

const props = defineProps({
    options: { type: Array as PropType<SelectorObject[]> },
    label: { type: String, required: false },
    placeholder: String,
    modelValue: [String, Number],
    disabled: Boolean,
    type: Object as PropType<Colors>,
    errorText: String
})
const emit = defineEmits(['change'])

const modelValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})
</script>

<template>
    <CustomField :label="label ?? ''" :message="errorText" :type="type" class="selector-field">
        <b-select
            :placeholder="placeholder ?? label"
            v-model="modelValue"
            :class="`is-${type} ${disabled ? 'disabled' : ''}`"
            :disabled="disabled"
            message="Test">
            <slot name="option">
                <option v-if="!options?.length" disabled class="padding">
                    {{ $t('common.noOption') }}
                </option>
                <option
                    v-else
                    v-for="(option, key) in options"
                    :key="key"
                    :value="option.value"
                    :class="props.modelValue === option.value ? 'selected' : ''">
                    {{ option?.name ?? option?.label ?? option?.value }}
                </option>
            </slot>
        </b-select>
    </CustomField>
</template>

<style scoped lang="scss">
.selector-field {
    &:deep(.select) {
        width: 100%;
        & select {
            width: 100%;
            background-color: var(--color-background-secondary);
            color: var(--color-text);
            & option {
                background-color: var(--color-background-secondary);
                color: var(--color-text);
            }
            &.disabled {
                background-color: var(--color-border);
            }
        }
        &.is-empty select {
            color: var(--color-placeholder);
        }
    }
}
</style>
