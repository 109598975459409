export type LoadingState = {
    isLoading: boolean
}

export enum ELoadingGetters {
    GET_IS_LOADING = 'getIsLoading'
}

export enum ELoadingActions {
    IS_LOADING = 'isLoading',
    NOT_IS_LOADING = 'notIsLoading'
}

export enum ELoadingMutations {
    SET_GLOBAL_LOADING = 'setIsLoading'
}
