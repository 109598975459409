<script setup lang="ts">
import { TypographyWithPrefix } from '@/components/Typography'
import ShowableTypography from '@/components/Typography/ShowableTypography.vue'
import { computed } from 'vue'
import { isNullOrUndefined } from '../../../utils/validateUtils'

const props = defineProps<{
    label?: string
    tooltip?: string
    value?: string | number | boolean
    boldLabel?: boolean
    isInEditMode?: boolean
    showableElement?: boolean
    required?: boolean
    suffix?: string
}>()

const formattedLabel = computed(() =>
    !isNullOrUndefined(props.value)
        ? `${props.value?.toString()} ${
              !isNullOrUndefined(props.value) && props.suffix ? props.suffix : ''
          }`
        : undefined
)
</script>

<template>
    <TypographyWithPrefix
        :prefix="label"
        :label="formattedLabel"
        :tooltip="tooltip"
        :boldLabel="boldLabel"
        :class="`field-wrapper ${isInEditMode ? 'edit-field' : ''}`"
        :required="isInEditMode && required"
        v-bind="$attrs">
        <template #edit>
            <ShowableTypography
                v-if="!isInEditMode && showableElement && !!value"
                :label="formattedLabel"
                class="field-value" />
            <slot v-if="isInEditMode" />
        </template>
    </TypographyWithPrefix>
</template>

<style scoped lang="scss">
.edit-field {
    width: 100%;
    justify-content: space-between;
    & :deep(.prefix-span) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-flow: wrap;
        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            & .field {
                width: 100%;
            }
        }
    }
}
.field-wrapper {
    & :deep(.prefix-span) {
        align-items: center;
    }
    .edit-field {
        width: 100%;
        justify-content: space-between;
    }
}

.field-value {
    float: inline-end;
}
</style>
