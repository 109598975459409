import TokenService from '@/services/token.service'
import { onMounted, ref } from 'vue'

export const useIsImpersonate = () => {
    onMounted(() => window.addEventListener('impersonate', refreshImpersonateStatus))
    const isCurrentlyImpersonate = ref(!!TokenService.getImpersonateUser())
    const refreshImpersonateStatus = () =>
        (isCurrentlyImpersonate.value = !!TokenService.getImpersonateUser())

    return isCurrentlyImpersonate
}
