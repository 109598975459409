<script setup lang="ts">
import DividerItem from '@/components/Divider/DividerItem.vue'
import { StackLayout } from '@/components/Layouts'
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue'
import TabsComponent from '@/components/Tabs/TabsComponent.vue'
import type { ActionModel } from '@/types/models/ActionModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DeviceConfigurationLayout from './Detail/ConfigurationTab/DeviceConfigurationLayout.vue'
import DeviceDetectionsLayout from './Detail/DetectionsTab/DeviceDetectionsLayout.vue'
import DevicePastDetections from './Detail/DetectionsTab/DevicePastDetections.vue'
import DeviceDocumentsLayout from './Detail/DocumentsTab/DeviceDocumentsLayout.vue'
import DeviceHistoryLayout from './Detail/HistoryTab/DeviceHistoryLayout.vue'
import DeviceInformationLayout from './Detail/InformationsTab/DeviceInformationLayout.vue'

type Props = {
    storedData?: Partial<DeviceModel>
    data: Partial<DeviceModel>
    status?: AlertSocketMessageModel
    templateKeys: DeviceTemplateKeysModel[]
    actions?: ActionModel[]
    loading?: boolean
    editPermission?: PermissionsEnums
    hasPermission?: boolean
    noDetectionLink?: boolean
    tabsMultilined?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<DeviceModel>): void
    (e: 'save'): void
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const { t } = useI18n()

const tabs = computed(() => [
    { id: 'configuration', label: t('device.detail.configuration.configurationTitle') },
    { id: 'informations', label: t('device.detail.informations.informationsTitle') },
    { id: 'detections', label: t('device.detail.detections.detectionsTitle') },
    /* { id: 'documents', label: t('device.detail.documents.documentsTitle') }, */
    { id: 'history', label: t('device.detail.history.historyTitle') }
])

const updateData = (value: Partial<DeviceModel>) => emit('update', { ...props.data, ...value })
</script>

<template>
    <StackLayout direction="column" :gap="8" class="device-detail relative">
        <LoadingSpinner :isLoading="!!loading" />

        <TabsComponent :multiline="tabsMultilined" :tabs="tabs" class="relative">
            <template #configuration>
                <DeviceConfigurationLayout
                    ref="configurationRef"
                    :storedData="storedData"
                    :data="data"
                    :status="status"
                    :templateKeys="templateKeys"
                    :editPermission="editPermission"
                    :hasPermission="hasPermission"
                    @save="$emit('save')"
                    @update="updateData"
                    @refresh="$emit('refresh')">
                    <template #actions>
                        <slot name="actions" />
                    </template>
                </DeviceConfigurationLayout>
            </template>
            <template #informations>
                <DeviceInformationLayout
                    :data="data"
                    :status="status"
                    :actions="actions"
                    @update="updateData">
                    <template #informations>
                        <slot name="informationsContent" />
                    </template>
                    <template #actions>
                        <slot name="informationsActions" />
                    </template>
                </DeviceInformationLayout>
            </template>
            <template #detections>
                <StackLayout direction="column" :gap="16">
                    <DevicePastDetections
                        :deviceId="data.device_id ?? data.id!"
                        :noDetectionLink="noDetectionLink" />
                    <StackLayout direction="column" :gap="16">
                        <DeviceDetectionsLayout
                            :deviceId="data.device_id ?? data.id!"
                            :noDetectionLink="noDetectionLink" />
                        <DividerItem variant="middle" />
                    </StackLayout>
                </StackLayout>
            </template>
            <template v-if="false" #documents>
                <DeviceDocumentsLayout :data="data" @update="updateData" />
            </template>
            <template v-if="data?.device_id || data?.id" #history>
                <DeviceHistoryLayout :deviceId="data.device_id ?? data.id!" />
            </template>
        </TabsComponent>
    </StackLayout>
</template>

<style scoped lang="scss">
.device-detail {
    container: outlook-layout / inline-size;
}
@container outlook-layout (width < 600px) {
    .stack {
        overflow-wrap: anywhere;
    }
}
@container outlook-layout (width < 480px) {
    .stack {
        flex-direction: column;
        & :deep(.stack) {
            flex-direction: column;
        }
        & :deep(.tabs ul) {
            flex-direction: column;
        }
    }
}
</style>
