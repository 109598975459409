<script setup lang="ts">
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import { moment } from '@/locales/langUtils'
import type { PaginationDataType } from '@/types/PaginationType'
import type { TableEmits } from '@/types/components/CommonTableTypes'
import { ESortBy } from '@/types/enum/SortByEnum'
import { EStatus, EStatusLabels } from '@/types/enum/StatusEnum'
import type { ApiModel } from '@/types/models/ApiModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { DATE_FORMAT } from '@/utils/dateUtils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ApiFiltersType } from '../../types/filters/ApiFilters'
import HrefCreateButton from '../Buttons/HrefCreateButton.vue'
import ApiFilters from './ApiFilters.vue'

type Props = {
    data: PaginationDataType<ApiModel[]>
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums
    createButtonHref?: string
    editButtonHref?: string
    sort?: ESortBy
    filters?: ApiFiltersType
}
type Emits = TableEmits<ApiModel>

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const columns = {
    id: { field: 'id', label: t('api.model.id') },
    name: { field: 'name', label: t('api.model.name') },
    active: { field: 'active', label: t('api.model.active') },
    expiration_date: { field: 'expiration_date', label: t('api.model.expirationDate') }
}

const goToCreate = () => emit('goToCreate')
const goToDetail = (api: ApiModel) => emit('goToDetail', api)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)

const table = ref<any>([])
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <PermissionWrapper v-if="createButtonHref" hasPermission :permission="editPermission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('api.buttons.createApi')"
                @click="goToCreate" />
        </PermissionWrapper>
        <FullTableLayout :sortBy="sort" @changeSortBy="$emit('changeSortBy', $event)">
            <template #filters>
                <ApiFilters :filters="filters" />
            </template>

            <template #table>
                <BaseTable v-bind:ref="table" :data="data.data" :loading="data.isLoading">
                    <template #columns>
                        <TableColumn field="id" :label="columns['id'].label" />
                        <TableColumn field="name" :label="columns['name'].label" />
                        <TableColumn :label="columns['active'].label" v-slot="props">
                            {{
                                $t(
                                    EStatusLabels[
                                        props.row.active ? EStatus.ENABLED : EStatus.DISABLED
                                    ]
                                )
                            }}
                        </TableColumn>
                        <TableColumn :label="columns['expiration_date'].label" v-slot="props">
                            {{
                                props.row.expiration_date
                                    ? moment(props.row.expiration_date)
                                          .format(DATE_FORMAT)
                                          .toString()
                                    : $t('common.undefined')
                            }}
                        </TableColumn>
                        <TableActionColumn
                            @goTo="goToDetail"
                            :editPermission="editPermission"
                            :editButtonHref="editButtonHref"
                            @delete="$emit('delete', $event)"
                            :deletePermission="deletePermission" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :perPage="data.per_page"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    forcePropsCurrentPage
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
