<script setup lang="ts">
import IconButton from '@/components/Buttons/IconButton.vue'
import ClockItem from '@/components/Clock/ClockItem.vue'
import { StackLayout } from '@/components/Layouts'
import { TitleTypography } from '@/components/Typography'
import type { IconType } from '@/types/components/IconType'
import { EColors } from '@/types/constants/ColorValues'
import { REGEX_CREATE, REGEX_DETAIL, REGEX_GENERATE, REGEX_TABLE } from '@/utils/regexUtils'
import { isMobile, isTablet, isTabletEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()

const showDesktopItems = ref(!(isMobile() || isTablet()))
isTabletEvent(e => (showDesktopItems.value = !e.matches))

const props = defineProps<{ title?: string; subtitle?: string }>()
defineEmits<{ (e: 'click'): void }>()

const icon: IconType = { label: 'arrow-left-long', type: EColors.LIGHT }

const computedTitle = computed(
    () => props.title ?? route.meta?.title?.value ?? t(route.meta?.title?.value)
)
const computedSubtitle = computed(() => {
    if (props.subtitle || route.meta?.subtitle?.value)
        return props.subtitle ?? t(route.meta?.subtitle?.value as string)
    return route.name?.toString().match(REGEX_CREATE)
        ? t('menu.type.create')
        : route.name?.toString().match(REGEX_DETAIL)
        ? t('menu.type.detail')
        : route.name?.toString().match(REGEX_TABLE)
        ? t('menu.type.list')
        : route.name?.toString().match(REGEX_GENERATE)
        ? t('menu.type.generate')
        : ''
})
</script>

<template>
    <section class="full-width hero">
        <div class="full-width hero-body dashboard-title">
            <StackLayout direction="row" :gap="8" class="space-between">
                <StackLayout direction="row" :gap="8">
                    <IconButton
                        v-if="route.meta.cb || $attrs.click"
                        @click="route.meta.cb ?? $emit('click')"
                        :icon="icon"
                        :type="EColors.PRIMARY" />
                    <TitleTypography
                        :title="computedTitle"
                        :subtitle="computedSubtitle"
                        withDivider
                        :type="EColors.PRIMARY" />
                </StackLayout>
                <StackLayout
                    v-if="showDesktopItems"
                    direction="row"
                    :gap="8"
                    class="align-items-center center-text">
                    <ClockItem />
                </StackLayout>
            </StackLayout>
        </div>
    </section>
</template>

<style scoped lang="scss">
.dashboard-title {
    padding: 1rem;
    & :deep(.title:not(:last-child)) {
        margin-bottom: unset;
    }
}
</style>
