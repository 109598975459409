import i18n from '@/locales/i18n'

export enum EConfirmDialog {
    ADD = 'add',
    DELETE = 'delete',
    EDIT = 'edit',
    ARCHIVE = 'archive'
}

export const EConfirmDialogLabels = {
    [EConfirmDialog.ADD]: {
        title: (item: string) =>
            i18n.global.t('common.confirm.confirmTitle', {
                action: i18n.global.t('common.confirm.add'),
                item
            }),
        content: i18n.global.t('common.confirm.confirmContent', {
            action: i18n.global.t('common.confirm.add')
        }),
        action: i18n.global.t('common.confirm.confirmAction', {
            action: i18n.global.t('common.confirm.add')
        })
    },
    [EConfirmDialog.DELETE]: {
        title: (item: string) =>
            i18n.global.t('common.confirm.confirmTitle', {
                action: i18n.global.t('common.confirm.delete'),
                item
            }),
        content: i18n.global.t('common.confirm.confirmContent', {
            action: i18n.global.t('common.confirm.delete'),
            complement: i18n.global.t('common.confirm.deleteComplement')
        }),
        action: i18n.global.t('common.confirm.confirmAction', {
            action: i18n.global.t('common.confirm.delete')
        })
    },
    [EConfirmDialog.EDIT]: {
        title: (item: string) =>
            i18n.global.t('common.confirm.confirmTitle', {
                action: i18n.global.t('common.confirm.edit'),
                item
            }),
        content: i18n.global.t('common.confirm.confirmContent', {
            action: i18n.global.t('common.confirm.edit')
        }),
        action: i18n.global.t('common.confirm.confirmAction', {
            action: i18n.global.t('common.confirm.edit')
        })
    },
    [EConfirmDialog.ARCHIVE]: {
        title: (item: string) =>
            i18n.global.t('common.confirm.confirmTitle', {
                action: i18n.global.t('common.confirm.archive'),
                item
            }),
        content: i18n.global.t('common.confirm.confirmContent', {
            action: i18n.global.t('common.confirm.archive'),
            complement: i18n.global.t('common.confirm.archiveComplement')
        }),
        action: i18n.global.t('common.confirm.confirmAction', {
            action: i18n.global.t('common.confirm.archive')
        })
    }
}
