<script setup lang="ts">
import SocketDetectionsWrapper from '@/socket/SocketDetectionsWrapper.vue'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { SocketDetectionModel } from '@/types/socket/SocketDetectionModel'
import { computed, ref } from 'vue'

type Props = {
    events?: number[]
    checkpoints?: CheckpointModel[]
    devices?: DeviceModel[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'latestDetection', latestDetections: DetectionModel[]): void
}
const emit = defineEmits<Emits>()

const checkpointIds = computed(() => props.checkpoints?.map(item => item.id))
const deviceIds = computed(
    () => props.devices?.map(item => item.device_id).filter(item => !!item) as number[]
)

const detections = ref<SocketDetectionModel>({
    device_detections: [],
    checkpoint_detections: [],
    event_detections: []
})

const handleDetectionsUpdate = (
    value: SocketDetectionModel,
    latestDetections: DetectionModel[]
) => {
    detections.value = value
    emit('latestDetection', latestDetections)
}
</script>

<template>
    <slot v-bind="detections" />
    <SocketDetectionsWrapper
        :event_ids="events"
        :checkpoint_ids="checkpointIds"
        :device_ids="deviceIds"
        @update="handleDetectionsUpdate" />
</template>
