export const detectionsEn = {
    api: {
        error: {
            exportDetections: 'Error while generating the file',
            fetchDetections: 'Error while fetching reads'
        },
        success: {
            exportDetections: 'File has been successfully generated',
            fetchDetections: 'Erreur pendant la récupération des détections'
        }
    },
    actions: {},
    export: {
        allDetections: 'All reads',
        currentPage: 'Only the current page (Page {page})',
        device: 'Device'
    },
    filters: {
        bib: 'Bib',
        checkpoint: 'Checkpoint',
        startDate: 'From',
        start_at: 'From',
        end_at: 'To',
        endDate: 'To',
        device: 'Device',
        event: 'Event',
        eventId: 'Event ID',
        timerId: 'Timer ID'
    },
    model: {
        bib: 'Bib',
        checkpointId: 'Checkpoint',
        deviceId: 'Device ID',
        eventFilter: 'Event ID',
        id: 'ID',
        timerFilter: 'Timer ID',
        timestamp: 'Date'
    },
    noDetectionFound: 'No detection found',
    table: {
        bib: 'Bib',
        checkpointName: 'Checkpoint',
        eventName: 'Event',
        detections: 'Reads',
        deviceId: 'Device ID',
        deviceName: 'Device name',
        eventFilter: 'Event ID',
        id: 'ID',
        timerFilter: 'Timer ID',
        timestamp: 'Date'
    }
}
