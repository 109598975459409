export enum ETicketsPerm {
    TICKETS_VIEW = 'tickets_view',
    TICKETS_EDIT = 'tickets_edit',
    TICKETS_DELETE = 'tickets_delete'
}
export enum ETicketsEventsPerm {
    TICKETS_EVENTS_VIEW = 'tickets_events_view',
    TICKETS_EVENTS_EDIT = 'tickets_events_edit',
    TICKETS_EVENTS_DELETE = 'tickets_events_delete'
}
