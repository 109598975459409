export enum EPaginationOptions {
    DEFAULT = 10,
    SIZE_25 = 25,
    SIZE_50 = 50,
    SIZE_100 = 100
}

export const EPaginationOptionsValues = [
    EPaginationOptions.DEFAULT,
    EPaginationOptions.SIZE_25,
    EPaginationOptions.SIZE_50,
    EPaginationOptions.SIZE_100
]
