<script setup lang="ts">
import CheckboxField from '@/components/Fields/CheckboxField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { type AdvancedDeviceFilters } from '@/types/filters/DeviceFilters'

type Props = {
    filters?: Partial<AdvancedDeviceFilters>
}
defineProps<Props>()
type Emits = {
    (e: 'change', filters: Partial<AdvancedDeviceFilters>): void
}
const emit = defineEmits<Emits>()

const handleAdvancedFilters = (value: Partial<AdvancedDeviceFilters>) =>
    emit('change', { ...value })
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <CheckboxField
            :label="$t('device.filters.lowBattery')"
            :value="filters?.lowBattery"
            @click="handleAdvancedFilters({ lowBattery: $event ? $event : undefined })"
            class="responsive-width" />
        <CheckboxField
            :label="$t('device.filters.lowSignal')"
            :value="filters?.lowSignal"
            @click="handleAdvancedFilters({ lowSignal: $event ? $event : undefined })"
            class="responsive-width" />
    </StackLayout>
</template>
