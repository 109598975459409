<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { CreateButton, SecondaryButton } from '.'
import { StackLayout } from '../Layouts'

const { t } = useI18n()

type Props = {
    saveLabel?: string
    editLabel?: string
    isInEditMode?: boolean
    disabled?: boolean
    loading?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'save'): Promise<boolean>
    (e: 'edit', value: boolean): void
}
const emit = defineEmits<Emits>()

const localEditMode = computed({
    get: () => props.isInEditMode,
    set: value => {
        emit('edit', value)
        return value
    }
})

const label = computed(() =>
    localEditMode.value
        ? props.saveLabel ?? t('common.saveModifications')
        : props.editLabel ?? t('common.editMode')
)

const editMode = () => {
    emit('edit', !localEditMode.value)
    localEditMode.value = !localEditMode.value
}

const handleSaveButton = async () => {
    if (localEditMode.value) return emit('save')
    editMode()
}
</script>

<template>
    <StackLayout direction="row" :gap="8" class="align-self-end" isResponsiveSmallScreen>
        <SecondaryButton
            v-if="localEditMode"
            class="fit-content place-self-end"
            :label="$t('common.cancel')"
            @click="editMode" />
        <CreateButton
            :label="label"
            @click="handleSaveButton"
            :disabled="localEditMode && disabled"
            :loading="loading" />
    </StackLayout>
</template>
