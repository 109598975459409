<script setup lang="ts">
import { ToggleButtonField } from '@/components/Fields'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import EditableFieldWrapper from './EditableFieldWrapper.vue'

const props = defineProps<{
    label?: string
    tooltip?: string
    value?: boolean
    boldLabel?: boolean
    isInEditMode?: boolean
    showableElement?: boolean
    required?: boolean
    errorText?: string
}>()
defineEmits<{ (e: 'change', value: boolean): void }>()

const { t } = useI18n()
const valueLabel = computed(() => t(`common.${props.value ? 'yes' : 'no'}`))
</script>

<template>
    <EditableFieldWrapper
        :label="label"
        :tooltip="tooltip"
        :value="valueLabel"
        :boldLabel="boldLabel"
        :showableElement="showableElement"
        :isInEditMode="isInEditMode">
        <ToggleButtonField
            :modelValue="value"
            @change="$emit('change', $event)"
            :required="required"
            :errorText="errorText"
            v-bind="$attrs" />
    </EditableFieldWrapper>
</template>
