import { API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'
import type { TopicModel } from '../types/models/TopicModel'

const TOPIC_API = `${API_PREFIX}/topics`

const topicApi = {
    getTopic: (id: number) => auth.get(`${TOPIC_API}/${id}`).then(response => response.data),
    getTopics: (params?: Partial<PaginationType>) =>
        auth.get(`${TOPIC_API}${buildQueryUrl(params)}`).then(response => response.data),
    createTopic: (topicData: Partial<TopicModel>) =>
        auth.post(TOPIC_API, topicData).then(response => response.data),
    updateTopic: (id: number, topicData: Partial<TopicModel>) =>
        auth.put(`${TOPIC_API}/${id}`, topicData).then(response => response.data),
    deleteTopic: (id: number) => auth.delete(`${TOPIC_API}/${id}`).then(response => response.data),

    getTopicsWithoutPagination: (id?: number) =>
        auth.get(`${TOPIC_API}/parent/${id ?? ''}`).then(response => response.data)
}

export default topicApi
