<script setup lang="ts">
import InputField from '@/components/Fields/InputField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { defaultAvailableLangs, type AvailableLangs } from '@/locales/i18n'

type Props = {
    value?: Record<string, string>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Record<string, string>): void
}
const emit = defineEmits<Emits>()

const handleChangeDescription = (lang: string, value: string) => {
    emit('change', {
        ...(props.value ? { ...props.value, [lang]: value } : { [lang]: value })
    })
}
</script>
<template>
    <StackLayout :gap="8" direction="row">
        <InputField
            v-for="(lang, key) in Object.keys(defaultAvailableLangs)"
            :key="key"
            :label="lang"
            :modelValue="value?.[lang as keyof AvailableLangs<string>]"
            @change="handleChangeDescription(lang, $event)" />
    </StackLayout>
</template>
