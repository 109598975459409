import { defaultAddressModel, type AddressModel } from './AddressModel'

export type OrganizationModel = {
    id: number
    name: string
    social_reason: string
    address: AddressModel
}

export const defaultOrganizationModel: OrganizationModel = {
    id: 0,
    name: '',
    social_reason: '',
    address: { ...defaultAddressModel }
}

export type OrganizationDataModel = OrganizationModel & {
    permissions: string[]
    roles: string[]
}
