<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import userApi from '@/services/user.service'
import type { SelectorObject } from '@/types/components/SelectorObject'
import type { TicketModel } from '@/types/models/TicketModel'
import type { UserModel } from '@/types/models/UserModel'
import { onMounted, ref } from 'vue'

type Props = {
    filters?: Partial<TicketModel>
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<TicketModel>): void
}
const emit = defineEmits<Emits>()

const usersList = ref<SelectorObject[]>([])

onMounted(() =>
    userApi.getUsers().then(res => {
        usersList.value = res.data.map((user: UserModel) => ({
            name: user.name,
            value: user.id
        }))
    })
)

const selectedUser = ref<SelectorObject>()
const handleSelect = (value?: SelectorObject) => {
    selectedUser.value = value
    emit('change', { user_id: value?.value as number })
}
</script>

<template>
    <AutocompleteField
        :label="$t('technician.ticket.filter.user')"
        :placeholder="$t('technician.ticket.filter.user')"
        :modelValue="usersList.find(item => item.value === filters?.user_id)?.name"
        :options="usersList"
        @select="handleSelect" />
</template>
