import i18n from '@/locales/i18n'
import { computed } from 'vue'

export enum EKeyCategory {
    AUTH = 'authentication',
    CONNECTIVITY = 'connectivity',
    FILTER_1 = 'filter1',
    FILTER_2 = 'filter2',
    RFID = 'rfid',
    INFORMATIONS = 'informations',
    CONFIGURATION = 'configuration',
    MISC = 'misc',
    SPECIFIC = 'specific'
}

export const EKeyCategoryValues = [
    EKeyCategory.FILTER_1,
    EKeyCategory.FILTER_2,
    EKeyCategory.AUTH,
    EKeyCategory.CONNECTIVITY,
    EKeyCategory.RFID,
    EKeyCategory.INFORMATIONS,
    EKeyCategory.CONFIGURATION,
    EKeyCategory.MISC,
    EKeyCategory.SPECIFIC
]

export const EKeyCategoryLabels = computed(() => ({
    [EKeyCategory.AUTH]: i18n.global.t('enum.keyCategory.authentication'),
    [EKeyCategory.CONNECTIVITY]: i18n.global.t('enum.keyCategory.connectivity'),
    [EKeyCategory.RFID]: i18n.global.t('enum.keyCategory.rfid'),
    [EKeyCategory.INFORMATIONS]: i18n.global.t('enum.keyCategory.informations'),
    [EKeyCategory.CONFIGURATION]: i18n.global.t('enum.keyCategory.configuration'),
    [EKeyCategory.MISC]: i18n.global.t('enum.keyCategory.misc'),
    [EKeyCategory.SPECIFIC]: i18n.global.t('enum.keyCategory.specific'),
    [EKeyCategory.FILTER_1]: i18n.global.t('enum.keyCategory.filter1'),
    [EKeyCategory.FILTER_2]: i18n.global.t('enum.keyCategory.filter2')
}))
