<script setup lang="ts">
import AddressItem from '@/components/Address/AddressItem.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import type { AddressModel } from '@/types/models/AddressModel'
import { ref } from 'vue'

type Props = {
    address?: AddressModel
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<AddressModel>): void
}
defineEmits<Emits>()
const isInEditMode = ref(false)
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <InformationBaseCard
            class="full-width input-information-card"
            :label="$t('organization.informations.general')">
            <AddressItem
                :address="address"
                :isInEditMode="isInEditMode"
                @change="$emit('change', $event)" />
        </InformationBaseCard>
    </StackLayout>
</template>
