<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { ref, watch } from 'vue'

type Props = {
    device?: Partial<DeviceModel>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): Promise<boolean>
}
const emit = defineEmits<Emits>()

const localData = ref({
    ...props.device,
    event_id: props.device?.event?.id
})
watch(
    () => props.device?.event,
    newEvent => (localData.value.event_id = newEvent?.id)
)

const handleConfirm = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_API_DEVICE}`, {
            deviceId: props.device?.device_id ?? props.device?.id,
            deviceData: localData.value
        })
        .then(() => {
            emit('refresh')
            open.value = false
            localData.value.checkpoint_id = undefined
        })

const handleChange = (value: { checkpoint_id?: number; event_id?: number }) =>
    (localData.value = { ...localData.value, ...value })

const open = ref(false)
</script>

<template>
    <PrimaryButton
        @click="open = true"
        :label="$t('device.actions.reassignCheckpoint')"
        v-bind="$attrs" />
    <BaseDialog
        :title="$t('device.actions.reassignCheckpoint')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout :gap="8">
                <EventCheckpointAutocomplete
                    :event="localData?.event_id === device?.event?.id ? device?.event : undefined"
                    :event_id="localData?.event_id"
                    :checkpoint_id="localData?.checkpoint_id"
                    @change="handleChange" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.event-checkpoint-component {
    & :deep(.dropdown-content) {
        position: fixed;
        display: flex;
        flex-flow: wrap;
    }
}
</style>
