<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import AttachmentsItem from '@/components/Message/AttachmentsItem.vue'
import MessageList from '@/components/Message/MessageList.vue'
import replyApi from '@/services/reply.service'
import type { ReplyModel } from '@/types/models/ReplyModel'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const ticketId = computed(() => route.params.id.toString())

const attachmentDocuments = ref([])

const data = ref<ReplyModel[]>([])

const getRepliesUsers = () => {
    replyApi.getRepliesWithDoc(parseInt(ticketId.value)).then(res => {
        data.value = res.data
    })
}
onMounted(() => getRepliesUsers())
</script>

<template>
    <StackLayout :gap="8">
        <MessageList :replies="data" class="full-width" />

        <AttachmentsItem :documents="attachmentDocuments" />
    </StackLayout>
</template>
