<script setup lang="ts">
import AnnouncementsLayout from '@/components/Announcements/AnnouncementLayout.vue'
import { mockupAdminAnnouncements } from '@/mockupData/AdminData'
import { ref } from 'vue'

const announcements = ref(mockupAdminAnnouncements)
</script>

<template>
    <AnnouncementsLayout :announcements="announcements" />
</template>

<style scoped lang="scss">
.announcements-layout {
    overflow-x: auto;
    padding-bottom: 0.5em;
}
</style>
