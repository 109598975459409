<script setup lang="ts">
import { CardContent, CardFooter, CardHeader } from '@/components/Cards'
import { ref } from 'vue'

const cardRef = ref<HTMLDivElement | null>(null)
defineExpose({ cardRef })
</script>

<template>
    <div ref="cardRef" class="card">
        <CardHeader v-if="$slots['title']">
            <slot name="title" />
        </CardHeader>
        <CardContent>
            <slot name="content" />
        </CardContent>
        <CardFooter @dragover.prevent v-if="$slots['footer']">
            <slot name="footer"></slot>
        </CardFooter>
    </div>
</template>

<style scoped lang="scss">
.card {
    & :deep(.card-header-title) {
        color: var(--color-text);
    }
    & :deep(.card-content) {
        color: var(--color-text);
    }
    & :deep(.card-footer) {
        border-top: 1px solid $primary;
        bottom: 0;
        position: relative;
        width: 100%;
    }
}
</style>
