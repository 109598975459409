<script setup lang="ts">
import { ADMIN_ROLES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { ERoleActions } from '@/store/roleStore/RoleStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EGuardNameValues } from '@/types/enum/GuardNameEnum'
import { defaultRoleModel, type RoleModel } from '@/types/models/RoleModel'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import type { SelectorObject } from '../../types/components/SelectorObject'
import AutocompleteField from '../Selector/AutocompleteField.vue'
import RolesDetail from './components/RolesDetail.vue'

const router = useRouter()

const data = ref<Partial<RoleModel>>({ ...defaultRoleModel })

const updateRole = (value: Partial<RoleModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(`${EStoreModules.ROLE}/${ERoleActions.CREATE_ROLE}`, data.value)
        .then(() => router.push(ADMIN_ROLES_ROUTES.baseUrl)) // TODO : Alerte

const options: SelectorObject[] = EGuardNameValues.map(value => ({ value }))
</script>

<template>
    <RolesDetail
        :data="data"
        :buttonLabel="$t('common.create')"
        hasPermission
        :loading="store.state.role.isLoading"
        @click="create"
        @update="updateRole">
        <AutocompleteField label="Guard name" :modelValue="data.guard_name" :options="options" />
    </RolesDetail>
</template>
