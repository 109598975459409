<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import BaseDialog from '@/components/Modals//Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ref } from 'vue'

type Props = {
    organizationId?: number
    deviceId?: number
    organizationName: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)

const detachDevice = () =>
    store
        .dispatch(
            `${EStoreModules.ORGANIZATION}/${EOrganizationActions.DETACH_DEVICE_TO_ORGANIZATION}`,
            {
                organizationId: props.organizationId,
                deviceId: props.deviceId
            }
        )
        .then(() => (open.value = false))
        .finally(() => emit('refresh'))
</script>

<template>
    <FullScreenLoader :isLoading="store.state.organization.isLoading" />

    <PrimaryButton
        :label="$t('organization.buttons.dissociateDevice')"
        @click="open = true"
        class="full-width" />
    <BaseDialog
        :title="$t('organization.title.dissociateDeviceTitle')"
        :open="open"
        @confirm="detachDevice"
        @close="open = false"
        class="dialog-with-dropdown modal-selector-full-width">
        <template #content>
            <StackLayout direction="column" class="full-width" :gap="16">
                <TypographyItem
                    :label="
                        $t('organization.admin.actions.dissociateDeviceMessage', {
                            name: organizationName
                        })
                    " />
            </StackLayout>
        </template>
    </BaseDialog>
</template>
