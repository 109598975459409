import { random } from 'lodash'

export const generateRandomString = (length?: number) =>
    window
        .btoa(
            String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array((length ?? 5) * 2)))
        )
        .replace(/[+/]/g, '')
        .substring(0, length)

export const roundedRandom = () => Math.round(Math.random())

export const roundRandomBetween = (min: number, max: number) => random(min, max)
