<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import type { IconType } from '@/types/components/IconType'
import { EColors } from '@/types/constants/ColorValues'
import { EConfirmDialog } from '@/types/enum/ConfirmDialogEnum'

type Props = {
    icon?: string
    type?: EConfirmDialog.ARCHIVE | EConfirmDialog.DELETE
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void
}>()

const iconObject: IconType = {
    label: props.icon ?? props.type === EConfirmDialog.ARCHIVE ? 'box-archive' : 'trash',
    position: 'start',
    type: EColors.LIGHT
}

const handleClick = (e: MouseEvent) => {
    e.stopImmediatePropagation()
    emit('click', e)
}
</script>

<template>
    <IconButton
        :icon="iconObject"
        :type="EColors.DANGER"
        class="delete-button"
        @click="handleClick"
        v-bind="$attrs" />
</template>

<style scoped lang="scss">
.delete-button {
    & :deep(span:hover) {
        background-color: inherit;
    }
}
</style>
