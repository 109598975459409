import { EDeviceType } from '../enum/DeviceTypeEnum'
import { EStatus } from '../enum/StatusEnum'

export type OrganizationOnboardingModel = {
    name: string
    timer_id?: string
}
export type UserOnboardingModel = {
    name: string
    email: string
}

export type OnboardingModel = {
    devices: DeviceOnboardingModel[]
    organization: OrganizationOnboardingModel
    user: UserOnboardingModel
    validated?: EStatus
}
export type DeviceOnboardingModel = {
    active: boolean
    api_key_type: 'short'
    device_type_id?: EDeviceType
    device_type_name?: string
    imei?: [number | undefined, number | undefined]
    name: string
    roles: ['device']
    serial_number?: string
}

export const defaultDeviceOnboardingModel: DeviceOnboardingModel = {
    active: !!EStatus.DISABLED,
    api_key_type: 'short',
    name: '',
    roles: ['device']
}

export enum EOnboardingDeviceType {
    URTIME_800 = 1,
    URTIME_150 = 3,
    MICRO = 4,
    LOOPCASE = 5,
    NANO = 7,
    NANO_SAT = 9,
    MODULE_SAT = 13
}
