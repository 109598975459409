<script setup lang="ts">
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import type { UserModel } from '@/types/models/UserModel'
import { PrimaryButton } from '../../Buttons'

type Props = {
    user: UserModel
}
const props = defineProps<Props>()

const handleDeactivate = () =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
        data: { ...props.user, active: 0 },
        userId: props.user.user_id
    })
</script>

<template>
    <PrimaryButton :label="$t('user.actions.desactiveAction')" @click="handleDeactivate" />
</template>
