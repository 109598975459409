import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import { getPageSize } from '@/utils/routeUtils'

export type PaginationType = {
    page: number
    per_page: number
}

export type PaginationDataType<T> = {
    data: T
    current_page: number
    from: number
    last_page: number
    per_page: number
    to: number
    total: number

    isLoading?: boolean
}

export const defaultPaginationDataType: PaginationDataType<any> = {
    current_page: 1,
    from: 0,
    last_page: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    to: 0,
    total: 0,
    data: []
}
