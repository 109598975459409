<script setup lang="ts">
import NoticeItem from '@/components/Alerts/NoticeItem.vue'
import CreateButton from '@/components/Buttons/CreateButton.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import FullScreenLoader from '@/components/LoadingSpinner/FullScreenLoader.vue'
import { BaseDialog } from '@/components/Modals/Dialog'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import { LOGIN_URL } from '@/router'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceOnboardingModel, OnboardingModel } from '@/types/models/OnboardingModel'
import { REGEX_EMAIL } from '@/utils/regexUtils'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TypographyItem from '../../components/Typography/TypographyItem.vue'
import DevicesStepView from './DevicesStep/DevicesStepView.vue'
import ValidatedDeviceTable from './DevicesStep/components/ValidatedDeviceTable.vue'
import OrganizationStepView from './OrganizationStep/OrganizationStepView.vue'
import UserStepView from './UserStep/UserStepView.vue'

const confirmDialogOpen = ref(false)
const submitted = ref(false)
const router = useRouter()

const route = useRoute()
const uuid = computed(() => route.query.uuid?.toString())
const onboardingIsLoading = computed(() => store.state.authentication.isLoading)

const openConfirmDialog = () => {
    submitted.value = true
    if (Object.values(hasErrors.value).filter(item => !!item).length) return
    confirmDialogOpen.value = true
}
const validate = () => {
    if (uuid.value)
        return store
            .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.VALIDATE_ONBOARDING}`, {
                uuid: uuid.value,
                data: data.value
            })
            .then(() => router.push(LOGIN_URL))
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.ONBOARDING}`, data.value)
        .then(() => router.push(LOGIN_URL))
}
const data = ref<OnboardingModel>({
    organization: {
        name: '',
        timer_id: undefined
    },
    user: {
        name: '',
        email: ''
    },
    devices: []
})

onMounted(() => {
    if (uuid.value)
        store
            .dispatch(
                `${EStoreModules.ORGANIZATION}/${EOrganizationActions.GET_ONBOARDING}`,
                uuid.value
            )
            .then(res => (data.value = res))
})

const handleAddDevice = (value: DeviceOnboardingModel) => data.value.devices.push(value)
const handleDeleteDevice = (value: number) => data.value.devices.splice(value, 1)

const hasErrors = computed(() => ({
    organizationName: submitted.value && !data.value.organization.name.length,
    userName: submitted.value && !data.value.user.name.length,
    userEmail: submitted.value && !data.value.user.email.match(REGEX_EMAIL)
}))
</script>

<template>
    <NoticeItem />
    <FullScreenLoader :isLoading="onboardingIsLoading" />
    <StackLayout direction="column" class="full-width onboarding-view" :gap="16">
        <TitleTypography
            :title="$t(`authentication.onboarding.${uuid ? 'validateTitle' : 'title'}`)" />

        <StackLayout direction="row" class="full-width" :gap="16">
            <InformationBaseCard
                :label="$t(`authentication.onboarding.organizationStep.title`)"
                class="full-width">
                <OrganizationStepView
                    :data="data.organization"
                    @change="data.organization = { ...data.organization, ...$event }"
                    :organizationNameError="hasErrors.organizationName" />
            </InformationBaseCard>
            <InformationBaseCard
                :label="$t('authentication.onboarding.userStep.title')"
                class="full-width">
                <UserStepView
                    :data="data.user"
                    @change="data.user = { ...data.user, ...$event }"
                    :hasErrors="{
                        userName: hasErrors.userName,
                        userEmail: hasErrors.userEmail
                    }" />
            </InformationBaseCard>
        </StackLayout>
        <InformationBaseCard :label="$t('authentication.onboarding.devicesStep.title')">
            <StackLayout direction="row" :gap="12" class="full-width">
                <DevicesStepView @add="handleAddDevice" class="full-width" />
                <StackLayout
                    v-if="data.devices.length"
                    direction="column"
                    :gap="8"
                    class="full-width">
                    <ValidatedDeviceTable :devices="data.devices" @delete="handleDeleteDevice" />
                </StackLayout>
            </StackLayout>
        </InformationBaseCard>
        <TypographyItem
            v-if="!!data.validated"
            :label="$t('authentication.onboarding.validated')"
            class="text-end" />
        <CreateButton
            :label="$t(`authentication.onboarding.${uuid ? 'validateButton' : 'sendButton'}`)"
            :loading="!!store.state.organization.isLoading"
            @click="openConfirmDialog" />
    </StackLayout>
    <BaseDialog :open="confirmDialogOpen" @close="confirmDialogOpen = false" @confirm="validate">
        <template #content>{{ $t('authentication.onboarding.confirmContent') }}</template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.onboarding-view {
    padding: 8px;
    & :deep(.input) {
        background-color: var(--color-background-secondary);
    }
    & :deep(.field.is-floating-label .label::before) {
        background-color: var(--color-background-secondary);
    }
}

.text-end {
    text-align: end;
}
</style>
