import type { UserDataModel, UserModel } from '@/types/models/UserModel'

export type AuthState = {
    loggedIn?: boolean
    userData: UserDataModel | null
    me: UserModel
    accessToken?: string
    isLoading: boolean
}

export enum EAuthActions {
    ME = 'me',
    LOGIN = 'login',
    LOGOUT = 'logout',
    FORGOT_PASSWORD = 'forgotPassword',
    RESET_PASSWORD = 'resetPassword',
    REFRESH_TOKEN = 'refreshToken'
}

export enum EAuthMutations {
    LOGIN_REQUEST = 'loginRequest',
    LOGIN_SUCCESS = 'loginSuccess',
    LOGIN_FAILURE = 'loginFailure',
    LOGOUT = 'logout',
    REFRESH_TOKEN = 'refreshToken',
    SET_USER = 'setUser',
    SET_ME = 'setMe',
    SET_IS_LOADING = 'setIsLoading'
}
