<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import { ADMIN_ORGANIZATION_USER_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultUserModel, type UserModel } from '@/types/models/UserModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const submitted = ref(false)
const isLoading = computed(() => store.state.user.isLoading)
const organizationId = ref(route.params.organization_id.toString())
const userValue = ref<UserModel>({ ...defaultUserModel })

const updateUserValue = (value: Partial<UserModel>) =>
    (userValue.value = { ...userValue.value, ...value })

const createUser = () => {
    submitted.value = true
    if (!userValue.value.email || !userValue.value.name) return
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_USER}`, {
            userData: userValue.value,
            organizationId: organizationId.value
        })
        .then(res => {
            submitted.value = false
            router.replace(
                ADMIN_ORGANIZATION_USER_ROUTES(parseInt(organizationId.value)).buildUrl(res.id)
            )
        })
}
</script>

<template>
    <ApiDetailView
        :data="userValue"
        :buttonLabel="$t('common.create')"
        :editPermission="EAdminPerm.ADMIN_ORGANIZATIONS"
        hasPermission
        isCreateMode
        :loading="isLoading"
        @update="updateUserValue"
        @click="createUser" />
</template>
