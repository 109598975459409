<script setup lang="ts">
import { PrimaryButton, SecondaryButton } from '@/components/Buttons'
import BaseCard from '@/components/Cards/BaseCard.vue'
import LocaleDropdown from '@/components/Selector/LocaleDropdown.vue'
import { moment } from '@/locales/langUtils'
import { isLight, setCurrentThemeMode } from '@/utils/viewsUtils'
import { onMounted } from 'vue'
import { StackLayout } from '.'
import NoticeItem from '../Alerts/NoticeItem.vue'

type Props = {
    primaryLabel?: string
    secondaryLabel?: string
    primaryDisabled?: boolean
    title: string
}
defineProps<Props>()

type Emits = {
    (e: 'clickSecondary'): void
    (e: 'clickSubmit'): void
}
const emit = defineEmits<Emits>()

const formSubmit = (event: MouseEvent | KeyboardEvent) => {
    event.preventDefault()
    emit('clickSubmit')
}

onMounted(() => {
    setCurrentThemeMode(isLight() ? 'light' : 'dark')
})
</script>

<template>
    <main class="full-width">
        <NoticeItem />
        <section class="hero is-fullheight is-error-section background-img">
            <div
                class="hero-body container place-content-center align-self-center justify-center full-width no-padding-y">
                <form :onsubmit="formSubmit" class="form-width">
                    <BaseCard>
                        <template #title>
                            <slot name="title">
                                <StackLayout class="space-between full-width">
                                    <StackLayout :gap="8" class="align-items-center guest-title">
                                        <b-icon icon="lock" size="is-small" />
                                        <span class="px-1">{{ title }}</span>
                                    </StackLayout>
                                    <LocaleDropdown />
                                </StackLayout>
                            </slot>
                        </template>
                        <template #content>
                            <StackLayout direction="column" :gap="16">
                                <slot name="content" />
                            </StackLayout>
                        </template>
                        <template #footer>
                            <slot name="footer">
                                <!--TODO : Change to resert pwd route-->
                                <SecondaryButton
                                    class="flex-button left-button full-width"
                                    @click="$emit('clickSecondary')"
                                    :label="secondaryLabel" />
                                <PrimaryButton
                                    class="flex-button right-button full-width"
                                    :label="primaryLabel"
                                    :disabled="primaryDisabled"
                                    @click="$emit('clickSubmit')" />
                            </slot>
                        </template>
                    </BaseCard>
                </form>
                <footer>
                    <span class="website-by">
                        {{ `Copyright © 2011-${moment().year()} – URTIME – All Rights Reserved` }}
                    </span>
                </footer>
            </div>
        </section>
    </main>
</template>

<style lang="scss">
.form-width {
    z-index: 1;
}
.background-img {
    background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.85)),
        url('@/assets/background.jpg');
    background-size: cover;
}

.website-by {
    position: absolute;
    text-align: end;
    bottom: 0;
    right: 0;
    color: var(--color-text);
    width: 100%;
    padding: 0.5em;
    font-size: 0.8em;
}

.no-padding-y {
    padding-top: 0;
    padding-bottom: 0;
}

.container {
    max-width: 100%;
}

.guest-title {
    color: var(--color-text);
}
</style>
