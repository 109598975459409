export const menuFr = {
    administration: {
        api: 'API',
        organizations: 'Entreprises',
        devices: 'Appareils',
        deviceConfiguration: "Configuration de l'appareil",
        roles: 'Rôles & permissions',
        title: 'Administration',
        users: 'Utilisateurs',
        devicesConfig: 'Configurations',
        devicesTypes: "Types d'appareil",
        devicesTemplate: 'Templates',
        devicesTemplateKeys: 'Clés de configuration'
    },
    organization: 'Mon entreprise',
    checkpoints: 'Checkpoints',
    deimpersonate: 'Désincarner',
    detections: 'Détections',
    devices: 'Appareils',
    events: 'Évènements',
    home: 'Accueil',
    logout: 'Se déconnecter',
    myAccount: 'Mon compte',
    recentlyVisited: 'Dernier évènement visité',
    technician: {
        documentations: 'Documentations',
        inventory: 'Inventaire',
        questions: 'Questions',
        tickets: 'Tickets',
        title: 'Technicien',
        topics: 'Topics'
    },
    tickets: 'Demandes',
    type: {
        create: 'Création',
        detail: 'Détail',
        generate: 'Génération',
        list: 'Liste'
    },
    supportFaq: 'Support & FAQ'
}
