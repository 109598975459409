import auth from '@//helpers/auth'
import { API_PREFIX } from '@/config/api'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { PartnerFilters } from '@/types/filters/PartnerFilters'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { buildQueryUrl } from '../utils/queryUtils'

const PARTNER_API_PREFIX = `${API_PREFIX}/partners`

const partnerApi = {
    getPartners: (
        params?: Partial<PaginationType & PartnerFilters>
    ): Promise<PaginationDataType<PartnerModel[]>> =>
        auth.get(`${PARTNER_API_PREFIX}${buildQueryUrl(params)}`).then(res => res.data),
    getPartner: (id: number) => auth.get(`${PARTNER_API_PREFIX}/${id}`),

    addPartner: (id: number, data: PartnerModel) => auth.post(`${PARTNER_API_PREFIX}/${id}`),
    updatePartner: (id: number, data: PartnerModel) => auth.patch(`${PARTNER_API_PREFIX}/${id}`),
    deletePartner: (id: number) => auth.delete(`${PARTNER_API_PREFIX}/${id}`)
}

export default partnerApi
