<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { useIsImpersonate } from '@/hooks/useIsImpersonate'
import store from '@/store'
import { defaultUserModel } from '@/types/models/UserModel'
import { computed } from 'vue'
import MyAccountChangePassword from './components/MyAccountChangePassword.vue'
import MyAccountInformations from './components/MyAccountInformations.vue'
import MyAccountRoles from './components/MyAccountRoles.vue'

const me = computed(() => ({ ...(store.state.authentication?.me ?? defaultUserModel) }))

const isImpersonate = useIsImpersonate()
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <StackLayout direction="row" :gap="8" isResponsive>
            <MyAccountInformations :me="me" />
            <MyAccountChangePassword v-if="!isImpersonate" :me="me" class="full-width" />
        </StackLayout>
        <MyAccountRoles :me="me" />
    </StackLayout>
</template>

<style lang="scss">
.profile-role-card {
    & .card-content {
        text-align: center;
        border: 1px solid $primary;
    }
}
</style>
