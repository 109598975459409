export enum EPasswordValidation {
    HAS_LOWERCASE = 'hasLowercase',
    HAS_MIN_LENGTH = 'hasMinLength',
    HAS_NUMBER = 'hasNumber',
    HAS_SPECIAL = 'hasSpecial',
    HAS_UPPERCASE = 'hasUppercase',
    HAS_NO_WHITESPACE = 'hasNoWhitespace',
    PASSWORD_MATCHES = 'passwordMatches'
}

export const EPasswordValidationLabels = {
    [EPasswordValidation.HAS_LOWERCASE]: 'authentication.passwordRules.lowercaseCharacters',
    [EPasswordValidation.HAS_MIN_LENGTH]: 'authentication.passwordRules.minLength',
    [EPasswordValidation.HAS_NUMBER]: 'authentication.passwordRules.numericCharacters',
    [EPasswordValidation.HAS_SPECIAL]: 'authentication.passwordRules.specialCharacters',
    [EPasswordValidation.HAS_UPPERCASE]: 'authentication.passwordRules.uppercaseCharacters',
    [EPasswordValidation.HAS_NO_WHITESPACE]: 'authentication.passwordRules.noWhitespaceCharacter',
    [EPasswordValidation.PASSWORD_MATCHES]: 'authentication.passwordRules.passwordMatches'
}
