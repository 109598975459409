import { EEnv, EEnvLabels } from '@/types/enum/EnvEnum'

export const currentEnv = import.meta.env.PROD
    ? EEnv.PRODUCTION
    : import.meta.env.DEV
    ? EEnv.DEVELOPMENT
    : EEnv.OTHER
export const currentEnvLabel = Object.values(EEnv).find(
    value => value === import.meta.env.VITE_BUILD_ENV
)
    ? EEnvLabels[import.meta.env.VITE_BUILD_ENV as EEnv]
    : import.meta.env.VITE_BUILD_ENV

export const currentVersion = import.meta.env.VITE_CURRENT_VERSION
