<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { type AvailableLangs } from '@/locales/i18n'
import { setLang } from '@/locales/langUtils'
import { EColors } from '@/types/constants/ColorValues'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()
// No traduction string, need to be in the flag language
defineProps<{ onlyFlag?: boolean }>()
type CountrySelectorType = {
    value: keyof AvailableLangs
    flag: string
    label: string
}
const availableLangs: CountrySelectorType[] = [
    { value: 'fr', label: 'Français', flag: 'fr' },
    { value: 'en', label: 'English', flag: 'gb' }
    /* { value: 'de', label: 'Deutsch', flag: 'de' }
    { value: "jp, label:'日本語"', flag: "jp" }, */
]
const selectedLocale = ref(
    availableLangs.find(item => item.value === i18n.locale.value) ?? availableLangs[0]
)
const changeLang = (country: CountrySelectorType) => {
    selectedLocale.value = country
    setLang(country.value)
}

const showDropdown = ref(false)
const dropdownIcon = computed(() => (showDropdown.value ? 'chevron-down' : 'chevron-up'))
</script>

<template>
    <div
        class="flag-select pointer color-text"
        @click="showDropdown = !showDropdown"
        tabindex="-1"
        @focusout="showDropdown = false">
        <StackLayout
            class="align-items-center border-radius primary-border locale-padding space-between">
            <StackLayout :style="{ 'justify-content': 'center' }" :gap="8">
                <span
                    class="fi"
                    :class="`fi-${selectedLocale.flag} ${
                        onlyFlag ? 'only-flag-class' : ''
                    }`"></span>
                <span v-show="!onlyFlag" class="locale-name">{{ selectedLocale.label }}</span>
            </StackLayout>
            <IconItem :icon="dropdownIcon" :type="EColors.PRIMARY" />
        </StackLayout>
        <div
            v-show="showDropdown"
            class="dropdown-locale border-radius background-secondary full-width primary-border">
            <div
                v-for="(option, key) in availableLangs"
                :key="key"
                @click="changeLang(option)"
                :class="`flex align-items-center pointer fit-content width-auto option ${
                    option.flag === selectedLocale.flag ? 'selected' : ''
                } locale-padding`">
                <StackLayout direction="row" :gap="8">
                    <span
                        class="fi"
                        :class="`fi fi-${option.flag} ${onlyFlag ? 'only-flag-class' : ''}`"></span>
                    <span v-show="!onlyFlag" class="locale-name color-text">
                        {{ option.label }}
                    </span>
                </StackLayout>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.locale-padding {
    padding: 0.5em;
}

.flag-select {
    display: inline-block;
    position: relative;
    min-width: 110px;
}

.dropdown-locale {
    position: absolute;
    border-top: none;
    z-index: 10;
}
.locale-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.only-flag-class {
    font-size: x-large;
    padding: unset;
    & .selected-option {
        height: 100%;
        & .fi {
            font-size: x-large;
        }
    }
}
</style>
