import i18n from '@/locales/i18n'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { EPrimitive } from '../types/enum/PrimitivesEnum'

export const setObjectValuesFromKeys = <T>(data: T, value: Partial<T>) => {
    Object.entries(value).forEach(([key, valueItem]) => (data[key as keyof T] = valueItem as any))
    return data
}

export const jsonToCsv = (data: Record<string, string>[]) => {
    const keys = Object.keys(data[0])
    const replacer = (_key: string, value: string | null) => (value === null ? '' : value)
    const processRow = (row: Record<string, string>) =>
        keys.map(key => JSON.stringify(row[key], replacer)).join(',')
    return [keys.join(','), ...data.map(processRow)].join('\r\n')
}

export const booleanToString = (value?: boolean) => i18n.global.t(`common.${value ? 'yes' : 'no'}`)

export const isLastArrayElementNullOrUndefined = (array?: any[]) =>
    array && !!array.length && isNullOrUndefined(array?.at(-1))

export const dataToType = (data: any, type: EPrimitive) => {
    if (type === EPrimitive.FLOAT || type === EPrimitive.INTEGER || type === EPrimitive.NUMBER)
        return parseInt(data) as number
    if (type === EPrimitive.STRING) return data?.toString() as string
    if (type === EPrimitive.BOOLEAN) return !!data as boolean
    return data
}
