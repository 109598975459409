<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import AdminOrganizationInformations from './components/AdminOrganizationInformations.vue'
import AdminOrganizationLocalization from './components/AdminOrganizationLocalization.vue'

type Props = {
    label: string
    submitted: boolean
    data: OrganizationModel
    loading?: boolean
}

defineProps<Props>()
const emit = defineEmits<{
    (e: 'click'): void
    (e: 'update', organizationValue: Partial<OrganizationModel>): void
}>()

const updateOrganizationValue = (value: Partial<OrganizationModel>) => emit('update', value)
</script>

<template>
    <StackLayout class="margin-bottom" :gap="16" isResponsive>
        <StackLayout direction="column" :gap="16" class="full-width">
            <AdminOrganizationInformations
                :data="data"
                :submitted="submitted"
                @change="updateOrganizationValue($event)" />
            <slot />
        </StackLayout>
        <AdminOrganizationLocalization
            :data="data.address"
            :submitted="submitted"
            @change="updateOrganizationValue({ address: { ...data.address, ...$event } })" />
    </StackLayout>
    <CreateButton :loading="loading" :label="label" @click="$emit('click')" />
</template>
