<script setup lang="ts">
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { LoadingSpinner } from '@/components/LoadingSpinner'
import { TitleTypography, TypographyItem } from '@/components/Typography'
import store from '@/store'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { computed, onUpdated, ref } from 'vue'

const results = ref<string[]>([])
const showResultsStack = ref(false)
const isResultLoading = computed(() => store.state.question.isLoading)
onUpdated(() => {
    if (isResultLoading.value) showResultsStack.value = true
})

const input = ref('')
const isLoading = ref(false)
const handleChange = (value: string) => {
    showResultsStack.value = true
    isLoading.value = true
    input.value = value
    debouncedQuestion()
}
const debouncedQuestion = useDebounceFn(() => getAnswers(), EDebounceTime.DEFAULT)
const getAnswers = () => {
    isLoading.value = false
    console.log('TODO : getAnswers', { input: input.value })
}
</script>

<template>
    <StackLayout direction="column" :gap="8" v-bind="$attrs">
        <InputField :label="$t('support.yourQuestion')" @change="handleChange" />
        <StackLayout v-show="showResultsStack || isLoading" direction="column" :gap="8">
            <TitleTypography :title="$t('support.results')" />
            <StackLayout v-show="isLoading" class="relative results-loading">
                <LoadingSpinner :isFullPage="false" :isLoading="!!isLoading" />
            </StackLayout>
            <StackLayout v-if="results.length">
                <TypographyItem v-for="(item, key) in results" :key="key" :label="item" />
            </StackLayout>
            <TypographyItem v-else :label="$t('common.noOption')" class="no-option-asked" />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.results-loading {
    min-height: 50px;
}
</style>
