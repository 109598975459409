<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import TabsComponent from '@/components/Tabs/TabsComponent.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ETicketActions } from '@/store/ticketStore/TicketStoreTypes'
import type { TicketModel } from '@/types/models/TicketModel'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import TicketDetailInformations from './Detail/TicketDetailInformations.vue'
import TicketDetailMessageReply from './Detail/TicketDetailMessageReply.vue'
import TicketDetailMessagesList from './Detail/TicketDetailMessagesList.vue'
import TicketEditableInformations from './Detail/TicketEditableInformations.vue'

const { t } = useI18n()

const tabs = computed(() => [
    { id: 'informations', label: t('ticket.tabs.information') },
    { id: 'messages', label: t('ticket.tabs.messages') }
])

const route = useRoute()
const ticketId = computed(() => route.params.id)

const ticketData = ref<TicketModel>()

onMounted(() => {
    store
        .dispatch(`${EStoreModules.TICKET}/${ETicketActions.FETCH_TICKET}`, ticketId.value)
        .then(res => (ticketData.value = res))
})

const handleUpdateTicketData = (updatedData: Partial<TicketModel>) => {
    if (ticketData.value) {
        ticketData.value = { ...ticketData.value, ...updatedData }
    }
}
</script>

<template>
    <TicketDetailInformations :data="ticketData" />
    <TabsComponent :tabs="tabs">
        <template #informations>
            <TicketEditableInformations :data="ticketData" @change="handleUpdateTicketData" />
        </template>

        <template #messages>
            <StackLayout direction="column" :gap="16">
                <TicketDetailMessagesList />
                <TicketDetailMessageReply />
            </StackLayout>
        </template>
    </TabsComponent>
</template>
