import { ref, type Ref } from 'vue'
import type { Commit } from 'vuex'
import {
    ELoadingActions,
    ELoadingGetters,
    ELoadingMutations,
    type LoadingState
} from './LoadingStoreTypes'

const state: Ref<LoadingState> = ref({
    isLoading: false
})

const getters = {
    [ELoadingGetters.GET_IS_LOADING]: () => state.value.isLoading
}

const actions = {
    [ELoadingActions.IS_LOADING]: ({ commit }: { commit: Commit }) => {
        commit(ELoadingMutations.SET_GLOBAL_LOADING, true)
    },
    [ELoadingActions.NOT_IS_LOADING]: ({ commit }: { commit: Commit }) => {
        commit(ELoadingMutations.SET_GLOBAL_LOADING, false)
    }
}

const mutations = {
    [ELoadingMutations.SET_GLOBAL_LOADING](_: LoadingState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    state,
    actions,
    getters,
    mutations
}
