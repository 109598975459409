<script setup lang="ts">
import { PrimaryButton } from '../Buttons'

const emit = defineEmits<{
    (e: 'sendReply'): void
}>()

const sendReply = () => emit('sendReply')
</script>

<template>
    <PrimaryButton
        class="buttonClass"
        :label="$t('technician.ticket.message.sendReply')"
        icon-right="reply"
        @click="sendReply" />
</template>
<style scoped lang="scss">
.buttonClass {
    max-width: min-content;
}
</style>
