<script setup lang="ts">
import deviceConfigApi from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DeviceTemplateView from './components/DeviceTemplateView.vue'

const route = useRoute()
const deviceConfigId = ref(route.params.id.toString())
const data = ref<Partial<DeviceConfigModel>>()

const getDeviceTemplate = () =>
    deviceConfigApi.getDeviceConfig(deviceConfigId.value).then(res => (data.value = res.data))
onMounted(() => {
    if (typeof deviceConfigId.value === 'string') getDeviceTemplate()
})

const updateDevice = (value: Partial<DeviceConfigModel>) =>
    (data.value = { ...data.value, ...value })

const save = () =>
    store
        .dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.UPDATE_DEVICE_CONFIG}`, {
            id: deviceConfigId.value,
            data: { ...data.value, template: data.value?.config_keys?.map(item => item.name) }
        })
        .then(() => getDeviceTemplate())
</script>

<template>
    <DeviceTemplateView
        :data="data"
        :confirmLabel="$t('deviceConfig.template.buttons.updateTemplate')"
        :permission="EAdminPerm.ADMIN_PERMISSIONS"
        @change="updateDevice"
        @confirm="save" />
</template>
