import {
    mdiBattery,
    mdiBattery20,
    mdiBattery50,
    mdiBattery60,
    mdiBattery80,
    mdiBatteryCharging,
    mdiBatteryChargingHigh,
    mdiBatteryChargingLow,
    mdiBatteryChargingMedium,
    mdiBatteryChargingOutline,
    mdiBatteryOutline
} from '@mdi/js'
import type { IconType } from '../components/IconType'
import { EColors } from '../constants/ColorValues'

export enum EBatteryLevel {
    FULL = 100,
    HIGH = 80,
    MEDIUM_HIGH = 60,
    MEDIUM = 50,
    LOW = 20,
    EMPTY = 0
}

export const EBatteryLevelValues: EBatteryLevel[] = [
    EBatteryLevel.EMPTY,
    EBatteryLevel.LOW,
    EBatteryLevel.MEDIUM,
    EBatteryLevel.MEDIUM_HIGH,
    EBatteryLevel.HIGH,
    EBatteryLevel.FULL
]

export const EBatteryLevelIcons = {
    [EBatteryLevel.FULL]: { path: mdiBattery, type: EColors.SUCCESS },
    [EBatteryLevel.HIGH]: { path: mdiBattery80, type: EColors.SUCCESS },
    [EBatteryLevel.MEDIUM_HIGH]: { path: mdiBattery60, type: EColors.WARNING },
    [EBatteryLevel.MEDIUM]: { path: mdiBattery50, type: EColors.WARNING },
    [EBatteryLevel.LOW]: { path: mdiBattery20, type: EColors.DANGER },
    [EBatteryLevel.EMPTY]: { path: mdiBatteryOutline, type: EColors.DANGER }
}

export const EBatteryLevelPluggedIcons: Record<string, IconType> = {
    [EBatteryLevel.FULL]: { path: mdiBatteryCharging, type: EColors.SUCCESS },
    [EBatteryLevel.HIGH]: { path: mdiBatteryCharging, type: EColors.SUCCESS },
    [EBatteryLevel.MEDIUM_HIGH]: { path: mdiBatteryChargingHigh, type: EColors.WARNING },
    [EBatteryLevel.MEDIUM]: { path: mdiBatteryChargingMedium, type: EColors.WARNING },
    [EBatteryLevel.LOW]: { path: mdiBatteryChargingLow, type: EColors.DANGER },
    [EBatteryLevel.EMPTY]: { path: mdiBatteryChargingOutline, type: EColors.DANGER }
}
