<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import type { AddressModel } from '@/types/models/AddressModel'
import { computed } from 'vue'

type Props = {
    data: Partial<AddressModel>
    submitted: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<AddressModel>): void
}
const emit = defineEmits<Emits>()

const dataLocalization = computed({
    get: () => props.data,
    set: value => emit('change', { ...value })
})

const errors = computed(() => ({
    streetError: props.submitted && !dataLocalization.value.street,
    zipCodeError: props.submitted && !dataLocalization.value.zip_code,
    cityError: props.submitted && !dataLocalization.value.city,
    countryError: props.submitted && !dataLocalization.value.country
}))
</script>

<template>
    <InformationBaseCard
        class="full-width"
        :label="$t('organization.admin.detail.localizationTitle')">
        <StackLayout
            direction="column"
            :gap="16"
            class="full-width center-items input-information-card">
            <InputField
                :label="$t('address.street')"
                v-model="dataLocalization.street"
                @change="value => (dataLocalization.street = value)"
                required
                :hasError="errors.streetError"
                class="full-width" />
            <InputField
                :label="$t('address.street2')"
                v-model="dataLocalization.street2"
                @change="value => (dataLocalization.street2 = value)"
                class="full-width" />
            <InputField
                :label="$t('address.street3')"
                v-model="dataLocalization.street3"
                @change="value => (dataLocalization.street3 = value)"
                class="full-width" />
            <StackLayout :gap="8" isResponsiveSmallScreen>
                <InputField
                    :label="$t('address.zipCode')"
                    v-model="dataLocalization.zip_code"
                    @change="value => (dataLocalization.zip_code = value)"
                    required
                    :hasError="errors.zipCodeError"
                    class="full-width" />
                <InputField
                    :label="$t('address.city')"
                    v-model="dataLocalization.city"
                    @change="value => (dataLocalization.city = value)"
                    required
                    :hasError="errors.cityError"
                    class="full-width" />
            </StackLayout>
            <InputField
                :label="$t('address.country')"
                v-model="dataLocalization.country"
                @change="value => (dataLocalization.country = value)"
                required
                :hasError="errors.countryError"
                class="full-width" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.input-information-card {
    & .field.is-floating-label .label::before {
        background-color: var(--color-background-secondary);
    }
}

.full-width > .control > .input {
    background-color: var(--color-background-secondary);
}
</style>
