import router from '@/router'
import { ref } from 'vue'

export const setDocumentAndTitle = (label: string, subtitle?: string) => {
    document.title = label
    router.currentRoute.value.meta.title.value = label
    if (subtitle) router.currentRoute.value.meta.subtitle = ref(subtitle)
}

export const getQueryParam = (key: string) => {
    const params = new URLSearchParams(window.location.search)
    return params.get(key)
}

export const getCurrentPage = () => {
    const currentPage = getQueryParam('page')

    const numberCurrentPage = currentPage ? parseInt(currentPage) : 1
    return numberCurrentPage ? numberCurrentPage : 1
}

export const getPageSize = (value?: string) => {
    const pageSize = value ?? getQueryParam('per_page') ?? localStorage.getItem('per_page')

    return pageSize ? parseInt(pageSize) : null
}
