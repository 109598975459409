<script setup lang="ts">
import { InputField } from '@/components/Fields'
import GuestLayout from '@/components/Layouts/GuestLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import store from '@/store'
import {
    EPasswordValidationLabels,
    type EPasswordValidation
} from '@/types/enum/PasswordValidationEnum'
import { passwordValidation } from '@/utils/passwordUtils'
import { computed, ref, watchEffect } from 'vue'

type Props = {
    primaryLabel: string
    secondaryLabel?: string
    title: string
}

type Emits = {
    (e: 'clickSecondary'): void
    (e: 'clickSubmit', password: string, confirmPassword: string): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const isLoading = computed(() => store.state.authentication.isLoading)

const form = ref({
    password: '',
    confirmPassword: ''
})

const submitForm = () => {
    submitted.value = true
    if (validatedPasswordHasErrors.value && form.value.password !== form.value.confirmPassword)
        return // TODO : Alerte
    submitted.value = false
    emit('clickSubmit', form.value.password, form.value.confirmPassword)
}

const validatedPassword = computed(() =>
    passwordValidation(form.value.password, form.value.confirmPassword)
)
const validatedPasswordHasErrors = computed(() =>
    Object.values(validatedPassword.value).find(item => !item)
)
const errors = computed(() => ({
    passwordError: submitted.value && !form.value.password,
    confirmPasswordError:
        submitted.value &&
        (!form.value.password || form.value.password !== form.value.confirmPassword)
}))

const submitted = ref(false)
watchEffect(() => {
    if (Object.values(validatedPassword.value).find(item => !item)) return (submitted.value = true)
    submitted.value = false
})
</script>

<template>
    <FullScreenLoader :isLoading="isLoading" />
    <GuestLayout
        :title="title"
        :primaryLabel="primaryLabel"
        :secondaryLabel="secondaryLabel ?? $t('authentication.back')"
        @clickSubmit="submitForm"
        @clickSecondary="$emit('clickSecondary')"
        class="password-guest-layout">
        <template #content>
            <form method="post" name="password">
                <StackLayout :gap="16" direction="column">
                    <span>
                        {{ $t('authentication.createPassword.contentTitle') }}
                        <slot name="title" />
                    </span>
                    <InputField
                        :label="$t('authentication.createPassword.password')"
                        @keyup.enter="submitForm"
                        v-model="form.password"
                        @change="value => (form.password = value)"
                        :placeholder="$t('authentication.createPassword.password')"
                        inputType="password"
                        required
                        autocomplete="new-password"
                        :hasError="errors.passwordError"
                        v-bind:password-reveal="true" />
                    <InputField
                        :label="$t('authentication.createPassword.confirmPassword')"
                        @keyup.enter="submitForm"
                        v-model="form.confirmPassword"
                        @change="value => (form.confirmPassword = value)"
                        :placeholder="$t('authentication.createPassword.confirmPassword')"
                        inputType="password"
                        required
                        :hasError="errors.confirmPasswordError"
                        v-bind:password-reveal="true" />
                    <span>{{ $t('authentication.passwordRules.content') }}</span>
                    <li
                        v-for="([key, value], index) in Object.entries(validatedPassword)"
                        :key="index"
                        :class="value ? 'success' : 'error'">
                        {{ $t(EPasswordValidationLabels[key as EPasswordValidation]) }}
                    </li>
                </StackLayout>
            </form>
        </template>
    </GuestLayout>
</template>
