export enum EOnboardingDeviceType {
    URTIME_800 = 1,
    URTIME_150 = 3,
    MICRO = 4,
    LOOPCASE = 5,
    NANO = 7,
    NANO_EU = 8,
    NANO_SAT = 9,
    NANO_US_SAT = 10,
    NANO_WF = 11,
    NANO_XB = 12,
    MODULE_SAT = 13
}

export const EOnboardingDeviceTypeLabels = {
    [EOnboardingDeviceType.URTIME_800]: 'uRTime 800',
    [EOnboardingDeviceType.URTIME_150]: 'uRTime 150',
    [EOnboardingDeviceType.MICRO]: 'Micro',
    [EOnboardingDeviceType.LOOPCASE]: 'Loopcase',
    [EOnboardingDeviceType.NANO]: 'Nano',
    [EOnboardingDeviceType.NANO_SAT]: 'Nano SAT',
    [EOnboardingDeviceType.MODULE_SAT]: 'Module SAT'
}

export const EOnboardingDeviceTypeOptions = Object.entries(EOnboardingDeviceTypeLabels).map(
    ([key, name]) => ({
        id: parseInt(key),
        name
    })
)
