<script setup lang="ts">
import { LMap, LTileLayer } from '@vue-leaflet/vue-leaflet'
import 'leaflet/dist/leaflet.css'
import { ref } from 'vue'

const zoom = ref(6)
const center = ref([47.41322, -1.219482])
</script>

<template>
    <l-map
        ref="map"
        v-model:zoom="zoom"
        v-model:center="center"
        :useGlobalLeaflet="false"
        class="map-class"
        v-bind="$attrs">
        <l-tile-layer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            name="Stadia Maps Basemap"></l-tile-layer>
    </l-map>
    <!-- <LMap ref="map" v-model:zoom="zoom" v-model:center="center" :useGlobalLeafet="false">
        <LTileLayer
            layer-type="base"
            name="Stadia Maps Basemap"
            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"></LTileLayer>
    </LMap> -->
</template>

<style scoped lang="scss">
.map-class {
    min-height: 300px;
    z-index: 1;
}
</style>
