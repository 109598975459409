<script setup lang="ts">
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { DeviceFiltersType, LocalStorageFilter } from '@/types/filters/DeviceFilters'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { ref } from 'vue'

type Props = {
    filters: DeviceFiltersType
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceFiltersType>): void
}
const emit = defineEmits<Emits>()

const storedEvent = ref(getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event)

const handleChange = (value: Partial<DeviceFiltersType>) => {
    storedEvent.value = undefined
    emit('change', value)
}
</script>

<template>
    <StackLayout :gap="8" isResponsiveSmallScreen>
        <EventCheckpointAutocomplete
            :event="storedEvent"
            :event_id="parseInt(filters.event_id?.toString() ?? '')"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            @change="handleChange($event)" />
    </StackLayout>
</template>
