import type { LangLocales } from '@/types/lang/locales'
import { createI18n } from 'vue-i18n'
import { accountEn } from './en/accountEn'
import { addressEn } from './en/addressEn'
import { administrationEn } from './en/administrationEn'
import { apiEn } from './en/apiEn'
import { authenticationEn } from './en/authenticationEn'
import { checkpointsEn } from './en/checkpointsEn'
import { dateEn } from './en/dateEn'
import { detectionsEn } from './en/detectionsEn'
import { deviceConfigEn } from './en/deviceConfigEn'
import { devicesEn } from './en/devicesEn'
import { en, enumEn } from './en/en'
import { errorEn } from './en/errorEn'
import { eventsEn } from './en/eventsEn'
import { homeEn } from './en/homeEn'
import { menuEn } from './en/menuEn'
import { organizationEn } from './en/organizationEn'
import { paginationEn } from './en/paginationEn'
import { partnerEn } from './en/partnerEn'
import { roleEn } from './en/roleEn'
import { supportEn } from './en/supportEn'
import { technicianEn } from './en/technicianEn'
import { ticketEn } from './en/ticketEn'
import { unitsEn } from './en/unitsEn'
import { userEn } from './en/userEn'
import { accountFr } from './fr/accountFr'
import { addressFr } from './fr/addressFr'
import { administrationFr } from './fr/administrationFr'
import { apiFr } from './fr/apiFr'
import { authenticationFr } from './fr/authenticationFr'
import { checkpointsFr } from './fr/checkpointsFr'
import { dateFr } from './fr/dateFr'
import { detectionsFr } from './fr/detectionsFr'
import { deviceConfigFr } from './fr/deviceConfigFr'
import { devicesFr } from './fr/devicesFr'
import { errorFr } from './fr/errorFr'
import { eventsFr } from './fr/eventsFr'
import { enumFr, fr } from './fr/fr'
import { homeFr } from './fr/homeFr'
import { menuFr } from './fr/menuFr'
import { organizationFr } from './fr/organizationFr'
import { paginationFr } from './fr/paginationFr'
import { partnerFr } from './fr/partnerFr'
import { roleFr } from './fr/roleFr'
import { supportFr } from './fr/supportFr'
import { technicianFr } from './fr/technicianFr'
import { ticketFr } from './fr/ticketFr'
import { userFr } from './fr/userFr'
import { getLang } from './langUtils'

export type AvailableLangs<T = string> = Record<string, T>
export const defaultAvailableLangs: AvailableLangs<undefined> = {
    fr: undefined,
    en: undefined
}

export type AvailableLangsType = AvailableLangs<LangLocales>

export const capitalizedAvailableLangs = Object.keys(defaultAvailableLangs).map(
    item => item.charAt(0).toUpperCase() + item.slice(1)
)

const messages: AvailableLangsType = {
    en: {
        account: accountEn,
        administration: administrationEn,
        address: addressEn,
        api: apiEn,
        authentication: authenticationEn,
        checkpoint: checkpointsEn,
        common: en,
        date: dateEn,
        detection: detectionsEn,
        device: devicesEn,
        deviceConfig: deviceConfigEn,
        enum: enumEn,
        error: errorEn,
        event: eventsEn,
        home: homeEn,
        menu: menuEn,
        organization: organizationEn,
        pagination: paginationEn,
        partner: partnerEn,
        role: roleEn,
        support: supportEn,
        technician: technicianEn,
        ticket: ticketEn,
        unit: unitsEn,
        user: userEn
    },
    fr: {
        account: accountFr,
        administration: administrationFr,
        address: addressFr,
        api: apiFr,
        authentication: authenticationFr,
        checkpoint: checkpointsFr,
        common: fr,
        date: dateFr,
        detection: detectionsFr,
        device: devicesFr,
        deviceConfig: deviceConfigFr,
        enum: enumFr,
        error: errorFr,
        event: eventsFr,
        home: homeFr,
        menu: menuFr,
        organization: organizationFr,
        pagination: paginationFr,
        partner: partnerFr,
        role: roleFr,
        support: supportFr,
        technician: technicianFr,
        ticket: ticketFr,
        unit: unitsEn,
        user: userFr
    }
}

export const i18n = createI18n({
    allowComposition: true,
    legacy: false,
    locale: getLang() ?? 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages
})

export default i18n
