<script setup lang="ts">
import { ref } from 'vue'
import { StackLayout } from '../Layouts'

const dropFiles = ref<File[]>([])

const emit = defineEmits<{
    (e: 'delete-drop-file', index: number): void
}>()

const deleteDropFile = (index: number) => {
    dropFiles.value.splice(index, 1)
    emit('delete-drop-file', index)
}

const truncateFileName = (fileName: string) => {
    const maxLength = 20
    return fileName.length > maxLength ? fileName.substring(0, maxLength) + '...' : fileName
}
</script>

<template>
    <StackLayout :gap="8" class="flex-wrap">
        <b-upload required class="min-content" v-model="dropFiles" multiple drag-drop>
            <a class="button is-primary">
                <span>{{ $t('technician.ticket.message.addFile') }}</span>
                <b-icon icon="upload"></b-icon>
            </a>
        </b-upload>

        <div
            v-for="(file, index) in dropFiles"
            :key="index"
            :numberFile="index"
            :nameFile="index"
            class="tag is-primary border">
            {{ truncateFileName(file.name) }}
            <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
        </div>
    </StackLayout>
</template>
