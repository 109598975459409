<script setup lang="ts">
import { InputField } from '@/components/Fields'
import DatePickerField from '@/components/Fields/DatePickerField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import type { AdminApiFiltersType } from '@/types/filters/AdminFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters: AdminApiFiltersType
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: AdminApiFiltersType): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value: string) => {
    isLoadingName.value = true
    debouncedName(value)
}
const isLoadingName = ref(false)
const debouncedName = useDebounceFn(
    (name: string) => emit('change', { name }),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout :gap="8" direction="column">
        <StackLayout :gap="8" class="responsive-width" isResponsiveSmallScreen>
            <InputField
                :label="$t('api.filters.name')"
                :modelValue="filters.name"
                @change="handleTyping"
                class="responsive-width" />
            <StackLayout :gap="8" class="full-width">
                <DatePickerField
                    :label="$t('api.filters.expirationDateFrom')"
                    :modelValue="filters.expirationDateFrom"
                    @change="$emit('change', { expirationDateFrom: $event })"
                    :min-date="new Date()"
                    class="full-width" />
                <DatePickerField
                    :label="$t('api.filters.expirationDateTo')"
                    :modelValue="filters.expirationDateTo"
                    @change="$emit('change', { expirationDateTo: $event })"
                    :min-date="new Date()"
                    class="full-width" />
            </StackLayout>
        </StackLayout>
    </StackLayout>
</template>
