import type { QuestionModel } from '@/types/models/QuestionModel'

export type QuestionState = {
    question?: QuestionModel
    questions: QuestionModel[]
}

export enum EQuestionActions {
    FETCH_QUESTION = 'fetchQuestion',
    FETCH_QUESTIONS = 'fetchQuestions',
    CREATE_QUESTION = 'createQuestion',
    UPDATE_QUESTION = 'updateQuestion',
    DELETE_QUESTION = 'deleteQuestion'
}

export enum EQuestionMutations {
    SET_QUESTIONS = 'setQuestions',
    SET_ALL_QUESTIONS = 'setAllQuestions',
    SET_QUESTION = 'setQuestion',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
