import { API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { ReplyModel } from '@/types/models/ReplyModel'
import { buildQueryUrl } from '@/utils/queryUtils'

const REPLY_API_PREFIX = `${API_PREFIX}/tickets`

const replyApi = {
    getReplies: (
        ticketId: string,
        params?: Partial<PaginationType>
    ): Promise<PaginationDataType<ReplyModel[]>> =>
        auth
            .get(`${REPLY_API_PREFIX}/${ticketId}/replies${buildQueryUrl(params)}`)
            .then(response => response.data),
    getRepliesWithDoc: (
        ticketId: number,
        params?: Partial<PaginationType>,
        withDocuments?: boolean
    ): Promise<PaginationDataType<ReplyModel[]>> => {
        const url = `${REPLY_API_PREFIX}/${ticketId}/replies/docs/${buildQueryUrl(params)}`
        const config = {
            params: withDocuments ? { with_documents: true, ...params } : params
        }

        return auth.get(url, config).then(response => response.data)
    },
    createReply: (ticketId: string, replyData: any) =>
        auth
            .post(`${REPLY_API_PREFIX}/${ticketId}/replies`, replyData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(response => response.data),
    updateReply: (ticketId: string, replyId: string, replyData: any) =>
        auth
            .put(`${REPLY_API_PREFIX}/${ticketId}/replies/${replyId}`, replyData)
            .then(response => response.data),
    deleteReply: (ticketId: string, replyId: string) =>
        auth
            .delete(`${REPLY_API_PREFIX}/${ticketId}/replies/${replyId}`)
            .then(response => response.data)
}

export default replyApi
