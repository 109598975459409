import i18n from '@/locales/i18n'

export enum EQRCode {
    NOT_ALLOWED = 'NotAllowedError',
    NOT_FOUND = 'NotFoundError',
    NOT_SUPPORTED = 'NotSupportedError',
    NOT_READABLE = 'NotReadableError',
    OVERCONSTRAINED = 'OverconstrainedError',
    STREAM_API_NOT_SUPPORTED = 'StreamApiNotSupportedError',
    INSECURE_CONTEXT = 'InsecureContextError'
}

export const EQRCodeValues: string[] = [
    EQRCode.NOT_ALLOWED,
    EQRCode.NOT_FOUND,
    EQRCode.NOT_SUPPORTED,
    EQRCode.NOT_READABLE,
    EQRCode.OVERCONSTRAINED,
    EQRCode.STREAM_API_NOT_SUPPORTED,
    EQRCode.INSECURE_CONTEXT
]

export const EQRCodeLabels = {
    [EQRCode.NOT_ALLOWED]: i18n.global.t('enum.qrcode.error.notAllowed') as string,
    [EQRCode.NOT_FOUND]: i18n.global.t('enum.qrcode.error.notFound') as string,
    [EQRCode.NOT_SUPPORTED]: i18n.global.t('enum.qrcode.error.notSupported') as string,
    [EQRCode.NOT_READABLE]: i18n.global.t('enum.qrcode.error.notReadable') as string,
    [EQRCode.OVERCONSTRAINED]: i18n.global.t('enum.qrcode.error.overconstrained') as string,
    [EQRCode.STREAM_API_NOT_SUPPORTED]: i18n.global.t(
        'enum.qrcode.error.streamApiNotSupported'
    ) as string,
    [EQRCode.INSECURE_CONTEXT]: i18n.global.t('enum.qrcode.error.insecureContext') as string
}
