<script setup lang="ts">
import { DeviceCreatePage } from '@/components/DeviceComponents'
import { DEVICE_ROUTES } from '@/router/routes'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })

const updateDevice = (value: Partial<DeviceModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.CREATE_DEVICE}`, data.value)
        .then(res => router.replace(DEVICE_ROUTES.buildUrl(res.id)))
</script>

<template>
    <DeviceCreatePage
        :deviceData="data"
        :loading="store.state.device.isLoading"
        hasPermission
        @create="create"
        @change="updateDevice" />
</template>
