<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import { localFallback } from '@/locales/langUtils'
import { EColors } from '@/types/constants/ColorValues'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { configDataErrorText, getKeyValue } from '@/utils/validateUtils'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
    storedData?: Partial<DeviceModel>
    data: Partial<DeviceModel>
    templateKeys: DeviceTemplateKeysModel[]
    open: boolean
    hasError?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'save'): void
    (e: 'close'): void
}
defineEmits<Emits>()

const { t } = useI18n()

const getKeylabel = (templateKey: DeviceTemplateKeysModel) =>
    templateKey?.translation && localFallback(templateKey?.translation)
        ? localFallback(templateKey?.translation)
        : templateKey?.name

type ModificationsObject = Record<
    string,
    {
        old: string
        new: string
        label: string | undefined
    }
>
const hasModifications = computed(() =>
    props.data.config
        ? Object.entries(props.data.config).reduce((prev: ModificationsObject, [key, value]) => {
              if (props.storedData?.config?.[key] !== value) {
                  const found = props.templateKeys.find(item => item.name === key)
                  return {
                      ...prev,
                      [key]: {
                          old:
                              getKeyValue(props.storedData?.config?.[key], found) ??
                              t('common.undefined'),
                          new: getKeyValue(value, found) ?? t('common.undefined'),
                          label: found ? getKeylabel(found) : t('common.undefined')
                      }
                  }
              }
              return prev
          }, {})
        : undefined
)

type ErrorsObject = Record<
    string,
    {
        value: string | undefined
        label: string | undefined
    }
>
const modificationsErrors = computed(() =>
    props.hasError && props.data.config
        ? Object.entries(props.data.config).reduce((prev: ErrorsObject, [key, value]) => {
              if (props.storedData?.config?.[key] !== value) {
                  const found = props.templateKeys.find(item => item.name === key)
                  if (!found) return prev
                  const valueError = configDataErrorText(found!, value)
                  return valueError
                      ? {
                            ...prev,
                            [key]: {
                                value: configDataErrorText(found!, value) ?? t('common.undefined'),
                                label: found ? getKeylabel(found) : t('common.undefined')
                            }
                        }
                      : prev
              }
              return prev
          }, {})
        : undefined
)

defineExpose({
    hasModifications
})
</script>

<template>
    <BaseDialog
        :title="$t('common.saveModifications')"
        :open="open"
        :disabled="!!modificationsErrors"
        @confirm="$emit('save')"
        @close="$emit('close')">
        <template #content>
            <StackLayout v-if="hasModifications" direction="column" :gap="8">
                <TypographyItem :label="$t('device.configuration.hasModificationsContent')" />
                <ul class="modification-list">
                    <li
                        v-for="(item, key) in Object.values(hasModifications)"
                        :key="key"
                        direction="row"
                        :gap="4"
                        class="align-items-center">
                        <TypographyItem :label="`${item.label} :`" />
                        <TypographyItem :label="item.old" />
                        <IconItem icon="arrow-right" :type="EColors.WHITE" class="small-icon" />
                        <TypographyItem :label="item.new" />
                    </li>
                </ul>

                <template v-if="modificationsErrors">
                    <TypographyItem :label="$t('device.configuration.hasErrorContent')" />

                    <ul class="modification-list">
                        <li v-for="(item, key) in Object.values(modificationsErrors)" :key="key">
                            <TypographyWithPrefix
                                :prefix="item.label"
                                :label="item.value"
                                class="list-item" />
                        </li>
                    </ul>
                </template>
                <TypographyItem
                    :label="
                        $t(
                            `device.configuration.${
                                modificationsErrors
                                    ? 'hasErrorConfirmation'
                                    : 'hasModificationsConfirmation'
                            }`
                        )
                    " />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.modification-list {
    padding-left: 16px;
    list-style: initial;
}
</style>
