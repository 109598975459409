<script setup lang="ts">
import { ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import { useRouter } from 'vue-router'
import AddNewConfigKey from '../components/AddNewConfigKey.vue'
import DeviceTemplateKeysView from './components/DeviceTemplateKeysView.vue'

const router = useRouter()

const handleCreate = (value: DeviceTemplateKeysModel) =>
    router.replace(ADMIN_DEVICE_TEMPLATE_KEYS_ROUTES.buildUrl(value.id))
</script>

<template>
    <DeviceTemplateKeysView>
        <AddNewConfigKey @create="handleCreate" hasPermission />
    </DeviceTemplateKeysView>
</template>
