import store from '@/store'
import {
    EOrganizationsCustomersPerm,
    EOrganizationsPerm,
    EOrganizationsRolesPerm,
    EOrganizationsUsersPerm
} from '@/types/permissions/OrganizationsPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { computed } from 'vue'
import { ORGANIZATIONS_ROUTE } from './organizationRoutes'
import {
    EOrganizationCustomersRoutesValues,
    EOrganizationRolesRoutesValues,
    EOrganizationRoutesValues,
    EOrganizationUsersRoutesValues
} from './OrganizationRoutesEnum'

export const organizationMenu = computed(() => [
    {
        icon: 'building',
        label: 'menu.organization',
        to: ORGANIZATIONS_ROUTE,
        permissions:
            !!store.state.authentication.me.organization_id &&
            (userHasPermission(EOrganizationsPerm.ORGANIZATIONS_VIEW) ||
                userHasPermission(EOrganizationsCustomersPerm.ORGANIZATIONS_CUSTOMERS_VIEW) ||
                userHasPermission(EOrganizationsUsersPerm.ORGANIZATIONS_USERS_VIEW) ||
                userHasPermission(EOrganizationsRolesPerm.ORGANIZATIONS_ROLES_VIEW)),
        names: [
            ...EOrganizationRoutesValues,
            ...EOrganizationUsersRoutesValues,
            ...EOrganizationCustomersRoutesValues,
            ...EOrganizationRolesRoutesValues
        ]
    }
])
