<script setup lang="ts">
import { computed } from 'vue'
import { CustomField } from '.'

type Props = {
    modelValue?: boolean
    label?: string
    trueValue?: string
    falseValue?: string
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'click', event: MouseEvent): void
    (e: 'change', value: boolean): void
}>()

const modelValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})
</script>

<template>
    <CustomField>
        <b-switch v-model="modelValue" v-bind="$attrs">
            {{ label }}
        </b-switch>
    </CustomField>
</template>

<style lang="scss">
.switch {
    margin-right: unset;
}
</style>
