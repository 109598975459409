import type { EPartnerType } from '@/types/partners/PartnerKeysEnum'
import moment from 'moment'
import * as momentTz from 'moment-timezone'

export type EventCreateModel = {
    client_id: number
    comments: string

    end_at: Date | string
    name: string
    start_at: Date | string
    partner_config?: Partial<EPartnerType> | string | null
    partner_id?: number
    tz: string
}

export const defaultEventCreateModel = {
    client_id: 0,
    comments: '',
    start_at: moment().startOf('d').utc().toDate(),
    end_at: moment().endOf('d').utc().add(1, 'd').toDate(),
    name: '',
    tz: momentTz.tz.guess()
}
