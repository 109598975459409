<script setup lang="ts">
import { BaseDialog } from '@/components/Modals/Dialog'
import { EConfirmDialogLabels, type EConfirmDialog } from '@/types/enum/ConfirmDialogEnum'
import { computed } from 'vue'

type Props = {
    open: boolean
    titleItem?: string
    title?: string
    content?: string
    confirmLabel?: string
    type: EConfirmDialog
}
const props = defineProps<Props>()
type Emits = {
    (e: 'close'): void
    (e: 'confirm'): void
}
const emit = defineEmits<Emits>()
const advancedFiltersOpen = computed({
    get: () => props.open,
    set: () => emit('close')
})
</script>

<template>
    <BaseDialog
        :title="title ?? EConfirmDialogLabels[type].title(titleItem ?? '')"
        :open="advancedFiltersOpen"
        :confirmLabel="confirmLabel ?? EConfirmDialogLabels[type].action"
        @close="advancedFiltersOpen = false"
        @click="$emit('confirm')">
        <template #content>
            <span>
                {{ content ?? EConfirmDialogLabels[type].content }}
            </span>
        </template>
    </BaseDialog>
</template>
