<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import organizationApi from '@/services/organization.service'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

type Props = {
    organizationId?: number
    label?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'select', organizationValue: OrganizationModel): void
}
defineEmits<Emits>()

const getOrganizations = (name?: string) =>
    organizationApi
        .getOrganizations(name ? { filters: { name } } : {}, true)
        .then(res => (organizationsOptions.value = res.data))
        .finally(() => (isLoadingOrganization.value = false))
onMounted(() => getOrganizations())

const handleTyping = (value: string) => {
    isLoadingOrganization.value = true
    debouncedOrganization(value)
}
const isLoadingOrganization = ref(false)
const debouncedOrganization = useDebounceFn(
    (value: string) => getOrganizations(value),
    EDebounceTime.DEFAULT
)

const organizationsOptions = ref<OrganizationModel[]>([])
const selectedOrganization = computed(
    () => organizationsOptions.value.find(item => item.id === props.organizationId)?.name ?? ''
)
</script>

<template>
    <AutocompleteField
        :label="label ?? $t('user.create.existingOrganization')"
        :loading="isLoadingOrganization"
        :modelValue="selectedOrganization"
        :options="organizationsOptions"
        @typing="handleTyping"
        @select="$emit('select', $event as OrganizationModel)"
        :clearable="false" />
</template>
