import i18n from '@/locales/i18n'
import ticketService from '@/services/ticket.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { TicketModel } from '@/types/models/TicketModel'
import { generateFormData } from '@/utils/fileUtils'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { ETicketActions, ETicketMutations, type TicketState } from './TicketStoreTypes'

const state: Ref<PaginationDataType<TicketState>> = ref({
    data: {
        tickets: []
    },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getTickets: () => state.value.data.tickets,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [ETicketActions.FETCH_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        ticketId: number
    ) {
        commit(ETicketMutations.SET_LOADING, true)
        return await ticketService
            .getTicket(ticketId)
            .then(res => {
                commit(ETicketMutations.SET_TICKET, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(ETicketMutations.SET_LOADING, false))
    },

    async [ETicketActions.FETCH_TICKETS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(ETicketMutations.SET_LOADING, true)
        return await ticketService
            .getTickets(params)
            .then(data => {
                commit(ETicketMutations.SET_TICKETS, data.data)
                commit(ETicketMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(ETicketMutations.SET_LOADING, false)
            })
    },

    async [ETicketActions.CREATE_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { data, documents }: { data: TicketModel; documents: File[] }
    ) {
        commit(ETicketMutations.SET_LOADING, true)
        return ticketService
            .createTicket(generateFormData(data, documents))
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.object,
                    key: ETicketActions.CREATE_TICKET
                })
                return res.data
            })
            .finally(() => commit(ETicketMutations.SET_LOADING, false))
    },

    async [ETicketActions.UPDATE_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { ticketId, data }: { ticketId: number; data: TicketModel }
    ) {
        commit(ETicketMutations.SET_LOADING, true)
        return ticketService
            .updateTicket(ticketId, data)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.object,
                    key: ETicketActions.UPDATE_TICKET
                })
            )
            .finally(() => {
                commit(ETicketMutations.SET_LOADING, false)
            })
    },

    async [ETicketActions.DELETE_TICKET](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { ticketId, name }: { ticketId: number; name: string }
    ) {
        commit(ETicketMutations.SET_LOADING, true)
        return ticketService
            .deleteTicket(ticketId)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: ETicketActions.DELETE_TICKET
                })
            )
            .finally(() => commit(ETicketMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`ticket.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`ticket.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ETicketMutations.SET_TICKETS](_: TicketState, tickets: TicketModel[]) {
        state.value.data.tickets = tickets
    },
    [ETicketMutations.SET_TICKET](_: TicketState, ticket: TicketModel) {
        state.value.data.ticket = ticket
    },
    [ETicketMutations.SET_TOTAL](_: TicketState, data: PaginationDataType<TicketModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [ETicketMutations.SET_LOADING](_: TicketState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
