<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import DividerItem from '@/components/Divider/DividerItem.vue'
import InputField from '@/components/Fields/InputField.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import { isLastArrayElementNullOrUndefined } from '@/utils/dataUtils'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { ref } from 'vue'
import NumberInputField from '../../../../../components/Fields/NumberInputField.vue'

type EnumValues = {
    values?: (number | string | boolean | undefined)[]
    values_labels?: (string | undefined)[]
}

type Props = {
    values?: (number | string | boolean | undefined)[]
    values_labels?: (string | undefined)[]
    dataType?: EPrimitive
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: EnumValues): void
}
const emit = defineEmits<Emits>()

const isSubmitted = ref(false)

const handleUpdate = (value: number, key: 'values' | 'values_labels', index: number) => {
    const tmp = [...(props[key] ?? [])]
    tmp[index] = value
    emit('change', { [key]: tmp })
}
const handleClick = () => {
    if (
        isLastArrayElementNullOrUndefined(props.values) ||
        isLastArrayElementNullOrUndefined(props.values_labels)
    )
        return (isSubmitted.value = true)
    isSubmitted.value = false
    emit('change', {
        values: [...(props.values?.filter(item => !isNullOrUndefined(item)) ?? []), undefined],
        values_labels: [
            ...(props.values_labels?.filter(item => !isNullOrUndefined(item)) ?? []),
            undefined
        ]
    })
}
</script>
<template>
    <StackLayout direction="column" :gap="8">
        <StackLayout direction="row" :gap="8">
            <TypographyItem
                :label="$t('deviceConfig.templateKeys.model.enumValues')"
                class="full-width" />
            <TypographyItem
                :label="$t('deviceConfig.templateKeys.model.enumLabels')"
                class="full-width" />
        </StackLayout>
        <StackLayout v-for="(item, key) in values" :key="key" direction="column" :gap="8">
            <StackLayout direction="row" :gap="8">
                <NumberInputField
                    :modelValue="item as number"
                    :hasError="isSubmitted && isNullOrUndefined(item)"
                    @change="
                        handleUpdate(!isNullOrUndefined($event) ? $event : undefined, 'values', key)
                    "
                    class="full-width" />

                <InputField
                    :modelValue="values_labels?.[key]"
                    :hasError="isSubmitted && isNullOrUndefined(values_labels?.[key])"
                    @change="handleUpdate($event ?? undefined, 'values_labels', key)"
                    class="full-width" />
            </StackLayout>
            <DividerItem class="full-width enum-divider" />
        </StackLayout>
        <SecondaryButton
            :label="$t('deviceConfig.templateKeys.buttons.addNewPair')"
            @click="handleClick"
            class="fit-content align-self-end" />
    </StackLayout>
</template>
