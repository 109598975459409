<script setup lang="ts">
import { InputField } from '@/components/Fields'
import EventCheckpointAutocomplete from '@/components/Filters/components/EventCheckpointAutocomplete.vue'
import { GridLayout } from '@/components/Layouts'
import DeviceFilterAutocomplete from '@/components/Selector/components/DeviceFilterAutocomplete.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { useDebounceFn } from '@vueuse/core'

type Props = {
    filters: DetectionFilters
    deviceOptions?: AssociatedDeviceModel[]
    selectedEvent?: Partial<EventModel>
}
const props = defineProps<Props>()

type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
    (e: 'selectEvent', value?: EventModel): void
}
const emit = defineEmits<Emits>()

const gridSize = useGridSize()

const handleChange = (value: Partial<DetectionModel>) => {
    if (
        (props.filters.event_id ?? undefined) === (value.event_id ?? undefined) &&
        (props.filters.checkpoint_id ?? undefined) === (value.checkpoint_id ?? undefined) &&
        (props.filters.device_id ?? undefined) === (value.device_id ?? undefined)
    )
        return
    emit('change', value)
}

const handleTyping = (value: Record<string, string | number | undefined>) =>
    value ? debouncedOrganization(value) : emit('change', value)

const debouncedOrganization = useDebounceFn(
    (value: Record<string, string | number | undefined>) => emit('change', value),
    1000
)

const handleSelectEvent = (event?: EventModel) => emit('selectEvent', event)
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8" class="full-width flex-wrap" isResponsive>
        <EventCheckpointAutocomplete
            :event="selectedEvent"
            :event_id="filters.event_id"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            filterAllEvents
            @change="handleChange($event)"
            @selectEvent="handleSelectEvent" />
        <DeviceFilterAutocomplete
            :filters="filters"
            :deviceOptions="selectedEvent?.detection_devices"
            @change="handleChange($event)" />
        <InputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            :prefix="$t('unit.number')"
            @change="
                $event ? handleTyping({ bib: $event }) : $emit('change', { bib: undefined })
            " />
    </GridLayout>
</template>
