<script setup lang="ts">
import { InputField } from '@/components/Fields'
import SortByFilter from '@/components/Filters/SortByFilter.vue'
import { StackLayout } from '@/components/Layouts'
import { ESortBy, ESortByDate } from '@/types/enum/SortByEnum'
import type { DeviceHistoryModel } from '@/types/models/DeviceHistoryModel'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters?: Partial<DeviceHistoryModel>
    sort: ESortBy
}
defineProps<Props>()
type Emits = {
    (e: 'changeSortBy', value: ESortBy): void
    (e: 'changeFilters', value: Partial<DeviceHistoryModel>): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value: string) => {
    isLoadingName.value = true
    debouncedName(value)
}
const isLoadingName = ref(false)
const debouncedName = useDebounceFn((name: string) => {
    emit('changeFilters', { name })
    isLoadingName.value = false
}, 500)
</script>

<template>
    <StackLayout :gap="8" class="space-between">
        <InputField
            :label="$t('device.detail.history.filters.name')"
            :modelValue="filters?.name"
            :loading="isLoadingName"
            @change="handleTyping" />
        <SortByFilter
            :sortBy="sort"
            :options="ESortByDate"
            @click="$emit('changeSortBy', $event)"
            class="fit-content" />
    </StackLayout>
</template>

<style scoped lang="scss">
.key-name {
    color: $primary;
}
.modified-by {
    color: $primary;
    color: var(--color-text-secondary);
}
.user-name {
    color: var(--color-text-secondary);
}
</style>
