<script setup lang="ts">
import TypedInput from '@/components/Fields/TypedInput.vue'
import { StackLayout } from '@/components/Layouts'
import FullScreenLoader from '@/components/LoadingSpinner/FullScreenLoader.vue'
import { BaseDialog } from '@/components/Modals/Dialog'
import { TypographyItem } from '@/components/Typography'
import store from '@/store'
import { ECheckpointActions } from '@/store/checkpointStore/CheckpointStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { ref } from 'vue'
import EventCreateCheckpointInputs from './EventCreateCheckpointInputs.vue'

type Props = {
    eventId: number
    open: boolean
    partnerConfig?: PartnerModel
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'refresh'): void
    (e: 'close'): void
}>()

const isSubmitted = ref(false)
const data = ref<Partial<CheckpointModel>>({ comments: '' })
const createCheckpoint = () => {
    isSubmitted.value = true
    if (!data.value.name) return
    store
        .dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.CREATE_CHECKPOINT}`, {
            eventId: props.eventId,
            data: data.value
        })
        .then(() => {
            emit('refresh')
            isSubmitted.value = false
            data.value = { comments: '' }
        })
}
</script>

<template>
    <FullScreenLoader :isLoading="!!store.state.checkpoint.isLoading" />
    <BaseDialog
        :open="open"
        :title="$t('event.detail.checkpoints.createCheckpointTitle')"
        :loading="!!store.state.checkpoint.isLoading"
        @confirm="createCheckpoint"
        @close="$emit('close')">
        <template #content>
            <StackLayout direction="column" :gap="16">
                <EventCreateCheckpointInputs
                    :data="data"
                    :eventId="eventId"
                    :isSubmitted="isSubmitted"
                    @change="data = { ...data, ...$event }" />
                <TypographyItem
                    v-if="partnerConfig?.config?.checkpoint"
                    :label="`${$t('event.detail.checkpoints.partner')} : ${partnerConfig.name}`" />
                <StackLayout v-if="partnerConfig?.config?.checkpoint" class="full-width">
                    <TypedInput
                        v-for="([itemKey, itemValue], key) in Object.entries(
                            partnerConfig?.config?.checkpoint
                        )"
                        :key="key"
                        :label="itemKey"
                        :dataType="itemValue"
                        :modelValue="data.partner_config?.[itemKey]"
                        :hasError="isSubmitted && !data.partner_config"
                        required
                        @change="data.partner_config = { [itemKey]: $event }" />
                </StackLayout>
            </StackLayout>
        </template>
    </BaseDialog>
</template>
