<script setup lang="ts">
import { BaseCard } from '@/components/Cards'
import { DialogActions, DialogHeader } from '@/components/Modals/Dialog'
import { computed } from 'vue'
type Props = {
    title?: string
    open: boolean
    closeLabel?: string
    confirmLabel?: string
    noFooter?: boolean
    disabled?: boolean
    loading?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'close'): void
    (e: 'confirm'): void
}>()

const dialogOpen = computed({
    get: () => props.open,
    set: () => emit('close')
})
</script>

<template>
    <b-modal
        v-model="dialogOpen"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        :aria-label="title"
        aria-modal
        class="modal-dialog"
        v-bind="$attrs">
        <BaseCard class="modal-card">
            <template #title v-if="title">
                <DialogHeader :title="title" @close="$emit('close')" />
            </template>
            <template #content><slot name="content" /></template>
            <template #footer v-if="!noFooter">
                <DialogActions
                    :confirmLabel="confirmLabel"
                    :closeLabel="closeLabel"
                    :disabled="disabled"
                    :loading="loading"
                    @close="$emit('close')"
                    @confirm="$emit('confirm')">
                    <slot name="actions" />
                </DialogActions>
            </template>
        </BaseCard>
    </b-modal>
</template>
