<script setup lang="ts">
import InputField from '@/components/Fields/InputField.vue'
import { type TemplateKeysFilters } from '@/types/filters/TemplateFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'

type Props = {
    filters?: TemplateKeysFilters
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: TemplateKeysFilters): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value: TemplateKeysFilters) => debouncedName(value)

const debouncedName = useDebounceFn(
    (value: TemplateKeysFilters) => emit('change', value),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <InputField
        :label="$t('deviceConfig.templateKeys.table.key')"
        @change="handleTyping({ name: $event })" />
</template>
