export const REGEX_UPPERCASE = /[A-Z]/
export const REGEX_LOWERCASE = /[a-z]/
export const REGEX_NUMBER = /[0-9]/
export const REGEX_SPECIAL = /[^\s|\w]/

export const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,5}$/

export const REGEX_CREATE = /Create$/
export const REGEX_DETAIL = /Detail$/
export const REGEX_TABLE = /Table$/
export const REGEX_GENERATE = /Generate$/

export const REGEX_SERIALNUMBER = /^[345]/
