<script setup lang="ts">
import { TextButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import type { WidgetModel } from '@/types/models/WidgetModel'
import { useRouter } from 'vue-router'
import { BaseCard } from '../Cards'
import { TypographyWithPrefix } from '../Typography'

const router = useRouter()

type Props = {
    title?: string
    data?: WidgetModel
}
defineProps<Props>()
</script>

<template>
    <BaseCard class="widget-card primary-border background-secondary">
        <template #title>{{ title }}</template>
        <template #content>
            <slot>
                <StackLayout v-if="data?.content.length" direction="column" :gap="8">
                    <TypographyWithPrefix
                        v-for="(item, key) in data?.content"
                        :key="key"
                        :prefix="item.label">
                        <template #edit>
                            <TextButton
                                :text="item.value"
                                @click="router.push(item.link)"
                                class="is-primary" />
                        </template>
                    </TypographyWithPrefix>
                </StackLayout>
            </slot>
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.widget-card {
    height: fit-content;
    &.card {
        background-color: var(--color-background-secondary);
    }
    & a {
        color: $primary;
        text-decoration: underline;
    }
}
</style>
