/* TECHNICIAN ROUTES */
export const TECHNICIAN_ROUTE = '/technician'

export const TECHNICIAN_INVENTORY_ROUTE = `${TECHNICIAN_ROUTE}/inventory`
export const TECHNICIAN_INVENTORY_ROUTES = {
    baseUrl: TECHNICIAN_INVENTORY_ROUTE,
    createUrl: `${TECHNICIAN_INVENTORY_ROUTE}/create`,
    detailUrl: `${TECHNICIAN_INVENTORY_ROUTE}/:id`,
    buildUrl: (deviceId: number) => `${TECHNICIAN_INVENTORY_ROUTE}/${deviceId}`
}

export const TECHNICIAN_TICKETS_ROUTE = `${TECHNICIAN_ROUTE}/tickets`
export const TECHNICIAN_TICKETS_ROUTES = {
    baseUrl: TECHNICIAN_TICKETS_ROUTE,
    createUrl: `${TECHNICIAN_TICKETS_ROUTE}/create`,
    detailUrl: `${TECHNICIAN_TICKETS_ROUTE}/:id`,
    buildUrl: (ticketId: number) => `${TECHNICIAN_TICKETS_ROUTE}/${ticketId}`
}

export const TECHNICIAN_QUESTIONS_ROUTE = `${TECHNICIAN_ROUTE}/questions`
export const TECHNICIAN_QUESTIONS_ROUTES = {
    baseUrl: TECHNICIAN_QUESTIONS_ROUTE,
    createUrl: `${TECHNICIAN_QUESTIONS_ROUTE}/create`,
    detailUrl: `${TECHNICIAN_QUESTIONS_ROUTE}/:id`,
    buildUrl: (questionId: number) => `${TECHNICIAN_QUESTIONS_ROUTE}/${questionId}`
}

export const TECHNICIAN_DOCUMENTATIONS_ROUTE = `${TECHNICIAN_ROUTE}/documentations`
export const TECHNICIAN_DOCUMENTATIONS_ROUTES = {
    baseUrl: TECHNICIAN_DOCUMENTATIONS_ROUTE,
    createUrl: `${TECHNICIAN_DOCUMENTATIONS_ROUTE}/create`,
    detailUrl: `${TECHNICIAN_DOCUMENTATIONS_ROUTE}/:id`,
    buildUrl: (documentId: number) => `${TECHNICIAN_DOCUMENTATIONS_ROUTE}/${documentId}`
}

export const TECHNICIAN_TOPICS_ROUTE = `${TECHNICIAN_ROUTE}/topics`
export const TECHNICIAN_TOPICS_ROUTES = {
    baseUrl: TECHNICIAN_TOPICS_ROUTE,
    createUrl: `${TECHNICIAN_TOPICS_ROUTE}/create`,
    detailUrl: `${TECHNICIAN_TOPICS_ROUTE}/:id`,
    buildUrl: (topicId: number) => `${TECHNICIAN_TOPICS_ROUTE}/${topicId}`
}
