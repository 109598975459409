export type AddressModel = {
    street: string
    street2?: string
    street3?: string
    zip_code?: string
    city: string
    country: string
}

export const defaultAddressModel: AddressModel = {
    street: '',
    city: '',
    country: ''
}
