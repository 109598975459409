// axiosSetup.js
import { AUTH_PREFIX } from '@/config/api'
import i18n from '@/locales/i18n'
import router, { BASE_URL, LOGIN_URL } from '@/router'
import TokenService from '@/services/token.service'
import store from '@/store'
import { EAlertActions } from '@/store/alertStore/AlertStoreTypes'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { ApiError } from '@/types/ApiError'
import { setGlobalLoading } from '@/utils/storeUtils'
import axios, {
    type AxiosInstance,
    type AxiosResponse,
    type InternalAxiosRequestConfig
} from 'axios'
import axiosApiInstance from './api'
import axiosAuthInstance from './auth'
import axiosCrmInstance from './crm'
import axiosDeviceInstance from './device'

const AUTH_REFRESH_URL = `${AUTH_PREFIX}/refresh`
const AUTH_LOGIN_URL = `${AUTH_PREFIX}/login`

let isRefreshing: boolean = false
let failedQueue: { resolve: any; reject: any }[] = []

const processQueue = (error: any, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })
    failedQueue = []
}

const setup = () => {
    const configureRequestInterceptors = (instance: AxiosInstance) => {
        instance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                const token = TokenService.getLocalAccessToken()
                if (token) config.headers['Authorization'] = 'Bearer ' + token
                config.headers['X-Language'] = i18n.global.locale.value
                return config
            },
            error => {
                setGlobalLoading(false)
                store.dispatch(
                    `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
                    error ?? i18n.global.t('error.unknownError')
                )
                return Promise.reject({ error })
            }
        )
    }

    const handleNetworkError = () => {
        const message = i18n.global.t('error.serverConnectionFailed')
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message
        })
        return Promise.reject({ error: message })
    }

    const handleSessionExpired = () => {
        const message = i18n.global.t('error.sessionExpired')
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message
        })
        TokenService.removeUser()
        router.push(LOGIN_URL)
        return Promise.reject({ error: message })
    }

    const handleNotFound = (error: AxiosResponse) => {
        const message =
            error.data.message ?? error.data.error.message ?? i18n.global.t('error.notFound')
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message: message
        })
        return Promise.reject(message)
    }

    const handleForbidden = () => {
        const message = i18n.global.t('error.forbidden')
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message
        })
        router.replace(BASE_URL)
        return Promise.reject(message)
    }

    const handleNotProcessable = (error: AxiosResponse) => {
        const flattenedError = Object.entries(error.data.errors ?? error.data).reduce(
            (prev, [key, value]) =>
                `${prev}${(value as string[]).reduce(
                    (item, valueItem) => `${item}${key}: ${valueItem}\n`,
                    ''
                )}`,
            ''
        )
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message: flattenedError
        })
        return Promise.reject(flattenedError)
    }

    const isRefreshTokenEndpoint = (url: string) => url === AUTH_REFRESH_URL

    const isExcludedEndpoint = (url: string) => [AUTH_REFRESH_URL, AUTH_LOGIN_URL].includes(url)

    const handleAccessTokenRefresh = async (error: any) => {
        const refreshToken = TokenService.getLocalRefreshToken()
        try {
            const originalRequest = error.config
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
                    .then(token =>
                        axios({
                            ...originalRequest,
                            headers: { Authorization: `Bearer ${token}` }
                        })
                    )
                    .catch(err => Promise.reject(err))
            }

            originalRequest._retry = true
            isRefreshing = true

            const response = await axiosAuthInstance({
                method: 'POST',
                url: AUTH_REFRESH_URL,
                headers: { Authorization: `Bearer ${refreshToken}` }
            })
                .then(res => {
                    processQueue(null, res.data.access_token)
                    return res
                })
                .catch(err => {
                    processQueue(err, null)
                    isRefreshing = false
                    store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGOUT}`)
                    return err
                })
                .finally(() => (isRefreshing = false))

            const accessToken = response.data.access_token
            store.dispatch(
                `${EStoreModules.AUTHENTICATION}/${EAuthActions.REFRESH_TOKEN}`,
                accessToken
            )
            TokenService.updateLocalAccessToken(accessToken)

            setGlobalLoading(false)
            return axiosApiInstance(error.config)
        } catch (refreshError: unknown) {
            setGlobalLoading(false)
            store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
                action: EAlertActions.ERROR,
                message: (refreshError as ApiError)?.error ?? i18n.global.t('error.refreshError')
            })
            store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGOUT}`)
            return Promise.reject(refreshError)
        }
    }

    configureRequestInterceptors(axiosApiInstance) // TODO : Remove global loading spinner
    configureRequestInterceptors(axiosAuthInstance)
    configureRequestInterceptors(axiosCrmInstance)
    configureRequestInterceptors(axiosDeviceInstance)

    const handleInterceptorResponse = (response: AxiosResponse) => {
        setGlobalLoading(false)
        return response
    }

    const handleInterceptorError = async (error: any) => {
        setGlobalLoading(false)
        if (error.message === 'Network Error') return handleNetworkError()
        if (error.response && error.response.status === 403) return handleForbidden()
        if (
            error.response &&
            error.response.status === 401 &&
            isRefreshTokenEndpoint(error.config.url)
        )
            return handleSessionExpired()
        else if (
            error.response &&
            error.response.status === 401 &&
            !error.config._retry &&
            !isExcludedEndpoint(error.config.url)
        ) {
            error.config._retry = true
            return await handleAccessTokenRefresh(error.response)
        }
        if (error.response && error.response.status === 422)
            return handleNotProcessable(error.response)
        if (error.response && error.response.status === 404) return handleNotFound(error.response)

        const errorMessage =
            (error.response?.data &&
                (error.response.data.message ?? error.response.data.error?.message)) ??
            i18n.global.t('error.unknownError')
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message: errorMessage
        })
        return Promise.reject(errorMessage)
    }

    axiosAuthInstance.interceptors.response.use(handleInterceptorResponse, handleInterceptorError)

    axiosApiInstance.interceptors.response.use(handleInterceptorResponse, handleInterceptorError)

    axiosCrmInstance.interceptors.response.use(handleInterceptorResponse, handleInterceptorError)

    axiosDeviceInstance.interceptors.response.use(handleInterceptorResponse, handleInterceptorError)
}

export default setup
