<script setup lang="ts">
import { DeviceCreatePage } from '@/components/DeviceComponents'
import {
    ADMIN_DEVICES_ROUTES,
    ADMIN_ORGANIZATION_DEVICE_HREF
} from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()

const organizationId = computed(() =>
    route.params.organization_id ? parseInt(route.params.organization_id?.toString()) : undefined
)

const data = ref<Partial<DeviceModel>>({
    ...defaultCreateDevice,
    data: organizationId.value ? { organization_id: organizationId.value } : {}
})

const updateDevice = (value: Partial<DeviceModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.CREATE_DEVICE}`, data.value)
        .then(res =>
            router.replace(
                organizationId.value
                    ? `${ADMIN_ORGANIZATION_DEVICE_HREF(organizationId.value).editButtonHref}/${
                          res.id
                      }`
                    : ADMIN_DEVICES_ROUTES.buildUrl(res.id)
            )
        )
</script>

<template>
    <DeviceCreatePage
        :organizationId="organizationId"
        :deviceData="data"
        :loading="store.state.device.isLoading"
        @create="create"
        @change="updateDevice" />
</template>
