<script setup lang="ts">
import TableLayout from '@/components/Layouts/TableLayout.vue'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import { computed, getCurrentInstance, ref } from 'vue'

type Props = {
    showDetails?: boolean
    data: unknown[]
    loading?: boolean
    pagination?: {
        pageSize?: number
        count?: number
        currentPage: number
    }
}

withDefaults(defineProps<Props>(), {
    pagination: () => ({ pageSize: EPaginationOptions.SIZE_25, count: 0, currentPage: 1 })
})
defineEmits<{
    (e: 'click', row: any): void
}>()

const table = ref<any>([])

const toggleDetails = (row: unknown) => table.value.toggleDetails(row)

const isOddNumber = (key: number) => key % 2 !== 0

const onClickDefined = computed(() => getCurrentInstance()?.vnode.props?.['onClick'])

defineExpose({ table })
</script>

<template>
    <TableLayout>
        <b-table
            ref="table"
            :data="data"
            detailed
            narrowed
            :bordered="false"
            custom-detail-row
            detail-key="id"
            :loading="loading"
            backend-pagination
            :mobile-cards="true"
            :total="pagination.count"
            :per-page="pagination.pageSize"
            :current-page="pagination.currentPage"
            :page-input="true"
            sticky-header
            page-input-position="is-input-left"
            page-input-size="is-small"
            :show-detail-icon="showDetails"
            @cellclick="(row: unknown) => (onClickDefined ? undefined : toggleDetails(row))"
            @click="$emit('click', $event)"
            cell-class="fit-buttons"
            class="flex flex-column space-between custom-table"
            :row-class="(_: unknown, index: number) => isOddNumber(index) && 'striped-row'"
            v-bind="$attrs">
            <slot name="columns" class="columns" />
            <template #detail="props">
                <slot name="detail" class="striped-detail" v-bind="props" />
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('common.noRecord') }}</div>
            </template>
        </b-table>
    </TableLayout>
</template>
