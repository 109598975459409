<script setup lang="ts">
import { type FilterTagModel } from '@/types/components/FilterTagModel'
import { EColors } from '@/types/constants/ColorValues'

type Props = {
    item: FilterTagModel
}
defineProps<Props>()
const emit = defineEmits(['close'])

const removeItemFromSelected = (event: MouseEvent, value: FilterTagModel) => {
    event.stopPropagation()
    emit('close', value)
}
</script>

<template>
    <b-tag
        :type="`is-${EColors.PRIMARY}`"
        closable
        aria-close-label="Close tag"
        @close="removeItemFromSelected($event, item)"
        v-bind="$attrs">
        {{ item.label ?? item.name }}
    </b-tag>
</template>
