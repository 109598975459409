export type ZoneState = {
    zone?: any
    zones: any
    isLoading: boolean
    isCreating: boolean
    isDeleting: boolean
}

export enum EZoneMutations {
    SET_ZONES = 'setZones',
    SET_DELETE_ZONE = 'setZone',
    SET_IS_DELETING = 'setTotal'
}
