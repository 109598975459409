export const organizationFr = {
    admin: {
        actions: {
            dissociateDeviceMessage: 'Êtes-vous sûr de vouloir dissocier cet appareil de {name} ?'
        },
        detail: {
            actionsTitle: 'Actions',
            informationsTitle: 'Informations',
            localizationTitle: 'Localisation'
        },
        model: {
            name: 'Nom',
            socialReason: 'Raison sociale',

            street: 'Rue',
            zipCode: 'Code postal',
            city: 'Ville',
            country: 'Pays'
        },
        table: {
            id: 'ID',
            name: 'Nom',
            createOrganization: 'Ajouter une entreprise'
        }
    },
    api: {
        error: {
            createOrganization: "Erreur lors de la création de l'entreprise {name}",
            deleteOrganization: "Erreur lors de la suppression de l'entreprise {name}",
            fetchOrganization: "Erreur lors de la récupération de l'entreprise",
            fetchOrganizations: 'Erreur lors de la récupération des entreprises',
            updateOrganization: "Erreur lors de la modification de l'entreprise {name}",

            createUser: "Erreur lors de la création de l'utilisateur {name}",
            initOrganizationWithUser:
                "Erreur lors de la création de l'utilisateur {name} et de l'organisation",

            fetchMyOrganization: 'Erreur lors de la récupération de mon entreprise',
            fetchOrganizationUsers:
                'Erreur lors de la récupération des utilisateurs de mon entreprises',
            fetchOrganizationApis: 'Erreur lors de la récupération des APIs de mon entreprises',
            attachUserToOrganization:
                "Erreur lors du rattachement de l'utilisateur à l'entreprises",
            detachUserFromOrganization:
                "Erreur lors du détachement de l'utilisateur à l'entreprises",
            fetchOrganizationDevices:
                "Erreur lors de la récupération de l'appareil de mon entreprises",
            attachDeviceToOrganization: "Erreur lors du rattachement de l'appareil à l'entreprises",
            detachDeviceFromOrganization:
                "Erreur lors du détachement de l'appareil à l'entreprises",

            onboarding: "Erreur lors de l'envoi de la demande d'accès"
        },
        success: {
            createOrganization: "Création de l'entreprise {name} effectuée avec succès !",
            deleteOrganization: "Suppression de l'entreprise {name} effectuée avec succès !",
            fetchOrganization: "Récupération de l'entreprise effectuée avec succès !",
            fetchOrganizations: 'Récupération des entreprises effectuée avec succès !',
            updateOrganization: "Modification(s) de l'entreprise {name} effectuée(s) avec succès !",

            createUser: "Création de l'utilisateur {name} effectuée avec succès !",
            initOrganizationWithUser:
                "Création de l'utilisateur {name} et initialisation de l'organisation effectuées avec succès !",

            fetchMyOrganization: 'Récupération de mon entreprise effectuée avec succès !',
            fetchOrganizationUsers:
                "Récupération des utilisateurs de l'entreprises effectuée avec succès !",
            fetchOrganizationApis: "Récupération des APIs de l'entreprises effectuée avec succès !",
            attachUserToOrganization:
                "Rattachement de l'utilisateur à l'entreprise effectuée avec succès !",
            detachUserFromOrganization:
                "Détachement de l'utilisateur à l'entreprise effectuée avec succès !",
            fetchOrganizationDevices:
                "Récupération des appareils de l'entreprise effectuée avec succès !",
            attachDeviceToOrganization:
                "Rattachement de l'appareil à l'entreprise effectuée avec succès !",
            detachDeviceFromOrganization:
                "Détachement de l'appareil à l'entreprise effectuée avec succès !",

            onboarding: "Demande d'accès effectuée avec succès !"
        }
    },
    buttons: {
        associateAnUser: 'Associer un utilisateur',
        dissociateAnUser: 'Dissocier un utilisateur',
        associateUser: "Associer l'utilisateur",
        dissociateUser: "Dissocier l'utilisateur",
        associateDevice: "Associer l'appareil",
        dissociateDevice: "Dissocier l'appareil"
    },
    filters: {
        deactivated: 'Uniquement les utilisateurs désactivés'
    },
    organizations: {
        api: {
            error: {
                updateOrganization: "Erreur lors de la modification de l'entreprise {name}"
            },
            success: {
                updateOrganization:
                    "Modification(s) de l'entreprise {name} effectuée(s) avec succès !"
            }
        },
        buttons: {
            addOrganization: 'Ajouter un partenaire'
        },
        invite: {
            addFriendCode: 'Ajouter un code ami',
            friendCode: 'Code ami',
            inviteLink: "Lien d'invitation",
            yourFriendCode: 'Votre code ami',
            yourLink: "Votre lien d'invitation",
            qrCode: 'QR Code'
        },
        table: {
            id: 'ID',
            name: 'Nom complet',
            email: 'Email',
            roles: 'Rôles'
        }
    },
    informations: {
        general: 'Général'
    },
    model: {
        id: 'ID',
        name: 'Nom',
        socialReason: 'Raison sociale',
        organizationId: "ID de l'organisation",
        deviceId: "ID de l'appareil",
        userId: "ID de l'utilisateur"
    },
    users: {
        buttons: {
            associateUser: 'Associer un utilisateur existant',
            createUser: 'Créer un utilisateur'
        },
        roles: 'Rôles'
    },
    title: {
        associateUserTitle: 'Associer un utilisateur à une organisation',
        dissociateUserTitle: "Dissocier un utilisateur d'une organisation",

        associateDeviceTitle: 'Associer un appareil à une organisation',
        dissociateDeviceTitle: "Dissocier un appareil d'une organisation",

        myOrganization: "Gestion d'entreprise"
    },
    tabs: {
        devicesTitle: 'Appareils',
        informationsTitle: 'Informations',
        customersTitle: 'Clients',
        rolesTitle: 'Rôles',
        usersTitle: 'Utilisateurs',
        apisTitle: 'Apis'
    }
}
