<script setup lang="ts">
import { DeleteButton, EditButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import { TableColumn } from '@/components/Table'
import { EConfirmDialog } from '@/types/enum/ConfirmDialogEnum'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { computed, getCurrentInstance, ref } from 'vue'
import { userHasPermission } from '../../utils/permissionsUtils'
import ConfirmationDialog from '../Modals/Dialog/ConfirmationDialog.vue'
import PermissionWrapper from '../Permission/PermissionWrapper.vue'
import HrefWrapper from '../Wrappers/HrefWrapper.vue'

type Props = {
    editLabel?: string
    editIcon?: string
    deleteIcon?: string
    dataKey?: string
    titleItemKey?: string
    type?: EConfirmDialog.ARCHIVE | EConfirmDialog.DELETE
    hideEdit?: boolean
    hideDelete?: boolean
    withDeleteDialog?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    editButtonHref?: string
    hrefKey?: string
}
defineProps<Props>()
const emit = defineEmits<{
    (e: 'goTo', data: any): void
    (e: 'delete', data: any): void
}>()

const selectedRow = ref()

const handleDeleteButton = (data: any, e: MouseEvent) => {
    e.stopPropagation()
    selectedRow.value = data
}

const confirmDeleteRow = () => {
    emit('delete', selectedRow.value)
    selectedRow.value = undefined
}

const deleteIsDefined = computed(() => getCurrentInstance()?.vnode.props?.['onDelete'])
</script>

<template>
    <TableColumn :label="$t('common.actions')" width="50px" v-slot="props">
        <StackLayout :gap="8" class="button-end">
            <slot name="before" v-bind="props.row" />
            <PermissionWrapper
                v-if="!hideEdit"
                :permission="editPermission"
                :hasPermission="!editPermission">
                <HrefWrapper
                    :hasPermission="editPermission ? userHasPermission(editPermission) : true"
                    :href="`${editButtonHref}/${props.row[hrefKey ?? 'id']}`">
                    <EditButton :icon="editIcon" isResponsive />
                </HrefWrapper>
            </PermissionWrapper>
            <PermissionWrapper
                v-if="!hideDelete"
                :permission="deletePermission"
                :hasPermission="!deletePermission">
                <DeleteButton
                    v-if="deleteIsDefined"
                    :icon="deleteIcon"
                    :type="type"
                    @click="e => handleDeleteButton(props.row, e)"
                    isResponsive />
            </PermissionWrapper>
            <slot name="after" v-bind="props.row" />
        </StackLayout>
    </TableColumn>
    <ConfirmationDialog
        v-if="selectedRow"
        :open="!!selectedRow"
        :type="type ?? EConfirmDialog.DELETE"
        :titleItem="selectedRow[titleItemKey ?? 'name']"
        @close="selectedRow = undefined"
        @confirm="confirmDeleteRow" />
</template>
