<script setup lang="ts">
import { ToggleButtonField } from '@/components/Fields'
import { getCurrentThemeMode, setCurrentThemeMode } from '@/utils/viewsUtils'
import { computed } from 'vue'

const modeSwitch = computed({
    get: () => (getCurrentThemeMode.value === 'light' ? true : false),
    set: value => {
        getCurrentThemeMode.value = value ? 'light' : 'dark'
        setCurrentThemeMode(value ? 'light' : 'dark')
    }
})
</script>

<template>
    <ToggleButtonField
        passive-type="is-primary"
        type="is-warning"
        v-model="modeSwitch"
        @change="value => (modeSwitch = value)"
        :label="$t(`common.theme.${getCurrentThemeMode}`)"
        v-bind="$attrs" />
</template>
