<script setup lang="ts">
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { BaseDialog } from '@/components/Modals/Dialog'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import topicApi from '@/services/topic.service'
import { defaultTopicFilters } from '@/types/filters/TopicFilters'
import type { TopicModel } from '@/types/models/TopicModel'
import { ref } from 'vue'

type Props = {
    selectedTopic: TopicModel
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'close'): void
}
const emit = defineEmits<Emits>()

const topic = ref(props.selectedTopic ?? defaultTopicFilters)

const updateTopic = () => {
    if (!props.selectedTopic?.id) return
    topicApi.updateTopic(props.selectedTopic?.id, props.selectedTopic).then(() => emit('refresh'))
}

const nameOption = ref([])
</script>

<template>
    <BaseDialog
        hasModalCard
        :title="$t('technician.ticket.topic.updateTopic')"
        :open="!!topic"
        :confirmLabel="$t('technician.ticket.topic.update')"
        @confirm="updateTopic"
        @close="$emit('close')">
        <template #content>
            <StackLayout direction="column" :gap="16">
                <InputField
                    :modelValue="topic.name?.fr"
                    :label="$t('technician.ticket.topic.nameTopicFr')"
                    :placeholder="$t('technician.ticket.topic.placeholderNameTopicFr')"
                    @change="topic.name = { ...topic.name, fr: $event }"
                    required />
                <InputField
                    :modelValue="topic.name?.en"
                    :label="$t('technician.ticket.topic.nameTopicEn')"
                    :placeholder="$t('technician.ticket.topic.placeholderNameTopicEn')"
                    @change="topic.name = { ...topic.name, en: $event }"
                    required />
                <AutocompleteField
                    :label="$t('technician.ticket.ticket.parent_id')"
                    :placeholder="$t('technician.ticket.ticket.select_parent_id')"
                    :modelValue="topic.parent_id"
                    :options="nameOption"
                    @select="topic.parent_id = $event.value as number" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>
