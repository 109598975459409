<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import { EKeyCategory, EKeyCategoryLabels } from '@/types/enum/KeyCategoryEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { computed, ref } from 'vue'
import DeviceConfigurationItem from './DeviceConfigurationItem.vue'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
    showAdvanced: boolean
    config: Record<string, DeviceConfigTemplateKey[]>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', data: Partial<DeviceModel>): void
    (e: 'refresh'): void
    (e: 'hasAdvancedConfig', value: boolean): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: { [key: string]: ExtendedPrimitives | undefined }) => {
    emit('update', { config: { ...props.data.config, ...value } })
}

const specificRef = ref()
const hasError = computed(() => specificRef.value?.configurationHasError)
const hasAdvancedConfig = computed(() => !!specificRef.value?.advancedConfigLength)

defineExpose({ hasError, hasAdvancedConfig })
</script>

<template>
    <InformationBaseCard
        v-if="config[EKeyCategory.SPECIFIC]"
        v-show="specificRef?.configLength || (specificRef?.advancedConfigLength && showAdvanced)"
        :label="EKeyCategoryLabels[EKeyCategory.SPECIFIC]"
        class="full-width fit-height">
        <DeviceConfigurationItem
            ref="specificRef"
            :data="data.config"
            :templateKeys="config[EKeyCategory.SPECIFIC]"
            @change="handleChange"
            :isInEditMode="isInEditMode"
            :showAdvanced="showAdvanced" />
    </InformationBaseCard>
</template>
