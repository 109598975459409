<script setup lang="ts">
import IconButton from '@/components/Buttons/IconButton.vue'
import NumberInputField from '@/components/Fields/NumberInputField.vue'
import { StackLayout } from '@/components/Layouts'
import SelectorField from '@/components/Selector/SelectorField.vue'
import store from '@/store'
import { EPaginationMutations } from '@/store/paginationStore/PaginationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EColors } from '@/types/constants/ColorValues'
import { EPaginationOptionsValues } from '@/types/enum/PaginationOptionsEnum'
import { EDebounceTime } from '@/utils/debounceUtils'
import { addToQuery } from '@/utils/queryUtils'
import { getCurrentPage, getPageSize } from '@/utils/routeUtils'
import { isSmallScreen, isSmallScreenEvent } from '@/utils/viewsUtils'
import { onKeyStroke, useDebounceFn } from '@vueuse/core'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import DividerItem from '../Divider/DividerItem.vue'
import TypographyWithPrefix from '../Typography/TypographyWithPrefix.vue'
import TablePaginationButton from './TablePaginationButton.vue'

const pageSizeOptions = EPaginationOptionsValues.map(item => ({
    name: item.toString(),
    value: item.toString()
}))

type Props = {
    total?: number
    perPage?: number
    currentPage?: number
    lastPage?: number
    from?: number
    to?: number
    forcePropsCurrentPage?: boolean
}
const props = withDefaults(defineProps<Props>(), {
    total: 0,
    currentPage: getCurrentPage() ?? 1,
    lastPage: 1
})

type Emits = {
    (e: 'change:pageNumber', value: number): void
    (e: 'change:pageSize', value: number): void
}
const emit = defineEmits<Emits>()

const route = useRoute()

const pageSize = computed({
    get: () => props.perPage ?? getPageSize() ?? store.state.pagination.per_page,
    set: per_page => {
        store.commit(`${EStoreModules.PAGINATION}/${EPaginationMutations.SET_PAGE_SIZE}`, per_page)
        emit('change:pageSize', per_page)
    }
})
const pageNumber = computed({
    get: () => props.currentPage ?? 1,
    set: page => emit('change:pageNumber', page)
})
watch(
    () => route.query.page,
    newValue =>
        props.forcePropsCurrentPage
            ? props.currentPage
            : pageNumber.value !== parseInt((newValue ?? 1)?.toString())
            ? (pageNumber.value = parseInt((newValue ?? 1)?.toString()))
            : undefined
)

onMounted(() => {
    pageNumber.value = props.forcePropsCurrentPage
        ? props.currentPage
        : getCurrentPage() ?? props.currentPage ?? 1
})

const previousPageExists = computed(() => pageNumber.value > 1)
const nextPageExists = computed(() => pageNumber.value * pageSize.value < props.total)

const changePageSize = (value: number) => (pageSize.value = value)

const changePageNumber = (value: number) => (pageNumber.value = Math.min(value, props.lastPage))

const hideDivider = ref(isSmallScreen())
isSmallScreenEvent(e => (hideDivider.value = e.matches))

const handleChange = (value: number) => {
    isLoadingPagination.value = true
    debouncedQuestion(value)
}
const isLoadingPagination = ref(false)
const debouncedQuestion = useDebounceFn(
    (value: number) => changePageNumber(value),
    EDebounceTime.DEFAULT
)

onKeyStroke('ArrowLeft', () =>
    previousPageExists.value ? changePageNumber(pageNumber.value - 1) : null
)
onKeyStroke('ArrowRight', () =>
    nextPageExists.value ? changePageNumber(pageNumber.value + 1) : null
)

onBeforeUnmount(() => {
    addToQuery({ page: undefined }, true)
})
</script>

<template>
    <StackLayout direction="column" :gap="8" class="align-items-center full-width">
        <StackLayout direction="row" :gap="8" isResponsiveSmallScreen>
            <IconButton
                :icon="{ label: 'chevron-left', type: EColors.LIGHT }"
                @click="changePageNumber(currentPage - 1)"
                v-bind:disabled="!previousPageExists"
                :type="EColors.PRIMARY"
                tabindex="0"
                class="pagination-icon" />

            <TablePaginationButton
                :currentPage="currentPage"
                :lastPage="lastPage"
                @change="changePageNumber" />

            <IconButton
                :icon="{ label: 'chevron-right', type: EColors.LIGHT }"
                v-bind:disabled="!nextPageExists"
                tabindex="1"
                @click="changePageNumber(currentPage + 1)"
                :type="EColors.PRIMARY"
                class="pagination-icon" />
        </StackLayout>
        <SelectorField
            :modelValue="pageSize"
            @change="value => changePageSize(value)"
            :options="pageSizeOptions" />
        <StackLayout :gap="8" class="align-self-center fit-content" isResponsive>
            <TypographyWithPrefix
                prefix="Page"
                class="color-text pagination-page no-wrap"
                isResponsive>
                <template #edit>
                    <NumberInputField
                        :modelValue="pageNumber"
                        @change="handleChange"
                        class="field-size background-color"
                        v-bind:min="1"
                        v-bind:max="lastPage" />
                    <span class="align-self-center color-text">
                        / {{ lastPage ? lastPage : 1 }}
                    </span>
                </template>
            </TypographyWithPrefix>
            <DividerItem v-if="!hideDivider" orientation="vertical" variant="middle" isResponsive />
            <span v-if="total !== undefined" class="align-self-center color-text">
                {{ $t('pagination.total') }} : {{ total }}
                {{ $t(`pagination.${total > 1 ? 'lines' : 'line'}`) }}
            </span>
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.field-size {
    width: 50px;
}
.pagination-icon svg {
    height: 24px;
}
.pagination-page {
    & :deep(.prefix-span) {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: unset;
    }
}
</style>
