<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { computed } from 'vue'

type Props = {
    data: Partial<OrganizationModel>
    submitted: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<OrganizationModel>): void
}
const emit = defineEmits<Emits>()

const dataInformations = computed({
    get: () => props.data,
    set: value => emit('change', { ...value })
})

const errors = computed(() => ({
    nameError: props.submitted && !dataInformations.value.name,
    socialReasonError: props.submitted && !dataInformations.value.social_reason
}))
</script>

<template>
    <InformationBaseCard
        class="full-width fit-height"
        :label="$t('organization.admin.detail.informationsTitle')">
        <StackLayout
            direction="column"
            :gap="16"
            class="full-width center-items input-information-card">
            <InputField
                :label="$t('organization.admin.model.name')"
                v-model="dataInformations.name"
                @change="value => (dataInformations.name = value)"
                :placeholder="$t('organization.admin.model.name')"
                required
                :hasError="errors.nameError"
                class="full-width" />
            <InputField
                :label="$t('organization.admin.model.socialReason')"
                v-model="dataInformations.social_reason"
                @change="value => (dataInformations.social_reason = value)"
                :placeholder="$t('organization.admin.model.socialReason')"
                required
                :hasError="errors.socialReasonError"
                class="full-width" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.input-information-card {
    & .field.is-floating-label .label::before {
        background-color: var(--color-background-secondary);
    }
}

.full-width > .control > .input {
    background-color: var(--color-background-secondary);
}
</style>
