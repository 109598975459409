<script setup lang="ts">
import { EditButton } from '@/components/Buttons'
import FullTableLayout from '@/components/Layouts/FullTableLayout.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import type { TableEmits, TableProps } from '@/types/components/CommonTableTypes'
import { type TicketFilters } from '@/types/filters/TicketFilters'
import type { TicketModel } from '@/types/models/TicketModel'
import { ETicketsPerm } from '@/types/permissions/TicketsPermissionsEnum'
import moment from 'moment'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import HrefCreateButton from '../Buttons/HrefCreateButton.vue'
import DatePickerField from '../Fields/DatePickerField.vue'
import InputField from '../Fields/InputField.vue'
import StackLayout from '../Layouts/StackLayout.vue'
import PermissionWrapper from '../Permission/PermissionWrapper.vue'

type Props = TableProps<TicketModel, TicketFilters> & {
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
}
type Emits = TableEmits<TicketModel, TicketFilters>

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const columns = {
    id: { field: 'id', label: t('technician.ticket.model.id') },
    name: { field: 'name', label: t('technician.ticket.model.name') },
    title: { field: 'title', label: t('technician.ticket.model.title') },
    topic: { field: 'topic', label: t('technician.ticket.model.topic') },
    statut: { field: 'statut', label: t('technician.ticket.model.statut') },
    start_date: { field: 'start_date', label: t('technician.ticket.model.startDate') },
    severity: { field: 'severity', label: t('technician.ticket.model.severity') },
    status: { field: 'status', label: t('technician.ticket.model.statut') }
}

const goToCreate = () => emit('goToCreate')
const goToDetail = (ticket: TicketModel) => emit('goToDetail', ticket)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)

const table = ref()
</script>

<template>
    <PermissionWrapper :permission="ETicketsPerm.TICKETS_EDIT">
        <HrefCreateButton
            :href="createButtonHref"
            :label="$t('technician.ticket.table.createTicket')"
            @click="goToCreate" />
    </PermissionWrapper>
    <FullTableLayout
        isResponsiveSmallScreen
        :sortBy="sortBy"
        @search="$emit('search')"
        @changeSortBy="$emit('changeSortBy', $event)"
        v-bind="$attrs">
        <template #filters>
            <StackLayout direction="row" :gap="8" class="full-width" isResponsive>
                <InputField
                    :label="$t('technician.ticket.filter.object')"
                    :modelValue="filters?.filters?.object"
                    @change="$emit('change', { object: $event })" />

                <DatePickerField
                    :label="$t('technician.ticket.filter.createDate')"
                    :modelValue="filters?.filters?.start_date"
                    @change="
                        $emit('change', {
                            start_date: $event ? moment($event).startOf('d').toDate() : $event
                        })
                    " />
                <slot name="filters" />
            </StackLayout>
        </template>

        <template #table>
            <BaseTable showDetails v-bind:ref="table" :data="data.data">
                <template #columns>
                    <TableColumn field="id" :label="columns['id'].label" />
                    <TableColumn field="object" :label="columns['title'].label" />
                    <TableColumn :label="columns['topic'].label" v-slot="props">
                        {{ props.row.topic.trans }}
                    </TableColumn>
                    <TableColumn :label="columns['status'].label" v-slot="props">
                        {{ props.row.status.trans }}
                    </TableColumn>
                    <TableColumn :label="columns['severity'].label" v-slot="props">
                        {{ props.row.severity.trans }}
                    </TableColumn>
                    <TableActionColumn
                        editIcon="ticket"
                        deleteIcon="box-archive"
                        @goTo="goToDetail"
                        @delete="$emit('delete', $event)"
                        :editButtonHref="editButtonHref"
                        :editPermission="editPermission"
                        :deletePermission="deletePermission">
                        <template #before>
                            <EditButton icon="envelope-open" is-responsive />
                        </template>
                    </TableActionColumn>
                </template>
            </BaseTable>
        </template>

        <template #pagination>
            <TablePagination
                :currentPage="data.current_page"
                :lastPage="data.last_page"
                :total="data.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>
        <template #dialogContent>
            <slot name="advancedFilters" />
        </template>
    </FullTableLayout>
</template>
