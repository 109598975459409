<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import {
    EDeviceStatus,
    EDeviceStatusColors,
    EDeviceStatusLabels
} from '@/types/enum/DeviceStatusEnum'
import { computed } from 'vue'

type Props = {
    status?: EDeviceStatus | string
    withLabel?: boolean
}
const props = defineProps<Props>()

const deviceStatus = computed(() =>
    props.status ? (parseInt(props.status as string) as EDeviceStatus) : EDeviceStatus.OFFLINE
)
</script>

<template>
    <TypographyWithPrefix :label="$t(EDeviceStatusLabels[deviceStatus])">
        <IconItem icon="circle" :type="EDeviceStatusColors[deviceStatus]" />
    </TypographyWithPrefix>
</template>
