<script setup lang="ts">
import CustomField from '@/components/Fields/CustomField.vue'
import TagItem from '@/components/Tags/TagItem.vue'
import { type SelectorObject } from '@/types/components/SelectorObject'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { computed, ref } from 'vue'
import type { FilterTagModel } from '../../types/components/FilterTagModel'

type Props = {
    options: SelectorObject[]
    label?: string
    placeholder?: string
    modelValue: SelectorObject[]
    type?: Colors
    field?: string
    errorText?: string
}
const props = defineProps<Props>()

type Emits = {
    (e: 'change', value: SelectorObject[]): void
}
const emit = defineEmits<Emits>()

const modelValue = computed({
    get: () =>
        props.modelValue.map(item => ({
            ...item,
            name: item.name ?? item.label ?? item.value?.toString() ?? ''
        })),
    set: value => emit('change', value)
})

const inputText = ref<string>()
const filteredOptions = computed(() =>
    inputText.value
        ? props.options?.filter(
              item =>
                  item[(props.field as keyof Pick<SelectorObject, 'name'>) ?? 'name']?.includes(
                      inputText.value?.toLowerCase() ?? ''
                  )
          )
        : props.options?.filter(
              item => !modelValue.value.find(itemModel => itemModel.name === item.name)
          )
)
const getFilteredTags = (value: string) => (inputText.value = value)

const addTag = (value: FilterTagModel) => (modelValue.value = [...modelValue.value, value])
const removeTag = (value: FilterTagModel) =>
    (modelValue.value = modelValue.value.filter(item => item.name !== value.name))
</script>

<template>
    <CustomField :label="label ?? ''" :message="errorText" :type="type" class="multitag-field">
        <b-taginput
            v-model="modelValue"
            :data="filteredOptions"
            ellipsis
            autocomplete
            openOnFocus
            :field="field ?? 'name'"
            :placeholder="placeholder ?? label"
            :type="`is-${EColors.PRIMARY}`"
            @add="addTag"
            @typing="getFilteredTags">
            <template #empty>
                {{ $t('common.noOption') }}
            </template>
            <template #selected>
                <TagItem
                    v-for="(item, key) in modelValue"
                    :item="item"
                    :key="key"
                    @close="removeTag" />
            </template>
        </b-taginput>
    </CustomField>
</template>

<style scoped lang="scss">
.multitag-field {
    &:deep(.taginput-container) {
        background-color: var(--color-background-secondary);
    }
    & :deep(.dropdown-content) {
        background-color: var(--color-background-secondary);
        border: 1px solid $primary;

        &.is-selectable {
            color: var(--color-text);
        }
        & .dropdown-item {
            color: var(--color-text);
            white-space: normal;
            &:hover {
                background-color: $primary;
            }
        }
    }
}
</style>
