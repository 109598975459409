<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { computed } from 'vue'

type Props = {
    currentPage?: number
    lastPage?: number
}
const props = withDefaults(defineProps<Props>(), {
    currentPage: 1,
    lastPage: 1
})

const emit = defineEmits(['change'])

const changePageNumber = (value: number) => emit('change', value)

const firstPage = computed(() =>
    props.currentPage !== 1
        ? {
              label: '1',
              cb: () => changePageNumber(1)
          }
        : undefined
)
const pageBefore2 = computed(() =>
    props.currentPage - 3 >= 1
        ? {
              label: '...',
              cb: () => changePageNumber(props.currentPage - 2)
          }
        : undefined
)
const pageBefore = computed(() =>
    props.currentPage - 2 >= 1
        ? {
              label: (props.currentPage - 1).toString(),
              cb: () => changePageNumber(props.currentPage - 1)
          }
        : undefined
)

const pageAfter = computed(() =>
    props.currentPage + 2 <= props.lastPage
        ? {
              label: (props.currentPage + 1).toString(),
              cb: () => changePageNumber(props.currentPage + 1)
          }
        : undefined
)
const pageAfter2 = computed(() =>
    props.currentPage + 3 <= props.lastPage
        ? {
              label: '...',
              cb: () => changePageNumber(props.currentPage + 2)
          }
        : undefined
)
const lastPage = computed(() =>
    props.lastPage !== 0 && props.currentPage !== props.lastPage
        ? {
              label: props.lastPage.toString(),
              cb: () => changePageNumber(props.lastPage)
          }
        : undefined
)
</script>

<template>
    <StackLayout direction="row" :gap="8">
        <SecondaryButton v-if="firstPage" :label="firstPage.label" @click="firstPage.cb" />
        <SecondaryButton v-if="pageBefore2" :label="pageBefore2.label" @click="pageBefore2.cb" />
        <SecondaryButton
            v-if="pageBefore !== undefined"
            :label="pageBefore.label"
            @click="pageBefore.cb" />
        <div class="button button-gap is-primary not-clickable">
            {{ props.currentPage }}
        </div>
        <SecondaryButton v-if="pageAfter" :label="pageAfter.label" @click="pageAfter.cb" />
        <SecondaryButton v-if="pageAfter2" :label="pageAfter2.label" @click="pageAfter2.cb" />
        <SecondaryButton v-if="lastPage" :label="lastPage.label" @click="lastPage.cb" />
    </StackLayout>
</template>

<style scoped lang="scss">
.not-clickable {
    pointer-events: none;
}
</style>
