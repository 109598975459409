<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import DividerItem from '@/components/Divider/DividerItem.vue'
import { CheckboxField, InputField } from '@/components/Fields'
import { GridLayout, StackLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { TypographyItem } from '@/components/Typography'
import store from '@/store'
import { EPermissionActions } from '@/store/permissionsStore/PermissionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { PermissionModel } from '@/types/models/PermissionModel'
import type { RoleModel } from '@/types/models/RoleModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { groupPermissions } from '@/utils/permissionsUtils'
import { isSmallScreen, isSmallScreenEvent } from '@/utils/viewsUtils'
import { computed, ref, watch } from 'vue'

type Props = {
    data: Partial<RoleModel>
    buttonLabel: string
    permission?: PermissionsEnums
    hasPermission?: boolean
    loading?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<RoleModel>): void
    (e: 'click'): void
}

const emit = defineEmits<Emits>()
const updateDeviceValue = (value: Partial<RoleModel>) => emit('update', value)

const data = computed(() => props.data)
const permissions = computed(() =>
    groupPermissions<PermissionModel>(store.state.permission.permissions ?? [], 'name')
)

const getPermissions = () =>
    store.dispatch(`${EStoreModules.PERMISSION}/${EPermissionActions.FETCH_PERMISSIONS}`, {
        guard_name: props.data.guard_name ?? 'user'
    })
watch(
    () => props.data?.guard_name,
    newValue => getPermissions(),
    { immediate: true }
)

const createRole = () => {
    submitted.value = true
    if (errors.value.nameError || errors.value.guardNameError) return
    emit('click')
}

const handlePermission = (permission: string) => {
    updateDeviceValue({
        permissions: props.data.permissions?.find(item => item === permission)
            ? props.data.permissions.filter(item => item !== permission)
            : [...(props.data.permissions ?? []), permission]
    })
}

const submitted = ref(false)
const errors = computed(() => ({
    nameError: submitted.value && !props.data.name,
    guardNameError: submitted.value && !props.data.guard_name
}))

const MAX_ITEM_PER_ROW = ref(isSmallScreen() ? 1 : 3)
isSmallScreenEvent(e => (MAX_ITEM_PER_ROW.value = e.matches ? 1 : 3))
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <StackLayout :gap="16" isResponsive>
            <InformationBaseCard :label="$t('role.informationsTitle')" class="fit-content">
                <StackLayout :gap="8" class="full-width">
                    <InputField
                        :label="$t('role.model.name')"
                        v-model="data.name"
                        @change="name => updateDeviceValue({ name })"
                        :placeholder="$t('role.model.name')"
                        required
                        :hasError="errors.nameError"
                        class="full-width" />
                </StackLayout>
            </InformationBaseCard>
            <slot />
        </StackLayout>
        <InformationBaseCard :label="$t('role.permissionsTitle')">
            <StackLayout v-if="permissions" direction="column" :gap="12" class="full-width">
                <StackLayout
                    v-for="([itemKey, itemValue], key) in Object.entries(permissions as any)"
                    :key="key"
                    direction="column"
                    :gap="12">
                    <StackLayout direction="column" :gap="4">
                        <TypographyItem :label="itemKey" />
                        <GridLayout
                            :maxItemPerRow="MAX_ITEM_PER_ROW"
                            :gap="8"
                            class="wrap-anywhere">
                            <CheckboxField
                                v-for="(permission, index) in itemValue as PermissionModel[]"
                                :key="index"
                                :label="`${permission.trans}`"
                                :value="!!data.permissions?.find(item => item === permission.name)"
                                @click="handlePermission(permission.name)" />
                        </GridLayout>
                    </StackLayout>
                    <DividerItem
                        v-if="key + 1 < Object.entries(permissions).length"
                        class="divider-item" />
                </StackLayout>
            </StackLayout>
        </InformationBaseCard>
        <PermissionWrapper :permission="permission" :hasPermission="hasPermission">
            <CreateButton :loading="loading" :label="buttonLabel" @click="createRole" />
        </PermissionWrapper>
    </StackLayout>
</template>

<style scoped lang="scss">
.divider-item {
    color: $primary;
}
</style>
