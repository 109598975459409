<script setup lang="ts">
import { PrimaryButton } from '@/components/Buttons'
import { InputField, NumberInputField } from '@/components/Fields'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { EDeviceType } from '@/types/enum/DeviceTypeEnum'
import { EOnboardingDeviceTypeOptions } from '@/types/enum/OnboardingEnum'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import {
    defaultDeviceOnboardingModel,
    type DeviceOnboardingModel
} from '@/types/models/OnboardingModel'
import { computed, ref } from 'vue'

type Emits = {
    (e: 'add', value: DeviceOnboardingModel): void
}
const emit = defineEmits<Emits>()

const newDevice = ref<DeviceOnboardingModel>({
    ...defaultDeviceOnboardingModel
})

const submitted = ref(false)
const hasErrors = computed(() => ({
    nameError: submitted.value && !newDevice.value.name.length,
    serialNumberError: submitted.value && newDevice.value.serial_number?.length !== 6,
    deviceTypeError: submitted.value && !newDevice.value.device_type_id,
    imeiSatError:
        submitted.value &&
        deviceTypeHasImeiSAT.value &&
        !newDevice.value.imei?.[1]?.toString().length
}))

const handleValidation = () => {
    submitted.value = true
    if (Object.values(hasErrors.value).find(item => !!item)) return
    emit('add', newDevice.value)
    newDevice.value = { ...defaultDeviceOnboardingModel }
    submitted.value = false
}

const deviceTypeHasImeiSAT = computed(
    () =>
        newDevice.value.device_type_id === EDeviceType.NANO_SAT ||
        newDevice.value.device_type_id === EDeviceType.MODULE_SAT
)

const handleSelect = (value?: AssociatedDeviceModel) => {
    newDevice.value.device_type_id = value?.id
    newDevice.value.device_type_name = value?.name
}
</script>

<template>
    <StackLayout :gap="16" direction="column" class="half-width">
        <InputField
            :label="$t('authentication.onboarding.devicesStep.model.name')"
            :modelValue="newDevice?.name"
            @change="newDevice.name = $event"
            :hasError="hasErrors.nameError"
            required />
        <InputField
            :label="$t('authentication.onboarding.devicesStep.model.serialNumber')"
            :modelValue="newDevice?.serial_number"
            @change="newDevice.serial_number = $event"
            :hasError="hasErrors.serialNumberError"
            required />
        <AutocompleteField
            :label="$t('authentication.onboarding.devicesStep.model.deviceType')"
            :modelValue="
                EOnboardingDeviceTypeOptions.find(type => type.id === newDevice.device_type_id)
                    ?.name
            "
            @select="handleSelect($event as AssociatedDeviceModel)"
            :hasError="hasErrors.deviceTypeError"
            :options="EOnboardingDeviceTypeOptions" />
        <NumberInputField
            v-if="deviceTypeHasImeiSAT"
            :label="$t('authentication.onboarding.devicesStep.model.imeiSAT')"
            :modelValue="newDevice?.imei?.[1]"
            @change="newDevice.imei = [newDevice.imei?.[0] ?? undefined, $event]"
            :hasError="hasErrors.imeiSatError"
            required />
        <PrimaryButton
            :label="$t('authentication.onboarding.devicesStep.buttons.validateDevice')"
            @click="handleValidation"
            class="fit-content" />
    </StackLayout>
</template>

<style scoped lang="scss">
.half-width {
    max-width: 50%;
}
</style>
