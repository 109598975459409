import type { TechnicianTicketModel } from '@/types/models/TechnicianTicketModel'
import type { PaginationDataType } from '@/types/PaginationType'

export type TechnicianState = {
    tickets: {
        ticket?: TechnicianTicketModel
        isLoading: boolean
    } & PaginationDataType<TechnicianTicketModel[]>
}

export enum ETechnicianActions {
    FETCH_TECHNICIAN_TICKETS = 'fetchTechnicianTickets',
    FETCH_TECHNICIAN_TICKET = 'fetchTechnicianTicket',
    CREATE_TECHNICIAN_TICKET = 'createTechnicianTicket',
    UPDATE_TECHNICIAN_TICKET = 'updateTechnicianTicket',
    DELETE_TECHNICIAN_TICKET = 'deleteTechnicianTicket',
    IMPERSONATE_TECHNICIAN_TICKET = 'impersonateTechnicianTicket',
    DEIMPERSONATION_TECHNICIAN_TICKET = 'disincarnateTechnicianTicket'
}

export enum ETechnicianMutations {
    SET_TECHNICIAN_TICKETS = 'setTechnicianTickets',
    SET_TECHNICIAN_TICKET = 'setTechnicianTicket',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
