export enum ESortBy {
    ALPHABETICAL_ASC = 'asc',
    ALPHABETICAL_DESC = 'desc',
    DATE_RECENT = 'latest',
    DATE_OLD = 'oldest'
}

export const ESortByAlphabetical = [ESortBy.ALPHABETICAL_ASC, ESortBy.ALPHABETICAL_DESC]
export const ESortByDate = [ESortBy.DATE_RECENT, ESortBy.DATE_OLD]

export const ESortByLabels = {
    [ESortBy.ALPHABETICAL_ASC]: 'enum.sortBy.alphabeticalAsc',
    [ESortBy.ALPHABETICAL_DESC]: 'enum.sortBy.alphabeticalDesc',
    [ESortBy.DATE_RECENT]: 'enum.sortBy.dateRecent',
    [ESortBy.DATE_OLD]: 'enum.sortBy.dateOld'
}

export const getSortByKey = (enumValue: ESortBy) => {
    if (ESortByAlphabetical.includes(enumValue)) return { alphabetical: enumValue }
    if (ESortByDate.includes(enumValue)) return { [enumValue]: 1 }
    return { alphabetical: ESortBy.ALPHABETICAL_ASC }
}

export type SortKeyType = Partial<{
    alphabetical: ESortBy
    latest: 1
    oldest: 1
}>
