<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import CollapseItem from '@/components/Collapse/CollapseItem.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { TypographyItem } from '@/components/Typography'
import router from '@/router'
import { SUPPORTFAQ_ROUTE } from '@/router/routes'
import { type ThumbnailModel } from '@/types/models/ThumbnailModel'
import { ref } from 'vue'
import { CardContent } from '../../../Cards'

type Props = {
    thumbnails: ThumbnailModel[]
}
defineProps<Props>()

const filter = ref<string>()
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <InputField
            :modelValue="filter"
            @change="value => (filter = value)"
            :placeholder="$t('ticket.filter.deviceType')" />

        <CollapseItem
            v-for="(item, index) in thumbnails.filter(item =>
                item.name?.toLowerCase().includes((filter ?? '').toLowerCase())
            )"
            :key="index"
            :open="true"
            class="primary-border">
            <template #title>
                <TypographyItem :label="item.name" class="card-header-title" />
            </template>

            <template #content>
                <CardContent class="card-content">
                    <StackLayout direction="column" :gap="8">
                        <TypographyItem :label="item.content" />
                        <CreateButton
                            :label="$t('ticket.create.faq')"
                            @click="router.push(SUPPORTFAQ_ROUTE)" />
                    </StackLayout>
                </CardContent>
            </template>
        </CollapseItem>
    </StackLayout>
</template>
