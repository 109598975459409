import type { AnnouncementModel } from '@/types/models/AnnouncementModel'
import type { WidgetModel } from '@/types/models/WidgetModel'
import { DEVICE_ROUTE } from '../router/routes'

export const mockupAdminAnnouncements: AnnouncementModel[] = []

export const mockupAdminWidgets: WidgetModel[] = [
    {
        title: "Données d'inventaire",
        content: [
            {
                label: "Total d'appareils",
                value: 20,
                link: DEVICE_ROUTE
            },
            {
                label: 'Appareils en stocks',
                value: 15,
                link: `${DEVICE_ROUTE}`
            },
            {
                label: 'Appareils en location',
                value: 5,
                link: `${DEVICE_ROUTE}`
            }
        ]
    }
]
