<script setup lang="ts">
import { ADMIN_DEVICE_TEMPLATES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import DeviceTemplateView from './components/DeviceTemplateView.vue'

const router = useRouter()

const data = ref<Partial<DeviceConfigModel>>()

const updateDevice = (value: Partial<DeviceConfigModel>) =>
    (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(
            `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.CREATE_DEVICE_CONFIG}`,
            data.value
        )
        .then(res => router.replace(ADMIN_DEVICE_TEMPLATES_ROUTES.buildUrl(res.id)))
</script>

<template>
    <DeviceTemplateView
        :data="data"
        @change="updateDevice"
        @confirm="create"
        :permission="EAdminPerm.ADMIN_DEVICES_CONFIG"
        hasPermission />
</template>
