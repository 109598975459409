export enum ETechnicianDevicesPerm {
    TECHNICIAN_DEVICES_VIEW = 'technician_devices_view',
    TECHNICIAN_DEVICES_EDIT = 'technician_devices_edit',
    TECHNICIAN_DEVICES_DELETE = 'technician_devices_delete'
}
export enum ETechnicianTicketsPerm {
    TECHNICIAN_TICKETS_VIEW = 'technician_tickets_view',
    TECHNICIAN_TICKETS_EDIT = 'technician_tickets_edit',
    TECHNICIAN_TICKETS_DELETE = 'technician_tickets_delete'
}
export enum ETechnicianQuestionsPerm {
    TECHNICIAN_QUESTIONS_VIEW = 'technician_questions_view',
    TECHNICIAN_QUESTIONS_EDIT = 'technician_questions_edit',
    TECHNICIAN_QUESTIONS_DELETE = 'technician_questions_delete'
}
export enum ETechnicianDocumentationsPerm {
    TECHNICIAN_DOCUMENTATIONS_VIEW = 'technician_documentations_view',
    TECHNICIAN_DOCUMENTATIONS_EDIT = 'technician_documentations_edit',
    TECHNICIAN_DOCUMENTATIONS_DELETE = 'technician_documentations_delete'
}
export enum ETechnicianTopicsPerm {
    TECHNICIAN_TOPICS_VIEW = 'technician_topics_view',
    TECHNICIAN_TOPICS_EDIT = 'technician_topics_edit',
    TECHNICIAN_TOPICS_DELETE = 'technician_topics_delete'
}
