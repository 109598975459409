<script setup lang="ts">
import { MultilineField } from '@/components/Fields'
import { computed } from 'vue'
import EditableFieldWrapper from './EditableFieldWrapper.vue'

const props = defineProps<{
    label?: string
    tooltip?: string
    value?: string
    boldLabel?: boolean
    isInEditMode?: boolean
    required?: boolean
}>()
const emit = defineEmits<{ (e: 'change', value: string | undefined): void }>()

const modelValue = computed({
    get: () => props.value,
    set: value => emit('change', value)
})
</script>

<template>
    <EditableFieldWrapper
        :label="label"
        :tooltip="tooltip"
        :value="value"
        :boldLabel="boldLabel"
        :isInEditMode="isInEditMode">
        <MultilineField
            :modelValue="modelValue"
            @change="value => (modelValue = value)"
            :placeholder="label"
            :required="required"
            v-bind="$attrs" />
    </EditableFieldWrapper>
</template>
