<script setup lang="ts">
import { ADMIN_DEVICE_TYPES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import DeviceTypesView from './components/DeviceTypesView.vue'

const router = useRouter()

const data = ref<Partial<DeviceConfigModel>>()

const updateDevice = (value: Partial<DeviceConfigModel>) =>
    (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(
            `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.CREATE_DEVICE_TYPE}`,
            data.value
        )
        .then(res => router.replace(ADMIN_DEVICE_TYPES_ROUTES.buildUrl(res.id)))
</script>

<template>
    <DeviceTypesView :data="data" @change="updateDevice" @confirm="create" hasPermission />
</template>
