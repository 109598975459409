import {
    defaultDeviceTemplateKeysCreateModel,
    type DeviceTemplateKeysCreateModel
} from './DeviceTemplateKeysCreateModel'

export type DeviceTemplateKeysModel = DeviceTemplateKeysCreateModel & {
    id: number
}

export const defaultDeviceTemplateKeysModel: DeviceTemplateKeysModel = {
    ...defaultDeviceTemplateKeysCreateModel,
    id: 0
}
