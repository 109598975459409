<script setup lang="ts">
import { type IconType } from '@/types/components/IconType'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { computed } from 'vue'

interface ButtonProps {
    label?: string
    type: Colors
    icon?: IconType | string
}

const props = withDefaults(defineProps<ButtonProps>(), {
    type: EColors.PRIMARY
})
defineEmits<{
    (e: 'click', event: MouseEvent): void
}>()

const iconIsString = computed(() => (typeof props.icon === 'string' ? true : false))
const computedIcon = computed(() =>
    typeof props.icon === 'string'
        ? { label: props.icon, position: 'start' }
        : { ...props.icon, position: props.icon?.position ?? 'start' }
)
</script>

<template>
    <b-button
        v-bind="$attrs"
        :type="`is-${type}`"
        @click="$emit('click', $event)"
        :icon-left="computedIcon.position === 'start' ? computedIcon.label : undefined"
        :icon-right="computedIcon.position === 'end' ? computedIcon.label : undefined"
        :icon-pack="iconIsString ? undefined : (icon as IconType)?.pack ?? undefined"
        class="button-gap">
        <slot />
        {{ label }}
    </b-button>
</template>

<style>
.button .icon:first-child:not(:last-child) {
    margin: 0;
}
.button-gap {
    gap: 4px;
}
</style>
