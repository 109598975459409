<script setup lang="ts">
import { TextButton } from '@/components/Buttons'
import { BaseCard } from '@/components/Cards'
import { StackLayout } from '@/components/Layouts'
import SelectorField from '@/components/Selector/SelectorField.vue'
import { TitleTypography } from '@/components/Typography'
import { TECHNICIAN_DOCUMENTATIONS_ROUTES } from '@/router/routing/technicianRouting/technicianRoutes'
import type { DocumentationModel } from '@/types/models/DocumentationModel'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

type Props = {
    title: string
    versions: string[]
    serialNumbers: string[]
    documents: DocumentationModel[]
}
const props = defineProps<Props>()

const router = useRouter()

const versionsOptions = computed(() => props.versions.map(item => ({ name: item, value: item })))
const serialNumbersOptions = computed(() =>
    props.serialNumbers.map(item => ({ name: item, value: item }))
)
const goToDoc = (doc: DocumentationModel) =>
    router.push(TECHNICIAN_DOCUMENTATIONS_ROUTES.buildUrl(doc.id))
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TitleTypography :title="title" class="title-large align-items-center" />
        <BaseCard class="documentation-card primary-border">
            <template #title>
                <StackLayout class="full-width">
                    <SelectorField
                        icon="bars"
                        placeholder="Version"
                        :options="versionsOptions"
                        class="full-width button-left" />
                    <SelectorField
                        placeholder="Serial Number"
                        :options="serialNumbersOptions"
                        class="full-width button-right" />
                </StackLayout>
            </template>
            <template #content>
                <StackLayout v-for="(item, key) in documents" :key="key" :gap="8">
                    <TextButton :text="`- ${item.name}`" @click="goToDoc(item)" class="text" />
                </StackLayout>
            </template>
        </BaseCard>
    </StackLayout>
</template>

<style scoped lang="scss">
.documentation-card {
    & :deep(.card-header-title) {
        padding: 0;
    }
    & :deep(.card-content) {
        padding: 1rem;
    }
    & .button-left {
        & :deep(.select select) {
            border-radius: 4px 0 0 0;
        }
    }
    & .button-right {
        & :deep(.select select) {
            border-radius: 0 4px 0 0;
        }
    }
    & .text:hover {
        color: $primary;
    }
}
</style>
