export enum EOrganizationRoutes {
    ORGANIZATION_HOME = 'organizationHome'
}
export const EOrganizationRoutesValues = [EOrganizationRoutes.ORGANIZATION_HOME]

export enum EOrganizationUsersRoutes {
    ORGANIZATION_USERS_CREATE = 'organizationUsersCreate',
    ORGANIZATION_USERS_DETAIL = 'organizationUsersDetail',
    ORGANIZATION_USERS_TABLE = 'organizationUsersTable'
}
export const EOrganizationUsersRoutesValues = [
    EOrganizationUsersRoutes.ORGANIZATION_USERS_CREATE,
    EOrganizationUsersRoutes.ORGANIZATION_USERS_DETAIL,
    EOrganizationUsersRoutes.ORGANIZATION_USERS_TABLE
]

export enum EOrganizationCustomersRoutes {
    ORGANIZATION_CUSTOMERS_CREATE = 'organizationCustomersCreate',
    ORGANIZATION_CUSTOMERS_DETAIL = 'organizationCustomersDetail',
    ORGANIZATION_CUSTOMERS_TABLE = 'organizationCustomersTable',
    ORGANIZATION_CUSTOMERS_GENERATE = 'organizationCustomersGenerate'
}
export const EOrganizationCustomersRoutesValues = [
    EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_CREATE,
    EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_DETAIL,
    EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_GENERATE,
    EOrganizationCustomersRoutes.ORGANIZATION_CUSTOMERS_TABLE
]

export enum EOrganizationRolesRoutes {
    ORGANIZATION_ROLES_CREATE = 'organizationRolesCreate',
    ORGANIZATION_ROLES_DETAIL = 'organizationRolesDetail',
    ORGANIZATION_ROLES_TABLE = 'organizationRolesTable'
}
export const EOrganizationRolesRoutesValues = [
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_CREATE,
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_DETAIL,
    EOrganizationRolesRoutes.ORGANIZATION_ROLES_TABLE
]

export enum EOrganizationApisRoutes {
    ORGANIZATION_APIS_CREATE = 'organizationApisCreate',
    ORGANIZATION_APIS_DETAIL = 'organizationApisDetail',
    ORGANIZATION_APIS_TABLE = 'organizationApisTable'
}
export const EOrganizationApisRoutesValues = [
    EOrganizationApisRoutes.ORGANIZATION_APIS_CREATE,
    EOrganizationApisRoutes.ORGANIZATION_APIS_DETAIL,
    EOrganizationApisRoutes.ORGANIZATION_APIS_TABLE
]
