export enum ETableMode {
    CARD = 0,
    LIST = 1,
    OUTLOOK = 2
}

export const ETableModeValues = [ETableMode.CARD, ETableMode.LIST]
export const ETableModeIcons = {
    [ETableMode.CARD]: 'table',
    [ETableMode.LIST]: 'list',
    [ETableMode.OUTLOOK]: 'table-list'
}
