<script setup lang="ts">
import defaultTheme from '@/scss/theme-default.module.scss'
import { onUpdated, ref } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { CreateButton, IconButton } from '../Buttons'
import { LoadingSpinner } from '../LoadingSpinner'
import { BaseDialog } from '../Modals/Dialog'
import QRCodeScannerMobile from './QRCodeScannerMobile.vue'

type Props = {
    isMobile?: boolean
}
defineProps<Props>()
type Emits = {
    (e: 'detect', detection: any): void
    (e: 'error', error: any): void
}
const emit = defineEmits<Emits>()

const { primary } = defaultTheme
const open = ref(false)
const loading = ref(true)
const paused = ref(false)

const onDetect = ([firstDetectedCodeValue]: [DetectedBarcode]) => {
    paused.value = true
    loading.value = true
    setTimeout(() => {
        open.value = false
        paused.value = false
        emit('detect', firstDetectedCodeValue)
    }, 2000)
}
const onError = (err: any) => emit('error', err)

const inputRef = ref()
const handleUploadClick = () => inputRef.value.$el.click()

const onCameraOn = () => {
    inputRef.value.$el.value = ''
    paused.value = false
    loading.value = false
}

const paintBoundingBox = (detectedCodes: DetectedBarcode[], ctx: any) => {
    detectedCodes.forEach(item => {
        ctx.strokeStyle = primary
        ctx.beginPath()
        const [firstPoint, ...otherPoints] = item.cornerPoints
        ctx.moveTo(firstPoint.x, firstPoint.y)
        otherPoints.forEach(({ x, y }: { x: number; y: number }) => ctx.lineTo(x, y))
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
    })
}
onUpdated(() => {
    if (open.value) {
        paused.value = false
        loading.value = true
    }
})
</script>

<template>
    <CreateButton :label="$t('common.scan')" @click="open = true" />
    <BaseDialog
        :open="open"
        @close="open = false"
        :full-screen="isMobile"
        noFooter
        :class="`${isMobile ? 'full-screen-scanner' : ''}`">
        <template #content>
            <LoadingSpinner :isLoading="loading" />
            <QrcodeStream
                v-if="open"
                :track="paintBoundingBox"
                @detect="onDetect"
                @error="onError"
                :paused="paused"
                @camera-on="onCameraOn"
                class="streaming-component">
                <IconButton icon="image" @click="handleUploadClick" />
            </QrcodeStream>
        </template>
    </BaseDialog>
    <QRCodeScannerMobile ref="inputRef" @detect="onDetect" @error="onError" />
</template>

<style scoped lang="scss">
.streaming-component {
    display: flex;
    & :deep(div) {
        display: flex;
        align-items: end;
        padding: 8px;
    }
}
.full-screen-scanner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    & :deep(.card-content) {
        height: 100%;
        width: 100%;
        padding: 0;
    }
}
</style>
