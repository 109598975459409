<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import BaseTable from '@/components/Table/BaseTable.vue'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import TableColumn from '@/components/Table/TableColumn.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import deviceConfigApi from '@/services/deviceConfig.service'
import { EKeyCategory, EKeyCategoryLabels } from '@/types/enum/KeyCategoryEnum'
import type { DeviceTemplateKeysCreateModel } from '@/types/models/DeviceTemplateKeysCreateModel'
import type { DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { booleanToString } from '@/utils/dataUtils'
import { computed, onMounted, ref } from 'vue'

type Props = {
    data?: Partial<DeviceTemplateKeysCreateModel & DeviceTemplateKeysModel>[]
    permission?: PermissionsEnums
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: DeviceTemplateKeysModel): void
    (e: 'delete', row: DeviceTemplateKeysModel): void
}
const emit = defineEmits<Emits>()

const options = ref<DeviceTemplateKeysModel[]>([])

const handleChange = (value: DeviceTemplateKeysModel) => {
    if (!value) return (inputField.value = '')
    if (props.data?.find(item => item.id === value.id)) return
    inputField.value = ''
    emit('change', value)
}

const getConfigKeys = (name?: string) =>
    deviceConfigApi
        .getAllDeviceTemplateKeys(name ? { filters: { name } } : undefined)
        .then(
            res =>
                (options.value = res.data.filter(
                    item => !props.data?.find(dataItem => dataItem.id === item.id)
                ))
        )

onMounted(() => {
    getConfigKeys()
})

const table = ref()

const inputField = ref('')
const filteredOptions = computed<DeviceTemplateKeysModel[]>(() =>
    options.value.filter(
        item =>
            !props.data?.find(dataItem => dataItem.id === item.id) &&
            item.name.includes(inputField.value)
    )
)
const handleTyping = (value: string) => (inputField.value = value)
</script>
<template>
    <StackLayout :gap="16" direction="column" class="full-width">
        <BaseTable :data="data ?? []" v-bind:ref="table" class="small">
            <template #columns>
                <TableColumn field="name" :label="$t('deviceConfig.templateKeys.table.key')" />
                <TableColumn
                    :label="$t('deviceConfig.templateKeys.model.description')"
                    v-slot="props">
                    <StackLayout direction="column" :gap="4">
                        <TypographyItem
                            v-for="([key, value], index) in Object.entries(
                                props.row.description ?? {}
                            )"
                            :key="index"
                            :label="`${key}: ${value}`" />
                    </StackLayout>
                </TableColumn>
                <TableColumn
                    :label="$t('deviceConfig.templateKeys.model.translation')"
                    v-slot="props">
                    <StackLayout direction="column" :gap="4">
                        <TypographyItem
                            v-for="([key, value], index) in Object.entries(
                                props.row.translation ?? {}
                            )"
                            :key="index"
                            :label="`${key}: ${value}`" />
                    </StackLayout>
                </TableColumn>
                <TableColumn field="type" :label="$t('deviceConfig.templateKeys.model.type')" />
                <TableColumn
                    field="default"
                    :label="$t('deviceConfig.templateKeys.model.default')" />
                <TableColumn :label="$t('deviceConfig.templateKeys.model.range')" v-slot="props">
                    <TypographyItem
                        :label="
                            props.row?.range?.min || props.row?.range?.max
                                ? `${props.row?.range?.min}-${props.row?.range?.max}`
                                : ''
                        " />
                </TableColumn>
                <TableColumn :label="$t('deviceConfig.templateKeys.model.excluded')" v-slot="props">
                    <TypographyItem
                        :label="
                            !(typeof props.row?.excluded === 'string')
                                ? props.row?.excluded?.join(',')
                                : ''
                        " />
                </TableColumn>
                <TableColumn :label="$t('deviceConfig.templateKeys.model.isAdmin')" v-slot="props">
                    <TypographyItem :label="booleanToString(props.row?.is_admin)" />
                </TableColumn>
                <TableColumn :label="$t('deviceConfig.templateKeys.model.masked')" v-slot="props">
                    <TypographyItem :label="booleanToString(props.row?.masked)" />
                </TableColumn>
                <TableColumn :label="$t('deviceConfig.templateKeys.model.readOnly')" v-slot="props">
                    <TypographyItem :label="booleanToString(props.row?.read_only)" />
                </TableColumn>
                <TableColumn :label="$t('deviceConfig.templateKeys.model.category')" v-slot="props">
                    <TypographyItem
                        :label="EKeyCategoryLabels[props.row?.category as EKeyCategory]" />
                </TableColumn>
                <TableActionColumn @delete="$emit('delete', $event)" hideEdit />
            </template>
        </BaseTable>
        <PermissionWrapper :permission="permission">
            <AutocompleteField
                :label="$t('deviceConfig.templateKeys.detail.addKey')"
                clearOnSelect
                :options="filteredOptions"
                :modelValue="inputField"
                keepOpen
                @typing="handleTyping"
                @select="handleChange($event as DeviceTemplateKeysModel)"
                class="fit-content" />
        </PermissionWrapper>
    </StackLayout>
</template>

<style scoped lang="scss">
td {
    padding: 4px;
    vertical-align: middle;
}
.spaced-columns {
    &:deep(td) {
        padding: 4px;
    }
}
</style>
