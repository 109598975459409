import i18n from '@/locales/i18n'
import topicService from '@/services/topic.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { TopicModel } from '@/types/models/TopicModel'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { ETopicActions, ETopicMutations, type TopicState } from './TopicStoreTypes'

const state: Ref<PaginationDataType<TopicState>> = ref({
    data: {
        topics: []
    },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getTopics: () => state.value.data.topics,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [ETopicActions.FETCH_TOPIC](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        topicId: number
    ) {
        commit(ETopicMutations.SET_LOADING, true)
        return await topicService
            .getTopic(topicId)
            .then(res => {
                commit(ETopicMutations.SET_TOPIC, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(ETopicMutations.SET_LOADING, false))
    },

    async [ETopicActions.FETCH_TOPICS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(ETopicMutations.SET_LOADING, true)
        return await topicService
            .getTopics(params)
            .then(data => {
                commit(ETopicMutations.SET_TOPICS, data.data)
                commit(ETopicMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(ETopicMutations.SET_LOADING, false)
            })
    },

    async [ETopicActions.CREATE_TOPIC](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: TopicModel
    ) {
        commit(ETopicMutations.SET_LOADING, true)
        return topicService
            .createTopic(data)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: ETopicActions.CREATE_TOPIC
                })
                return res.data
            })
            .finally(() => commit(ETopicMutations.SET_LOADING, false))
    },

    async [ETopicActions.UPDATE_TOPIC](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { topicId, data }: { topicId: number; data: TopicModel }
    ) {
        commit(ETopicMutations.SET_LOADING, true)
        return topicService
            .updateTopic(topicId, data)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: ETopicActions.UPDATE_TOPIC
                })
            )
            .finally(() => {
                commit(ETopicMutations.SET_LOADING, false)
            })
    },

    async [ETopicActions.DELETE_TOPIC](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { topicId, name }: { topicId: number; name: string }
    ) {
        commit(ETopicMutations.SET_LOADING, true)
        return topicService
            .deleteTopic(topicId)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: ETopicActions.DELETE_TOPIC
                })
            )
            .finally(() => commit(ETopicMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`topic.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`topic.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ETopicMutations.SET_TOPICS](_: TopicState, topics: TopicModel[]) {
        state.value.data.topics = topics
    },
    [ETopicMutations.SET_TOPIC](_: TopicState, topic: TopicModel) {
        state.value.data.topic = topic
    },
    [ETopicMutations.SET_TOTAL](_: TopicState, data: PaginationDataType<TopicModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [ETopicMutations.SET_LOADING](_: TopicState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
