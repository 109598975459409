<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import deviceConfigApi from '@/services/deviceConfig.service'
import type { DeviceTypesModel } from '@/types/models/DeviceTypesModel'
import { onMounted, ref } from 'vue'

type Props = {
    label?: string
    modelValue?: number
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'select', device: DeviceTypesModel): void
}>()

const deviceTypes = ref<DeviceTypesModel[]>([])
const getDeviceType = () =>
    deviceConfigApi.getDeviceTypes({ per_page: -1 }).then(res => {
        deviceTypes.value = res as DeviceTypesModel[]
    })
onMounted(() => getDeviceType())
</script>

<template>
    <AutocompleteField
        :label="label ?? $t('device.model.deviceType')"
        :modelValue="deviceTypes.find(item => item.id === props.modelValue)?.name"
        @select="$emit('select', $event as DeviceTypesModel)"
        :options="deviceTypes"
        v-bind="$attrs" />
</template>
