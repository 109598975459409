<script setup lang="ts">
import type { Colors } from '@/types/constants/ColorValues'
import { computed, type InputHTMLAttributes, type PropType } from 'vue'
import { InputField } from '.'

const props = defineProps({
    label: { type: String, required: false },
    type: Object as PropType<Colors>,
    placeholder: { type: String },
    modelValue: String,
    required: Boolean,
    inputType: String as PropType<InputHTMLAttributes['type']>
})
const emit = defineEmits(['change'])

const modelValue = computed({
    get: () => props.modelValue,
    set: value => emit('change', value)
})
</script>

<template>
    <InputField
        :label="label"
        v-model="modelValue"
        @change="value => (modelValue = value)"
        :placeholder="placeholder"
        inputType="textarea"
        class="full-width"
        v-bind="$attrs" />
</template>
