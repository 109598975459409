import type { UsersLocales } from '@/types/lang/locales'

export const userEn: UsersLocales = {
    actions: {
        attachAction: 'Associate',
        detachAction: 'Dissociate',
        activeAction: 'Activate user',
        desactiveAction: 'Desactivate user'
    },
    api: {
        error: {
            createUser: 'An error occured while creating the user {name}',
            deleteUser: 'An error occured while deleting the user {name}',
            fetchUser: 'An error occured while fetching the user',
            fetchUsers: 'An error occured while fetching the users',
            updateUser: 'An error occured while updating the user {name}',

            disincarnateUser: 'Error during the deimpersonation',
            impersonate: 'Error during the impersonation',

            changePassword: 'Error while trying to change password'
        },
        success: {
            createUser: 'User {name} successfully created !',
            deleteUser: 'User {name} successfully deleted !',
            fetchUser: 'User successfully fetched !',
            fetchUsers: 'Users successfully fetched !',
            updateUser: 'User {name} successfully edited !',

            disincarnateUser: 'Sucessfully deimpersonated',
            impersonate: 'Successfully impersonated : {name}',

            changePassword: 'Password successfully changed !'
        }
    },
    buttons: {
        resetPassword: 'Send a reset password link'
    },
    create: {
        existingOrganization: 'Existing organization',
        newOrganizationName: "New organization's name",
        organizationTitle: 'Organization'
    },
    detail: {
        create: 'Create user',
        edit: 'Save modifications',
        email: 'Email',
        informationsTitle: "User's information",
        name: 'Fullname',
        permissionsTitle: 'Permissions',
        organizationTitle: 'Organization',
        rolesTitle: 'Roles',
        validationDate: 'Account validation date',
        notValidatedYet: 'User has not created his password yet',
        lastLoginDate: 'Last connection',
        noLastLoginDate: 'User has not logged in yet'
    },
    dialog: {
        impersonate: 'Impersonate',
        impersonateContent: 'Take control of the account ?',
        impersonateTitle: 'Impersonate'
    },
    filters: {
        name: 'Fullname',
        email: 'Email'
    },
    table: {
        createUser: 'Create an user',
        email: 'Email',
        id: 'ID',
        name: 'FullName',
        noRole: 'No role assigned',
        organization: 'User',
        role: 'Role'
    },
    title: {
        associateUserTitle: '',
        dissociateUserTitle: '',
        blockUserTitle: '',
        unblockUserAction: ''
    }
}
