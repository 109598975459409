<script setup lang="ts">
import AvatarItem from '@/components/Avatar/AvatarItem.vue'
import { DeleteButton, SecondaryButton } from '@/components/Buttons'
import { BaseCard } from '@/components/Cards'
import { StackLayout } from '@/components/Layouts'
import TagStack from '@/components/Tags/TagStack.vue'
import { TypographyItem, TypographyWithPrefix } from '@/components/Typography'
import type { UserModel } from '@/types/models/UserModel'
import type { EOrganizationsUsersPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import PermissionWrapper from '../../Permission/PermissionWrapper.vue'

type Props = {
    data: UserModel
    editPermission?: EOrganizationsUsersPerm
    deletePermission?: EOrganizationsUsersPerm
    editButtonHref?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'click', data: UserModel): void
    (e: 'delete', data: UserModel): void
}
defineEmits<Emits>()

const computedRoles = computed(() => props.data.roles?.map((name, id) => ({ id, name })) ?? [])

const router = useRouter()
const handleGoTo = () => router.push(`${props.editButtonHref}/${props.data.user_id}`)
</script>

<template>
    <BaseCard class="user-card flex flex-column">
        <template #title>
            <AvatarItem :name="data.name" class="user-avatar" />
        </template>
        <template #content>
            <StackLayout
                direction="column"
                :gap="8"
                class="user-card-content space-between full-height">
                <StackLayout direction="column" :gap="8">
                    <TypographyItem :label="data.name" />
                    <TypographyItem :label="data.email" />
                </StackLayout>
                <TypographyWithPrefix
                    v-if="computedRoles.length"
                    :prefix="$t('organization.users.roles')">
                    <template #edit>
                        <StackLayout :gap="4" class="flex-wrap">
                            <TagStack
                                :data="computedRoles"
                                :closable="false"
                                class="justify-center" />
                        </StackLayout>
                    </template>
                </TypographyWithPrefix>
            </StackLayout>
        </template>
        <template #footer>
            <StackLayout class="full-width">
                <PermissionWrapper :permission="deletePermission">
                    <DeleteButton class="full-width left-button" @click="$emit('delete', data)" />
                </PermissionWrapper>
                <PermissionWrapper :permission="editPermission">
                    <SecondaryButton
                        @click="handleGoTo"
                        :label="$t('common.edit')"
                        class="right-button full-width" />
                </PermissionWrapper>
            </StackLayout>
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.user-avatar {
    margin: auto;
}
.user-card {
    & :deep(.card-header) {
        height: 36px;
    }
    & :deep(.card-content) {
        margin-top: 8px;
        height: 100%;
        overflow-wrap: anywhere;
        text-align: center;
    }
}
</style>
