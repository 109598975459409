export enum EPrimitive {
    STRING = 'string',
    BOOLEAN = 'boolean',
    INTEGER = 'int',
    FLOAT = 'float',
    NUMBER = 'number',
    ENUM = 'enum'
}

export const EPrimitiveValues = [
    EPrimitive.STRING,
    EPrimitive.INTEGER,
    EPrimitive.FLOAT,
    EPrimitive.BOOLEAN,
    EPrimitive.ENUM
]

export const EPrimitiveDefaultValue = {
    [EPrimitive.STRING]: null,
    [EPrimitive.INTEGER]: null,
    [EPrimitive.FLOAT]: null,
    [EPrimitive.BOOLEAN]: false,
    [EPrimitive.NUMBER]: null,
    [EPrimitive.ENUM]: null
}
