import type { TopicModel } from '@/types/models/TopicModel'

export type TopicState = {
    topic?: TopicModel
    topics: TopicModel[]
}

export enum ETopicActions {
    FETCH_TOPIC = 'fetchTopic',
    FETCH_TOPICS = 'fetchTopics',
    CREATE_TOPIC = 'createTopic',
    UPDATE_TOPIC = 'updateTopic',
    DELETE_TOPIC = 'deleteTopic'
}

export enum ETopicMutations {
    SET_TOPICS = 'setTopics',
    SET_ALL_TOPICS = 'setAllTopics',
    SET_TOPIC = 'setTopic',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
