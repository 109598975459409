import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { computed } from 'vue'

export type DeviceFiltersType = Partial<DeviceModel> & AdvancedDeviceFilters

export const defaultDeviceFilters: DeviceFiltersType = {}

export type LocalStorageFilter = {
    event: Partial<EventModel>
}
const eventId = computed(
    () => getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event?.id
)

export type AdvancedDeviceFilters = {
    lowSignal?: boolean
    lowBattery?: boolean
}

export const defaultAdvancedDeviceFilters = computed<Partial<DeviceModel>>(() => ({
    event_id: eventId.value ? parseInt(eventId.value.toString()) : undefined
}))
