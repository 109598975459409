import i18n from '../../locales/i18n'

export enum EDateLimit {
    PAST = 'past',
    FUTURE = 'future'
}

export const EDateLimitLabels = {
    [EDateLimit.FUTURE]: i18n.global.t('event.filters.future'),
    [EDateLimit.PAST]: i18n.global.t('event.filters.past')
}

export enum EDateFieldLimit {
    START_AT = 'start_at',
    END_AT = 'end_at'
}

export const EDateFieldLabels = {
    [EDateFieldLimit.START_AT]: i18n.global.t('event.filters.startAt'),
    [EDateFieldLimit.END_AT]: i18n.global.t('event.filters.endAt')
}
