import type { GridSize } from '@/types/constants/GridSize'
import { gridSize } from '@/types/enum/ViewsUtilsEnum'
import {
    isDesktopEvent,
    isFullHdEvent,
    isMobileEvent,
    isSmallScreenEvent,
    isTabletEvent,
    isWidescreenEvent
} from '@/utils/viewsUtils'
import { ref } from 'vue'

export const useGridSize = (sizes?: GridSize) => {
    const maxCardPerRow = ref(gridSize())
    isSmallScreenEvent(e => {
        maxCardPerRow.value = sizes?.smallscreen && e.matches ? sizes?.smallscreen : gridSize()
    })
    isMobileEvent(e => {
        maxCardPerRow.value = sizes?.mobile && e.matches ? sizes?.mobile : gridSize()
    })
    isTabletEvent(e => {
        maxCardPerRow.value = sizes?.tablet && e.matches ? sizes?.tablet : gridSize()
    })
    isDesktopEvent(e => {
        maxCardPerRow.value = sizes?.desktop && e.matches ? sizes?.desktop : gridSize()
    })
    isWidescreenEvent(e => {
        maxCardPerRow.value = sizes?.widescreen && e.matches ? sizes?.widescreen : gridSize()
    })
    isFullHdEvent(e => {
        maxCardPerRow.value = sizes?.fullscreen && e.matches ? sizes?.fullscreen : gridSize()
    })

    return maxCardPerRow
}
