<script setup lang="ts">
import UsersBaseActions from '@/components/User/components/UsersBaseActions.vue'
import type { LinkAction } from '@/types/components/LinkActions/LinkAction'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
    organizationId: number
}
defineProps<Props>()

const attachItem: LinkAction = {
    title: t('organization.title.associateUserTitle'),
    confirmLabel: t('organization.buttons.associateAnUser')
}
const detachItem: LinkAction = {
    title: t('organization.title.dissociateUserTitle'),
    confirmLabel: t('organization.buttons.dissociateAnUser')
}
</script>

<template>
    <UsersBaseActions :attach="attachItem" :detach="detachItem" :organizationId="organizationId" />
</template>
