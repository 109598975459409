<script setup lang="ts">
import { computed } from 'vue'
import { StackLayout } from '../Layouts'

type Props = {
    progressValue: number
    value: number
    minValue?: number
    maxValue?: number
    unitType?: string
}

const props = withDefaults(defineProps<Props>(), { minValue: 0, maxValue: 100 })

const progressVar = computed(() => props.progressValue)
</script>

<template>
    <StackLayout class="justify-center align-items-center progress-bar" v-bind="$attrs">
        <progress :value="value" :min="minValue" :max="maxValue" class="progress-tag">
            {{ value }}
        </progress>
        <div>{{ value }}</div>
    </StackLayout>
</template>

<style scoped lang="scss">
.progress-tag {
    visibility: hidden;
    height: 0;
    width: 0;
}
@keyframes progress {
    to {
        --progress-value: v-bind(progressVar);
    }
}
.progress-bar {
    border-radius: 50%;
    width: 100px;
    height: 100px;

    background: radial-gradient(closest-side, var(--color-background) 79%, transparent 80% 100%),
        conic-gradient(rgba($primary, 50%) calc(v-bind(progressValue) * 1%), $primary 0);
    animation: progress 2s 1 forwards;
}

.progress-bar::before {
    counter-reset: percentage v-bind(progressValue);
    content: counter(percentage) v-bind(unitType);
    animation: progress 2s 1 forwards;
}
</style>
