<script setup lang="ts">
import { ButtonsGroup } from '@/components/Buttons'
import { thumbnails } from '@/mockupData/ThumbnailsData'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import StackLayout from '../../Layouts/StackLayout.vue'
import TicketDeviceCardTable from './DeviceTable/TicketDeviceCardTable.vue'
import TicketDeviceListTable from './DeviceTable/TicketDeviceListTable.vue'
import TicketDeviceSearchTable from './DeviceTable/TicketDeviceSearchTable.vue'

const icons = [
    {
        icon: 'magnifying-glass'
    },
    {
        icon: 'bars'
    },
    {
        icon: 'table-cells-large'
    }
]

const selectedMode = ref(0)

const showIsMobile = ref(isMobile())
isMobileEvent(e => (showIsMobile.value = e.matches))
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <ButtonsGroup
            :items="icons"
            :value="selectedMode"
            @click="value => (selectedMode = value as number)" />
        <TicketDeviceSearchTable :thumbnails="thumbnails" v-if="selectedMode === 0" />
        <TicketDeviceListTable :thumbnails="thumbnails" v-if="selectedMode === 1" />
        <TicketDeviceCardTable :thumbnails="thumbnails" v-if="selectedMode === 2" />
    </StackLayout>
</template>
