<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import { type IconType } from '@/types/components/IconType'
import { EColors } from '@/types/constants/ColorValues'
import { computed, type ComputedRef } from 'vue'

const props = defineProps<{ open: boolean }>()
defineEmits<{
    (e: 'click'): void
}>()

const icon: ComputedRef<IconType> = computed(() => ({
    label: props.open ? 'close' : 'bars',
    type: EColors.LIGHT
}))
</script>

<template>
    <IconButton size="is-medium" :type="EColors.PRIMARY" :icon="icon" @click="$emit('click')" />
</template>
