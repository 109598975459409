<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import PartnersSelector from '@/components/Selector/components/PartnersSelector.vue'
import type { SelectorObject } from '@/types/components/SelectorObject'
import { type EventCreateModel } from '@/types/models/EventCreateModel'
import { EPartnersPerm } from '@/types/permissions/PartnersPermissionsEnum'
import { formatDate } from '@/utils/dateUtils'
import moment from 'moment'
import * as momentTz from 'moment-timezone'
import { computed, ref } from 'vue'
import DateTimePickerField from '../../../components/Fields/DateTimePickerField.vue'

type Props = {
    data: EventCreateModel
    submitted?: boolean
    hasErrors: {
        nameError: boolean
        startDateError: boolean
        endDateError: boolean
    }
}
const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'update', value: Partial<EventCreateModel>): void
}>()

/* TODO PARTNER EVENT */
const partnerId = ref()
const handleSelect = (value: SelectorObject) => (partnerId.value = value)
const handleChange = (value: Partial<EventCreateModel>) => emit('update', value)

const handleChangeStartDate = (value: Date) =>
    emit('update', {
        ...props.data,
        start_at: value,
        end_at: moment(value).isAfter(moment(props.data.end_at).utc())
            ? moment(value).utc().toDate()
            : props.data.end_at
    })

const timezones = momentTz.tz.names().map(value => ({ value, name: value }))

const typedTz = ref<string>()
const filteredTimezones = computed(() =>
    timezones.filter(item => item.value.toLowerCase().includes(typedTz.value?.toLowerCase() ?? ''))
)

const handleChangeTz = (value: SelectorObject) => {
    handleChange({ tz: value?.value as string })
    typedTz.value = undefined
}
</script>

<template>
    <InformationBaseCard :label="$t('event.createEvent')" class="input-information-card">
        <StackLayout direction="column" :gap="16" class="full-width">
            <StackLayout direction="row" :gap="8" class="full-width">
                <StackLayout direction="column" :gap="12" class="full-width">
                    <InputField
                        :label="$t('event.model.name')"
                        :modelValue="data.name"
                        @change="handleChange({ name: $event })"
                        :placeholder="$t('event.model.name')"
                        required
                        :hasError="hasErrors.nameError"
                        class="full-width" />
                    <InputField
                        :label="$t('event.model.comment')"
                        :modelValue="data.comments"
                        @change="handleChange({ comments: $event })"
                        :placeholder="$t('event.model.comment')"
                        inputType="textarea"
                        class="full-width" />
                </StackLayout>
                <PermissionWrapper :permission="EPartnersPerm.PARTNERS_VIEW">
                    <PartnersSelector
                        @select="handleSelect"
                        @update="handleChange"
                        class="full-width" />
                </PermissionWrapper>
            </StackLayout>
            <StackLayout direction="row" :gap="8" class="full-width">
                <DateTimePickerField
                    :label="$t('event.model.startAt')"
                    :modelValue="formatDate(data.start_at as Date)"
                    @change="handleChangeStartDate(moment($event).utc().toDate())"
                    :hasError="hasErrors.startDateError"
                    :minDatetime="moment().startOf('d').utc().toDate()"
                    required
                    class="full-width" />
                <DateTimePickerField
                    :label="$t('event.model.endAt')"
                    :modelValue="formatDate(data.end_at as Date)"
                    @change="handleChange({ end_at: moment($event).utc().toDate() })"
                    :minDatetime="moment(data.start_at).utc().toDate()"
                    :hasError="hasErrors.endDateError"
                    required
                    class="full-width" />
            </StackLayout>
            <StackLayout direction="row" :gap="8" class="full-width">
                <AutocompleteField
                    :label="$t('event.model.tz')"
                    :modelValue="data.tz"
                    @typing="typedTz = $event"
                    @select="handleChangeTz"
                    :clearable="false"
                    :options="filteredTimezones"
                    required
                    class="full-width" />
            </StackLayout>
        </StackLayout>
    </InformationBaseCard>
</template>
