export enum EDeviceType {
    URTIME_800 = 1,
    URTIME_150 = 3,
    MICRO = 4,
    LOOPCASE = 5,
    NANO = 7,
    NANO_EU = 8,
    NANO_SAT = 9,
    NANO_US_SAT = 10,
    NANO_WF = 11,
    NANO_XB = 12,
    MODULE_SAT = 13
}

export const EDeviceTypeLabels = {
    [EDeviceType.URTIME_800]: 'uRTime 800',
    [EDeviceType.URTIME_150]: 'uRTime 150',
    [EDeviceType.MICRO]: 'Micro',
    [EDeviceType.LOOPCASE]: 'Loopcase',
    [EDeviceType.NANO]: 'Nano',
    [EDeviceType.NANO_EU]: 'Nano EU',
    [EDeviceType.NANO_SAT]: 'Nano SAT',
    [EDeviceType.NANO_US_SAT]: 'Nano US SAT',
    [EDeviceType.NANO_WF]: 'Nano WF',
    [EDeviceType.NANO_XB]: 'Nano XB',
    [EDeviceType.MODULE_SAT]: 'Module SAT'
}
