/* DEVICE ROUTE */
export const DEVICE_ROUTE = '/devices'
export const DEVICE_ROUTES = {
    baseUrl: DEVICE_ROUTE,
    createUrl: `${DEVICE_ROUTE}/create`,
    detailUrl: `${DEVICE_ROUTE}/:id`,
    buildUrl: (deviceId: number) => `${DEVICE_ROUTE}/${deviceId}`
}

/* TICKETS ROUTES */
export const SUPPORTFAQ_ROUTE = '/support'

export const TICKETS_ROUTE = '/tickets'
export const TICKETS_ROUTES = {
    baseUrl: TICKETS_ROUTE,
    createUrl: `${TICKETS_ROUTE}/create`,
    detailUrl: `${TICKETS_ROUTE}/:id`,
    buildUrl: (ticketId: number) => `${TICKETS_ROUTE}/${ticketId}`
}

/* EVENTS ROUTES */
export const EVENTS_ROUTE = '/events'
export const EVENTS_ROUTES = {
    baseUrl: EVENTS_ROUTE,
    createUrl: `${EVENTS_ROUTE}/create`,
    detailUrl: `${EVENTS_ROUTE}/:id`,
    buildUrl: (eventId: number) => `${EVENTS_ROUTE}/${eventId}`
}

export const CHECKPOINT_ROUTE = '/checkpoints'
export const EVENT_CHECKPOINT_ROUTES = {
    baseUrl: `${EVENTS_ROUTES.detailUrl}${CHECKPOINT_ROUTE}`,
    detailUrl: `${EVENTS_ROUTES.detailUrl}${CHECKPOINT_ROUTE}`,
    createUrl: `${EVENTS_ROUTES.detailUrl}${CHECKPOINT_ROUTE}/create`,
    buildUrl: (id: number) => `${EVENTS_ROUTES.buildUrl(id)}${CHECKPOINT_ROUTE}`
}

export const DETECTION_ROUTE = '/detections'

/* ACCOUNT ROUTES */
export const MY_ACCOUNT_ROUTE = '/myAccount'

/* GUEST ROUTES */
export const GUEST_ROUTE = '/guest'
export const CREATE_PASSWORD_ROUTE = `${GUEST_ROUTE}/create_password`
export const FORGOT_PASSWORD_ROUTE = `${GUEST_ROUTE}/forgot_password`
export const SEND_LINK_PWD_ROUTE = `${GUEST_ROUTE}/sent_link`
export const RESET_PASSWORD_ROUTE = `${GUEST_ROUTE}/reset_password`
export const ONBOARDING_ROUTE = '/onboarding'
