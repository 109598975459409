<script setup lang="ts">
import store from '@/store'
import { EAlertActions } from '@/store/alertStore/AlertStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { AlertProps } from '@/types/components/AlertComponent'
import { useEventListener } from '@vueuse/core'
import { onMounted, onUpdated, ref } from 'vue'

type Props = {
    data?: AlertProps
}
const props = defineProps<Props>()

const alertRef = ref<HTMLDivElement | null>(null)
const timeoutId = ref<ReturnType<typeof setTimeout>>()

const hideItem = () => store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.CLEAR}`)

const setLocalTimeout = () => {
    cancelTimeout()
    timeoutId.value = setTimeout(() => hideItem(), props.data?.duration ?? 3000)
}
const cancelTimeout = () => {
    if (timeoutId.value) clearTimeout(timeoutId.value)
}

useEventListener(alertRef, 'animationstart', hideItem)

onMounted(() => setLocalTimeout())
onUpdated(() => setLocalTimeout())
</script>

<template>
    <div ref="alertRef" @mouseenter="cancelTimeout" @mouseleave="hideItem">
        <slot />
    </div>
</template>

<style scoped lang="scss">
.notification-component {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media print, screen and (min-width: 769px) {
        min-width: 350px;
        max-width: 600px;
    }
}
</style>
