<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import BaseWidget from '@/components/Widget/BaseWidget.vue'
import { mockupTechWidgets } from '@/mockupData/TechData'
import { ref } from 'vue'

const widgets = ref(mockupTechWidgets)
</script>

<template>
    <StackLayout direction="row" :gap="8" wrap>
        <BaseWidget
            v-for="(item, key) in widgets"
            :title="item.title ?? ''"
            :data="item"
            :key="key"
            class="flex-auto-wrapped" />
        <!-- <ChartWidget title="Pie Chart" /> -->
    </StackLayout>
</template>
