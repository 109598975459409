import { API_PREFIX } from '@/config/api'
import api from '@/helpers/api'

const getSearchSessions = (params: any, field: any, value: any) =>
    api
        .get(`${API_PREFIX}/sessions/${field}/${value}`, { params: params })
        .then(response => Promise.resolve(response.data))

export const sessionService = {
    getSearchSessions
}
