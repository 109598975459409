import { type UserDataModel } from '@/types/models/UserModel'
import moment from 'moment'

export const setItemToStorage = <T>(key: string, data: T) => {
    if (data) {
        const item = getItemFromStorage<T>(key)
        localStorage.setItem(key, JSON.stringify({ ...item, ...data }))
    } else localStorage.removeItem(key)
}

export const getItemFromStorage = <T>(key: string) => {
    const item = localStorage.getItem(key)
    if (!item) return null
    return JSON.parse(item) as T
}

export const isTokenExpired = () => {
    const user = getItemFromStorage<UserDataModel>('user')
    return user?.expires_at ? moment().isAfter(user.expires_at) : true
}
