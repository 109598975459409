<script setup lang="ts">
import { UploadButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import { mockupTechLatestUpdates } from '@/mockupData/TechData'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const latestNews = ref(mockupTechLatestUpdates)

const isInEditMode = ref(false)
const save = () => console.log('save')
</script>

<template>
    <StackLayout direction="column" :gap="8" class="full-height">
        <UploadButton :label="'Upload button'" class="fit-content place-self-end" />
        <iframe
            height="100%"
            src="http://support.urtime.net/storage/documents/20180419_Nano_documentation_609a5f40f2554.pdf"></iframe>
    </StackLayout>
</template>

<style scoped lang="scss">
.latest-news {
    color: var(--color-text);
}
</style>
