<script setup lang="ts">
import { moment } from '@/locales/langUtils'
import { DATE_FORMAT } from '@/utils/dateUtils'
import { computed } from 'vue'
import DateTimePickerField from '../DateTimePickerField.vue'
import EditableFieldWrapper from './EditableFieldWrapper.vue'

const props = defineProps<{
    label?: string
    tooltip?: string
    value?: Date
    boldLabel?: boolean
    isInEditMode?: boolean
    required?: boolean
    showableElement?: boolean
    format?: string
    errorText?: string
}>()
defineEmits<{ (e: 'change', value: Date | undefined): void }>()

const dateLabel = computed(() =>
    moment.value(props.value).format(props.format ?? DATE_FORMAT.value)
)
</script>

<template>
    <EditableFieldWrapper
        :label="label"
        :tooltip="tooltip"
        :value="dateLabel"
        :boldLabel="boldLabel"
        :showableElement="showableElement"
        :isInEditMode="isInEditMode">
        <DateTimePickerField
            :modelValue="value"
            @change="$emit('change', $event)"
            :placeholder="label"
            :errorText="errorText"
            :required="required"
            v-bind="$attrs" />
    </EditableFieldWrapper>
</template>
