<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import replyApi from '@/services/reply.service'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import AttachedFiles from '@/components/Reply/AttachedFiles.vue'
import MessageInput from '@/components/Reply/MessageInput.vue'
import SendButton from '@/components/Reply/SendButton.vue'

const route = useRoute()
const ticketId = ref<string>('')

const dropFiles = ref<File[]>([])

onMounted(() => {
    ticketId.value = route.params.id.toString()
})
const messageContent = ref<string>('')

const sendReply = async () => {
    const formData = new FormData()
    formData.append('message', messageContent.value)

    dropFiles.value.forEach(file => {
        formData.append('documents[]', file)
    })
    await replyApi.createReply(ticketId.value, formData).then(() => {
        messageContent.value = ''
        dropFiles.value = []
    })
}

const deleteDropFile = (index: number) => {
    dropFiles.value.splice(index, 1)
}
</script>

<template>
    <InformationBaseCard :gap="8" :label="$t('ticket.message.reply')">
        <StackLayout :gap="8" class="space-between full-width">
            <MessageInput
                class="padding"
                @change="message => (messageContent = message)"
                v-model="messageContent" />
            <StackLayout direction="column" class="one-thirds-width">
                <SendButton @sendReply="sendReply" />
                <StackLayout>
                    <AttachedFiles
                        v-model="dropFiles"
                        :dropFiles="dropFiles"
                        @deleteDropFile="deleteDropFile" />
                </StackLayout>
            </StackLayout>
        </StackLayout>
    </InformationBaseCard>
</template>
