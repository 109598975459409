import i18n from '@/locales/i18n'
import checkpointService from '@/services/checkpoint.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import { getPageSize } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import {
    ECheckpointActions,
    ECheckpointMutations,
    type CheckpointState
} from './CheckpointStoreTypes'

const state: Ref<PaginationDataType<CheckpointState>> = ref({
    data: {
        checkpoints: []
    },
    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getCheckpoints: () => state.value.data.checkpoints,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [ECheckpointActions.FETCH_CHECKPOINT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { eventId, checkpointId }: { eventId: number; checkpointId: number }
    ) {
        commit(ECheckpointMutations.SET_LOADING, true)
        return await checkpointService
            .getSpecificCheckpointFromEvent(eventId, checkpointId)
            .then(res => {
                commit(ECheckpointMutations.SET_CHECKPOINT, res.data)
                return res.data
            })
            .finally(() => commit(ECheckpointMutations.SET_LOADING, false))
    },

    async [ECheckpointActions.FETCH_CHECKPOINTS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { id, params }: { id: number; params?: Partial<PaginationType> }
    ) {
        commit(ECheckpointMutations.SET_LOADING, true)
        return await checkpointService
            .getCheckpointsFromEvent(id, params)
            .then(data => {
                commit(ECheckpointMutations.SET_CHECKPOINTS, data?.data ?? data)
                commit(ECheckpointMutations.SET_TOTAL, data)
                return data?.data ?? data
            })
            .finally(() => {
                commit(ECheckpointMutations.SET_LOADING, false)
            })
    },

    async [ECheckpointActions.CREATE_CHECKPOINT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { eventId, data }: { eventId: number; data: CheckpointModel }
    ) {
        commit(ECheckpointMutations.SET_LOADING, true)
        return await checkpointService
            .addCheckpointToEvent(eventId, data)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: ECheckpointActions.CREATE_CHECKPOINT
                })
                return res.data
            })
            .finally(() => {
                commit(ECheckpointMutations.SET_LOADING, false)
            })
    },

    async [ECheckpointActions.UPDATE_CHECKPOINT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        {
            eventId,
            checkpointId,
            data
        }: { eventId: number; checkpointId: number; data: CheckpointModel }
    ) {
        commit(ECheckpointMutations.SET_LOADING, true)
        return await checkpointService
            .updateCheckpointFromEvent(eventId, checkpointId, data)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: ECheckpointActions.UPDATE_CHECKPOINT
                })
                return res.data
            })
            .finally(() => {
                commit(ECheckpointMutations.SET_LOADING, false)
            })
    },

    async [ECheckpointActions.DELETE_CHECKPOINT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { eventId, checkpointId, name }: { eventId: number; checkpointId: number; name: string }
    ) {
        commit(ECheckpointMutations.SET_LOADING, true)
        return await checkpointService
            .deleteCheckpointFromEvent(eventId, checkpointId)
            .then(response => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: ECheckpointActions.DELETE_CHECKPOINT
                })
                return response
            })
            .finally(() => {
                commit(ECheckpointMutations.SET_LOADING, false)
            })
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`checkpoint.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`checkpoint.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [ECheckpointMutations.SET_CHECKPOINTS](_: CheckpointState, checkpoints: CheckpointModel[]) {
        state.value.data.checkpoints = checkpoints
    },
    [ECheckpointMutations.SET_CHECKPOINT](_: CheckpointState, checkpoint: CheckpointModel) {
        state.value.data.checkpoint = checkpoint
    },
    [ECheckpointMutations.SET_TOTAL](
        _: CheckpointState,
        data: PaginationDataType<CheckpointModel>
    ) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [ECheckpointMutations.SET_LOADING](_: CheckpointState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
