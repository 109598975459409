<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import DividerItem from '@/components/Divider/DividerItem.vue'
import { StackLayout } from '@/components/Layouts'
import { TypographyItem } from '@/components/Typography'
import router from '@/router'
import { SUPPORTFAQ_ROUTE } from '@/router/routes'
import type { ThumbnailModel } from '@/types/models/ThumbnailModel'

type Props = {
    thumbnails: ThumbnailModel[]
}
const navigateToFAQ = () => router.push(SUPPORTFAQ_ROUTE)
defineProps<Props>()
</script>

<template>
    <StackLayout v-for="(item, key) in thumbnails" :key="key">
        <StackLayout class="device-infos space-between border-radius full-width">
            <StackLayout :gap="8" isResponsive class="full-width">
                <StackLayout v-if="item.img">
                    <img :src="item.img" class="image is-64x64" />
                    <DividerItem isResponsive orientation="vertical" variant="middle" />
                </StackLayout>

                <TypographyItem :label="item.content" class="full-width" />
            </StackLayout>
            <IconButton
                class="open-in-tab"
                icon="arrow-up-right-from-square"
                size="is-small"
                @click="navigateToFAQ" />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.open-in-tab {
    border: unset;
    color: $primary;
    background-color: var(--color-background);
    display: flex;
}

.device-infos {
    background-color: var(--color-background);
    color: var(--color-text-secondary);
    padding: 8px;
}
</style>
