import { AUTH_API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'

const API_PREFIX = `${AUTH_API_PREFIX}/questions`

const questionApi = {
    getQuestion: (id: string) => auth.get(`${API_PREFIX}/${id}`).then(response => response.data),
    getQuestions: (params?: Partial<PaginationType>) =>
        auth.get(`${API_PREFIX}${buildQueryUrl(params)}`).then(response => response.data),
    createQuestion: (questionData: any) =>
        auth.post(API_PREFIX, questionData).then(response => response.data),
    updateQuestion: (id: string, questionData: any) =>
        auth.patch(`${API_PREFIX}/${id}`, questionData).then(response => response.data),
    deleteQuestion: (id: number) =>
        auth.delete(`${API_PREFIX}/${id}`).then(response => response.data)
}

export default questionApi
