<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import type { IconType } from '@/types/components/IconType'

type Props = {
    icon?: string
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'click', event: MouseEvent): void
}>()

const iconObject: IconType = {
    label: props.icon ?? 'pen',
    position: 'start'
}
</script>

<template>
    <IconButton :icon="iconObject" @click="$emit('click', $event)" class="secondary-button" />
</template>
