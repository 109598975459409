export enum ETechnicianRoutes {
    TECHNICIAN_HOME = 'technicianHome'
}
export const ETechnicianRoutesValues = [ETechnicianRoutes.TECHNICIAN_HOME]

export enum ETechnicianInventoryRoutes {
    TECHNICIAN_INVENTORY_TABLE = 'technicianInventoryTable'
}
export const ETechnicianInventoryRoutesValues = [
    ETechnicianInventoryRoutes.TECHNICIAN_INVENTORY_TABLE
]

export enum ETechnicianQuestionsRoutes {
    TECHNICIAN_QUESTIONS_TABLE = 'technicianQuestionsTable'
}
export const ETechnicianQuestionsRoutesValues = [
    ETechnicianQuestionsRoutes.TECHNICIAN_QUESTIONS_TABLE
]

export enum ETechnicianTicketsRoutes {
    TECHNICIAN_TICKETS_CREATE = 'technicianTicketsCreate',
    TECHNICIAN_TICKETS_DETAIL = 'technicianTicketsDetail',
    TECHNICIAN_TICKETS_TABLE = 'technicianTicketsTable'
}
export const ETechnicianTicketsRoutesValues = [
    ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_CREATE,
    ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_DETAIL,
    ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_TABLE
]

export enum ETechnicianDocumentationsRoutes {
    TECHNICIAN_DOCUMENTATIONS_CREATE = 'technicianDocumentationsCreate',
    TECHNICIAN_DOCUMENTATIONS_DETAIL = 'technicianDocumentationsDetail',
    TECHNICIAN_DOCUMENTATIONS_TABLE = 'technicianDocumentationsTable'
}
export const ETechnicianDocumentationsRoutesValues = [
    ETechnicianDocumentationsRoutes.TECHNICIAN_DOCUMENTATIONS_CREATE,
    ETechnicianDocumentationsRoutes.TECHNICIAN_DOCUMENTATIONS_DETAIL,
    ETechnicianDocumentationsRoutes.TECHNICIAN_DOCUMENTATIONS_TABLE
]

export enum ETechnicianTopicsRoutes {
    TECHNICIAN_TOPICS_CREATE = 'technicianTopicsCreate',
    TECHNICIAN_TOPICS_DETAIL = 'technicianTopicsDetail',
    TECHNICIAN_TOPICS_TABLE = 'technicianTopicsTable'
}
export const ETechnicianTopicsRoutesValues = [
    ETechnicianTopicsRoutes.TECHNICIAN_TOPICS_CREATE,
    ETechnicianTopicsRoutes.TECHNICIAN_TOPICS_DETAIL,
    ETechnicianTopicsRoutes.TECHNICIAN_TOPICS_TABLE
]
