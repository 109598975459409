import type { AuthenticationLocales } from '@/types/lang/locales'

export const authenticationEn: AuthenticationLocales = {
    api: {
        error: {
            forgotPassword: 'An error occurred while trying to send the password change email',
            login: "The account doesn't seem to exist. Check email and password",
            logout: 'An error occurred while attempting to logout',
            me: 'An error occurred while trying to retrieve your information',
            resetPassword: 'An error occurred while trying to change your password',
            refreshToken: 'An error occured while refreshing the session'
        },
        success: {
            forgotPassword: 'The link has been sent!',
            login: 'You are now logged in !',
            logout: 'Successfully logged out',
            me: 'Your information has been retrieved successfully !',
            refreshToken: 'Session successfully refreshed !',
            resetPassword: 'The password has been changed !'
        }
    },
    back: 'Back',
    backToLogin: 'Back to login page',
    createPassword: {
        confirmPassword: 'Confirm password',
        contentTitle: 'Please create password for the email : ',
        password: 'Password',
        title: 'Create a password'
    },
    email: 'Email',
    error: 'Please correct your information',
    forgotPassword: {
        title: 'Forgot password',
        sendEmail: 'Send email',
        contentTitle: 'Send an email to recover your password',
        email: 'Email',
        content:
            'An email containing a link to change your password will be sent to the address provided.'
    },
    goToLoginPage: 'Go to the login page',
    login: 'Login',
    newPassword: 'New Password',
    password: 'Password',
    passwordConfirmation: 'Confirm Password',
    passwordRules: {
        content: 'The password should contain :',
        lowercaseCharacters: 'At least a lowercase',
        minLength: 'At least 8 characters',
        noWhitespaceCharacter: 'No whitespace',
        numericCharacters: 'At least a number',
        specialCharacters: 'At least a special character',
        uppercaseCharacters: 'At least an uppercase',
        passwordMatches: 'Passwords are matching'
    },
    resetPasswordAction: 'Reset',
    resetPasswordTitle: 'Reset Password',
    sendEmail: 'Send email',
    sendLink: {
        title: 'Password reset link has been sent',
        content: "If the email doesn't appear after a few minutes :",
        contentTitle:
            'Email containing the password reset link has been sent to the email address : ',
        checkSpam: 'Check your spams',
        redoSend: 'Request a new email',
        resendLink: 'Resend email'
    },
    submit: 'Submit',
    title: {
        title: 'Authentication',
        resetPassword: 'Reset Password',
        sendLinkConfirmation: 'Password modification email receipt'
    },

    onboarding: {
        api: {
            success: {
                sendOnboarding: 'Access request successfully sent !',
                validateOnboarding: 'Access request successfully validated !'
            }
        },
        title: 'Access request',
        sendButton: 'Send access request',
        validateTitle: 'Validate an access request',
        validateButton: 'Validate request',
        confirmContent: 'Confirm data registration ?',
        organizationStep: {
            title: 'Company',
            model: {
                name: 'Company name',
                timerId: 'Timer ID'
            }
        },
        userStep: {
            title: 'Referring user',
            model: {
                email: 'Email',
                name: 'Full name'
            }
        },
        devicesStep: {
            buttons: {
                validateDevice: 'Validate this device'
            },
            title: 'Devices',
            model: {
                active: 'Status',
                apiKeyType: 'API key type',
                deviceType: 'Device type',
                imeiGSM: 'GSM IMEI number',
                imeiSAT: 'SAT IMEI number',
                name: 'Custom name',
                roles: 'Roles',
                serialNumber: 'Serial number (6 chars)'
            }
        },
        validated: 'Request already validated'
    }
}
