<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import { EDeviceStatus } from '@/types/enum/DeviceStatusEnum'
import type { ActionModel } from '@/types/models/ActionModel'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import DeviceActionsCard from './DeviceActionsCard.vue'
import DeviceInformationCard from './DeviceInformationCard.vue'
import DeviceLocalization from './DeviceLocalization.vue'

type Props = {
    data: Partial<DeviceModel>
    status?: AlertSocketMessageModel
    actions?: ActionModel[]
}
defineProps<Props>()
const emit = defineEmits<{ (e: 'update', deviceValue: Partial<DeviceModel>): void }>()

const updateUserValue = (value: Partial<DeviceModel>) => emit('update', value)
</script>

<template>
    <StackLayout direction="column" :gap="16" isResponsive>
        <StackLayout direction="row" :gap="16" isResponsive>
            <DeviceInformationCard
                :data="data"
                :status="status"
                @update="value => updateUserValue(value)">
                <slot name="informations" />
            </DeviceInformationCard>
            <InformationBaseCard
                v-if="actions?.length"
                :label="$t('device.detail.informations.actions')"
                class="full-width fit-height">
                <StackLayout :gap="16" direction="column" class="full-width">
                    <DeviceActionsCard :actions="actions" />
                    <slot name="actions" />
                </StackLayout>
            </InformationBaseCard>
        </StackLayout>
        <DeviceLocalization v-if="data.online === EDeviceStatus.ONLINE && data.localization" />
    </StackLayout>
</template>
