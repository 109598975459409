import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { setItemToStorage } from '@/utils/localStorageUtils'
import type { Commit, Dispatch } from 'vuex'
import { EFiltersActions } from './FiltersStoreTypes'

const actions = {
    [EFiltersActions.SET_FILTER]: (
        { commit }: { commit: Commit; dispatch: Dispatch },
        filter: Record<string, string | number | boolean>
    ) => setItemToStorage(ELocalStorage.FILTERS, filter)
}

export default {
    namespaced: true,
    actions
}
