<script setup lang="ts">
import { EShowMore } from '@/types/enum/ShowMoreEnum'
import type { AnnouncementModel } from '@/types/models/AnnouncementModel'
import { MessageCard } from '../Cards'
import ShowMoreTypography from '../Typography/ShowMoreTypography.vue'

type Props = {
    data: AnnouncementModel
}
const props = defineProps<Props>()

const maxWidth = props.data.fullWidth ? '100%' : '300px'
</script>

<template>
    <MessageCard
        :type="data.type"
        :title="data.title"
        class="announcement-card"
        :closable="data.closable ?? true"
        v-bind="$attrs">
        <ShowMoreTypography
            :text="data.content"
            :showMoreType="EShowMore.DIALOG"
            :type="data.type" />
    </MessageCard>
</template>

<style scoped lang="scss">
.announcement-card {
    min-width: 250px;
    max-width: v-bind(maxWidth);
}
</style>
