import type { DetectionModel } from '@/types/models/DetectionModel'

export type DetectionState = {
    detection?: any
    detections: DetectionModel[]
}

export enum EDetectionActions {
    FETCH_DETECTIONS = 'fetchDetections',
    GET_LATEST_DETECTIONS = 'getLatestDetections',
    EXPORT_DETECTIONS = 'exportDetections'
}

export enum EDetectionMutations {
    SET_DETECTIONS = 'setDetections',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoader'
}
