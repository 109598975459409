export const accountFr = {
    informationsTitle: 'Informations',
    rolesTitle: 'Rôles',
    changePasswordTitle: 'Changer de mot de passe',

    model: {
        name: 'Nom',
        email: 'Email',
        roles: 'Rôles',
        password: 'Mot de passe',
        confirmPassword: 'Confirmer le mot de passe',
        currentPassword: 'Mot de passe actuel'
    }
}
