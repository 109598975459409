<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { EColors } from '@/types/constants/ColorValues'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'

type Props = {
    config?: Record<string, ExtendedPrimitives>
}
const props = defineProps<Props>()

const keys = ['f1', 'f2']

const formatEIDString = (key: string) =>
    props.config?.[`${key}_evt_val`] ? `EID : ${props.config?.[`${key}_evt_val`]}` : ''
const formatTIDString = (key: string) =>
    props.config?.[`${key}_tmr_val`] ? `TID : ${props.config?.[`${key}_tmr_val`]}` : ''
</script>

<template>
    <StackLayout direction="column">
        <StackLayout v-for="(filter, key) in keys" :key="key">
            <StackLayout
                v-if="config?.[`${filter}_tmr_val`] || config?.[`${filter}_evt_val`]"
                direction="row"
                :gap="8">
                <TypographyItem :label="`${filter.toUpperCase()} :`" />
                <TypographyItem
                    :label="formatEIDString(filter)"
                    :class="`has-text-${
                        config?.[`${filter}_evt_en`] ? EColors.SUCCESS : EColors.DANGER
                    }`" />
                <TypographyItem
                    :label="formatTIDString(filter)"
                    :class="`has-text-${
                        config?.[`${filter}_tmr_en`] ? EColors.SUCCESS : EColors.DANGER
                    }`" />
            </StackLayout>
        </StackLayout>
    </StackLayout>
</template>
