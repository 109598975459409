<script setup lang="ts">
import OrganizationView from '@/components/Organization/OrganizationView.vue'
import {
    ADMIN_ORGANIZATION_API_HREF,
    ADMIN_ORGANIZATION_CUSTOMERS_HREF,
    ADMIN_ORGANIZATION_DEVICE_HREF,
    ADMIN_ORGANIZATION_USER_HREF,
    ADMIN_ORGANIZATION_USER_ROUTES
} from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultOrganizationModel, type OrganizationModel } from '@/types/models/OrganizationModel'
import {
    EAdminOrganizationCustomersTabPerm,
    EAdminOrganizationDevicesTabPerm,
    EAdminOrganizationsApisTabPerm,
    EAdminOrganizationUsersTabPerm
} from '@/types/permissions/AdminPermissionsEnum'
import AdministrationOrganizationDetailView from '@/views/AdministrationView/Organization/AdministrationOrganizationDetailView.vue'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AdministrationOrganizationActions from '../components/AdminOrganizationActions.vue'

const route = useRoute()
const router = useRouter()
const organizationStore = computed(() => store.state.organization)
const organizationId = ref(route.params.id?.toString())

const getOrganization = () =>
    store
        .dispatch(
            `${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATION}`,
            organizationId.value
        )
        .then(
            res =>
                (organizationValue.value = {
                    ...res,
                    address: { ...res.address, zip_code: parseInt(res.address.zip_code) }
                })
        )

onMounted(() => {
    if (typeof organizationId.value === 'string') getOrganization()
})

const organizationValue = ref<OrganizationModel>({ ...defaultOrganizationModel })

const submitted = ref(false)

const updateOrganizationValue = (value: Partial<OrganizationModel>) =>
    (organizationValue.value = { ...organizationValue.value, ...value })

const updateOrganization = () => {
    submitted.value = true
    if (!organizationValue.value.name) return
    store.dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.UPDATE_ORGANIZATION}`, {
        organizationId: organizationId.value,
        organizationData: { ...organizationValue.value, ...organizationValue.value.address }
    })
    submitted.value = false
}
const goToCreate = () =>
    router.push(ADMIN_ORGANIZATION_USER_ROUTES(parseInt(organizationId.value)).createUrl)
const goToDetail = (userId: number) =>
    router.push(ADMIN_ORGANIZATION_USER_ROUTES(parseInt(organizationId.value)).buildUrl(userId))
</script>

<template>
    <OrganizationView
        :data="organizationValue"
        :isLoading="organizationStore.isLoading"
        @goToDetail="goToDetail"
        @goToCreate="goToCreate"
        :usersViewPermission="EAdminOrganizationUsersTabPerm"
        :usersViewButtonsHref="ADMIN_ORGANIZATION_USER_HREF(parseInt(organizationId))"
        :customersViewPermission="EAdminOrganizationCustomersTabPerm"
        :customersViewButtonsHref="ADMIN_ORGANIZATION_CUSTOMERS_HREF(parseInt(organizationId))"
        :devicesViewPermission="EAdminOrganizationDevicesTabPerm"
        :devicesViewButtonsHref="ADMIN_ORGANIZATION_DEVICE_HREF(parseInt(organizationId))"
        :apisViewPermission="EAdminOrganizationsApisTabPerm"
        :apisViewButtonsHref="ADMIN_ORGANIZATION_API_HREF(parseInt(organizationId))"
        showDevices>
        <template #informations>
            <AdministrationOrganizationDetailView
                :data="organizationValue"
                :label="$t('common.saveModifications')"
                :submitted="submitted"
                :loading="!!store.state.organization.isLoading"
                @click="updateOrganization"
                @update="updateOrganizationValue">
                <AdministrationOrganizationActions :organizationId="parseInt(organizationId)" />
            </AdministrationOrganizationDetailView>
        </template>
    </OrganizationView>
</template>
