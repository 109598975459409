<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import UpdateUserView from '@/components/User/Detail/UpdateUserView.vue'
import ResetPasswordButton from '@/components/User/components/ResetPasswordButton.vue'
import UsersBaseActions from '@/components/User/components/UsersBaseActions.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { defaultUserModel, type UserModel } from '@/types/models/UserModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { DATETIME_FORMAT } from '@/utils/dateUtils'
import { userHasPermission } from '@/utils/permissionsUtils'
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import AdminUserImpersonateButton from '../components/AdminUserImpersonateButton.vue'
import AdminDetailUserOrganization from './components/AdminDetailUserOrganization.vue'

const route = useRoute()
const isLoading = computed(() => store.state.user.isLoading)
const userId = ref(route.params.id.toString())
const submitted = ref(false)

const getUser = () =>
    store.dispatch(`${EStoreModules.USER}/${EUserActions.FETCH_USER}`, userId.value).then(res => {
        userValue.value = { ...res }
        storedValue.value = { ...res }
    })

onMounted(() => {
    if (typeof userId.value === 'string') getUser()
})

const storedValue = ref<UserModel>({ ...defaultUserModel })
const userValue = ref<UserModel>({ ...defaultUserModel })

const updateUserValue = (value: Partial<UserModel>) =>
    (userValue.value = { ...userValue.value, ...value })

const updateUser = () => {
    submitted.value = true
    if (!userValue.value.name || !userValue.value.email) return
    store
        .dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
            data: userValue.value,
            userId: userId.value
        })
        .then(() => (submitted.value = false))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <UpdateUserView
        :data="userValue"
        :submitted="submitted"
        :label="$t('common.saveModifications')"
        :editPermission="EAdminPerm.ADMIN_USERS"
        @click="updateUser"
        @change="updateUserValue">
        <AdminDetailUserOrganization :organizationName="userValue.organization_name" />
        <template #update>
            <UsersBaseActions :user="userValue" class="full-width">
                <PermissionWrapper :permission="EAdminPerm.ADMIN_USERS_IMPERSONATE">
                    <AdminUserImpersonateButton withLabel :user="userValue" class="full-width" />
                </PermissionWrapper>
                <ResetPasswordButton :email="storedValue.email" />
            </UsersBaseActions>
        </template>
        <template #metadata>
            <TypographyItem
                v-if="storedValue.last_login_date && userHasPermission(EAdminPerm.ADMIN_USERS)"
                :label="`${$t('user.detail.lastLoginDate')}: ${moment(
                    storedValue.last_login_date
                ).format(DATETIME_FORMAT)}`"
                class="align-self-end" />
            <TypographyItem
                v-else-if="!storedValue.last_login_date"
                :label="$t('user.detail.noLastLoginDate')"
                class="align-self-end" />
        </template>
    </UpdateUserView>
</template>
