<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import store from '@/store'
import { computed, onUpdated, ref } from 'vue'
import SupportAskQuestion from './Search/SupportAskQuestion.vue'
import SupportFrequentlyAsked from './Search/SupportFrequentlyAsked.vue'

const questionInput = ref('')

const results = ref([])
const showResultsStack = ref(false)
const isResultLoading = computed(() => store.state.question.isLoading)
onUpdated(() => {
    if (isResultLoading.value) showResultsStack.value = true
})
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <SupportAskQuestion class="title-larger" />
        <SupportFrequentlyAsked class="title-larger" />
    </StackLayout>
</template>

<style scoped lang="scss">
.support-title {
    & :deep(.title) {
        font-size: larger;
    }
}
.results-loading {
    min-height: 50px;
}
</style>
