<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { TypographyItem } from '@/components/Typography'
import { currentEnv, currentEnvLabel, currentVersion } from '@/config/env'
import { computed } from 'vue'
import { EEnv } from '../../types/enum/EnvEnum'
import TagItem from '../Tags/TagItem.vue'

const currentVersionLabel = computed(() =>
    currentVersion || import.meta.env.VITE_BUILD_VERSION
        ? `version ${import.meta.env.VITE_BUILD_VERSION ?? currentVersion}`
        : null
)
const currentEnvItem =
    currentEnv !== EEnv.PRODUCTION || import.meta.env.VITE_BUILD_ENV
        ? { name: currentEnvLabel }
        : null
</script>

<template>
    <StackLayout
        v-if="currentVersion || currentEnvItem"
        class="full-width space-between version-layout align-items-center">
        <TypographyItem v-if="!!currentVersionLabel" :label="currentVersionLabel" class="small" />
        <TagItem
            v-if="!!currentEnvItem"
            :item="currentEnvItem"
            :closable="false"
            class="version-tag" />
    </StackLayout>
</template>

<style scoped lang="scss">
.version-layout {
    padding: 4px;
}
.version-tag {
    height: 16px;
}
</style>
