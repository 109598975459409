<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { BaseDialog } from '@/components/Modals/Dialog'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { capitalizedAvailableLangs } from '@/locales/i18n'
import topicApi from '@/services/topic.service'
import type { SelectorObject } from '@/types/components/SelectorObject'
import type { TopicModel } from '@/types/models/TopicModel'
import { onMounted, ref } from 'vue'

type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)
const newTopic = ref<Partial<TopicModel>>()

const createTopic = () => {
    if (!newTopic.value) return // TODO : Dispatch/Manage error
    topicApi.createTopic(newTopic.value).then(() => emit('refresh'))
}

const nameOption = ref<SelectorObject[]>([])

onMounted(() =>
    topicApi.getTopics().then(res => {
        nameOption.value = res.map?.((topics: TopicModel) => ({
            name: topics.trans,
            value: topics.id
        }))
    })
)

const handleChange = (value: Partial<TopicModel>) =>
    (newTopic.value = { ...newTopic.value, ...value })
</script>

<template>
    <CreateButton @click="open = true" :label="$t('technician.ticket.topic.addTopic')" />
    <BaseDialog
        hasModalCard
        :title="$t('technician.ticket.topic.addTopic')"
        :open="open"
        :confirmLabel="$t('technician.ticket.topic.create')"
        @confirm="createTopic"
        @close="open = false">
        <template #content>
            <StackLayout direction="column" :gap="16">
                <InputField
                    v-for="(lang, index) in capitalizedAvailableLangs"
                    :key="index"
                    :modelValue="newTopic?.name?.[lang]"
                    :label="$t(`technician.ticket.topic.nameTopic${lang}`)"
                    :placeholder="$t(`technician.ticket.topic.placeholderNameTopic${lang}`)"
                    @change="handleChange({ name: { ...(newTopic?.name ?? {}), [lang]: $event } })"
                    required />

                <AutocompleteField
                    :label="$t('technician.ticket.ticket.parent_id')"
                    :placeholder="$t('technician.ticket.ticket.select_parent_id')"
                    :modelValue="newTopic?.parent_id"
                    :options="nameOption"
                    @select="handleChange({ parent_id: $event?.value as number })" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>
