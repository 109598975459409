import i18n from '@/locales/i18n'
import type { InputHTMLAttributes } from 'vue'

export enum EValidationMessage {
    EMAIL = 'email'
}

export const EValidationMessageLabels = (value: InputHTMLAttributes['type']) => {
    switch (value) {
        case EValidationMessage.EMAIL:
            i18n.global.t('enum.validationMessages.email', { email: value })
    }
    return undefined
}
