<script setup lang="ts">
import RolesTable from '@/components/Roles/RolesTable.vue'
import { ADMIN_ROLES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { ERoleActions } from '@/store/roleStore/RoleStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { RoleModel } from '@/types/models/RoleModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const data = computed(() => store.state.role)
const filters = ref(getQueryFilters())
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getRoles = () =>
    store.dispatch(`${EStoreModules.ROLE}/${ERoleActions.FETCH_ROLES}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const goToRoleCreate = () => router.push(ADMIN_ROLES_ROUTES.createUrl)
const goToRolesDetail = (role: RoleModel) => router.push(ADMIN_ROLES_ROUTES.buildUrl(role.id))
const deleteRole = (role: RoleModel) => {
    store
        .dispatch(`${EStoreModules.ROLE}/${ERoleActions.DELETE_ROLE}`, {
            roleId: role.id,
            name: role.name
        })
        .then(() => getRoles())
}

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getRoles()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getRoles()
}

const handleChangeSortBy = (value: ESortBy) => {
    sort.value = value
    getRoles()
}
</script>

<template>
    <RolesTable
        :data="{ ...data, data: data.data.roles }"
        :editPermission="EAdminPerm.ADMIN_ROLES"
        :deletePermission="EAdminPerm.ADMIN_ROLES"
        :editButtonHref="ADMIN_ROLES_ROUTES.baseUrl"
        :createButtonHref="ADMIN_ROLES_ROUTES.createUrl"
        @delete="deleteRole"
        @goToCreate="goToRoleCreate"
        @goToDetail="goToRolesDetail"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeSortBy="handleChangeSortBy"
        @search="getRoles" />
</template>
