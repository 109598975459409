<script setup lang="ts">
import store from '@/store'
import { type PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { computed } from 'vue'
type Props = {
    permission?: PermissionsEnums
    hasPermission?: boolean
}
const props = defineProps<Props>()

const userHasPermission = computed(
    () =>
        store.state.authentication.me?.permissions?.includes(
            (props.permission as PermissionsEnums) ?? ''
        ) ?? false
)
</script>

<template>
    <slot v-if="userHasPermission || hasPermission" />
</template>
