<script setup lang="ts">
import CreateButton from '@/components/Buttons/CreateButton.vue'
import DividerItem from '@/components/Divider/DividerItem.vue'
import EditableBooleanField from '@/components/Fields/EditableFields/EditableBooleanField.vue'
import EditableSelectField from '@/components/Fields/EditableFields/EditableSelectField.vue'
import EditableStringField from '@/components/Fields/EditableFields/EditableStringField.vue'
import TypedInput from '@/components/Fields/TypedInput.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import FullScreenLoader from '@/components/LoadingSpinner/FullScreenLoader.vue'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import { EKeyCategoryLabels, EKeyCategoryValues } from '@/types/enum/KeyCategoryEnum'
import { EPrimitive, EPrimitiveDefaultValue, EPrimitiveValues } from '@/types/enum/PrimitivesEnum'
import { type DeviceTemplateKeysCreateModel } from '@/types/models/DeviceTemplateKeysCreateModel'
import { type DeviceTemplateKeysModel } from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { dataIsNumberPrimitive, isNullOrUndefined } from '@/utils/validateUtils'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import SelectorField from '../../../../../components/Selector/SelectorField.vue'
import { dataToType } from '../../../../../utils/dataUtils'
import ColumnWithTranslations from '../templateColumnsComponents/ColumnWithTranslations.vue'
import EnumColumn from '../templateColumnsComponents/EnumColumn.vue'
import ExcludedColumn from '../templateColumnsComponents/ExcludedColumn.vue'
import RangeColumn from '../templateColumnsComponents/RangeColumn.vue'
import ValuesColumn from '../templateColumnsComponents/ValuesColumn.vue'

type Props = {
    confirmLabel?: string
    data?: Partial<DeviceTemplateKeysCreateModel | DeviceTemplateKeysModel>
    editPermission?: PermissionsEnums
    hasPermission?: boolean
    loading?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceTemplateKeysCreateModel | DeviceTemplateKeysModel>): void
    (e: 'confirm'): void
}
const emit = defineEmits<Emits>()

const handleConfirm = () => {
    submitted.value = true
    if (Object.values(hasErrors.value).filter(item => item).length) return
    emit('confirm')
    submitted.value = false
}

const showDivider = ref(!isMobile())
isMobileEvent(e => (showDivider.value = !e.matches))

const submitted = ref(false)
const hasErrors = computed(() => ({
    nameHasError: submitted.value && !props.data?.name
}))

const handleChangeType = (type: EPrimitive) => {
    emit('change', { type, default: EPrimitiveDefaultValue[type] })
    emit('change', {
        values: props.data?.values?.map(item => dataToType(item, type)),
        excluded: props.data?.excluded?.map(item => dataToType(item, type)),
        range: undefined,
        values_labels: undefined
    })
    if (type === EPrimitive.BOOLEAN)
        return emit('change', { values: undefined, excluded: undefined, range: undefined })
    if (type === EPrimitive.ENUM)
        return emit('change', {
            values_labels: [
                ...(props.data?.values
                    ?.filter(item => !isNullOrUndefined(item))
                    .map(item => item?.toString()) ?? [])
            ]
        })
}

const enumOptions = computed(
    () =>
        props.data?.values?.map((value, index) => ({
            value: dataToType(value, EPrimitive.STRING) as string,
            name: props.data?.values_labels?.[index] ?? value?.toString()
        }))
)
</script>

<template>
    <FullScreenLoader :isLoading="!!loading" />
    <StackLayout direction="column" :gap="8" class="full-width">
        <StackLayout direction="row" :gap="8" class="full-width" isResponsive>
            <StackLayout direction="column" :gap="8" class="full-width">
                <EditableStringField
                    :label="$t('deviceConfig.templateKeys.model.key')"
                    :value="data?.name"
                    @change="$emit('change', { name: $event })"
                    :hasError="hasErrors.nameHasError"
                    required
                    isInEditMode />
                <TypographyWithPrefix class="space-between">
                    <TypographyItem
                        :label="`${$t('deviceConfig.templateKeys.model.translation')} :`" />
                    <template #edit>
                        <ColumnWithTranslations
                            :value="data?.translation"
                            @change="$emit('change', { translation: $event })" />
                    </template>
                </TypographyWithPrefix>
                <EditableSelectField
                    :label="$t('deviceConfig.templateKeys.model.type')"
                    :value="data?.type"
                    :options="EPrimitiveValues.map(value => ({ value }))"
                    @change="handleChangeType"
                    required
                    isInEditMode />
                <EditableStringField
                    v-if="data?.type && dataIsNumberPrimitive(data.type)"
                    :label="$t('deviceConfig.templateKeys.model.unit')"
                    :value="data?.unit"
                    :options="EPrimitiveValues.map(value => ({ value }))"
                    @change="$emit('change', { unit: $event })"
                    isInEditMode />
                <TypographyWithPrefix class="space-between">
                    <TypographyItem
                        :label="`${$t('deviceConfig.templateKeys.model.description')} :`" />
                    <template #edit>
                        <ColumnWithTranslations
                            :value="data?.description"
                            @change="$emit('change', { description: $event })" />
                    </template>
                </TypographyWithPrefix>

                <TypographyWithPrefix class="space-between">
                    <TypographyItem :label="`${$t('deviceConfig.templateKeys.model.default')} :`" />
                    <template #edit>
                        <SelectorField
                            v-if="data?.type && data.type === EPrimitive.ENUM"
                            :modelValue="data?.default as string"
                            @change="$emit('change', { default: $event })"
                            :options="enumOptions" />
                        <TypedInput
                            v-else
                            :dataType="data?.type ?? EPrimitive.STRING"
                            @change="$emit('change', { default: $event })"
                            :modelValue="data?.default" />
                    </template>
                </TypographyWithPrefix>

                <TypographyWithPrefix
                    v-if="data?.type && dataIsNumberPrimitive(data.type)"
                    class="space-between">
                    <TypographyItem :label="`${$t('deviceConfig.templateKeys.model.range')} :`" />
                    <template #edit>
                        <RangeColumn
                            :value="data?.range"
                            @change="
                                $emit('change', { range: { ...(data?.range ?? {}), ...$event } })
                            " />
                    </template>
                </TypographyWithPrefix>

                <StackLayout v-if="data?.type === EPrimitive.ENUM" class="full-width">
                    <EnumColumn
                        :values="data?.values"
                        :values_labels="data?.values_labels"
                        @change="$emit('change', $event)"
                        class="full-width space-between" />
                </StackLayout>
                <TypographyWithPrefix
                    v-else-if="data?.type !== EPrimitive.BOOLEAN"
                    class="space-between">
                    <TypographyItem :label="`${$t('deviceConfig.templateKeys.model.values')} :`" />
                    <template #edit>
                        <ValuesColumn
                            :value="data?.values"
                            :dataType="data?.type ?? EPrimitive.STRING"
                            @change="$emit('change', { values: $event })" />
                    </template>
                </TypographyWithPrefix>

                <TypographyWithPrefix
                    v-if="data?.type !== EPrimitive.BOOLEAN && data?.type !== EPrimitive.ENUM"
                    class="space-between">
                    <TypographyItem
                        :label="`${$t('deviceConfig.templateKeys.model.excluded')} :`" />
                    <template #edit>
                        <ExcludedColumn
                            :value="data?.excluded"
                            @change="$emit('change', { excluded: $event })" />
                    </template>
                </TypographyWithPrefix>
            </StackLayout>
            <DividerItem
                v-if="showDivider"
                variant="middle"
                orientation="vertical"
                isPrimary
                isResponsive />
            <StackLayout direction="column" :gap="8" class="full-width">
                <EditableSelectField
                    :label="$t('deviceConfig.templateKeys.model.category')"
                    :value="EKeyCategoryValues.find(item => item === data?.category)"
                    :options="
                        EKeyCategoryValues.map(value => ({
                            value,
                            name: EKeyCategoryLabels[value]
                        }))
                    "
                    @change="$emit('change', { category: $event })"
                    required
                    isInEditMode />
                <EditableBooleanField
                    :value="!!data?.is_admin"
                    :label="$t('deviceConfig.templateKeys.model.isAdmin')"
                    @change="$emit('change', { is_admin: $event ? 1 : 0 })"
                    isInEditMode />

                <EditableBooleanField
                    :value="!!data?.masked"
                    :label="$t('deviceConfig.templateKeys.model.masked')"
                    @change="$emit('change', { masked: $event ? 1 : 0 })"
                    isInEditMode />

                <EditableBooleanField
                    :value="!!data?.read_only"
                    :label="$t('deviceConfig.templateKeys.model.readOnly')"
                    @change="$emit('change', { read_only: $event ? 1 : 0 })"
                    isInEditMode />

                <EditableBooleanField
                    :value="!!data?.is_advanced"
                    :label="$t('deviceConfig.templateKeys.model.isAdvanced')"
                    @change="$emit('change', { is_advanced: $event ? 1 : 0 })"
                    isInEditMode />
            </StackLayout>
        </StackLayout>
        <PermissionWrapper :permission="editPermission" :hasPermission="hasPermission">
            <CreateButton
                :loading="loading"
                :label="confirmLabel ?? $t('deviceConfig.templateKeys.buttons.createNewKey')"
                @click="handleConfirm" />
        </PermissionWrapper>
    </StackLayout>
</template>
