<script setup lang="ts">
import DividerItem from '@/components/Divider/DividerItem.vue'
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import MenuItem from '@/components/Menu/MenuItem.vue'
import LocaleDropdown from '@/components/Selector/LocaleDropdown.vue'
import ToggleTheme from '@/components/Theme/ToggleTheme.vue'
import { TypographyWithPrefix } from '@/components/Typography'
import { useIsImpersonate } from '@/hooks/useIsImpersonate'
import { menuAccountItems } from '@/router/menuRoutes'
import store from '@/store'
import { computed } from 'vue'

type Props = {
    showToggles?: boolean
    hideDividers?: boolean
}
defineProps<Props>()
defineEmits<{ (e: 'close'): void }>()

const routes = menuAccountItems
const currentlyImpersonate = useIsImpersonate()
const me = computed(() => store.state.authentication.me)
</script>

<template>
    <StackLayout direction="column" v-bind="$attrs">
        <MenuItem :item="routes.myAccount" class="my-account-mobile" @click="$emit('close')">
            <template #label>
                <StackLayout direction="column" :gap="4">
                    <TypographyWithPrefix>
                        <IconItem :icon="routes.myAccount.icon" />
                        <template #edit>
                            {{ me.name }}
                        </template>
                    </TypographyWithPrefix>
                    <TypographyWithPrefix v-if="me.organization_name">
                        <IconItem icon="briefcase" />
                        <template #edit>
                            {{ me.organization_name }}
                        </template>
                    </TypographyWithPrefix>
                </StackLayout>
            </template>
        </MenuItem>
        <MenuItem
            v-if="currentlyImpersonate"
            :item="routes.impersonate"
            class="my-account-mobile" />
        <StackLayout v-if="showToggles" direction="column">
            <DividerItem v-if="!hideDividers" variant="middle" />
            <StackLayout direction="column" :gap="8" class="padding">
                <LocaleDropdown class="mobile-menu-locale" />
                <ToggleTheme @click="$event.stopPropagation()" />
            </StackLayout>
            <DividerItem v-if="!hideDividers" variant="middle" />
        </StackLayout>

        <MenuItem
            :item="routes.disconnect"
            @click="routes.disconnect.click"
            :class="`${showToggles ? 'disconnect-mobile' : ''}`" />
    </StackLayout>
</template>
