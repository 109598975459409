import type { CommonLocales, EnumLocales } from '@/types/lang/locales'

export const en: CommonLocales = {
    actions: 'Actions',
    add: 'Add',
    advancedFilters: 'Advanced filters',
    announcements: 'Announcements',
    archive: 'Archive',
    back: 'Back',
    cancel: 'Cancel',
    comment: 'Comment',
    confirm: {
        confirmAction: 'Confirm {action}',
        confirmContent: 'Would you like to confirm {action}? {complement}',
        confirmTitle: 'Confirm {action} of "{item}"',

        add: 'addition',
        archive: 'archiving',
        archiveComplement: 'This action is final',
        delete: 'deletion',
        deleteComplement: 'This action is final',
        edit: 'edition'
    },
    close: 'Close',
    connectedAs: 'Connected as',
    create: 'Create',
    delete: 'Delete',
    description: 'Description',
    details: 'Details',
    diagnostic: 'Diagnostic',
    edit: 'Edit',
    editMode: 'Edit mode',
    exportToCsv: 'Export to CSV',
    hello: 'hello',
    modifiedBy: 'Modified by',
    myAccount: 'My account',
    next: 'Next',
    no: 'No',
    noAnnouncement: 'No announcement at the moment',
    noHistory: 'No history has been found',
    noOption: 'No option available',
    noRecord: 'No record',
    noRole: 'Aucun rôle',
    object: 'Object',
    or: 'or',
    resolution: 'Resolution',
    save: 'Save',
    saveModifications: 'Save modifications',
    scan: 'Scanner',
    search: 'Search',
    send: 'Send',
    showDetails: 'Show details',
    showMore: 'show more',
    sortBy: 'Sort by',
    theme: {
        light: 'Light mode',
        dark: 'Dark mode'
    },
    undefined: 'Undefined',
    unknown: 'Unknown',
    upload: 'Upload files',
    validate: 'Validate',
    value: 'Value',
    typeYourSearch: 'Type your search...',
    yes: 'Yes'
}

export const enumEn: EnumLocales = {
    env: {
        production: 'PROD',
        development: 'DEV',
        preproduction: 'PREPROD',
        other: 'Autre'
    },
    keyCategory: {
        authentication: 'Authentication',
        configuration: 'Configuration',
        connectivity: 'Connectivity',
        informations: 'Information',
        misc: 'Misc',
        rfid: 'RFID',
        specific: 'Specific',
        filter1: 'Filter 1',
        filter2: 'Filter 2'
    },
    qrcode: {
        error: {
            detectionError: 'No QR code has been detected',
            notAllowed: 'You need to grant camera access permission',
            notFound: 'No camera on this device',
            notSupported: 'Secure context required (HTTPS, localhost)',
            notReadable: 'Is the camera already in use ?',
            overconstrained: 'Installed cameras are not suitable',
            streamApiNotSupported: 'This feature is not supported in this browser',
            insecureContext:
                'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
        },
        success: {
            detectionSuccess: 'The code has been successfully detected'
        }
    },
    status: {
        disabled: 'Disabled',
        enabled: 'Active'
    },
    sortBy: {
        alphabeticalAsc: 'Alphabetical',
        alphabeticalDesc: 'Unalphabetical',
        dateRecent: 'Most recent',
        dateOld: 'Least recent'
    },
    severity: {
        medium: 'Medium',
        high: 'High'
    },
    topic: {
        information: 'information',
        dysfunction: 'malfunction'
    },
    status_ticket: {
        technician: 'Technician',
        commercial: 'Commercial'
    },
    validationMessages: {
        email: 'Input is not an email.'
    }
}
