<script setup lang="ts">
import { EditButton } from '@/components/Buttons'
import EditableButtonGroup from '@/components/Buttons/EditableButtonGroup.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import EditableAutoCompleteField from '@/components/Fields/EditableFields/EditableAutoCompleteField.vue'
import EditableMultilineField from '@/components/Fields/EditableFields/EditableMultilineField.vue'
import { StackLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ETicketActions } from '@/store/ticketStore/TicketStoreTypes'
import type { TicketModel } from '@/types/models/TicketModel'
import { ETechnicianTicketsPerm } from '@/types/permissions/TechnicianPermissionsEnum'
import moment from 'moment'
import { ref } from 'vue'
import { DATETIME_FORMAT } from '../../../utils/dateUtils'
import TicketDetailDeviceInformations from './components/TicketDetailDeviceInformations.vue'
import TicketDetailUserInformations from './components/TicketDetailUserInformations.vue'

type Props = {
    data?: TicketModel
}
const props = defineProps<Props>()

type Emits = {
    (e: 'change', value: Partial<TicketModel>): void
}
defineEmits<Emits>()

const isInEditMode = ref(false)

const save = () => {
    const updatedTicketData = {
        ...props.data,
        end_date: moment().add(10, 'years').format(DATETIME_FORMAT.value)
    }

    store.dispatch(`${EStoreModules.TICKET}/${ETicketActions.UPDATE_TICKET}`, {
        ticketId: props.data?.id,
        ticketData: updatedTicketData
    })

    isInEditMode.value = false
}
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <PermissionWrapper
            :editPermission="ETechnicianTicketsPerm.TECHNICIAN_TICKETS_EDIT"></PermissionWrapper>
        <EditableButtonGroup
            :isInEditMode="isInEditMode"
            @save="save"
            @edit="isInEditMode = $event" />

        <StackLayout direction="row" :gap="8" isResponsive class="full-width">
            <TicketDetailUserInformations :data="data" class="two-thirds-width" />

            <InformationBaseCard
                :label="$t('technician.ticket.detail.reassignenment')"
                class="full-width">
                <StackLayout :gap="8" direction="column" class="full-width">
                    <EditableAutoCompleteField
                        :value="data?.user?.name"
                        boldLabel
                        :isInEditMode="isInEditMode"
                        required />
                    <EditButton
                        v-if="isInEditMode"
                        is-responsive
                        :label="$t('technician.ticket.detail.reassignenment')" />
                </StackLayout>
            </InformationBaseCard>
        </StackLayout>

        <InformationBaseCard :label="$t('technician.ticket.detail.comment')">
            <EditableMultilineField
                :value="data?.comment"
                @change="$emit('change', { comment: $event })"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
        </InformationBaseCard>

        <InformationBaseCard :label="$t('technician.ticket.detail.resolution')">
            <EditableMultilineField
                :value="data?.resolution"
                @change="$emit('change', { resolution: $event })"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
        </InformationBaseCard>

        <InformationBaseCard :label="$t('technician.ticket.detail.diagnostic')">
            <EditableMultilineField
                :value="data?.diagnostic"
                @change="$emit('change', { diagnostic: $event })"
                boldLabel
                :isInEditMode="isInEditMode"
                required />
        </InformationBaseCard>

        <TicketDetailDeviceInformations :device="data?.serial_number_device" />
    </StackLayout>
</template>
