<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import { ADMIN_APIS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })
const isLoading = computed(() => store.state.organization.apis.isLoading)
const submitted = ref(false)

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const create = () => {
    submitted.value = true
    if (!data.value.name) return
    store
        .dispatch(`${EStoreModules.API}/${EApiActions.CREATE_API}`, data.value)
        .then(() => router.push(ADMIN_APIS_ROUTES.baseUrl))
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <ApiDetailView
        :data="data"
        :buttonLabel="$t('api.buttons.createApi')"
        hasPermission
        isCreateMode
        :loading="!!store.state.organization.apis.isLoading"
        @click="create"
        @update="updateApi" />
</template>
