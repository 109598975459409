<script setup lang="ts">
import store from '@/store'
import { EAlertComponent } from '@/types/components/AlertComponent'
import { computed } from 'vue'
import AlertWrapper from './AlertWrapper.vue'
import NotificationWrapper from './NotificationWrapper.vue'
import SnackbarItem from './SnackbarItem.vue'
import ToastItem from './ToastItem.vue'

const data = computed(() => store.state.alert)
const notification = computed(() => store.state.notification)
</script>
<template>
    <div class="notices is-top">
        <Transition name="fade">
            <AlertWrapper v-if="data.component" :data="data">
                <ToastItem
                    v-if="data.component === EAlertComponent.TOAST"
                    :message="data.message"
                    :type="data.type"
                    class="alert-type alert-component" />
                <SnackbarItem
                    v-if="data.component === EAlertComponent.SNACKBAR"
                    :message="data.message"
                    :type="data.type"
                    class="alert-type alert-component" />
            </AlertWrapper>
        </Transition>
        <NotificationWrapper v-if="notification.message" :notification="notification" />
    </div>
</template>

<style scoped lang="scss">
.center-notification {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}
</style>
