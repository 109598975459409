import { AUTH_API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'

const API_PREFIX = `${AUTH_API_PREFIX}/apis`

const apiApi = {
    getApi: (id: string) => auth.get(`${API_PREFIX}/${id}`),
    getApis: (params?: any) => auth.get(API_PREFIX, { params }).then(response => response.data),
    createApi: (apiData: any) => auth.post(API_PREFIX, apiData).then(response => response.data),
    updateApi: (id: string, apiData: any) =>
        auth.patch(`${API_PREFIX}/${id}`, apiData).then(response => response.data),
    deleteApi: (id: string) => auth.delete(`${API_PREFIX}/${id}`).then(response => response.data)
}

export default apiApi
