import type { PaginationDataType } from '@/types/PaginationType'

export const getQueryParams = (data: PaginationDataType<unknown>) => ({
    per_page: data.per_page,
    page: data.current_page
})

export const isCurrentPageOverTotal = (
    total: number,
    pageSize: number,
    currentPage: number,
    lastPage: number
) => currentPage === lastPage && Math.ceil(total / pageSize) < lastPage
