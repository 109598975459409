import i18n from '@/locales/i18n'
import type { RouteType } from '@/types/Route'
import HomeView from '@/views/HomeView/HomeView.vue'
import { ref } from 'vue'

export const HOME_ROUTE_NAME = 'homePage'

const routes: RouteType[] = [
    {
        path: '/',
        name: HOME_ROUTE_NAME,
        component: HomeView,
        meta: {
            title: ref(i18n.global.t('menu.home'))
        }
    }
]

export default routes
