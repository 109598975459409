import type { EStatus } from '@/types/enum/StatusEnum'

export type ApiFiltersType = Partial<{
    name: string
    status: EStatus
    expirationDateFrom: Date
    expirationDateTo: Date
}>
export const defaultApiFiltersType: ApiFiltersType = {
    expirationDateFrom: undefined,
    expirationDateTo: undefined
}
