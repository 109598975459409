export const authenticationFr = {
    api: {
        error: {
            forgotPassword:
                "Une erreur est survenue lors de la tentative d'envoi de l'email de changement de mot de passe",
            login: "Le compte ne semble pas exister. Vérifiez l'email et le mot de passe",
            logout: 'Une erreur est survenue lors de la tentative de déconnexion',
            me: 'Une erreur est survenue lors de la récupération de vos informations',
            resetPassword: 'Une erreur est survenue lors du changement de mot de passe',
            refreshToken: 'Une erreur est survenue lors du rafraichissement de la session'
        },
        success: {
            forgotPassword: 'Le lien a bien été envoyé !',
            login: 'Vous êtes maintenant connecté !',
            logout: 'Déconnexion réussie',
            me: 'Récupération de vos informations avec succès',
            resetPassword: 'Le mot de passe a bien été modifié !',
            refreshToken: 'Rafraichissement de la session effectué avec succès !'
        }
    },
    back: 'Retour',
    backToLogin: 'Retour au login',
    createPassword: {
        confirmPassword: 'Confirmer mot de passe',
        contentTitle: "Veuillez créer votre mot de passe pour l'email : ",
        password: 'Mot de passe',
        title: 'Créer un mot de passe'
    },
    email: 'Email',
    error: 'Veuillez corriger le formulaire',
    forgotPassword: {
        title: 'Mot de passe oublié',
        sendEmail: "Envoyer l'email",
        contentTitle: 'Envoyer un email de récupération de mot de passe',
        email: 'Email',
        content:
            "Un email contenant un lien pour changer de mot de passe va être envoyé à l'adresse fournie."
    },
    goToLoginPage: "Aller à l'écran de connexion",
    login: 'Se connecter',
    newPassword: 'Nouveau mot de passe',
    password: 'Mot de passe',
    passwordConfirmation: 'Confirmer le mot de passe',
    passwordRules: {
        content: 'Le mot de passe doit être composé de :',
        lowercaseCharacters: 'Au moins une lettre minuscle',
        minLength: 'Au moins 8 caractères',
        noWhitespaceCharacter: "Pas d'espace",
        numericCharacters: 'Au moins un chiffre',
        specialCharacters: 'Au moins un caractère spécial',
        uppercaseCharacters: 'Au moins une lettre majuscule',
        passwordMatches: 'Les mots de passe concordent'
    },
    resetPasswordAction: 'Réinitialiser',
    resetPasswordTitle: 'Réinitialiser le mot de passe',
    sendEmail: "Envoyer l'email",
    sendLink: {
        title: 'Lien de réinitialisation de mot de passe envoyé',
        content: "Si l'email n'apparaît pas au bout de quelques minutes :",
        contentTitle:
            "L'email contenant le lien de réinitialisation de mot de passe a bien été envoyé à l'adresse : ",
        checkSpam: 'Vérifiez vos spams',
        redoSend: 'Demandez un nouvel envoi',
        resendLink: "Renvoyer l'email"
    },
    submit: 'Confirmer',
    title: {
        title: "S'identifier",
        resetPassword: 'Mot de passe oublié',
        sendLinkConfirmation: "Réception de l'email de modification de mot de passe"
    },

    onboarding: {
        api: {
            success: {
                sendOnboarding: 'Demande envoyée avec succès !',
                validateOnboarding: 'Validation effectuée avec succès !'
            }
        },
        title: "Demande d'accès",
        sendButton: 'Envoyer la demande',
        validateTitle: "Validation de demande d'accès",
        validateButton: 'Valider la demande',
        confirmContent: "Confirmer l'enregistrement des données ?",
        organizationStep: {
            title: 'Société',
            model: {
                name: 'Nom de la société',
                timerId: "Numéro d'identifiant du chronométreur"
            }
        },
        userStep: {
            title: 'Utilisateur référent',
            model: {
                email: 'Email',
                name: 'Nom complet'
            }
        },
        devicesStep: {
            buttons: {
                validateDevice: 'Valider cet appareil'
            },
            title: 'Appareils',
            model: {
                active: 'Status',
                apiKeyType: 'Type de clé API',
                deviceType: "Type d'appareil",
                imeiGSM: 'Numéro IMEI GSM',
                imeiSAT: 'Numéro IMEI SAT',
                name: 'Identifiant personnalisé',
                roles: 'Rôles',
                serialNumber: 'Numéro de série (6 caractères)'
            }
        },
        validated: 'Demande déjà validée'
    }
}
