<script setup lang="ts">
import AnnouncementCard from '@/components/Announcements/AnnouncementCard.vue'
import { StackLayout } from '@/components/Layouts'
import { TitleTypography } from '@/components/Typography'
import type { AnnouncementModel } from '@/types/models/AnnouncementModel'

type Props = {
    announcements: AnnouncementModel[]
}
defineProps<Props>()

const emit = defineEmits<{ (e: 'close', id: number): void }>()
const closeAnnouncement = (id: number) => emit('close', id)
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <TitleTypography :title="$t('common.announcements')" />
        <StackLayout
            v-if="announcements.length"
            direction="row"
            :gap="8"
            class="announcements-layout">
            <AnnouncementCard
                v-for="(item, key) in announcements"
                :key="key"
                :data="item"
                @close="closeAnnouncement(key)" />
        </StackLayout>
        <span v-else>
            {{ $t('common.noAnnouncement') }}
        </span>
    </StackLayout>
</template>

<style scoped lang="scss">
.announcements-layout {
    overflow-x: auto;
    padding-bottom: 0.5em;
}
</style>
