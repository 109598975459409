<script setup lang="ts">
import { getCurrentThemeMode } from '@/utils/viewsUtils'
import { computed } from 'vue'
const darkModeLogo = new URL('@/assets/logo-light.png', import.meta.url).href
const lightModeLogo = new URL('@/assets/logo-dark.png', import.meta.url).href

const props = defineProps<{ width?: string; mode?: 'lightMode' | 'darkMode' }>()

const theme = computed(() => getCurrentThemeMode.value)
const logo = computed(() => (theme.value === 'light' ? lightModeLogo : darkModeLogo))

const width = computed(() => (props.width ? `${props.width}px` : 'auto'))
const fixedLogo = props.mode
    ? props.mode === 'lightMode'
        ? lightModeLogo
        : darkModeLogo
    : undefined
</script>

<template>
    <img :src="fixedLogo ?? logo" class="img" />
</template>

<style scoped lang="scss">
.img {
    width: v-bind(width);
}
</style>
