<script setup lang="ts">
import { EditableStringField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import TypographyWithPrefix from '@/components/Typography/TypographyWithPrefix.vue'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { DATETIME_FORMAT } from '@/utils/dateUtils'
import moment from 'moment'

type Props = {
    data: Partial<DeviceModel>
    isInEditMode: boolean
}
defineProps<Props>()
const emit = defineEmits<{ (e: 'update', data: Partial<DeviceModel>): void }>()

const updateDeviceValue = (key: keyof DeviceModel, value?: string) =>
    emit('update', { [key]: value })
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <EditableStringField
            :label="$t('device.model.deviceName')"
            :value="data?.name"
            boldLabel
            :isInEditMode="isInEditMode"
            @change="value => updateDeviceValue('name', value)"
            required />
        <EditableStringField
            :label="$t('device.model.eventName')"
            :value="data?.event?.name"
            boldLabel
            required />
        <EditableStringField
            :label="$t('device.model.checkpointName')"
            :value="data?.checkpoint?.name"
            boldLabel
            required />
        <slot />

        <TypographyWithPrefix
            :prefix="$t('device.model.config_synced_at')"
            :label="
                data?.config_synced_at
                    ? moment(data.config_synced_at).format(DATETIME_FORMAT)
                    : undefined
            "
            boldLabel />
        <TypographyItem
            v-if="data?.config_status == 0"
            :label="$t('device.model.config_status')"
            class="bold" />
    </StackLayout>
</template>
