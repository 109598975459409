<script setup lang="ts">
import FullScreenLoader from '@/components/LoadingSpinner/FullScreenLoader.vue'
import CreateUserView from '@/components/User/Detail/CreateUserView.vue'
import { ORGANIZATIONS_USERS_ROUTES } from '@/router/routing/organizationRouting/organizationRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultUserModel, type UserModel } from '@/types/models/UserModel'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const submitted = ref(false)
const isLoading = computed(() => store.state.user.isLoading)
const organization_id = computed(() => store.state.authentication.me.organization_id ?? undefined)
const userValue = ref<UserModel>({ ...defaultUserModel })

const updateUserValue = (value: Partial<UserModel>) =>
    (userValue.value = { ...userValue.value, ...value })

const createUser = () => {
    submitted.value = true
    if (!userValue.value.email || !userValue.value.name) return
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_USER}`, {
            userData: userValue.value,
            organizationId: organization_id.value
        })
        .then(res => {
            submitted.value = false
            router.replace(ORGANIZATIONS_USERS_ROUTES.buildUrl(res.id))
        })
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <CreateUserView
        :data="userValue"
        :submitted="submitted"
        @click="createUser"
        @change="updateUserValue" />
</template>
