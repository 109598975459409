// import the api endpoints
import { statisticService } from '@/services/statistic.service'
import type { PaginationType } from '@/types/PaginationType'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { EStatisticsMutations, type StatisticsState } from './StatisticsStoreTypes'

const getters = {
    getDetectionsCount: (state: StatisticsState) => state.detections_count,
    getSessionsCount: (state: StatisticsState) => state.sessions_count
}

const actions = {
    fetchStatistics(
        { dispatch, commit }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EStatisticsMutations.SET_LOADING, true)
        statisticService.getStatistics(params).then(
            data => {
                commit(EStatisticsMutations.SET_DETECTIONS_COUNT, data.detections_count)
                commit(EStatisticsMutations.SET_SESSIONS_COUNT, data.sessions_count)
                commit(EStatisticsMutations.SET_LOADING, false)
            },
            error => {
                dispatch(`${EStoreModules.ALERT}/${EAlertActions.ERROR}`, error, { root: true })
                commit(EStatisticsMutations.SET_LOADING, false)
            }
        )
    }
}

const mutations = {
    [EStatisticsMutations.SET_DETECTIONS_COUNT](_: any, data: any) {
        state.detections_count = data
    },
    [EStatisticsMutations.SET_SESSIONS_COUNT](_: any, data: any) {
        state.sessions_count = data
    },
    [EStatisticsMutations.SET_LOADING](_: any, data: any) {
        state.isLoading = data
    }
}

const state: StatisticsState = {
    detections_count: null,
    sessions_count: null,
    isLoading: false
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
