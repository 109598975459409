<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { type IconType } from '@/types/components/IconType'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { getCurrentThemeMode } from '@/utils/viewsUtils'
import { computed } from 'vue'
import BaseButton from './BaseButton.vue'

interface ButtonProps {
    type?: Colors
    icon?: IconType | string
}

const props = defineProps<ButtonProps>()

defineEmits<{
    (e: 'click', event: MouseEvent): void
}>()

const iconType = computed(() => {
    if (typeof props.icon !== 'string') return props.icon?.type
    return getCurrentThemeMode.value === EColors.LIGHT ? EColors.DARK : EColors.LIGHT
})
</script>

<template>
    <BaseButton
        :class="type ? '' : 'secondary-button'"
        :type="type ?? EColors.SECONDARY"
        @click="$emit('click', $event)"
        v-bind="$attrs">
        <IconItem v-if="!!icon" :icon="icon" :type="iconType" class="flex" />
    </BaseButton>
</template>
