<script setup lang="ts">
import { ESortBy, ESortByAlphabetical, ESortByDate } from '@/types/enum/SortByEnum'
import { orderByEnumToOptions } from '@/utils/enumUtils'
import { computed, ref } from 'vue'
import SelectorField from '../Selector/SelectorField.vue'

type Props = {
    options: ESortBy[]
    sortBy?: ESortBy
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'click', value: ESortBy): void
}>()

const orderByOptions = computed(
    () => orderByEnumToOptions(props.options ?? [...ESortByAlphabetical, ...ESortByDate]).value
)
const orderBy = ref(props.sortBy ?? orderByOptions.value[0].value)
const changeOrderBy = (value: string): void => {
    if (!value) return
    const found =
        orderByOptions.value.find(item => item.value === value)?.value ?? ESortBy.ALPHABETICAL_ASC
    orderBy.value = found
    emit('click', found)
}
</script>

<template>
    <SelectorField
        :label="$t('common.sortBy')"
        :modelValue="orderBy"
        @change="changeOrderBy"
        :options="orderByOptions"
        class="sort-by" />
</template>

<style scoped lang="scss">
.sort-by {
    min-width: 160px;
}
</style>
