import type { PartnerLocales } from '@/types/lang/locales'

export const partnerEn: PartnerLocales = {
    api: {
        error: {
            getPartners: 'Error retrieving partners',
            getPartner: 'Error retrieving the partner',
            addPartner: 'Error adding partner',
            updatePartner: 'Error modifying partner',
            deletePartner: 'Error when deleting the partner'
        },
        success: {
            getPartners: 'Partners retrieved successfully !',
            getPartner: 'Partner retrieval completed successfully !',
            addPartner: 'Partner added successfully !',
            updatePartner: 'Partner modified successfully !',
            deletePartner: 'Partner deleted successfully !'
        }
    },
    config: {
        event: 'Event ID',
        checkpoint: 'Checkpoint ID',
        url: 'URL',
        api: 'API'
    },
    line: 'ligne',
    lines: 'lignes',
    total: 'Total'
}
