<script setup lang="ts">
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import {
    defaultDeviceTemplateKeysModel,
    type DeviceTemplateKeysModel
} from '@/types/models/DeviceTemplateKeysModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { isEqual } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { isNullOrUndefined } from '../../../../utils/validateUtils'
import NewConfigComponent from './newConfigKeyComponents/NewConfigComponent.vue'

type Props = {
    editPermission?: PermissionsEnums
    hasPermission?: boolean
}
defineProps<Props>()

const route = useRoute()

const storeData = computed(() => store.state.deviceConfig.templateKeys.templateKey)
const data = ref<DeviceTemplateKeysModel>({ ...defaultDeviceTemplateKeysModel })
const id = ref(route.params.id.toString())

const getTemplateKey = () => {
    store
        .dispatch(
            `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.FETCH_DEVICE_TEMPLATE_KEY}`,
            id.value
        )
        .then(res => (data.value = res))
}
onMounted(() => getTemplateKey())

const handleUpdateKey = () => {
    if (!isEqual(data.value, storeData.value))
        store
            .dispatch(
                `${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.UPDATE_DEVICE_TEMPLATE_KEY}`,
                {
                    id: id.value,
                    data: {
                        ...data.value,
                        values: data.value.values?.filter(item => !isNullOrUndefined(item)),
                        values_labels: data.value.values_labels?.filter(
                            item => !isNullOrUndefined(item)
                        ),
                        excluded: data.value.excluded?.filter(item => !isNullOrUndefined(item))
                    }
                }
            )
            .then(() => getTemplateKey())
}
</script>
<template>
    <NewConfigComponent
        :confirmLabel="$t('deviceConfig.templateKeys.buttons.updateKey')"
        :data="data"
        :editPermission="editPermission"
        :hasPermission="hasPermission"
        :loading="store.state.deviceConfig.templateKeys.isLoading"
        @change="data = { ...data, ...$event }"
        @confirm="handleUpdateKey" />
</template>
