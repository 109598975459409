<script setup lang="ts">
import { DeviceTablePage } from '@/components/DeviceComponents'
import { TECHNICIAN_INVENTORY_ROUTES } from '@/router/routing/technicianRouting/technicianRoutes'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { AdvancedDeviceFilters, DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { ETechnicianDevicesPerm } from '@/types/permissions/TechnicianPermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const data = computed(() => store.state.device)
const filters = ref<Record<string, ExtendedPrimitives>>({ ...getQueryFilters() })
const advancedFilters = ref<AdvancedDeviceFilters>({})

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getDevices = () =>
    store.dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.FETCH_DEVICES}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const goToDetail = (device: DeviceModel) =>
    router.push(TECHNICIAN_INVENTORY_ROUTES.buildUrl(device.id))

const handleChangeFilters = (value: Partial<DeviceFiltersType>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getDevices()
}
const handleAdvancedFilters = (value: Partial<DeviceFiltersType & AdvancedDeviceFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })
const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDevices()
}
const handleChangePageNumber = (pageNumber: number) => {
    data.value.current_page = pageNumber
    getDevices()
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    data.value.current_page = 1
    getDevices()
}

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    data.value.current_page = 1
}
</script>

<template>
    <DeviceTablePage
        :data="data"
        :filters="filters"
        :advancedFilters="advancedFilters"
        :loading="data.isLoading"
        :sortBy="sort"
        :editPermission="ETechnicianDevicesPerm.TECHNICIAN_DEVICES_EDIT"
        :deletePermission="ETechnicianDevicesPerm.TECHNICIAN_DEVICES_DELETE"
        :createButtonHref="TECHNICIAN_INVENTORY_ROUTES.createUrl"
        :editButtonHref="TECHNICIAN_INVENTORY_ROUTES.baseUrl"
        @goToDetail="goToDetail"
        @changePageSize="handleChangePageSize"
        @changePageNumber="handleChangePageNumber"
        @changeFilters="handleChangeFilters"
        @changeAdvancedFilters="handleAdvancedFilters"
        @removeTag="handleRemoveTag"
        @search="handleSearch">
        <template #filters></template>
    </DeviceTablePage>
</template>
