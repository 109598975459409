import type { RouteType } from '@/types/Route'
import MyAccountView from '@/views/MyAccountView/MyAccountView.vue'

import i18n from '@/locales/i18n'
import { MY_ACCOUNT_ROUTE } from '@/router/routes'
import { ref } from 'vue'

export const MY_ACCOUNT_ROUTE_NAME = 'myAccount'

const routes: RouteType[] = [
    {
        path: MY_ACCOUNT_ROUTE,
        name: MY_ACCOUNT_ROUTE_NAME,
        component: MyAccountView,
        meta: {
            title: ref(i18n.global.t('menu.myAccount'))
        }
    }
]

export default routes
