import i18n from '@/locales/i18n'
import {
    ETechnicianDevicesPerm,
    ETechnicianDocumentationsPerm,
    ETechnicianQuestionsPerm,
    ETechnicianTicketsPerm,
    ETechnicianTopicsPerm
} from '@/types/permissions/TechnicianPermissionsEnum'
import type { RouteType } from '@/types/Route'
import { getValuesFromEnum } from '@/utils/enumUtils'
import TechnicianDocumentationDetailView from '@/views/TechnicianView/Documentation/TechnicianDocumentationDetailView.vue'
import TechnicianDocumentationTableView from '@/views/TechnicianView/Documentation/TechnicianDocumentationTableView.vue'
import TechnicianInventoryView from '@/views/TechnicianView/Inventory/TechnicianInventoryView.vue'
import TechnicianQuestionsView from '@/views/TechnicianView/Questions/TechnicianQuestionsView.vue'
import TechnicianHomeView from '@/views/TechnicianView/TechnicianHomeView.vue'
import TechnicianTicketsCreateView from '@/views/TechnicianView/Tickets/TechnicianTicketsCreateView.vue'
import TechnicianTicketsEditView from '@/views/TechnicianView/Tickets/TechnicianTicketsEditView.vue'
import TechnicianTicketsTableView from '@/views/TechnicianView/Tickets/TechnicianTicketsTableView.vue'
import { ref } from 'vue'
import {
    TECHNICIAN_DOCUMENTATIONS_ROUTES,
    TECHNICIAN_INVENTORY_ROUTES,
    TECHNICIAN_QUESTIONS_ROUTES,
    TECHNICIAN_ROUTE,
    TECHNICIAN_TICKETS_ROUTES,
    TECHNICIAN_TOPICS_ROUTES
} from './technicianRoutes'
import {
    ETechnicianDocumentationsRoutes,
    ETechnicianInventoryRoutes,
    ETechnicianQuestionsRoutes,
    ETechnicianRoutes,
    ETechnicianTicketsRoutes,
    ETechnicianTopicsRoutes
} from './TechnicianRoutesEnum'

export const allTechniciansPermissions = getValuesFromEnum({
    ...ETechnicianDevicesPerm,
    ...ETechnicianDocumentationsPerm,
    ...ETechnicianQuestionsPerm,
    ...ETechnicianTicketsPerm,
    ...ETechnicianTopicsPerm
})

const technicianRouting: RouteType[] = [
    {
        path: TECHNICIAN_ROUTE,
        name: ETechnicianRoutes.TECHNICIAN_HOME,
        component: TechnicianHomeView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permissions: allTechniciansPermissions
        }
    },
    {
        path: TECHNICIAN_INVENTORY_ROUTES.baseUrl,
        name: ETechnicianInventoryRoutes.TECHNICIAN_INVENTORY_TABLE,
        component: TechnicianInventoryView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianDevicesPerm.TECHNICIAN_DEVICES_VIEW
        }
    },
    {
        path: TECHNICIAN_QUESTIONS_ROUTES.baseUrl,
        name: ETechnicianQuestionsRoutes.TECHNICIAN_QUESTIONS_TABLE,
        component: TechnicianQuestionsView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_VIEW
        }
    },
    {
        path: TECHNICIAN_TICKETS_ROUTES.baseUrl,
        name: ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_TABLE,
        component: TechnicianTicketsTableView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianTicketsPerm.TECHNICIAN_TICKETS_VIEW
        }
    },
    {
        path: TECHNICIAN_TICKETS_ROUTES.createUrl,
        name: ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_CREATE,
        component: TechnicianTicketsCreateView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianTicketsPerm.TECHNICIAN_TICKETS_EDIT
        }
    },
    {
        path: TECHNICIAN_TICKETS_ROUTES.detailUrl,
        name: ETechnicianTicketsRoutes.TECHNICIAN_TICKETS_DETAIL,
        component: TechnicianTicketsEditView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianTicketsPerm.TECHNICIAN_TICKETS_EDIT
        }
    },
    {
        path: TECHNICIAN_DOCUMENTATIONS_ROUTES.baseUrl,
        name: ETechnicianDocumentationsRoutes.TECHNICIAN_DOCUMENTATIONS_TABLE,
        component: TechnicianDocumentationTableView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianDocumentationsPerm.TECHNICIAN_DOCUMENTATIONS_VIEW
        }
    },
    {
        path: TECHNICIAN_DOCUMENTATIONS_ROUTES.detailUrl,
        name: ETechnicianDocumentationsRoutes.TECHNICIAN_DOCUMENTATIONS_DETAIL,
        component: TechnicianDocumentationDetailView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianDocumentationsPerm.TECHNICIAN_DOCUMENTATIONS_VIEW
        }
    },

    {
        path: TECHNICIAN_TOPICS_ROUTES.baseUrl,
        name: ETechnicianTopicsRoutes.TECHNICIAN_TOPICS_TABLE,
        component: TechnicianTicketsTableView,
        meta: {
            title: ref(i18n.global.t('menu.technician.title')),
            permission: ETechnicianTopicsPerm.TECHNICIAN_TOPICS_VIEW
        }
    }
]

export default technicianRouting
