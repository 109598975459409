<script setup lang="ts">
import StepItem from '@/components/Stepper/StepItem.vue'
import StepperLayout from '@/components/Stepper/StepperLayout.vue'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'
import TicketCategoryTable from './Create/TicketCategoryTable.vue'
import TicketDescriptionCreate from './Create/TicketDescriptionCreate.vue'
import TicketDeviceTable from './Create/TicketDeviceTable.vue'
import TicketTopicTable from './Create/TicketTopicTable.vue'

type Props = {
    canCreateTopic?: boolean
    editButtonHref: string
    createButtonHref: string
}
defineProps<Props>()

const showIsMobile = ref(isMobile())
isMobileEvent(e => (showIsMobile.value = e.matches))
const isFullWidth = computed(() => (showIsMobile.value ? '100%' : 'min-content'))
</script>

<template>
    <StepperLayout position="is-left" size="medium" mobile-mode="compact" class="step-item-custom">
        <StepItem
            class="steps is-animated is-rounded mobile-compact overflow-y-auto justify-center"
            :label="$t('ticket.stepper.topic')">
            <TicketTopicTable
                :canCreateTopic="canCreateTopic"
                :editButtonHref="editButtonHref"
                :createButtonHref="createButtonHref" />
        </StepItem>
        <StepItem
            class="steps is-animated is-rounded mobile-compact"
            :label="$t('ticket.stepper.category')">
            <TicketCategoryTable />
        </StepItem>
        <StepItem
            class="steps is-animated is-rounded mobile-compact"
            :label="$t('ticket.stepper.device')">
            <TicketDeviceTable />
        </StepItem>
        <StepItem
            class="steps is-animated is-rounded mobile-compact overflow-y-auto justify-center"
            :label="$t('ticket.stepper.description')">
            <TicketDescriptionCreate :topicId="1" />
        </StepItem>
    </StepperLayout>
</template>

<style scoped lang="scss">
.b-steps {
    background-color: var(--stepper-detail-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
