<script setup lang="ts">
import store from '@/store'
import { ENotificationActions } from '@/store/notificationStore/NotificationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { NotificationProps } from '@/types/components/NotificationComponent'
import NotificationItem from './NotificationItem.vue'

type Props = {
    notification: NotificationProps
}
defineProps<Props>()
const hideItem = () => store.dispatch(`${EStoreModules.NOTIFICATION}/${ENotificationActions.CLEAR}`)
</script>

<template>
    <NotificationItem
        :message="notification.message"
        :type="notification.type"
        class="alert-type center-notification"
        @close="hideItem" />
</template>

<style scoped lang="scss">
.notification-component {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media print, screen and (min-width: 769px) {
        min-width: 350px;
        max-width: 600px;
    }
}
</style>
