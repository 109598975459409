import questionService from '@/services/question.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { QuestionModel } from '@/types/models/QuestionModel'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EQuestionActions, EQuestionMutations, type QuestionState } from './QuestionStoreTypes'

const state: Ref<PaginationDataType<QuestionState>> = ref({
    data: {
        questions: []
    },
    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    isLoading: false
})

const getters = {
    getQuestions: () => state.value.data.questions,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EQuestionActions.FETCH_QUESTION]({ commit }: { commit: Commit }, questionId: string) {
        const question = await questionService.getQuestion(questionId)
        // Faites quelque chose avec les données de l'utilisateur
        commit(EQuestionMutations.SET_QUESTION, question)
        setDocumentAndTitle(question.data.name)
        return question
    },

    async [EQuestionActions.FETCH_QUESTIONS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EQuestionMutations.SET_LOADING, true)
        try {
            questionService.getQuestions(params).then(data => {
                commit(EQuestionMutations.SET_QUESTIONS, data.data)
                commit(EQuestionMutations.SET_TOTAL, data)
            })
        } finally {
            commit(EQuestionMutations.SET_LOADING, false)
        }
    },

    async [EQuestionActions.CREATE_QUESTION](
        { dispatch }: { dispatch: Dispatch },
        questionData: any
    ) {
        return await questionService.createQuestion(questionData)
    },

    async [EQuestionActions.UPDATE_QUESTION](
        { dispatch }: { commit: Commit; dispatch: Dispatch },
        { questionId, questionData }: { questionId: string; questionData: any }
    ) {
        return await questionService.updateQuestion(questionId, questionData)
    },

    async [EQuestionActions.DELETE_QUESTION](
        { dispatch }: { dispatch: Dispatch },
        questionId: number
    ) {
        return await questionService.deleteQuestion(questionId)
    },

    [EHandler.HANDLE_ERROR]({ commit }: { commit: Commit }, error: Error) {
        console.error(error)
        // Handle error globally or show alerts
        // You might want to commit a mutation to show an error message
    }
}

const mutations = {
    [EQuestionMutations.SET_QUESTIONS](_: QuestionState, questions: QuestionModel[]) {
        state.value.data.questions = questions
    },

    [EQuestionMutations.SET_QUESTION](_: QuestionState, question: QuestionModel) {
        state.value.data.question = question
    },

    [EQuestionMutations.SET_TOTAL](_: QuestionState, data: PaginationDataType<QuestionModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },

    [EQuestionMutations.SET_LOADING](_: QuestionState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
