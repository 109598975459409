import type { TopicModel } from '../models/TopicModel'

// export type TopicFilters = {
//     input: any
//     name: any
//     trans: any
//     filters: Partial<TopicModel>
// }

export type TopicFilters = Partial<TopicModel>

export const defaultTopicFilters: TopicFilters = {
    name: undefined,
    trans: undefined,
    archived: undefined
}

export type AdvancedTopicFilters = {
    archived: boolean
    filters: {
        input: undefined
        name: undefined
        trans: undefined
    }
}

export const defaultAdvancedTopicFilters = {
    archived: false,
    filters: {
        input: undefined,
        name: undefined,
        trans: undefined
    }
}
