<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { LinkDevice } from '@/types/components/LinkActions/LinkDevice'
import DevicesOrganizationsLinkComponent from './DeviceOrganizationsLinkComponent.vue'

type Props = {
    organizationId?: number
    deviceId?: number
}
defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const attachDevice = (value: Partial<LinkDevice>) =>
    store
        .dispatch(
            `${EStoreModules.ORGANIZATION}/${EOrganizationActions.ATTACH_DEVICE_TO_ORGANIZATION}`,
            {
                organizationId: value.organizationId,
                deviceId: value.deviceId
            }
        )
        .then(() => emit('refresh'))
</script>

<template>
    <FullScreenLoader :isLoading="store.state.organization.isLoading" />
    <DevicesOrganizationsLinkComponent
        :organizationId="organizationId"
        :deviceId="deviceId"
        :title="$t('organization.title.associateDeviceTitle')"
        :confirmLabel="$t('organization.buttons.associateDevice')"
        @confirm="attachDevice" />
</template>
