<script setup lang="ts">
import QRCodeScanner from '@/components/QRCode/QRCodeScanner.vue'
import store from '@/store'
import { EAlertActions } from '@/store/alertStore/AlertStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EQRCode, EQRCodeLabels, EQRCodeValues } from '@/types/enum/QRCodeEnum'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const firstDetectedCode = ref()
const rawValue = ref()

const showFullScreen = ref(isMobile())
isMobileEvent(e => (showFullScreen.value = e.matches))

const onDetect = (firstDetectedCodeValue: DetectedBarcode) => {
    if (firstDetectedCodeValue) {
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.SUCCESS,
            message: t('enum.qrcode.success.detectionSuccess')
        })
    } else
        store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
            action: EAlertActions.ERROR,
            message: t('enum.qrcode.error.detectionError')
        })
    firstDetectedCode.value = firstDetectedCodeValue
    rawValue.value = firstDetectedCodeValue?.rawValue
}
const onError = (error: Error) =>
    store.dispatch(`${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`, {
        alert: EAlertActions.ERROR,
        message: EQRCodeValues.includes(error.name)
            ? EQRCodeLabels[error.name as EQRCode]
            : error.name
    })
</script>

<template>
    <QRCodeScanner :isMobile="showFullScreen" @detect="onDetect" @error="onError" />
</template>

<style scoped lang="scss">
.detected {
    color: red;
}
.raw {
    color: blueviolet;
}
</style>
