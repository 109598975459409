export type RoleModel = {
    guard_name: string
    id: number
    name: string
    permissions: string[]
}

export const defaultRoleModel: RoleModel = {
    guard_name: '',
    id: 0,
    name: '',
    permissions: []
}
