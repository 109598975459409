<script setup lang="ts">
import defaultTheme from '@/scss/theme-default.module.scss'
import QRCodeStyling, {
    type CornerDotType,
    type CornerSquareType,
    type DotType,
    type DrawType,
    type ErrorCorrectionLevel,
    type Mode,
    type TypeNumber
} from 'qr-code-styling'
import { onMounted, ref } from 'vue'

type Props = {
    value: string
}
const props = defineProps<Props>()

const logo = new URL('@/assets/logo-carre.png', import.meta.url).href
const { primary } = defaultTheme

const options = {
    width: 300,
    height: 300,
    type: 'canvas' as DrawType,
    data: props.value,
    image: logo,
    margin: 10,
    qrOptions: {
        typeNumber: 0 as TypeNumber,
        mode: 'Byte' as Mode,
        errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
    },
    imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        crossOrigin: 'anonymous'
    },
    squaresOptions: {
        color: primary,
        type: 'rounded' as DotType
    },
    backgroundOptions: {
        color: '#fff'
    },
    cornersSquareOptions: {
        color: undefined,
        type: 'square' as CornerSquareType
    },
    cornersDotOptions: {
        color: undefined,
        type: 'square' as CornerDotType
    }
}

const qrCode = ref(new QRCodeStyling(options))
const qrCodeRef = ref()

onMounted(() => qrCode.value.append(qrCodeRef.value))
</script>

<template>
    <div ref="qrCodeRef" class="qrCode"></div>
</template>

<style scoped lang="scss">
.qrCode {
    width: 100%;
    & :deep(canvas) {
        width: 100%;
    }
}
</style>
