<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { mockupTechDevicesCards, mockupTechLatestUpdates } from '@/mockupData/TechData'
import { ref } from 'vue'
import TechnicianDocumentationDevices from './Table/TechnicianDocumentationDevices.vue'
import TechnicianDocumentationLatestNews from './Table/TechnicianDocumentationLatestNews.vue'

const latestNews = ref(mockupTechLatestUpdates)
const devicesDocs = ref(mockupTechDevicesCards)
</script>

<template>
    <StackLayout direction="column" :gap="8" v-bind="$attrs">
        <TechnicianDocumentationLatestNews :latestNews="latestNews" />
        <TechnicianDocumentationDevices :devices="devicesDocs" />
    </StackLayout>
</template>

<style scoped lang="scss">
.latest-news {
    color: var(--color-text);
}
</style>
