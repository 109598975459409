<script setup lang="ts">
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import TagStack from '../Tags/TagStack.vue'

type Props = {
    data: FilterTagModel[]
    translationPath: string
}

const props = defineProps<Props>()
type Emits = {
    (e: 'close', tag: FilterTagModel): void
}
defineEmits<Emits>()

const { t } = useI18n()
const formattedData = computed<FilterTagModel[]>(() =>
    props.data.map(item => ({
        ...item,
        label:
            typeof item.value === 'boolean'
                ? t(`${props.translationPath}.${item.label ?? item.name ?? ''}`)
                : `${t(`${props.translationPath}.${item.name ?? ''}`)} : ${
                      item.label ?? item.value
                  }`
    }))
)
</script>

<template><TagStack :data="formattedData" @close="$emit('close', $event)" /></template>
