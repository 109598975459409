export const partnerFr = {
    api: {
        error: {
            getPartners: 'Erreur lors de la récupération des partenaires',
            getPartner: 'Erreur lors de la récupération du partenaire',
            addPartner: "Erreur lors de l'ajout du partenaire",
            updatePartner: 'Erreur lors de la modification du partenaire',
            deletePartner: 'Erreur lors de la suppression du partenaire'
        },
        success: {
            getPartners: 'Récupération des partenaires effectuée avec succès !',
            getPartner: 'Récupération du partenaire effectuée avec succès !',
            addPartner: 'Ajout du partenaire effectuée avec succès !',
            updatePartner: 'Modification du partenaire effectuée avec succès !',
            deletePartner: 'Suppression du partenaire effectuée avec succès !'
        }
    },
    config: {
        event: "ID de l'évènement",
        checkpoint: 'ID du checkpoint',
        url: 'URL',
        api: 'API'
    },
    line: 'ligne',
    lines: 'lignes',
    total: 'Total'
}
