<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import { useRecentlyVisited } from '@/hooks/useRecentlyVisited'
import IconItem from '../Icon/IconItem.vue'
import { TypographyItem } from '../Typography'
import MenuItem from './MenuItem.vue'

const recentlyVisited = useRecentlyVisited()
</script>

<template>
    <MenuItem v-if="recentlyVisited?.to" :item="recentlyVisited" class="recently-visited">
        <template #label>
            <StackLayout direction="row" :gap="12" class="align-items-center full-width">
                <IconItem icon="clock-rotate-left" />
                <StackLayout direction="column" :gap="4">
                    <TypographyItem :label="`${$t('menu.recentlyVisited')} :`" />
                    <TypographyItem :label="recentlyVisited.label" />
                </StackLayout>
            </StackLayout>
        </template>
    </MenuItem>
</template>

<style scoped lang="scss">
.recently-visited {
    border-bottom: 1px solid $primary;

    & :deep(a.is-active) {
        background-color: transparent;
    }

    & :deep(.icon svg) {
        height: 24px;
    }
}
</style>
