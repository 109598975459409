import i18n from '@/locales/i18n'
import eventService from '@/services/event.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { EventCreateModel } from '@/types/models/EventCreateModel'
import type { EventModel } from '@/types/models/EventModel'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { EEventActions, EEventMutations, type EventState } from './EventStoreTypes'

const state: Ref<PaginationDataType<EventState>> = ref({
    data: {
        events: []
    },

    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    isLoading: false
})

const getters = {
    getEvents: () => state.value.data.events,
    isLoading: () => state.value.isLoading
}

const actions = {
    async [EEventActions.FETCH_EVENT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        eventId: number
    ) {
        commit(EEventMutations.SET_LOADING, true)
        return await eventService
            .getEvent(eventId)
            .then(res => {
                commit(EEventMutations.SET_EVENT, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(EEventMutations.SET_LOADING, false))
    },

    async [EEventActions.FETCH_EVENTS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EEventMutations.SET_LOADING, true)
        return await eventService
            .getEvents(params)
            .then(data => {
                commit(EEventMutations.SET_EVENTS, data.data)
                commit(EEventMutations.SET_TOTAL, data)
            })
            .finally(() => {
                commit(EEventMutations.SET_LOADING, false)
            })
    },

    async [EEventActions.CREATE_EVENT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: EventCreateModel
    ) {
        commit(EEventMutations.SET_LOADING, true)
        return eventService
            .createEvent(data)
            .then(res => {
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EEventActions.CREATE_EVENT
                })
                return res.data
            })
            .finally(() => commit(EEventMutations.SET_LOADING, false))
    },

    async [EEventActions.UPDATE_EVENT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { eventId, data }: { eventId: number; data: EventModel }
    ) {
        commit(EEventMutations.SET_LOADING, true)
        return eventService
            .updateEvent(eventId, data)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name: data.name,
                    key: EEventActions.UPDATE_EVENT
                })
            )
            .finally(() => {
                commit(EEventMutations.SET_LOADING, false)
            })
    },

    async [EEventActions.DELETE_EVENT](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        { eventId, name }: { eventId: number; name: string }
    ) {
        commit(EEventMutations.SET_LOADING, true)
        return eventService
            .deleteEvent(eventId)
            .then(() =>
                dispatch(EHandler.HANDLE_SUCCESS, {
                    name,
                    key: EEventActions.DELETE_EVENT
                })
            )
            .finally(() => commit(EEventMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`event.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`event.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EEventMutations.SET_EVENTS](_: EventState, events: EventModel[]) {
        state.value.data.events = events
    },
    [EEventMutations.SET_EVENT](_: EventState, event: EventModel) {
        state.value.data.event = event
    },
    [EEventMutations.SET_TOTAL](_: EventState, data: PaginationDataType<EventModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
    },
    [EEventMutations.SET_LOADING](_: EventState, isLoading: boolean) {
        state.value.isLoading = isLoading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
