<script setup lang="ts">
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import topicApi from '@/services/topic.service'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ETopicActions } from '@/store/topicStore/TopicStoreTypes'
import { type TopicFilters } from '@/types/filters/TopicFilters'
import type { TopicModel } from '@/types/models/TopicModel'
import { ETopicsPerm } from '@/types/permissions/TopicsPermissionsEnum'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import TicketCreateTopicDialog from './TopicTable/TicketCreateTopicDialog.vue'
import TicketTopicFilters from './TopicTable/TicketTopicFilters.vue'
import TicketUpdateTopicDialog from './TopicTable/TicketUpdateTopicDialog.vue'

type Props = {
    canCreateTopic?: boolean
    editButtonHref: string
}

defineProps<Props>()
const table = ref()
const { t } = useI18n()
const columns = {
    id: { field: 'id', label: t('organization.organizations.table.id') },
    name: { field: 'name', label: t('organization.organizations.table.name') },
    parent_id: { field: 'parent_id', label: t('organization.organizations.table.email') },
    trans: { field: 'trans', label: t('organization.organizations.table.roles') },
    parent_name: { field: 'parent_name', label: t('technician.ticket.ticket.parent_name') }
}
const data = computed(() => store.state.topic)

const filters = ref<TopicFilters>({})
const handleFilters = (value: Partial<TopicFilters>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getTopics()
}

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getTopics()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getTopics()
}

const getTopics = () => {
    store.dispatch(`${EStoreModules.TOPIC}/${ETopicActions.FETCH_TOPICS}`, {
        ...filters.value,
        per_page: data.value.per_page,
        page: data.value.current_page
    })
}
const deleteTopic = (topic: TopicModel) => {
    if (!topic.id) return
    topicApi.deleteTopic(topic.id).then(() => {
        getTopics()
    })
}

const selectedTopic = ref<TopicModel>()
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <FullTableLayout>
            <template #export>
                <TicketCreateTopicDialog v-if="canCreateTopic" @refresh="getTopics()" />
            </template>
            <template #filters>
                <TicketTopicFilters :filters="filters" @change="handleFilters" />
            </template>
            <template #table>
                <BaseTable v-bind:ref="table" :column="columns" :data="data.data.topics">
                    <template #columns>
                        <TableColumn :label="columns['trans'].label" field="trans" />
                        <TableColumn field="parent_name" :label="columns['parent_name'].label" />
                        <TableActionColumn
                            :deletePermission="ETopicsPerm.TOPICS_DELETE"
                            :editPermission="ETopicsPerm.TOPICS_EDIT"
                            @goTo="selectedTopic = $event"
                            @delete="deleteTopic"
                            :editButtonHref="editButtonHref" />
                    </template>
                </BaseTable>
            </template>
            <template #pagination>
                <TablePagination
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </template>
        </FullTableLayout>
        <TicketUpdateTopicDialog
            v-if="selectedTopic"
            :selectedTopic="selectedTopic"
            @refresh="getTopics()"
            @close="selectedTopic = undefined" />
    </StackLayout>
</template>
