<script setup lang="ts">
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'

const showIsMobile = ref(isMobile())
isMobileEvent(e => (showIsMobile.value = e.matches))
</script>

<template>
    <b-steps
        position="is-left"
        label-position="bottom"
        mobile-mode="compact"
        size="medium"
        :vertical="!showIsMobile"
        v-bind="$attrs">
        <slot></slot>
    </b-steps>
</template>
<style scoped></style>
