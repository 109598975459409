<script setup lang="ts">
import { FullScreenLoader } from '@/components/LoadingSpinner'
import CreateUserView from '@/components/User/Detail/CreateUserView.vue'
import { ADMIN_USERS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { defaultUserModel, type UserModel } from '@/types/models/UserModel'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import AdminCreateUserOrganization from './components/AdminCreateUserOrganization.vue'

const router = useRouter()

const submitted = ref(false)
const isLoading = computed(() => store.state.user.isLoading)
const userValue = ref<Partial<UserModel>>({ ...defaultUserModel })

const updateUserValue = (value: Partial<UserModel>) =>
    (userValue.value = { ...userValue.value, ...value })

const createUser = () => {
    submitted.value = true
    if (!userValue.value.email || !userValue.value.name) return
    if (selectedOrganization.value)
        return store
            .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.CREATE_USER}`, {
                userData: userValue.value,
                organizationId: selectedOrganization.value?.id
            })
            .then(res => {
                submitted.value = false
                router.replace(ADMIN_USERS_ROUTES.buildUrl(res.id))
            })
    if (organizationName.value)
        return store
            .dispatch(
                `${EStoreModules.ORGANIZATION}/${EOrganizationActions.INIT_ORGANIZATION_WITH_USER}`,
                {
                    user: userValue.value,
                    organization: {
                        name: organizationName.value
                    }
                }
            )
            .then(res => {
                submitted.value = false
                router.replace(ADMIN_USERS_ROUTES.buildUrl(res.user_id))
            })
}

const selectedOrganization = ref<OrganizationModel>()
const organizationName = ref<string>()

const handleChange = (value: string) => {
    if (value) selectedOrganization.value = undefined
    organizationName.value = value
}
const handleSelect = (value: OrganizationModel) => {
    if (value) organizationName.value = undefined
    selectedOrganization.value = value
}
</script>

<template>
    <FullScreenLoader :isLoading="!!isLoading" />
    <CreateUserView
        :data="userValue"
        :submitted="submitted"
        @click="createUser"
        @update="updateUserValue">
        <AdminCreateUserOrganization
            :organizationId="selectedOrganization?.id"
            :organizationName="organizationName"
            @change="handleChange"
            @select="handleSelect" />
    </CreateUserView>
</template>
