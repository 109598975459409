<script setup lang="ts">
import { MultilineField } from '@/components/Fields'
import { ref } from 'vue'

const messageContent = ref<string>('')

const emit = defineEmits<{
    (e: 'change', value: string): void
}>()

const redirectToPdf = () => emit('change', messageContent.value)
</script>

<template>
    <MultilineField
        :modelValue="messageContent"
        class="ticket-reply full-width"
        :placeholder="$t('technician.ticket.message.placeholderMessage')"
        @change="redirectToPdf"></MultilineField>
</template>

<style scoped lang="scss">
.ticket-reply {
    & :deep(textarea) {
        background-color: var(--color-background);
    }
}
</style>
