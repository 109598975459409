<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import InputField from '@/components/Fields/InputField.vue'
import NumberInputField from '@/components/Fields/NumberInputField.vue'
import GridLayout from '@/components/Layouts/GridLayout.vue'
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import { isLastArrayElementNullOrUndefined } from '@/utils/dataUtils'
import { dataIsNumberPrimitive, isNullOrUndefined } from '@/utils/validateUtils'
import { ref } from 'vue'

type Props = {
    type?: EPrimitive
    value?: (string | number | undefined)[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: (string | number | undefined)[]): void
}
const emit = defineEmits<Emits>()

const isSubmitted = ref(false)

const handleUpdate = (value: string | number, index: number) => {
    const tmp = [...(props.value ?? [])]
    tmp[index] = value
    emit('change', tmp)
}
const handleClick = () => {
    isSubmitted.value = true
    if (isLastArrayElementNullOrUndefined(props.value)) return
    isSubmitted.value = false
    emit('change', [...(props.value?.filter(item => !isNullOrUndefined(item)) ?? []), undefined])
}
</script>
<template>
    <GridLayout :maxItemPerRow="2" :gap="8">
        <template v-if="type && dataIsNumberPrimitive(type)">
            <NumberInputField
                v-for="(item, key) in value"
                :key="key"
                :modelValue="item as number"
                :hasError="isSubmitted && isNullOrUndefined(item)"
                @change="handleUpdate($event, key)" />
        </template>
        <template v-else>
            <InputField
                v-for="(item, key) in value"
                :key="key"
                :modelValue="item as string"
                :hasError="isSubmitted && isNullOrUndefined(item)"
                @change="handleUpdate($event, key)" />
        </template>
        <SecondaryButton
            :label="$t('deviceConfig.templateKeys.buttons.addNewExcluded')"
            @click="handleClick" />
    </GridLayout>
</template>
