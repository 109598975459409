import type { MenuLocales } from '@/types/lang/locales'

export const menuEn: MenuLocales = {
    administration: {
        api: 'API',
        organizations: 'Organizations',
        devices: 'Devices',
        deviceConfiguration: 'Device settings',
        roles: 'Roles & permissions',
        title: 'Administration',
        users: 'Users',
        devicesConfig: 'Configurations',
        devicesTypes: 'Device types',
        devicesTemplate: 'Templates',
        devicesTemplateKeys: 'Template keys'
    },
    organization: 'My organization',
    checkpoints: 'Checkpoints',
    deimpersonate: 'Deimpersonate',
    detections: 'Reads',
    devices: 'Devices',
    events: 'Events',
    home: 'Home',
    logout: 'Logout',
    myAccount: 'My account',
    recentlyVisited: 'Last event visited',
    technician: {
        documentations: 'Documentations',
        inventory: 'Inventory',
        questions: 'Questions',
        tickets: 'Tickets',
        title: 'Technician',
        topics: 'Topics'
    },
    tickets: 'Tickets',
    type: {
        create: 'Create',
        detail: 'Detail',
        generate: 'Generation',
        list: 'List'
    },
    supportFaq: 'Support & questions'
}
