<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import { TitleTypography, TypographyItem } from '@/components/Typography'
import { EColors, type Colors } from '@/types/constants/ColorValues'
import { EPosition } from '@/types/constants/PositionValues'
import { ESize } from '@/types/constants/SizeValues'
import {
    EBatteryLevelIcons,
    EBatteryLevelPluggedIcons,
    EBatteryLevelValues
} from '@/types/enum/BatteryLevelEnum'
import { EDeviceStatus, EDeviceStatusColors } from '@/types/enum/DeviceStatusEnum'
import { ESignalLevelIcons } from '@/types/enum/SignalLevelEnum'
import type { DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { computed } from 'vue'
import TooltipItem from '../../Tooltip/TooltipItem.vue'

type Props = {
    title: string
    subtitle?: string
    data: DeviceModel
    status?: AlertSocketMessageModel
}

const props = defineProps<Props>()

const closestBatteryLevel = computed(() =>
    EBatteryLevelValues.find(item => item >= (props.status?.battery ?? props.data?.battery ?? 0))
)

const isOffline = computed(
    () => (props.status?.status ?? parseInt(props.data.status as string)) === EDeviceStatus.OFFLINE
)

const currentBatteryLevel = computed(() =>
    isOffline.value
        ? EBatteryLevelIcons[closestBatteryLevel.value!]
        : !isNullOrUndefined(props.status?.battery ?? props.data?.battery) &&
          !isNullOrUndefined(closestBatteryLevel.value)
        ? props.status?.plugged
            ? EBatteryLevelPluggedIcons[closestBatteryLevel.value!]
            : EBatteryLevelIcons[closestBatteryLevel.value!]
        : props.status?.plugged
        ? {
              label: 'plug-circle-check',
              type: EColors.SUCCESS
          }
        : EBatteryLevelIcons[closestBatteryLevel.value!]
)

const hasSocketStatus = computed(
    () => !!props.status?.battery || !!props.status?.plugged || !!props.status?.signal
)
</script>

<template>
    <StackLayout class="full-width space-between">
        <TitleTypography :title="title" :subtitle="subtitle" class="device-header" />
        <StackLayout :gap="8" class="align-items-center" v-if="hasSocketStatus && !isOffline">
            <StackLayout direction="column" class="align-items-center">
                <IconItem v-if="status?.signal" :icon="ESignalLevelIcons[status.signal]" />
                <TypographyItem v-if="status?.network_type" :label="status?.network_type" />
            </StackLayout>
            <StackLayout direction="column" class="align-items-center">
                <IconItem
                    :icon="currentBatteryLevel"
                    :type="currentBatteryLevel?.type as Colors"
                    class="battery-level" />
                <TypographyItem
                    v-if="!isNullOrUndefined(status?.battery)"
                    :label="`${status?.battery}%`"
                    :class="`has-text-${currentBatteryLevel?.type}`" />
            </StackLayout>
        </StackLayout>
        <span v-else-if="isOffline">
            <StackLayout :gap="8" class="align-items-center">
                <TooltipItem
                    :label="
                        closestBatteryLevel
                            ? $t('device.status.lastBatteryLevel', {
                                  battery: status?.battery ?? data?.battery
                              })
                            : $t('device.status.lastBatteryLevelUnknown')
                    "
                    :position="EPosition.BOTTOM"
                    :size="ESize.SMALL"
                    multilined>
                    <StackLayout direction="column" class="align-items-center">
                        <IconItem
                            :icon="{ ...currentBatteryLevel, type: EColors.SECONDARY }"
                            class="battery-level" />
                        <TypographyItem
                            v-if="!isNullOrUndefined(status?.battery)"
                            :label="`${status?.battery}%`"
                            :class="`has-text-secondary`" />
                    </StackLayout>
                </TooltipItem>
                <span class="fa-stack">
                    <IconItem
                        icon="power-off"
                        class="fa-stack-2x"
                        :type="EDeviceStatusColors[EDeviceStatus.OFFLINE]" />
                    <IconItem
                        icon="slash"
                        class="fa-stack-1x fa-flip-vertical"
                        :type="EDeviceStatusColors[EDeviceStatus.OFFLINE]" />
                </span>
            </StackLayout>
        </span>
        <StackLayout v-else direction="column" class="align-items-center">
            <IconItem
                :icon="currentBatteryLevel"
                :type="currentBatteryLevel?.type as Colors"
                class="battery-level" />
            <TypographyItem
                v-if="!isNullOrUndefined(closestBatteryLevel)"
                :label="`${closestBatteryLevel}%`"
                :class="`has-text-${currentBatteryLevel?.type}`" />
            <IconItem
                icon="power-off"
                :type="
                    EDeviceStatusColors[(data?.status as EDeviceStatus) ?? EDeviceStatus.OFFLINE]
                " />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.battery-level {
    &:deep(svg) {
        height: auto;
    }
}

.battery-tooltip {
    & :deep(.tooltip-content) {
        position: fixed;
        transform: translate(-43%, -35px);
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
    }
}
</style>
