import type { EAlertActions } from '@/store/alertStore/AlertStoreTypes'
import type { Colors } from '@/types/constants/ColorValues'
import type {
    BNoticeConfig,
    BNotificationConfig,
    BSnackbarConfig
} from '@ntohq/buefy-next/types/components'

export enum EAlertComponent {
    SNACKBAR = 'snackbar',
    NOTIFICATION = 'notification',
    TOAST = 'toast'
}

export type AlertProps = Omit<
    BNoticeConfig | BNotificationConfig | BSnackbarConfig,
    'message' | 'type' | 'queue'
> & {
    action: EAlertActions | null
    type: Colors | null
    message: string | null
    component: EAlertComponent | null
    queue: AlertProps[]
}
