<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { EColors } from '@/types/constants/ColorValues'
import { IconButton } from '.'

type Props = {
    items: {
        label?: string
        icon?: string
    }[]
    value: string | number
}

const props = defineProps<Props>()
defineEmits<{
    (e: 'click', value: string | number): void
}>()

const isSelected = (key: string | number) => props.value === key
</script>

<template>
    <StackLayout
        direction="row"
        v-if="!items.find(item => !!item.label)"
        class="border-radius"
        v-bind="$attrs">
        <IconButton
            v-for="(item, key) in items"
            v-bind:key="key"
            :icon="{ label: item.icon, type: isSelected(key) ? EColors.LIGHT : undefined }"
            :type="EColors.SECONDARY"
            @click="$emit('click', key)"
            :class="`background-transparent ${
                isSelected(key) ? 'button-is-selected' : ''
            } button-style `" />
    </StackLayout>
    <StackLayout direction="row" v-else v-bind="$attrs">
        <SecondaryButton
            v-for="(item, key) in items"
            v-bind:key="key"
            :label="item.label"
            :icon="item.icon"
            @click="$emit('click', key)"
            :class="`background-transparent ${
                isSelected(key) ? 'button-is-selected' : ''
            } button-style `" />
    </StackLayout>
</template>

<style scoped lang="scss">
.button-style {
    border: 1px solid $primary;
    background-color: var(--color-background-secondary);
}
.button-is-selected {
    background-color: $primary;
}
.button-style:first-of-type {
    border-radius: 3px 0px 0px 3px;
}
.button-style:not(:first-of-type) {
    border-radius: 0px;
}
.button-style:last-of-type {
    border-radius: 0px 3px 3px 0px;
}

.button-style:hover {
    background-color: rgba($primary, 0.6);
}
</style>
