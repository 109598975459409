import type { DeviceConfigLocales } from '@/types/lang/locales'

export const deviceConfigEn: DeviceConfigLocales = {
    api: {
        error: {
            createDeviceConfig: 'An error occured while creating the device configuration {name}',
            deleteDeviceConfig: 'An error occured while deleting the device configuration {name}',
            fetchDeviceConfig: 'An error occured while fetching the device configuration',
            fetchDeviceConfigs: 'An error occured while fetching device configurations',
            updateDeviceConfig: 'An error occured while updating the device configuration {name}',

            createDeviceTemplateKey: 'An error occured while creating the template key {name}',
            deleteDeviceTemplateKey: 'An error occured while deleting the template key {name}',
            fetchDeviceTemplateKey: 'An error occured while fetching the template key',
            fetchDeviceTemplateKeys: 'An error occured while fetching the template keys',
            updateDeviceTemplateKey: 'An error occured while updating the template key {name}'
        },
        success: {
            createDeviceConfig: 'Device configuration {name} successfully created !',
            deleteDeviceConfig: 'Device configuration {name} successfully deleted !',
            fetchDeviceConfig: 'Device configuration successfully fetched',
            fetchDeviceConfigs: 'Device configurations successfully fetched',
            updateDeviceConfig: 'Device configuration {name} successfully updated !',

            createDeviceTemplateKey: 'Template key {name} successfully created !',
            deleteDeviceTemplateKey: 'Template key {name} successfully deleted !',
            fetchDeviceTemplateKey: 'Template key {name} successfully fetched !',
            fetchDeviceTemplateKeys: 'Template key {name} successfully fetched !',
            updateDeviceTemplateKey: 'Template key {name} successfully updated !'
        }
    },
    tabs: {
        configurations: 'Configurations',
        templateKeys: 'Configuration keys',
        types: 'Device types'
    },
    types: {
        buttons: {
            createType: 'Create device type',
            createNewType: 'Create this device type',
            updateType: 'Save modifications'
        },
        model: {
            name: 'Name'
        },
        table: {
            name: 'Name'
        },
        title: {
            informations: 'Information'
        }
    },
    template: {
        buttons: {
            createTemplate: 'Create a template',
            updateTemplate: 'Save modifications'
        },
        model: {
            deviceType: "Type d'appareil",
            version: 'N° de version'
        },
        table: {
            deviceTypeName: 'Device type',
            version: 'Version'
        },
        title: {
            informations: 'Information',
            newKey: 'New key',
            templateKeys: 'Keys and settings'
        }
    },
    templateKeys: {
        buttons: {
            addNewExcluded: 'Add a value',
            addNewValue: 'Add a value',
            addNewPair: 'Add a pair',
            createKey: 'Create key',
            createNewKey: 'Create and add this new key',
            updateKey: 'Update key'
        },
        detail: {
            addKey: 'Add an existing key'
        },
        model: {
            category: 'Category',
            default: 'Default value',
            description: 'Description',
            excluded: 'Excluded values',
            isAdmin: 'Only for admins',
            isAdvanced: 'Advanced configuration',
            key: 'Key',
            masked: 'Show/hide field',
            range: 'Range of values',
            readOnly: 'Read only',
            translation: 'Display name',
            type: 'Type',
            unit: 'Unit',
            values: 'Values',
            enumValues: 'Enum values',
            enumLabels: 'Associated labels'
        },
        table: {
            key: 'Key name',
            description: 'Description',
            isAdmin: 'Admin only'
        },
        title: {
            newKey: 'New key',
            editKey: 'Edit key'
        }
    }
}
