<script setup lang="ts">
import type { Direction } from '@/types/constants/Direction'
import {
    isDesktop,
    isDesktopEvent,
    isMobile,
    isMobileEvent,
    isSmallScreen,
    isSmallScreenEvent,
    isTablet,
    isTabletEvent
} from '@/utils/viewsUtils'
import { computed, ref } from 'vue'

type Props = {
    direction?: Direction
    gap?: number
    wrap?: boolean
    isResponsiveTablet?: boolean | Direction
    isResponsive?: boolean | Direction
    isResponsiveSmallScreen?: boolean | Direction
    isResponsiveDesktop?: boolean | Direction
}
const props = defineProps<Props>()

const isResponsiveSmallScreen = ref(props.isResponsiveSmallScreen && isSmallScreen())
isSmallScreenEvent(e =>
    props.isResponsiveSmallScreen ? (isResponsiveSmallScreen.value = e.matches) : undefined
)
const isResponsive = ref(props.isResponsive && isMobile())
isMobileEvent(e => (props.isResponsive ? (isResponsive.value = e.matches) : undefined))
const isResponsiveTablet = ref(props.isResponsiveTablet && isTablet())
isTabletEvent(e => (props.isResponsiveTablet ? (isResponsiveTablet.value = e.matches) : undefined))
const isResponsiveDesktop = ref(props.isResponsiveDesktop && isDesktop())
isDesktopEvent(e =>
    props.isResponsiveDesktop ? (isResponsiveDesktop.value = e.matches) : undefined
)

const gapValue = computed(() => `${props.gap ?? 0}px`)
const directionRef = computed(() =>
    isResponsiveSmallScreen.value ||
    isResponsive.value ||
    isResponsiveTablet.value ||
    isResponsiveDesktop.value
        ? typeof props.isResponsive === 'string' ||
          typeof props.isResponsiveSmallScreen === 'string' ||
          typeof props.isResponsiveTablet === 'string' ||
          typeof props.isResponsiveDesktop === 'string'
            ? ((props.isResponsive ??
                  props.isResponsiveSmallScreen ??
                  props.isResponsiveTablet ??
                  props.isResponsiveDesktop) as Direction)
            : 'column'
        : props.direction ?? 'row'
)

const stackRef = ref<HTMLDivElement | null>(null)

defineExpose({ stackRef })
</script>

<template>
    <div ref="stackRef" :class="`stack ${wrap ? 'flex-wrap' : ''}`" v-bind="$attrs">
        <slot />
    </div>
</template>

<style scoped>
.stack {
    display: flex;
    flex-direction: v-bind(directionRef);
    gap: v-bind(gapValue);
}
</style>
