<script setup lang="ts">
import { PrimaryButton, UploadButton } from '@/components/Buttons'
import RadioButton from '@/components/Buttons/RadioButton.vue'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField, MultilineField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { TypographyWithPrefix } from '@/components/Typography'
import { TICKETS_ROUTES } from '@/router/routes'
import severityApi from '@/services/severity.service'
import ticketApi from '@/services/ticket.service'
import store from '@/store'
import type { SelectorObject } from '@/types/components/SelectorObject'
import type { SeverityInfo, TicketModel } from '@/types/models/TicketModel'
import { defaultUserModel } from '@/types/models/UserModel'
import moment from 'moment'
import { computed, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import TagStack from '../../Tags/TagStack.vue'

type Props = {
    topicId: number
}
const props = defineProps<Props>()

const router = useRouter()
const me = computed(() => ({ ...(store.state.authentication.me ?? defaultUserModel) }))

const dropFiles = ref<File[]>([])

const deleteFile = (index: number) => dropFiles.value.splice(index, 1)

const selectedSeverity = ref<number | undefined>(3)

const ticketData = reactive<Partial<TicketModel>>({
    topic_id: props.topicId,
    severity_id: selectedSeverity.value,
    status_id: 1,
    user_id: me.value.id,
    start_date: moment().toDate(),
    end_date: moment().toDate()
})

const createTicket = async () => {
    submitted.value = true
    if (Object.values(errors.value).filter(item => !!item).length) return
    const formData = new FormData()

    formData.append('data', JSON.stringify(ticketData))
    dropFiles.value.forEach(file => {
        formData.append('documents[]', file)
    })

    ticketApi.createTicket(formData).then(() => {
        dropFiles.value = []
        router.push(TICKETS_ROUTES.baseUrl)
    })
}

const submitted = ref(false)
const errors = computed(() => ({
    object: submitted.value && !ticketData.object,
    description: submitted.value && !ticketData.description
}))

const severityOptions = ref<SelectorObject[]>([])
const fetchSeverities = () =>
    severityApi.getSeverities({ per_page: -1 }).then(res => {
        severityOptions.value = res.map?.((severity: SeverityInfo) => ({
            name: severity.trans,
            value: severity.id
        }))
    })

onMounted(() => fetchSeverities())
</script>

<template>
    <StackLayout is-responsive :gap="16" direction="column" class="padding">
        <StackLayout is-responsive :gap="8">
            <InformationBaseCard :label="$t('ticket.create.selectSeverity')" class="full-width">
                <StackLayout :gap="8" direction="column">
                    <RadioButton
                        :label="$t('ticket.create.selectSeverity')"
                        :modelValue="selectedSeverity"
                        :options="severityOptions"
                        @change="selectedSeverity = $event" />
                </StackLayout>
            </InformationBaseCard>

            <InformationBaseCard :label="$t('ticket.create.selectDevice')" class="full-width">
                <InputField
                    :modelValue="ticketData.serial_number_device"
                    :label="$t('ticket.create.serialNumberDevice')" />
            </InformationBaseCard>
        </StackLayout>
        <InformationBaseCard :label="$t('ticket.create.information')">
            <TypographyWithPrefix :prefix="$t('ticket.create.topic')" :label="topicId" boldLabel />
        </InformationBaseCard>

        <InformationBaseCard :label="$t('ticket.create.message')">
            <StackLayout :gap="16" direction="column" class="space-between flow full-width">
                <InputField
                    :modelValue="ticketData.object"
                    @change="ticketData.object = $event"
                    :label="$t('ticket.table.object')"
                    :hasError="errors.object" />

                <StackLayout :gap="8">
                    <MultilineField
                        :modelValue="ticketData.description"
                        :label="$t('ticket.table.description')"
                        class="ticket-reply full-width"
                        :hasError="errors.description"
                        :placeholder="$t('ticket.create.placeholderMessage')"
                        @change="ticketData.description = $event" />

                    <StackLayout direction="column" :gap="8">
                        <PrimaryButton :label="$t('ticket.detail.send')" @click="createTicket" />
                        <UploadButton
                            :label="$t('ticket.create.addFile')"
                            :modelValue="dropFiles"
                            multiple
                            drag-drop />
                    </StackLayout>
                </StackLayout>

                <StackLayout :gap="8" class="flex-wrap">
                    <TagStack
                        :data="dropFiles.map(item => ({ name: item.name }))"
                        @close="(_, key) => deleteFile(key)" />
                </StackLayout>
            </StackLayout>
        </InformationBaseCard>
    </StackLayout>
</template>
