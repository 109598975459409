<script setup lang="ts">
import deviceConfigApi from '@/services/deviceConfig.service'
import store from '@/store'
import { EDeviceConfigActions } from '@/store/deviceConfigStore/DeviceConfigStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceConfigModel } from '@/types/models/DeviceConfigModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import DeviceTypesView from './components/DeviceTypesView.vue'

const route = useRoute()
const deviceTypeId = ref(route.params.id.toString())
const data = ref<Partial<DeviceConfigModel>>()

const getDeviceTemplate = () =>
    deviceConfigApi.getDeviceType(deviceTypeId.value).then(res => (data.value = res.data))
onMounted(() => {
    if (typeof deviceTypeId.value === 'string') getDeviceTemplate()
})

const updateDevice = (value: Partial<DeviceConfigModel>) =>
    (data.value = { ...data.value, ...value })

const save = () =>
    store
        .dispatch(`${EStoreModules.DEVICE_CONFIG}/${EDeviceConfigActions.UPDATE_DEVICE_TYPE}`, {
            id: deviceTypeId.value,
            data: data.value
        })
        .then(res => (data.value = { ...res }))
</script>

<template>
    <DeviceTypesView
        :data="data"
        :confirmLabel="$t('deviceConfig.types.buttons.updateType')"
        :editPermission="EAdminPerm.ADMIN_DEVICES_CONFIG"
        :loading="!!store.state.deviceConfig.deviceTypes.isLoading"
        @change="updateDevice"
        @confirm="save" />
</template>
