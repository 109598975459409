import type { PermissionModel } from '@/types/models/PermissionModel'

export type PermissionState = {
    permission?: PermissionModel
    permissions: PermissionModel[]
    isLoading: boolean
    total: number
}

export enum EPermissionActions {
    FETCH_PERMISSION = 'fetchPermission',
    FETCH_PERMISSIONS = 'fetchPermissions'
}

export enum EPermissionMutations {
    SET_PERMISSIONS = 'setPermissions',
    SET_PERMISSION = 'setPermission',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
