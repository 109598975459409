import type { UserModel } from '@/types/models/UserModel'

export type UserState = {
    user?: UserModel
    users: UserModel[]
}

export enum EUserActions {
    FETCH_USERS = 'fetchUsers',
    FETCH_USER = 'fetchUser',
    CHANGE_PASSWORD = 'changePassword',
    CREATE_USER = 'createUser',
    UPDATE_USER = 'updateUser',
    DELETE_USER = 'deleteUser',
    IMPERSONATE_USER = 'impersonateUser',
    DEIMPERSONATION_USER = 'disincarnateUser'
}

export enum EUserMutations {
    SET_USERS = 'setUsers',
    SET_USER = 'setUser',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
