<script setup lang="ts">
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { computed, ref } from 'vue'

type DividerProps = {
    variant?: 'middle'
    orientation?: 'vertical' | 'horizontal'
    isResponsive?: boolean
    isPrimary?: boolean
}
const props = defineProps<DividerProps>()

isMobileEvent(e => {
    if (!props.isResponsive) return
    if (!e.matches) return (orientationRef.value = props.orientation ?? 'horizontal')
    orientationRef.value = 'horizontal'
})

const orientationRef = ref(
    isMobile() && props.isResponsive ? 'horizontal' : props.orientation ?? 'horizontal'
)
const margin = computed(() =>
    props.variant === 'middle'
        ? orientationRef.value === 'horizontal'
            ? '0% 10%'
            : '0.33em 0'
        : ''
)
</script>

<template>
    <hr
        :class="`divider with-margin ${orientationRef === 'vertical' ? 'vertical-hr' : ''} ${
            isPrimary ? 'is-primary' : ''
        }`" />
</template>

<style scoped lang="scss">
.divider {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
}

.is-primary {
    background-color: rgba($primary, 0.4);
    color: rgba($primary, 0.4);
}

.with-margin {
    margin: v-bind(margin);
}

.vertical-hr {
    width: 1px;
    height: auto;
    display: inline-block;
}
</style>
