import type { NotificationProps } from '@/types/components/NotificationComponent'
import { EColors } from '@/types/constants/ColorValues'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { ENotificationActions, ENotificationMutations } from './NotificationStoreTypes'

const state: Ref<NotificationProps> = ref({
    action: null,
    type: null,
    message: null,
    queue: []
})

const actions = {
    [ENotificationActions.QUEUE_ITEM](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: NotificationProps
    ) {
        if (data.queue && state.value.message) {
            commit(ENotificationMutations.ADD_TO_QUEUE, data)
        } else dispatch(data.action as string, data)
    },
    [ENotificationActions.SUCCESS](
        { commit }: { commit: Commit },
        data: Partial<NotificationProps>
    ) {
        commit(ENotificationMutations.SUCCESS, { type: EColors.SUCCESS, ...data })
    },
    [ENotificationActions.WARNING](
        { commit }: { commit: Commit },
        data: Partial<NotificationProps>
    ) {
        commit(ENotificationMutations.WARNING, { type: EColors.WARNING, ...data })
    },
    [ENotificationActions.INFO]({ commit }: { commit: Commit }, data: Partial<NotificationProps>) {
        commit(ENotificationMutations.INFO, { type: EColors.INFO, ...data })
    },
    [ENotificationActions.LIGHT]({ commit }: { commit: Commit }, data: Partial<NotificationProps>) {
        commit(ENotificationMutations.LIGHT, { type: EColors.LIGHT, ...data })
    },
    [ENotificationActions.PRIMARY](
        { commit }: { commit: Commit },
        data: Partial<NotificationProps>
    ) {
        commit(ENotificationMutations.PRIMARY, { type: EColors.PRIMARY, ...data })
    },
    [ENotificationActions.ERROR]({ commit }: { commit: Commit }, data: Partial<NotificationProps>) {
        commit(ENotificationMutations.ERROR, { type: EColors.DANGER, ...data })
    },
    [ENotificationActions.CLEAR]({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
        commit(ENotificationMutations.CLEAR)
        setTimeout(() => {
            if (state.value.queue.length) {
                const nextItem = state.value.queue[0]
                commit(ENotificationMutations.REMOVE_FROM_QUEUE)
                dispatch(nextItem?.action as string, nextItem)
            }
        }, 2000)
    }
}

const mutations = {
    [ENotificationMutations.SUCCESS](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.SUCCESS
        state.value.message = data.message
    },
    [ENotificationMutations.ERROR](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.DANGER
        state.value.message = data.message
    },
    [ENotificationMutations.LIGHT](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.LIGHT
        state.value.message = data.message
    },
    [ENotificationMutations.INFO](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.INFO
        state.value.message = data.message
    },
    [ENotificationMutations.WARNING](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.WARNING
        state.value.message = data.message
    },
    [ENotificationMutations.PRIMARY](_: NotificationProps, data: NotificationProps) {
        state.value.type = EColors.PRIMARY
        state.value.message = data.message
    },
    [ENotificationMutations.CLEAR]() {
        state.value.action = null
        state.value.type = null
        state.value.message = null
    },
    [ENotificationMutations.ADD_TO_QUEUE](_: NotificationProps, data: NotificationProps) {
        state.value.queue = [...state.value.queue, data]
    },
    [ENotificationMutations.REMOVE_FROM_QUEUE]() {
        state.value.queue = state.value.queue.slice(1, state.value.queue.length)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
