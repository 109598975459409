<script setup lang="ts">
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { lastHelloDateParsing } from '@/utils/dateUtils'
import moment from 'moment'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

type Props = {
    devicePingTime?: Date | null | string
}
const props = defineProps<Props>()

const currentPingTime = ref(props.devicePingTime)
const pingtime = computed(() =>
    lastHelloDateParsing(currentPingTime.value ? moment(currentPingTime.value).toDate() : undefined)
)
const interval = ref()
onMounted(
    () =>
        (interval.value = setInterval(() => (currentPingTime.value = props.devicePingTime), 60000))
)
onBeforeUnmount(() => {
    clearInterval(interval.value)
})
</script>

<template>
    <TypographyItem
        v-if="pingtime"
        :label="
            $t(pingtime?.translation, {
                value: pingtime?.value
            })
        " />
    <TypographyItem v-else :label="$t('date.lastHelloDate', { value: $t('common.unknown') })" />
</template>
