export type SessionState = {
    sessions: any
    isLoading: any
    total: number
}

export enum ESessionMutations {
    SET_SESSIONS = 'setSessions',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
