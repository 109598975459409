import type { DetectionModel } from '../types/models/DetectionModel'

export const SOCKET_RETRY_DELAY = 5000

export type SocketDetectionsId = 'checkpoint_id' & 'event_id' & 'device_id'

export const buildDetectionArray = <T extends { detections: DetectionModel[] }>(
    detections: DetectionModel[],
    itemsList: T[],
    key: keyof T & keyof DetectionModel
) =>
    itemsList.reduce((prev: T[], currentItem: T) => {
        const filteredList = (detections as DetectionModel[]).filter(
            item => item[key] === currentItem[key]
        )
        if (!filteredList.length) return [...prev, currentItem]
        return [
            ...prev,
            {
                ...currentItem,
                detections: [
                    ...filteredList,
                    ...(currentItem.detections?.slice(0, 3 - filteredList.length) ?? [])
                ]
            }
        ]
    }, [])

export const buildSocketStateArray = <T extends { detections: DetectionModel[] }>(
    list: number[],
    detections: T[],
    key: keyof T
) =>
    list.map(id => ({
        [key]: id,
        detections: detections.find(item => item[key] === id)?.detections ?? []
    }))
