<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import { FullTableLayout, StackLayout } from '@/components/Layouts'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import { ClickableTypography } from '@/components/Typography'
import { DEVICE_ROUTES, EVENT_CHECKPOINT_ROUTES, EVENTS_ROUTES } from '@/router/routes'
import store from '@/store'
import { EDetectionActions } from '@/store/detectionStore/DetectionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { FilterTagModel } from '@/types/components/FilterTagModel'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import {
    type AdvancedDetectionFilters,
    type DetectionFilters
} from '@/types/filters/DetectionFilters'
import { type LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { DATETIME_FORMAT, formatTimestamp } from '@/utils/dateUtils'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DetectionsDesktopAdvancedFilters from './DetectionsDesktopAdvancedFilters.vue'
import DetectionsDesktopFilters from './DetectionsDesktopFilters.vue'
import DetectionsMobileAdvancedFilters from './DetectionsMobileAdvancedFilters.vue'
import DetectionsMobileFilters from './DetectionsMobileFilters.vue'
import ExportDetectionButton from '@/components/Buttons/components/ExportDetectionButton.vue'
import { type EventModel } from '@/types/models/EventModel'

const { t } = useI18n()

const data = computed(() => store.state.detection)
const storeFilters = computed(() => store.state.filters)
const selectedEvent = ref(getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event)

const filters = ref<Partial<AdvancedDetectionFilters & DetectionFilters>>({
    event_id: selectedEvent.value?.id ? parseInt(selectedEvent.value?.id.toString()) : undefined,
    ...getQueryFilters(),
    ...storeFilters.value
})
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)

const getDetections = () =>
    store.dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.FETCH_DETECTIONS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const columns = {
    deviceName: { field: 'device_name', label: t('detection.table.deviceName') },
    bib: { field: 'bib', label: t('detection.table.bib') },
    event_filter: { field: 'event_filter', label: 'Event ID' },
    timer_filter: { field: 'timer_filter', label: 'Timer ID' },
    checkpoint_name: { field: 'checkpoint_name', label: t('detection.table.checkpointName') },
    event_name: { field: 'event_name', label: t('detection.table.eventName') },
    timestamp: { field: 'timestamp', label: t('detection.model.timestamp') }
}

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getDetections()
}

const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getDetections()
}

const handleFiltersChange = (value: Partial<DetectionModel>) => {
    filters.value = { ...filters.value, ...value }
    data.value.current_page = 1
    getDetections()
}

const handleAdvancedFilters = (value: Partial<AdvancedDetectionFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDetections()
}

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    data.value.current_page = 1
    getDetections()
}

const handleRowClick = (row: DetectionModel) => {
    selectedEvent.value = { id: row.event_id, name: row.event_name }
    filters.value = {
        ...filters.value,
        event_id: row.event_id,
        checkpoint_id: row.checkpoint_id,
        bib: row.bib,
        event_filter: row.event_filter,
        timer_filter: row.timer_filter
    }
    data.value.current_page = 1
    getDetections()
}

const showMobileFilters = ref(isMobile())
isMobileEvent(e => (showMobileFilters.value = e.matches))
const table = ref<any>([])

const deviceOptions = ref<AssociatedDeviceModel[]>()

const advancedFilters = ref<Partial<AdvancedDetectionFilters & DetectionFilters>>({
    ...getQueryFilters()
})
const filtersTags = computed(() =>
    Object.entries({
        start_at: filters.value.start_at
            ? moment(filters.value.start_at).utc().format(DATETIME_FORMAT.value).toString()
            : undefined,
        end_at: filters.value.end_at
            ? moment(filters.value.end_at).utc().format(DATETIME_FORMAT.value).toString()
            : undefined
    })
        .map(([name, value]) => ({
            name,
            value
        }))
        .filter(item => !!item.value)
)

const handleRemoveTag = (value: FilterTagModel) => {
    filters.value = { ...filters.value, [value.name]: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name]: undefined }
    data.value.current_page = 1
    getDetections()
}

const handleSelectEvent = (event?: EventModel) => {
    selectedEvent.value = event
    handleFiltersChange({ event_id: event?.id })
}
</script>

<template>
    <FullTableLayout
        showAdvancedFilters
        :orderOptions="ESortByDate"
        :sortBy="sort"
        @search="handleSearch"
        @changeSortBy="handleChangeSortBy"
        :advancedFilters="filtersTags"
        translationPath="detection.filters"
        @removeTag="handleRemoveTag">
        <template #export>
            <ExportDetectionButton
                :event="selectedEvent"
                :checkpointId="filters.checkpoint_id"
                :sort="sort"
                :perPage="data.per_page"
                :currentPage="data.current_page" />
        </template>
        <template #filters>
            <DetectionsMobileFilters
                v-if="showMobileFilters"
                :filters="filters"
                :selectedEvent="selectedEvent"
                :deviceOptions="deviceOptions"
                @change="handleFiltersChange"
                @associates="deviceOptions = $event"
                @selectEvent="handleSelectEvent" />
            <DetectionsDesktopFilters
                v-else
                :filters="filters"
                :selectedEvent="selectedEvent"
                @change="handleFiltersChange"
                @selectEvent="handleSelectEvent" />
        </template>

        <template #table>
            <BaseTable
                v-bind:ref="table"
                :data="data.data.detections"
                :loading="data.isLoading"
                @click="handleRowClick">
                <template #columns>
                    <TableColumn
                        field="device_name"
                        :label="columns['deviceName'].label"
                        v-slot="props">
                        <ClickableTypography
                            v-if="props.row.device_available === 1"
                            :label="props.row.device_name"
                            :href="DEVICE_ROUTES.buildUrl(props.row.device_id)" />
                    </TableColumn>
                    <TableColumn field="bib" :label="columns['bib'].label" />
                    <TableColumn
                        v-slot="props"
                        field="checkpoint_name"
                        :label="columns['checkpoint_name'].label">
                        <ClickableTypography
                            :label="props.row.checkpoint_name"
                            :href="`${EVENT_CHECKPOINT_ROUTES.buildUrl(props.row.event_id)}/${
                                props.row.checkpoint_id
                            }`" />
                    </TableColumn>
                    <TableColumn :label="columns['event_name'].label" v-slot="props">
                        <ClickableTypography
                            :label="props.row.event_name"
                            :href="EVENTS_ROUTES.buildUrl(props.row.event_id)" />
                    </TableColumn>
                    <TableColumn field="event_filter" :label="columns['event_filter'].label" />
                    <TableColumn field="timer_filter" :label="columns['timer_filter'].label" />
                    <TableColumn
                        field="timestamp"
                        :label="columns['timestamp'].label"
                        v-slot="props">
                        {{ formatTimestamp(props.row.timestamp) }}
                    </TableColumn>
                </template>
            </BaseTable>
            <StackLayout :gap="8" class="centered-pagination border-table">
                <TablePagination
                    :perPage="data.per_page"
                    :currentPage="data.current_page"
                    :lastPage="data.last_page"
                    :total="data.total"
                    @change:pageSize="handleChangePageSize"
                    @change:pageNumber="handleChangePageNumber" />
            </StackLayout>
        </template>

        <template #dialogContent>
            <DetectionsMobileAdvancedFilters
                v-if="showMobileFilters"
                :filters="advancedFilters"
                @change="handleAdvancedFilters" />
            <DetectionsDesktopAdvancedFilters
                v-else
                :filters="advancedFilters"
                @change="handleAdvancedFilters" />
        </template>
    </FullTableLayout>
</template>
