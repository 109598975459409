<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'

type Props = {
    email: string
}
const props = defineProps<Props>()

const resetPassword = () =>
    store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.FORGOT_PASSWORD}`, props.email)
</script>

<template>
    <PrimaryButton
        :label="$t('user.buttons.resetPassword')"
        @click="resetPassword"
        class="reset-password-button" />
</template>

<style scoped lang="scss">
.reset-password-button {
    height: auto;
    & :deep(span) {
        white-space: normal;
    }
}
</style>
