<script setup lang="ts">
import ReactiveLogo from '@/components/Assets/ReactiveLogo.vue'
import { HOME_URL } from '@/router'
import type { MenuItemType } from '@/types/MenuItem'
import { useRouter } from 'vue-router'
import MenuItem from './MenuItem.vue'

const router = useRouter()

const item: MenuItemType = {
    to: HOME_URL,
    label: '',
    click: () => router.push(HOME_URL)
}
</script>

<template>
    <MenuItem :item="item" class="flex pointer home-logo">
        <template #label>
            <ReactiveLogo />
        </template>
    </MenuItem>
</template>

<style scoped lang="scss">
.home-logo {
    width: 100%;
    & :deep(li) {
        width: 100%;
        list-style: none;
        & a {
            place-content: center;
            &.is-active {
                background-color: transparent;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
}
</style>
