<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { GridLayout } from '@/components/Layouts'
import type { UserModel } from '@/types/models/UserModel'
import { isSmallScreen, isSmallScreenEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import UsersActivationAction from './UsersActivationAction.vue'
import UsersDesactivationAction from './UsersDesactivationAction.vue'

type Props = {
    organizationId?: number
    user?: UserModel
}
defineProps<Props>()

const maxItemPerRow = ref(isSmallScreen() ? 1 : 2)
isSmallScreenEvent(e => (maxItemPerRow.value = e.matches ? 1 : 2))
</script>

<template>
    <InformationBaseCard
        v-if="user?.user_id ?? user?.id"
        class="fit-height"
        :label="$t('common.actions')">
        <GridLayout :maxItemPerRow="maxItemPerRow" :gap="8" class="full-width">
            <UsersActivationAction v-if="!user?.active" :user="user" />
            <UsersDesactivationAction v-else :user="user" />
            <slot />
        </GridLayout>
    </InformationBaseCard>
</template>
