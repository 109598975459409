<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'

type Props = {
    label?: string
    href: string
    withLogo?: boolean
}
defineProps<Props>()
type Emits = {
    (e: 'click', event: MouseEvent): void
}
defineEmits<Emits>()

const handleClick = (event: MouseEvent) => event.stopImmediatePropagation()
</script>

<template>
    <router-link
        @click="handleClick"
        :to="href"
        class="link-title clickable-typography"
        v-bind="$attrs">
        {{ label }}
        <IconItem v-if="withLogo" icon="up-right-from-square" />
    </router-link>
</template>

<style scoped lang="scss">
.link-title {
    width: fit-content;
    cursor: pointer;
    & .icon svg {
        width: 16px;
    }
}

a {
    text-decoration: underline;
    color: $primary;
    text-decoration-color: $primary;
    text-underline-offset: 4px;
    &:hover {
        color: $primary;
        background-color: var(--color-background-secondary);
    }
}
</style>
