export const ticketFr = {
    table: {
        id: 'Ticket id',
        object: 'Titre',
        description: 'Description',
        topic: 'Thème',
        startDate: 'Date de création',
        endDate: 'Date de fin',
        statusId: 'Statut',
        supportId: 'Support',
        severityId: 'Gravité',
        topicId: 'Thème',
        action: 'Action',
        markAsRead: 'Marquer comme lu',
        markAsUnread: 'Marquer comme non lu',
        delete: 'Supprimer',
        serialNumber: 'Serial number',
        comment: 'Commentaire',
        device: 'Appareil',
        status: 'Statut',
        severitty: 'Gravité'
    },
    filter: {
        deviceType: "Type d'appareil",

        input: 'Filtre avancé',
        date: 'Date',
        status: 'Status',
        support: 'Support',
        period: 'Trier par période',
        mostRecent: 'Plus récent',
        leastRecent: 'Moins récent',
        techician: 'Techicien',
        commercial: 'Commercial',
        developer: 'Développeur',
        treated: 'Traité',
        notTreated: 'Non traité',
        inProgress: 'En cours'
    },
    model: {
        createDate: 'Date de création',
        status: 'statut',
        support: 'Support',
        sortByPeriod: 'Trier par période',
        advancedFilter: 'Filtre avancé',
        createTicket: 'Créer un ticket'
    },
    detail: {
        action: 'Action',
        date: 'Date',
        diagnostic: 'Diagnostique',
        file: 'Fichier',
        information: 'Les informations',
        message: 'Les messages',
        modalMessage: "Ouvrir l'image dans un nouvel onglet",
        name: 'Nom',
        reassignment: 'Réaffectation',
        reply: 'Réponse',
        resolution: 'Résolution',
        selectRecipient: 'Selectionner un destinataire',
        send: 'Envoyer',
        sendReply: 'Envoyer la réponse',
        ticketReassignment: 'Réaffectation du ticket',
        topic: 'Thème'
    },
    create: {
        title: 'Sélectionnez le thème de votre demande',
        home: "Retour a l'acceuil principal",
        faq: 'Voir la FAQ',
        selectSeverity: 'Sélectionner la gravité',
        information: 'Informations ticket',
        message: 'Message',
        placeholderMessage: 'Ajouter une description ...',
        addFile: 'Ajouter des images',
        topic: 'Thème',
        severity: 'Gravité',
        seeInformation: 'Voir les informations',
        device: 'Appareil',
        selectDevice: 'Selectionner un appareil',
        serialNumber: 'Numéro de série',
        serialNumberDevice: 'Numéro de série Appareil',
        youMustFillCategorie: 'Vous devez selectionner une catetégorie',
        youMustFillDescription: 'Vous devez selectionner une description',
        youMustFillTopic: 'Vous devez selectionner un thème',
        failCreateTicket: 'Échec de la création du ticket'
    },
    stepper: {
        category: 'Catégorie',
        description: 'Description',
        device: 'Appareil',
        title: 'Titre',
        topic: 'Topic'
    },
    message: {
        attachment: 'Pièce jointe',
        reply: 'Répondre',
        placeholderMessage: 'Message',
        sendReply: 'Envoyer une réponse',
        addFile: 'Ajouter Documents'
    },
    tab: {
        information: 'Informations',
        messages: 'Messages'
    }
}
