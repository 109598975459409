import type { EStatus } from '@/types/enum/StatusEnum'
import type { DeviceModel } from '../models/DeviceModel'

export type AdminUserFiltersType = {
    name?: string
    email?: string
    organization_id?: number
}
export const defaultAdminUserFiltersType: AdminUserFiltersType = {}

export type AdminRoleFiltersType = Partial<{
    name: string
}>
export const defaultAdminRoleFiltersType: AdminRoleFiltersType = {}

export type AdminDeviceFiltersType = Partial<DeviceModel>
export const defaultAdminDeviceFiltersType: AdminDeviceFiltersType = {}

export type AdminApiFiltersType = Partial<{
    name: string
    status: EStatus
    expirationDateFrom: Date
    expirationDateTo: Date
}>
export const defaultAdminApiFiltersType: AdminApiFiltersType = {
    expirationDateFrom: undefined,
    expirationDateTo: undefined
}

export type AdminOrganizationFiltersType = Partial<{
    name: string
}>
export const defaultAdminOrganizationFiltersType: AdminOrganizationFiltersType = {}
