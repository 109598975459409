<script setup lang="ts">
import { getCurrentInstance } from 'vue'

type Props = {
    hasPermission?: boolean
    href: string
}
defineProps<Props>()
type Emits = {
    (e: 'click', event: MouseEvent): void
}
const emit = defineEmits<Emits>()

const handleOnClick = (e: MouseEvent) => {
    e.stopImmediatePropagation()
    if (getCurrentInstance()?.vnode.props?.['onClick']) return emit('click', e)
}
</script>

<template>
    <router-link @click="handleOnClick" :to="hasPermission ? href : $route.fullPath">
        <slot />
    </router-link>
</template>
