<script setup lang="ts">
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import { EColors } from '@/types/constants/ColorValues'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { formatTimestamp } from '@/utils/dateUtils'
import { useDebounceFn } from '@vueuse/core'
import { computed, onMounted, onUpdated, ref } from 'vue'
import { TitleTypography } from '.'

type Props = DetectionModel & {
    index: number
}
const props = defineProps<Props>()

const generateTitle = () =>
    (title.value = `Bib ${props.bib} ${props.event_filter ? `- E${props.event_filter}` : ''} ${
        props.timer_filter ? `- T${props.timer_filter}` : ''
    }`)
const title = ref('')
const subtitle = computed(() => formatTimestamp(props.timestamp))

const generateTitleDelay = useDebounceFn(() => generateTitle(), 500)
onMounted(() => generateTitle())
onUpdated(() => {
    if (props.index === 0) {
        title.value = ''
        generateTitleDelay()
    } else generateTitle()
})
</script>

<template>
    <StackLayout>
        <IconItem icon="circle" :type="EColors.WHITE" class="small-icon" />
        <Transition :name="index === 0 ? 'slide-left' : ''">
            <TitleTypography
                v-if="title"
                :title="title"
                :subtitle="subtitle"
                class="detection-title"
                :gap="0" />
        </Transition>
    </StackLayout>
</template>

<style scoped lang="scss">
.detection-title {
    & :deep(.title),
    :deep(.subtitle) {
        font-size: small;
    }
    & :deep(.subtitle) {
        color: var(--color-text-secondary);
    }
}
.small-icon {
    & :deep(svg) {
        width: 12px;
    }
}
</style>
