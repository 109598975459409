<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import store from '@/store'
import { EApiActions } from '@/store/apiStore/ApiStateType'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const isLoading = computed(() => store.state.api.isLoading)
const apiId = ref(route.params.api_id.toString())
const submitted = ref(false)

const getApi = () =>
    store.dispatch(`${EStoreModules.API}/${EApiActions.FETCH_API}`, apiId.value).then(res => {
        apiValue.value = res
        storedValue.value = res
    })

onMounted(() => {
    if (typeof apiId.value === 'string') getApi()
})

const storedValue = ref<ApiModel>({ ...defaultApiModel })
const apiValue = ref<ApiModel>({ ...defaultApiModel })

const updateApiValue = (value: Partial<ApiModel>) =>
    (apiValue.value = { ...apiValue.value, ...value })

const updateApi = () => {
    submitted.value = true
    if (!apiValue.value.name) return
    store
        .dispatch(`${EStoreModules.API}/${EOrganizationActions.CREATE_API}`, {
            data: apiValue.value,
            apiId: apiId.value
        })
        .then(() => (submitted.value = false))
}
</script>

<template>
    <ApiDetailView
        :data="apiValue"
        :buttonLabel="$t('common.saveModifications')"
        :editPermission="EAdminPerm.ADMIN_ORGANIZATIONS"
        hasPermission
        :loading="isLoading"
        @update="updateApiValue"
        @click="updateApi" />
</template>
