<script setup lang="ts">
import SecondaryButton from '@/components/Buttons/SecondaryButton.vue'
import TypedInput from '@/components/Fields/TypedInput.vue'
import GridLayout from '@/components/Layouts/GridLayout.vue'
import type { EPrimitive } from '@/types/enum/PrimitivesEnum'
import { isLastArrayElementNullOrUndefined } from '@/utils/dataUtils'
import { isNullOrUndefined } from '@/utils/validateUtils'
import { ref } from 'vue'

type Props = {
    value?: (number | string | boolean | undefined)[]
    dataType?: EPrimitive
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: (number | string | boolean | undefined)[]): void
    (e: 'click'): void
}
const emit = defineEmits<Emits>()

const isSubmitted = ref(false)

const handleUpdate = (value: number, index: number) => {
    const tmp = [...(props.value ?? [])]
    tmp[index] = value
    emit('change', tmp)
}
const handleClick = () => {
    if (isLastArrayElementNullOrUndefined(props.value)) return (isSubmitted.value = true)
    isSubmitted.value = false
    emit('change', [...(props.value?.filter(item => !isNullOrUndefined(item)) ?? []), undefined])
}
</script>
<template>
    <GridLayout :maxItemPerRow="2" :gap="8">
        <TypedInput
            v-for="(item, key) in value"
            :key="key"
            :dataType="dataType"
            :modelValue="item"
            :hasError="isSubmitted && isNullOrUndefined(item)"
            @change="handleUpdate($event, key)" />
        <SecondaryButton
            :label="$t('deviceConfig.templateKeys.buttons.addNewValue')"
            @click="handleClick"
            class="fit-content align-self-end" />
    </GridLayout>
</template>
