<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { TypographyItem } from '@/components/Typography'
import deviceApi from '@/services/device.service'
import type { AssociatedDeviceModel, DeviceModel } from '@/types/models/DeviceModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core/index.cjs'
import { onMounted, ref } from 'vue'

type Props = {
    associates?: AssociatedDeviceModel[]
    label: string
    selectedDevice?: string
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'select', device: DeviceModel): void
}>()

const isLoadingDevices = ref(false)
const handleChange = (value: string) => {
    isLoadingDevices.value = true
    debouncedQuestion(value)
}
const debouncedQuestion = useDebounceFn((value: string) => getDevices(value), EDebounceTime.DEFAULT)
const getDevices = (name?: string) =>
    deviceApi
        .getDevices(name ? { filters: { name } } : {})
        .then(res =>
            props.associates
                ? (devicesOptions.value = res.data.filter(item =>
                      props.associates?.find(associated => associated.id === item.id) ? false : item
                  ))
                : (devicesOptions.value = res.data)
        )
        .finally(() => (isLoadingDevices.value = false))

const devicesOptions = ref<DeviceModel[]>([])

onMounted(() => getDevices())
</script>

<template>
    <AutocompleteField
        :label="label"
        :modelValue="selectedDevice"
        :loading="isLoadingDevices"
        @typing="handleChange"
        @select="$emit('select', $event as DeviceModel)"
        :options="devicesOptions"
        v-bind="$attrs">
        <template #default="props">
            <StackLayout direction="column">
                <TypographyItem :label="props.option.name" />
                <TypographyItem :label="`SN : ${props.option.serial_number}`" />
            </StackLayout>
        </template>
    </AutocompleteField>
</template>
