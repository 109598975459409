<script setup lang="ts">
import EditableTypedInput from '@/components/Fields/EditableTypedInput.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { localFallback } from '@/locales/langUtils'
import { type DeviceConfigurationItemRef } from '@/types/components/Configuration/DeviceConfigurationItemRef'
import type { DeviceConfigTemplateKey } from '@/types/device/DeviceConfigTemplateKey'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import { configDataErrorText, configDataHasError } from '@/utils/validateUtils'
import { computed } from 'vue'

type Props = {
    data?: { [key: string]: ExtendedPrimitives | undefined }
    templateKeys: DeviceConfigTemplateKey[]
    isInEditMode: boolean
    showAdvanced: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: { [key: string]: ExtendedPrimitives | undefined }): void
}
defineEmits<Emits>()

const getInputLabel = (item: DeviceConfigTemplateKey) =>
    localFallback(item.translation) ?? localFallback(item.description) ?? item.name
const getTooltipLabel = (item: DeviceConfigTemplateKey) =>
    localFallback(item.description, 'device.configuration.undefinedDescription', true)

type FilteredTemplate = {
    config: DeviceConfigTemplateKey[]
    advancedConfig: DeviceConfigTemplateKey[]
}
const filteredTemplates = computed(() =>
    props.templateKeys.reduce(
        (prev: FilteredTemplate, item) => ({
            config: item.is_advanced ? prev.config : [...prev.config, item],
            advancedConfig: item.is_advanced ? [...prev.advancedConfig, item] : prev.advancedConfig
        }),
        { config: [], advancedConfig: [] }
    )
)

const configurationHasError = computed<boolean>(
    () => !!props.templateKeys.find(item => configDataHasError(item, props.data?.[item.name]))
)

defineExpose<DeviceConfigurationItemRef>({
    configLength: filteredTemplates.value.config.length,
    advancedConfigLength: filteredTemplates.value.advancedConfig.length,
    configurationHasError
})
</script>

<template>
    <StackLayout direction="column" :gap="8" class="full-width" isResponsive>
        <EditableTypedInput
            v-for="(templateKey, key) in filteredTemplates.config"
            :label="getInputLabel(templateKey)"
            :tooltip="getTooltipLabel(templateKey)"
            :value="data?.[templateKey.name]"
            :values="templateKey.values"
            :valuesLabels="templateKey.values_labels"
            :dataType="templateKey.type"
            :suffix="templateKey.unit"
            :hasError="configDataHasError(templateKey, data?.[templateKey.name])"
            :errorText="configDataErrorText(templateKey, data?.[templateKey.name])"
            @change="$emit('change', { [templateKey.name]: $event })"
            :showableElement="!!templateKey.masked"
            :range="templateKey.range"
            :isInEditMode="!templateKey.read_only && isInEditMode"
            :class="templateKey.read_only && isInEditMode ? 'edit-field' : ''"
            :key="key" />
        <template v-if="showAdvanced">
            <EditableTypedInput
                v-for="(templateKey, key) in filteredTemplates.advancedConfig"
                :label="getInputLabel(templateKey)"
                :tooltip="getTooltipLabel(templateKey)"
                :value="data?.[templateKey.name]"
                :values="templateKey.values"
                :valuesLabels="templateKey.values_labels"
                :dataType="templateKey.type"
                :suffix="templateKey.unit"
                :hasError="configDataHasError(templateKey, data?.[templateKey.name])"
                :errorText="configDataErrorText(templateKey, data?.[templateKey.name])"
                @change="$emit('change', { [templateKey.name]: $event })"
                :showableElement="!!templateKey.masked"
                :range="templateKey.range"
                :isInEditMode="!templateKey.read_only && isInEditMode"
                :class="templateKey.read_only && isInEditMode ? 'edit-field' : ''"
                :key="key" />
        </template>
    </StackLayout>
</template>
