<script setup lang="ts">
import GridLayout from '@/components/Layouts/GridLayout.vue'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { DropZoneData } from '@/types/components/DropZoneData'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { SocketCheckpointDetectionModel } from '@/types/socket/SocketDetectionModel'
import { type Position } from '@vueuse/core'
import { ref } from 'vue'
import CheckpointCard from './components/CheckpointCard.vue'

type Props = {
    currentTabIndex: number
    data: CheckpointModel[]
    detections?: SocketCheckpointDetectionModel[]
    loading: boolean
    eventId: number
    partnerConfig?: PartnerModel
    editPermission: PermissionsEnums
    deletePermission: PermissionsEnums
}
defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'goTo', value?: CheckpointModel): void
    (e: 'delete', checkpoint: CheckpointModel): void
}
defineEmits<Emits>()

const size = useGridSize()

const dropZone = ref<DropZoneData>({
    checkpointId: undefined,
    device: undefined,
    position: undefined
})
const dragStart = (device?: AssociatedDeviceModel, checkpointId?: number) => {
    dropZone.value.device = device
    dropZone.value.checkpointId = checkpointId
}

const dragEnd = (position?: Position) => (dropZone.value.position = position)

const handleClose = () => (dropZone.value.device = undefined)
</script>

<template>
    <StackLayout
        ref="stackRef"
        v-if="data?.length"
        direction="column"
        :gap="8"
        isResponsiveSmallScreen
        class="full-width">
        <GridLayout
            :gap="8"
            :maxItemPerRow="size"
            class="relative flex-wrap checkpoint-carousel"
            isResponsiveSmallScreen>
            <CheckpointCard
                v-for="(checkpoint, key) in data"
                :key="key"
                :data="checkpoint"
                :detections="
                    detections?.find(item => item.checkpoint_id === checkpoint.id)?.detections ?? []
                "
                :editPermission="editPermission"
                :deletePermission="deletePermission"
                :dropZoneData="dropZone"
                @dragStart="dragStart"
                @dragEnd="dragEnd"
                @delete="$emit('delete', $event)"
                @click="$emit('goTo', $event)"
                @close="handleClose"
                @refresh="$emit('refresh')" />
        </GridLayout>
    </StackLayout>
    <StackLayout v-else class="align-self-center no-record">
        {{ $t('common.noRecord') }}
    </StackLayout>
</template>

<style scoped lang="scss">
.checkpoint-carousel {
    padding-bottom: 8px;

    & :deep(.checkpoint-dropdown) {
        cursor: default;
        min-width: 25%;

        & .card-header {
            pointer-events: none;
        }
        & .card-content {
            pointer-events: none;
        }
        & .dropzone-card {
            & .card-footer {
                pointer-events: none;
            }
        }
    }
}
</style>
