import { isDesktop, isMobile, isSmallScreen, isTablet, isWidescreen } from '@/utils/viewsUtils'

export enum EGridSize {
    IS_SMALLSCREEN = 1,
    IS_MOBILE = 2,
    IS_TABLET = 3,
    IS_DESKTOP = 3,
    IS_WIDESCREEN = 4,
    IS_FULLSCREEN = 5
}

export const gridSize = () => {
    if (isSmallScreen()) return EGridSize.IS_SMALLSCREEN
    if (isMobile()) return EGridSize.IS_MOBILE
    if (isTablet()) return EGridSize.IS_TABLET
    if (isDesktop()) return EGridSize.IS_DESKTOP
    if (isWidescreen()) return EGridSize.IS_WIDESCREEN
    return EGridSize.IS_FULLSCREEN
}
