import { EAlertComponent, type AlertProps } from '@/types/components/AlertComponent'
import { EColors } from '@/types/constants/ColorValues'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions, EAlertMutations } from './AlertStoreTypes'

const parseData = (data: Partial<AlertProps> | string) =>
    typeof data === 'string' ? { message: data } : data

const state: Ref<AlertProps> = ref({
    action: null,
    type: null,
    message: null,
    component: null,
    queue: []
})

const actions = {
    [EAlertActions.QUEUE_ITEM](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        data: AlertProps
    ) {
        if (data.queue && state.value.component) {
            commit(EAlertMutations.ADD_TO_QUEUE, data)
        } else dispatch(data.action as string, data)
    },
    [EAlertActions.SUCCESS]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.SUCCESS, { type: EColors.SUCCESS, ...parseData(data) })
    },
    [EAlertActions.WARNING]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.WARNING, { type: EColors.WARNING, ...parseData(data) })
    },
    [EAlertActions.INFO]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.INFO, { type: EColors.INFO, ...parseData(data) })
    },
    [EAlertActions.LIGHT]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.LIGHT, { type: EColors.LIGHT, ...parseData(data) })
    },
    [EAlertActions.PRIMARY]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.PRIMARY, { type: EColors.PRIMARY, ...parseData(data) })
    },
    [EAlertActions.ERROR]({ commit }: { commit: Commit }, data: Partial<AlertProps>) {
        commit(EAlertMutations.ERROR, { type: EColors.DANGER, ...parseData(data) })
        throw Error(data.message ?? 'Unknown error')
    },
    [EAlertActions.CLEAR]({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
        commit(EAlertMutations.CLEAR)
        setTimeout(() => {
            if (state.value.queue.length) {
                const nextItem = state.value.queue[0]
                commit(EAlertMutations.REMOVE_FROM_QUEUE)
                dispatch(nextItem?.action as string, nextItem)
            }
        }, 2000)
    }
}

const mutations = {
    [EAlertMutations.SUCCESS](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.SUCCESS
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.ERROR](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.DANGER
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.LIGHT](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.LIGHT
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.INFO](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.INFO
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.WARNING](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.WARNING
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.PRIMARY](_: AlertProps, data: AlertProps) {
        state.value.type = EColors.PRIMARY
        state.value.message = data.message
        state.value.component = data.component ?? EAlertComponent.SNACKBAR
    },
    [EAlertMutations.CLEAR]() {
        state.value.action = null
        state.value.type = null
        state.value.message = null
        state.value.component = null
    },
    [EAlertMutations.ADD_TO_QUEUE](_: AlertProps, data: AlertProps) {
        state.value.queue = [...state.value.queue, data]
    },
    [EAlertMutations.REMOVE_FROM_QUEUE]() {
        state.value.queue = state.value.queue.slice(1, state.value.queue.length)
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
