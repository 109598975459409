import type { PaginationDataType } from '@/types/PaginationType'
import type { AlertProps } from '@/types/components/AlertComponent'
import type { NotificationProps } from '@/types/components/NotificationComponent'
import type { ApiState } from './apiStore/ApiStateType'
import type { AuthState } from './authStore/AuthStoreTypes'
import type { CheckpointState } from './checkpointStore/CheckpointStoreTypes'
import type { DetectionState } from './detectionStore/DetectionStoreTypes'
import type { DeviceConfigState } from './deviceConfigStore/DeviceConfigStoreTypes'
import type { DeviceState } from './deviceStore/DeviceStoreTypes'
import type { EventState } from './eventStore/EventStoreTypes'
import type { FiltersState } from './filtersStore/FiltersStoreTypes'
import type { LoadingState } from './loadingStore/LoadingStoreTypes'
import type { OrganizationState } from './organizationStore/OrganizationStoreTypes'
import type { PaginationState } from './paginationStore/PaginationStoreTypes'
import type { PartnerState } from './partnersStore/PartnerStoreTypes'
import type { PermissionState } from './permissionsStore/PermissionStoreTypes'
import type { QuestionState } from './questionStore/QuestionStoreTypes'
import type { RoleState } from './roleStore/RoleStoreTypes'
import type { TechnicianState } from './technicianStore/TechnicianStoreTypes'
import type { TicketState } from './ticketStore/TicketStoreTypes'
import type { TopicState } from './topicStore/TopicStoreTypes'
import type { UserState } from './userStore/UserStoreTypes'

export type StoreState = {
    alert: AlertProps
    api: PaginationDataType<ApiState>
    authentication: AuthState
    checkpoint: PaginationDataType<CheckpointState>
    detection: PaginationDataType<DetectionState>
    device: DeviceState
    deviceConfig: DeviceConfigState
    event: PaginationDataType<EventState>
    filters: FiltersState
    loading: LoadingState
    notification: NotificationProps
    organization: OrganizationState
    pagination: PaginationState
    partner: PaginationDataType<PartnerState>
    permission: PermissionState
    question: PaginationDataType<QuestionState>
    role: PaginationDataType<RoleState>
    technician: TechnicianState
    ticket: PaginationDataType<TicketState>
    user: PaginationDataType<UserState>
    topic: PaginationDataType<TopicState>
}

export enum EStoreModules {
    ALERT = 'alert',
    API = 'api',
    AUTHENTICATION = 'authentication',
    CHECKPOINT = 'checkpoint',
    ORGANIZATION = 'organization',
    DETECTION = 'detection',
    DEVICE = 'device',
    DEVICE_CONFIG = 'deviceConfig',
    EVENT = 'event',
    FILTERS = 'filters',
    LOADING = 'loading',
    NOTIFICATION = 'notification',
    PAGINATION = 'pagination',
    PARTNER = 'partner',
    PERMISSION = 'permission',
    QUESTION = 'question',
    ROLE = 'role',
    TECHNICIAN = 'technician',
    TICKET = 'ticket',
    USER = 'user',
    TOPIC = 'topic'
}
