<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import { StackLayout } from '@/components/Layouts'
import { TypographyItem } from '@/components/Typography'
import { ref } from 'vue'

type Props = {
    value: string
    label?: string
}
const props = defineProps<Props>()

const copiedLabel = ref()
const setCopiedLabel = () => {
    copiedLabel.value = 'copied!'
    setTimeout(() => (copiedLabel.value = null), 1000)
}

const typography = ref<{ typographyRef: HTMLSpanElement } | null>(null)

const addLinkToClipboard = () => {
    navigator.clipboard.writeText(props.value)
    setCopiedLabel()
    typography.value?.typographyRef.focus()
}
</script>

<template>
    <StackLayout direction="column" class="full-width">
        <StackLayout v-if="!!label" class="field is-floating-label">
            <TypographyItem class="label" :label="label" />
        </StackLayout>
        <StackLayout :gap="8" class="align-items-center copy-link">
            <TypographyItem :label="value" ref="typography" class="link-class" />
            <IconButton icon="copy" @click="addLinkToClipboard" />
        </StackLayout>
        <Transition name="fade" class="align-self-end">
            <TypographyItem v-if="!!copiedLabel" :label="copiedLabel" />
        </Transition>
    </StackLayout>
</template>

<style scoped lang="scss">
.copy-link {
    border: 1px solid gray;
    border-radius: var(--border-radius);
    padding: 4px;
}
.link-class {
    padding: 4px;
    border-radius: 4px;
    height: fit-content;
    overflow-x: auto;
    &:focus {
        background-color: gray;
    }
    &:focus-within {
        background-color: gray;
    }
}
</style>
