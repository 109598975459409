<script setup lang="ts">
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseDialog from '@/components/Modals/Dialog/BaseDialog.vue'
import DeviceTypeSelector from '@/components/Selector/components/DeviceTypeSelector.vue'
import store from '@/store'
import { EDeviceActions } from '@/store/deviceStore/DeviceStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { ref } from 'vue'

type Props = {
    data: Partial<DeviceModel>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const open = ref(false)
const selectedType = ref<number | undefined>(props.data.device_type_id)

const save = () => {
    if (!selectedType.value) return
    store
        .dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_DEVICE}`, {
            deviceId: props.data.id,
            deviceData: {
                ...props.data,
                device_type_id: selectedType.value,
                roles: ['device']
            }
        })
        .then(() => emit('refresh'))
        .finally(() => (open.value = false))
}
</script>

<template>
    <PrimaryButton :label="$t('device.actions.changeDeviceType')" @click="open = true" />

    <BaseDialog
        :title="$t('device.actions.changeDeviceType')"
        :open="open"
        @confirm="save"
        @close="open = false"
        class="dialog-with-dropdown modal-selector-full-width">
        <template #content>
            <DeviceTypeSelector :modelValue="selectedType" @select="selectedType = $event.id" />
        </template>
    </BaseDialog>
</template>
