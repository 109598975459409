// import the api endpoints
import { sessionService } from '@/services/session.service'
import type { Commit, Dispatch } from 'vuex'
import { EAlertActions } from '../alertStore/AlertStoreTypes'
import { EStoreModules } from '../storeType'
import { ESessionMutations, type SessionState } from './SessionStoreTypes'

const getters = {
    getSessions: (state: SessionState) => state.sessions
}

const actions = {
    fetchSearchSessions({ dispatch, commit }: { commit: Commit; dispatch: Dispatch }, params: any) {
        commit(ESessionMutations.SET_LOADING, true)
        sessionService.getSearchSessions(params.query, params.field, params.value).then(
            data => {
                commit(ESessionMutations.SET_SESSIONS, data.data)
                commit(ESessionMutations.SET_TOTAL, data.total)
                commit(ESessionMutations.SET_LOADING, false)
            },
            error => {
                dispatch(`${EStoreModules.ALERT}/${EAlertActions.ERROR}`, error, { root: true })
                commit(ESessionMutations.SET_LOADING, false)
            }
        )
    }
}

const mutations = {
    [ESessionMutations.SET_SESSIONS](state: SessionState, data: any) {
        state.sessions = data
    },
    [ESessionMutations.SET_TOTAL](state: SessionState, data: any) {
        state.total = data
    },
    [ESessionMutations.SET_LOADING](state: SessionState, data: any) {
        state.isLoading = data
    }
}

const state: SessionState = {
    sessions: [],
    isLoading: false,
    total: 0
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
