<script setup lang="ts">
import MenuAccount from '@/components/Menu/MenuAccount.vue'
import MenuComponent from '@/components/Menu/MenuComponent.vue'
import VersionItem from '@/components/Version/VersionItem.vue'
import { menuRoutesBottom, menuRoutesTop } from '@/router/menuRoutes'
import { isMobile, isTablet } from '@/utils/viewsUtils'
import { computed } from 'vue'
import { StackLayout } from '../../Layouts'

type Props = {
    showToggles?: boolean
}
defineProps<Props>()
type Emits = {
    (e: 'click'): void
}
defineEmits<Emits>()

const isDesktop = computed(() => !(isMobile() || isTablet()))

const menuRoutes = computed(() => [...menuRoutesTop.value, ...menuRoutesBottom])
</script>

<template>
    <div class="menu-div-container">
        <MenuComponent :items="menuRoutes" @click="$emit('click')">
            <template #home-item>
                <slot name="home-item" />
            </template>
            <template #footer-item>
                <slot name="footer-item" />
            </template>
        </MenuComponent>
        <StackLayout direction="column">
            <VersionItem />
            <MenuComponent>
                <template #home-item>
                    <MenuAccount
                        :showToggles="isDesktop || showToggles"
                        :hideDividers="isDesktop"
                        class="account-menu-desktop" />
                </template>
            </MenuComponent>
        </StackLayout>
    </div>
</template>

<style lang="scss">
.account-menu-desktop {
    & .my-account-mobile {
        border-bottom: 1px solid $primary;
    }
}
.account-menu-desktop {
    & .disconnect-mobile {
        border-top: 1px solid $primary;
    }
}

.connected-as {
    padding: 8px;
    color: var(--color-text);
}
</style>
