<script setup lang="ts">
import { GridLayout } from '@/components/Layouts'
import { useGridSize } from '@/hooks/useGridSize'
import type { GridSize } from '@/types/constants/GridSize'
import { EGridSize } from '@/types/enum/ViewsUtilsEnum'
import type { DocumentationModel } from '@/types/models/DocumentationModel'
import DocumentationCard from './components/DocumentationCard.vue'

type Props = {
    devices: {
        title: string
        documents: DocumentationModel[]
        serialNumbers: string[]
        versions: string[]
    }[]
}
defineProps<Props>()

const documentationGridSizes: GridSize = {
    mobile: EGridSize.IS_SMALLSCREEN,
    tablet: EGridSize.IS_MOBILE,
    desktop: EGridSize.IS_TABLET,
    widescreen: EGridSize.IS_DESKTOP,
    fullscreen: EGridSize.IS_WIDESCREEN
}
const gridSize = useGridSize(documentationGridSizes)
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8" class="relative">
        <DocumentationCard
            v-for="(item, key) in devices"
            :key="key"
            :title="item.title"
            :documents="item.documents"
            :serialNumbers="item.serialNumbers"
            :versions="item.versions"
            class="document-card" />
    </GridLayout>
</template>
