import i18n from '@/locales/i18n'
import { EVENTS_ROUTES } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import { EEventsPerm } from '@/types/permissions/EventsPermissionsEnum'
import EventsCreateView from '@/views/EventsView/EventsCreateView.vue'
import EventsDetailView from '@/views/EventsView/EventsDetailView.vue'
import EventsTableView from '@/views/EventsView/EventsTableView.vue'
import { ref } from 'vue'
import checkpointsRoutes from './checkpointsRoutes'

export enum EEventRoutesNames {
    EVENT_TABLE = 'eventTable',
    EVENT_DETAIL = 'eventDetail',
    EVENT_CREATE = 'eventCreate'
}
export const EEventRoutesNamesValues = [
    EEventRoutesNames.EVENT_TABLE,
    EEventRoutesNames.EVENT_DETAIL,
    EEventRoutesNames.EVENT_CREATE
]

const routes: RouteType[] = [
    {
        path: EVENTS_ROUTES.baseUrl,
        name: EEventRoutesNames.EVENT_TABLE,
        component: EventsTableView,
        meta: {
            title: ref(i18n.global.t('menu.events')),
            permission: EEventsPerm.EVENTS_VIEW
        }
    },
    {
        path: EVENTS_ROUTES.detailUrl,
        name: EEventRoutesNames.EVENT_DETAIL,
        component: EventsDetailView,
        meta: {
            title: ref(i18n.global.t('menu.events')),
            permission: EEventsPerm.EVENTS_VIEW
        },
        children: checkpointsRoutes
    },
    {
        path: EVENTS_ROUTES.createUrl,
        name: EEventRoutesNames.EVENT_CREATE,
        component: EventsCreateView,
        meta: {
            title: ref(i18n.global.t('menu.events')),
            permission: EEventsPerm.EVENTS_EDIT
        }
    }
]

export default routes
