import store from '@/store'
import type { TabType } from '@/types/TabType'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'

export const userHasPermission = (permission: PermissionsEnums) =>
    store.state.authentication.me.permissions.includes(permission)

export const showTab = (tab: TabType, permission?: PermissionsEnums) =>
    permission && userHasPermission(permission) ? [tab] : []

export const splitPermissionString = (permissionName: string) => permissionName.split('_')[0]

export const groupPermissions = <T>(permissions?: T[], key?: string) =>
    permissions?.reduce((prev: Record<string, T[]>, current: T) => {
        const tmp = splitPermissionString(
            key ? (current as Record<string, string>)[key] : (current as string)
        )
        if (prev[tmp]) return { ...prev, [tmp]: [...(prev as Record<string, any>)[tmp], current] }
        return { ...prev, [tmp]: [current] }
    }, {})

/* export const groupPermissionsString = (permissionNames: string[]) => {
    permissionNames.reduce((prev: Record<string, string[]>, current: string) => {
        const tmp = splitPermissionString(current)
        return groupPermissions<string>(tmp, prev, current, current)
    }, {})
}

export const groupPermissionsModel = (permissions: PermissionModel[]) =>
    permissions.reduce((prev: Record<string, PermissionModel[]>, current: PermissionModel) => {
        const tmp = splitPermissionString(current.name)
        return groupPermissions<PermissionModel>(tmp, prev, current, current.name)
    }, {})
 */
