<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { StackLayout } from '@/components/Layouts'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'
import OrganizationFriendCode from './components/OrganizationFriendCode.vue'
import OrganizationLinkShare from './components/OrganizationLinkShare.vue'
import OrganizationQRCode from './components/OrganizationQRCode.vue'

const isMobileScreen = ref(isMobile())
isMobileEvent(e => (isMobileScreen.value = e.matches))
</script>

<template>
    <StackLayout
        :gap="16"
        :class="`full-width ${isMobileScreen ? 'align-items-center' : ''}`"
        isResponsive>
        <InformationBaseCard
            :label="$t('organization.organizations.invite.qrCode')"
            class="background-color qrCode-card full-width">
            <OrganizationQRCode />
        </InformationBaseCard>
        <StackLayout
            direction="column"
            :gap="16"
            :class="isMobileScreen ? 'full-width align-items-center' : ''">
            <InformationBaseCard
                :label="$t('organization.organizations.invite.friendCode')"
                class="background-color fit-content invite-link">
                <OrganizationFriendCode />
            </InformationBaseCard>
            <InformationBaseCard
                :label="$t('organization.organizations.invite.inviteLink')"
                class="background-color fit-content friend-code invite-link">
                <OrganizationLinkShare />
            </InformationBaseCard>
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.qrCode-card {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    max-width: 400px;
}
.friend-code {
    & :deep(.information-card-content) {
        min-height: fit-content;
        & .field.is-floating-label .label::before {
            background-color: var(--color-background-secondary);
        }
    }
    & span {
        overflow-x: auto;
    }
}
.invite-link {
    max-width: 100%;
}
.copy-link {
    border: 1px solid gray;
    border-radius: var(--border-radius);
    padding: 4px;
}
.link-class {
    padding: 4px;
    border-radius: 4px;
    height: fit-content;
    overflow-x: auto;
    &:focus {
        background-color: gray;
    }
    &:focus-within {
        background-color: gray;
    }
}
</style>
