import type { Colors } from '../constants/ColorValues'

export enum EDeviceStatus {
    OFFLINE = 0,
    ONLINE = 1,
    WARNING = 2
}

export const EDeviceStatusLabels = {
    [EDeviceStatus.OFFLINE]: 'device.status.offline',
    [EDeviceStatus.ONLINE]: 'device.status.online',
    [EDeviceStatus.WARNING]: 'device.status.warning'
}

export const EDeviceStatusColors = {
    [EDeviceStatus.OFFLINE]: 'danger' as Colors,
    [EDeviceStatus.ONLINE]: 'success' as Colors,
    [EDeviceStatus.WARNING]: 'warning' as Colors
}
