<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import QRCodeComponent from '@/components/QRCode/QRCodeComponent.vue'
import QRCodeScannerWrapper from '@/components/QRCode/QRCodeScannerWrapper.vue'

const link = window.location.origin
</script>

<template>
    <StackLayout direction="column" :gap="8" class="full-width">
        <QRCodeComponent :value="link" />
        <QRCodeScannerWrapper />
    </StackLayout>
</template>

<style scoped lang="scss">
.qrCode-card {
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    max-width: 400px;
}
</style>
