import type { ThumbnailModel } from '@/types/models/ThumbnailModel'

export const thumbnails: ThumbnailModel[] = [
    {
        name: 'nano',
        img: 'https://shop.urtime.net/99-large_default/urtime-nano-classique.jpg',
        content:
            'Manual RFID scanner with data storage and touchscreen display Broadcasting capabilities3G * Wifi (optional)* Satellite (optional)'
    },
    {
        name: 'Valise de transport pour nano',
        img: 'https://shop.urtime.net/101-large_default/valise-de-transport-pour-nano.jpg',
        content: 'Valise de transport pour nano'
    },
    {
        name: 'Coque de protection',
        img: 'https://shop.urtime.net/44-large_default/coque-de-protection.jpg',
        content: 'Coque de protection'
    },
    {
        name: 'nano without image',
        content: 'Coque de protection'
    }
]
