import type { RoleModel } from '@/types/models/RoleModel'

export type RoleState = {
    role?: RoleModel
    roles: RoleModel[]
}

export enum ERoleActions {
    FETCH_ROLE = 'fetchRole',
    FETCH_ROLES = 'fetchRoles',
    CREATE_ROLE = 'createRole',
    UPDATE_ROLE = 'updateRole',
    DELETE_ROLE = 'deleteRole'
}

export enum ERoleMutations {
    SET_ROLES = 'setRoles',
    SET_ALL_ROLES = 'setAllRoles',
    SET_ROLE = 'setRole',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
