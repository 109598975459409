<script setup lang="ts">
import { TableColumn } from '@/components/Table'
import { ClickableTypography, TypographyItem } from '@/components/Typography'
import { DEVICE_ROUTES, EVENT_CHECKPOINT_ROUTES } from '@/router/routes'
import { formatTimestamp } from '@/utils/dateUtils'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()

const route = useRoute()
const eventId = ref(parseInt(route.params.id.toString()))

const columns = {
    bib: { field: 'bib', label: t('event.detection.table.bib') },
    checkpoint: { field: 'checkpoint_name', label: t('event.detection.table.checkpoint') },
    device: { field: 'device_name', label: t('event.detection.table.device') },
    timestamp: { field: 'timestamp', label: t('event.detection.table.timestamp') },
    event_filter: { field: 'event_filter', label: t('event.detection.table.event_filter') },
    timer_filter: { field: 'timer_filter', label: t('event.detection.table.timer_filter') }
}
</script>

<template>
    <TableColumn :field="columns['bib'].field" :label="columns['bib'].label" />
    <TableColumn :label="columns['device'].label" v-slot="props">
        <ClickableTypography
            :label="props.row.device_name"
            :href="DEVICE_ROUTES.buildUrl(props.row.device_id)" />
    </TableColumn>
    <TableColumn :label="columns['checkpoint'].label" v-slot="props">
        <ClickableTypography
            :label="props.row.checkpoint_name"
            :href="`${EVENT_CHECKPOINT_ROUTES.buildUrl(eventId)}/${props.row.checkpoint_id}`" />
    </TableColumn>
    <TableColumn :label="columns['timestamp'].label" v-slot="props">
        <TypographyItem :label="formatTimestamp(props.row.timestamp)" />
    </TableColumn>
    <TableColumn :field="columns['event_filter'].field" :label="columns['event_filter'].label" />
    <TableColumn :field="columns['timer_filter'].field" :label="columns['timer_filter'].label" />
</template>
