<script setup lang="ts">
import { computed } from 'vue'
import MenuDrawerContent from './MenuDrawer/MenuDrawerContent.vue'

type Props = {
    open: boolean
    overlay: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'open', value: boolean): void
    (e: 'click'): void
}
const emit = defineEmits<Emits>()
const open = computed({
    get: () => props.open,
    set: value => emit('open', value)
})
</script>

<template>
    <b-sidebar fullheight :overlay="overlay" v-model="open" class="sidebar-drawer" v-bind="$attrs">
        <div class="menu-div-container">
            <MenuDrawerContent @click="$emit('click')">
                <template #home-item>
                    <slot />
                </template>
                <template #footer-item>
                    <slot name="footer-item" />
                </template>
            </MenuDrawerContent>
        </div>
    </b-sidebar>
</template>

<style scoped lang="scss">
.sidebar-drawer {
    z-index: 0;
    box-shadow:
        inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
        /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
        /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
        0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
        0 3.5px 6px hsla(0, 0%, 0%, 0.09);
    & :deep(.sidebar-content) {
        background-color: var(--color-background-secondary);
    }
}
</style>
