<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import partnerApi from '@/services/partners.service'
import { type EventModel } from '@/types/models/EventModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref } from 'vue'
import TypedInput from '../../Fields/TypedInput.vue'

type Emits = {
    (e: 'update', value: Partial<EventModel>): void
}
const emit = defineEmits<Emits>()

const getPartners = (name?: string) =>
    partnerApi
        .getPartners(name ? { filters: { name } } : undefined)
        .then(res => (partnersOptions.value = res.data))
        .finally(() => (isLoadingEvents.value = false))
onMounted(() => getPartners())

const selectedPartner = ref()
const partnersOptions = ref<PartnerModel[]>([])
const handleSelect = (partner: PartnerModel) => {
    selectedPartner.value = partner
    emit('update', { partner_id: partner.id, partner_config: null })
}

const handleChange = (value: string) => {
    isLoadingEvents.value = true
    debouncedQuestion(value)
}
const isLoadingEvents = ref(false)
const debouncedQuestion = useDebounceFn(
    (value: string) => getPartners(value),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <AutocompleteField
            :label="$t('event.model.partnerName')"
            :options="partnersOptions"
            @typing="handleChange"
            @select="handleSelect($event as PartnerModel)"
            :clearable="false"
            class="full-width" />
        <StackLayout v-if="selectedPartner?.config">
            <TypedInput
                v-for="([itemKey, itemValue], key) in Object.entries(selectedPartner.config.event)"
                :key="key"
                :label="itemKey"
                :dataType="itemValue as string"
                @change="$emit('update', { partner_config: { [itemKey]: $event } })"
                class="full-width" />
        </StackLayout>
    </StackLayout>
</template>
