<script setup lang="ts">
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { RangeType } from '@/types/Range'
import moment from 'moment'
import { computed } from 'vue'
import {
    EditableBooleanField,
    EditableDateField,
    EditableNumberField,
    EditableStringField
} from '.'
import EditableSelectField from './EditableFields/EditableSelectField.vue'

type Props = {
    value?: any
    values?: (string | number | boolean | undefined)[]
    valuesLabels?: (string | undefined)[]
    dataType?: EPrimitive | 'Date' | object | string
    range?: Partial<RangeType>
    label?: string
    tooltip?: string
    suffix?: string
    showableElement?: boolean
    isInEditMode?: boolean
    hasError?: boolean
    errorText?: string
    required?: boolean
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'click', event: MouseEvent): void
    (e: 'change', value: ExtendedPrimitives | undefined): void
}>()

const valuesOptions = computed(
    () =>
        props.values?.map((value: string | number | boolean | undefined, index) => ({
            value,
            name: props.valuesLabels?.[index]
        })) ?? []
)

const selectedOption = computed(() =>
    valuesOptions.value.find(valueItem => valueItem.value === props.value)
)
</script>

<template>
    <EditableSelectField
        v-if="values && values.length"
        :label="label"
        :tooltip="tooltip"
        :value="selectedOption?.value?.toString()"
        :valueLabel="selectedOption?.name ?? selectedOption?.value?.toString()"
        :options="valuesOptions"
        @change="$emit('change', $event)"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :hasError="hasError"
        :errorText="errorText"
        :required="required"
        v-bind="$attrs" />
    <EditableStringField
        v-else-if="dataType === EPrimitive.STRING"
        :label="label"
        :tooltip="tooltip"
        :value="value"
        @change="$emit('change', $event)"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :hasError="hasError"
        :errorText="errorText"
        :required="required"
        v-bind="$attrs" />
    <EditableNumberField
        v-else-if="
            dataType === EPrimitive.INTEGER ||
            dataType === EPrimitive.FLOAT ||
            dataType === EPrimitive.NUMBER
        "
        :label="label"
        :tooltip="tooltip"
        :value="value"
        :min="range?.min"
        :max="range?.max"
        @change="$emit('change', $event)"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :suffix="suffix"
        :hasError="hasError"
        :errorText="errorText"
        :required="required"
        v-bind="$attrs" />
    <EditableBooleanField
        v-else-if="dataType === EPrimitive.BOOLEAN"
        :label="label"
        :tooltip="tooltip"
        :value="value"
        @change="$emit('change', $event)"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :hasError="hasError"
        :errorText="errorText"
        :required="required"
        v-bind="$attrs" />
    <EditableDateField
        v-else-if="dataType === 'Date' || (typeof value === 'object' && moment(value).isValid())"
        :label="label"
        :tooltip="tooltip"
        :value="value"
        @change="$emit('change', $event)"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :hasError="hasError"
        :errorText="errorText"
        :required="required"
        v-bind="$attrs" />
    <EditableTypedInput
        v-else
        v-for="([itemKey, itemValue], key) in Object.entries(value)"
        :dataType="itemValue as string"
        :key="key"
        :value="itemValue"
        :label="itemValue as string"
        :dataKey="itemKey"
        @change="$emit('change', { [itemKey]: $event })"
        :isInEditMode="isInEditMode"
        :showableElement="showableElement"
        :hasError="hasError"
        :errorText="errorText"
        :required="required" />
</template>
