<script setup lang="ts">
import { InputField } from '@/components/Fields'
import StackLayout from '@/components/Layouts/StackLayout.vue'
import type { AdminOrganizationFiltersType } from '@/types/filters/AdminFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters: AdminOrganizationFiltersType
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: AdminOrganizationFiltersType): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value: string) => {
    isLoadingName.value = true
    debouncedName(value)
}
const isLoadingName = ref(false)
const debouncedName = useDebounceFn(
    (name: string) => emit('change', { name }),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout :gap="8" class="full-width" isResponsiveSmallScreen>
        <InputField
            :label="$t('user.filters.name')"
            :modelValue="filters.name"
            @change="handleTyping" />
    </StackLayout>
</template>
