<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { InputField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import { FullScreenLoader } from '@/components/LoadingSpinner'
import store from '@/store'
import { EAuthActions } from '@/store/authStore/AuthStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { EUserActions } from '@/store/userStore/UserStoreTypes'
import { type UserModel } from '@/types/models/UserModel'
import { isEqual } from 'lodash'
import { computed } from 'vue'

type Props = {
    me: UserModel
}
const props = defineProps<Props>()
const currentData = computed(() => ({ ...store.state.authentication.me }))

const localMe = computed({
    get: () => props.me,
    set: value => value
})
const updateMeValue = (value: Partial<UserModel>) =>
    (localMe.value = { ...localMe.value, ...value })

const save = () => {
    if (!isEqual(currentData.value, localMe.value))
        store
            .dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
                data: localMe.value,
                userId: currentData.value.id
            })
            .then(() => {
                store.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.ME}`)
            })
}
</script>

<template>
    <FullScreenLoader
        :isLoading="!!store.state.user.isLoading || !!store.state.authentication.isLoading" />
    <InformationBaseCard :label="$t('account.informationsTitle')" class="full-width">
        <StackLayout direction="column" :gap="16" class="full-width">
            <InputField
                :label="$t('account.model.name')"
                :value="localMe.name"
                @change="name => updateMeValue({ name })"
                class="full-width" />
            <InputField
                :label="$t('account.model.email')"
                :value="localMe.email"
                @change="email => updateMeValue({ email })"
                class="full-width" />
            <CreateButton
                :loading="!!store.state.user.isLoading"
                :label="$t('common.save')"
                @click="save" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style lang="scss">
.profile-role-card {
    & .card-content {
        text-align: center;
        border: 1px solid $primary;
    }
}
</style>
