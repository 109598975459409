<script setup lang="ts">
import { CreateButton } from '@/components/Buttons'
import { GridLayout, StackLayout } from '@/components/Layouts'
import { TypographyItem } from '@/components/Typography'
import { useGridSize } from '@/hooks/useGridSize'
import router from '@/router'
import { SUPPORTFAQ_ROUTE } from '@/router/routes'
import type { ThumbnailModel } from '@/types/models/ThumbnailModel'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import { ref } from 'vue'

type Props = {
    thumbnails: ThumbnailModel[]
}
defineProps<Props>()

const gridSize = useGridSize()

const isHovered = ref(false)
const toggleHover = (mouseValue: boolean) => (isHovered.value = mouseValue)

const showIsMobile = ref(isMobile())
isMobileEvent(e => (showIsMobile.value = e.matches))

const navigateToFAQ = () => router.push(SUPPORTFAQ_ROUTE)
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8">
        <StackLayout
            direction="column"
            class="content flex space-between custom-box background-color"
            v-for="(item, key) in thumbnails"
            :key="key"
            @mouseenter="toggleHover(true)"
            @mouseleave="toggleHover(false)">
            <StackLayout
                v-if="item.img"
                class="primary-border justify-center border-radius full-width">
                <img :src="item.img" class="card-img background-mute" />
            </StackLayout>
            <TypographyItem bold :label="item.name" />
            <TypographyItem :label="item.content" />
            <CreateButton label="FAQ" @click="navigateToFAQ" />
        </StackLayout>
    </GridLayout>
</template>

<style scoped lang="scss">
.card-img {
    min-width: 128px;
    height: 128px;
}

.custom-box::before {
    z-index: -1;
}
</style>
