import { EStatus } from '@/types/enum/StatusEnum'

export type ApiModel = {
    active: EStatus
    api_key: string
    data: string | null
    expiration_date: Date | null
    id: number
    name: string
    roles: string[]
}

export const defaultApiModel: ApiModel = {
    active: EStatus.DISABLED,
    api_key: '',
    data: null,
    expiration_date: null,
    id: 0,
    name: '',
    roles: []
}
