import type { PartnerModel } from '@/types/partners/PartnerModel'

export type PartnerState = {
    partner?: PartnerModel
    partners: PartnerModel[]
}

export enum EPartnerActions {
    FETCH_PARTNERS = 'getPartners',
    FETCH_PARTNER = 'getPartner',
    ADD_PARTNER = 'addPartner',
    UPDATE_PARTNER = 'updatePartner',
    DELETE_PARTNER = 'deletePartner'
}

export enum EPartnerMutations {
    SET_PARTNERS = 'setPartners',
    SET_PARTNER = 'setPartner',
    SET_TOTAL = 'setTotal',
    SET_LOADING = 'setLoading'
}
