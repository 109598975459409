// import the api endpoints
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import { getPageSize } from '@/utils/routeUtils'
import { ref, type Ref } from 'vue'
import { EPaginationMutations, type PaginationState } from './PaginationStoreTypes'

const state: Ref<PaginationState> = ref({
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,
    isLoading: false
})

const getters = {
    getPageSize: () => state.value.per_page
}

const mutations = {
    [EPaginationMutations.SET_PAGE_SIZE](_: PaginationState, data: number) {
        state.value.per_page = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
