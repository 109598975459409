<script setup lang="ts">
import { IconButton } from '@/components/Buttons'
import { ref } from 'vue'

type Props = {
    open?: boolean
    withIcon?: boolean
}
const props = defineProps<Props>()

const isOpen = ref(props.open || false)
const handleIsOpen = (value: boolean) => (isOpen.value = value)
</script>

<template>
    <b-collapse
        :open="isOpen"
        :class="`card ${isOpen ? 'collapse-open' : ''}`"
        @open="handleIsOpen(true)"
        @close="handleIsOpen(false)"
        animation="slide">
        <template #trigger>
            <slot name="title" />
            <IconButton v-if="withIcon" :icon="isOpen ? 'arrow-up' : 'arrow-down'"></IconButton>
        </template>
        <slot name="content" />
    </b-collapse>
</template>

<style scoped lang="scss">
.collapse-open {
    & :deep(.card-header-title) {
        border-bottom: 1px solid $primary;
    }
}
</style>
