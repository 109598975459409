import type { Colors } from '@/types/constants/ColorValues'

export type IconType = {
    label?: string | undefined
    path?: string
    position?: 'start' | 'end'
    pack?: 'fas' | 'fad' | 'far'
    type?: Colors
}

export const defaultIconType: IconType = {
    label: undefined,
    position: 'start'
}
