export const roleFr = {
    api: {
        error: {
            createRole: 'Erreur lors de la création du rôle {name}',
            deleteRole: 'Erreur lors de la suppression du rôle {name}',
            fetchRole: 'Erreur lors de la récupération du rôle',
            fetchRoles: 'Erreur lors de la récupération des rôles',
            updateRole: 'Erreur lors de la modification du rôle {name}'
        },
        success: {
            createRole: 'Création du rôle {name} effectuée avec succès !',
            deleteRole: 'Suppression du rôle {name} effectuée avec succès !',
            fetchRole: 'Récupération du rôle effectuée avec succès !',
            fetchRoles: 'Récupération des rôles effectuée avec succès !',
            updateRole: 'Modification(s) du rôle {name} effectuée(s) avec succès !'
        }
    },
    buttons: {
        createRole: 'Créer un rôle'
    },
    filters: {
        name: 'Nom'
    },
    model: {
        name: 'Nom',
        guardName: 'Nom de la garde',
        permissions: 'Permissions'
    },
    table: {
        id: 'ID',
        name: 'Nom'
    },
    informationsTitle: 'Informations',
    permissionsTitle: 'Permissions',
    guardNameTitle: 'Guard name',
    noGuardSelected: 'Aucune permission trouvée. Sélectionnez une garde'
}
