<script setup lang="ts">
import HrefCreateButton from '@/components/Buttons/HrefCreateButton.vue'
import { FullTableLayout } from '@/components/Layouts'
import PermissionWrapper from '@/components/Permission/PermissionWrapper.vue'
import { BaseTable, TableColumn, TablePagination } from '@/components/Table'
import TableActionColumn from '@/components/Table/TableActionColumn.vue'
import {
    TECHNICIAN_QUESTIONS_ROUTE,
    TECHNICIAN_QUESTIONS_ROUTES
} from '@/router/routing/technicianRouting/technicianRoutes'
import store from '@/store'
import { EQuestionActions } from '@/store/questionStore/QuestionStoreTypes'
import { EStoreModules } from '@/store/storeType'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { QuestionModel } from '@/types/models/QuestionModel'
import { ETechnicianQuestionsPerm } from '@/types/permissions/TechnicianPermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()

const columns = {
    id: { field: 'id', label: t('technician.question.model.id') },
    name: { field: 'name', label: t('technician.question.model.name') }
}

const data = computed(() => store.state.question)
const filters = ref<any>(getQueryFilters())
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getQuestions = () =>
    store.dispatch(`${EStoreModules.QUESTION}/${EQuestionActions.FETCH_QUESTIONS}`, {
        filters: filters.value,
        sort: getSortByKey(sort.value),
        per_page: data.value.per_page,
        page: data.value.current_page
    })

const goToQuestionDetail = (question: QuestionModel) =>
    router.push(`${TECHNICIAN_QUESTIONS_ROUTE}/${question.id}`)
const goToQuestionCreate = () => router.push(TECHNICIAN_QUESTIONS_ROUTES.createUrl)
const deleteQuestion = (question: QuestionModel) =>
    store
        .dispatch(`${EStoreModules.QUESTION}/${EQuestionActions.DELETE_QUESTION}`, question.id)
        .then(() => getQuestions())

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    getQuestions()
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    getQuestions()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getQuestions()
}

const table = ref<any>([])
</script>

<template>
    <PermissionWrapper :permission="ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_EDIT">
        <HrefCreateButton
            :href="TECHNICIAN_QUESTIONS_ROUTES.createUrl"
            :label="$t('technician.question.table.createQuestion')"
            @click="goToQuestionCreate" />
    </PermissionWrapper>
    <FullTableLayout :sortBy="sort" @changeSortBy="handleChangeSortBy">
        <template #filters></template>

        <template #table>
            <BaseTable v-bind:ref="table" :data="data.data.questions">
                <template #columns>
                    <TableColumn field="id" :label="columns['id'].label" />
                    <TableColumn field="name" :label="columns['name'].label" />
                    <TableActionColumn
                        :editPermission="ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_EDIT"
                        :deletePermission="ETechnicianQuestionsPerm.TECHNICIAN_QUESTIONS_DELETE"
                        @goTo="goToQuestionDetail"
                        @delete="deleteQuestion"
                        :editButtonHref="TECHNICIAN_QUESTIONS_ROUTES.baseUrl" />
                </template>
            </BaseTable>
        </template>
        <template #pagination>
            <TablePagination
                :perPage="data.per_page"
                :currentPage="data.current_page"
                :lastPage="data.last_page"
                :total="data.total"
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </template>

        <template #dialogFilters:title></template>
        <template #dialogFilters:content></template>
        <template #dialogFilters:footer></template>
    </FullTableLayout>
</template>
