import { API_PREFIX } from '@/config/api'
import auth from '@/helpers/auth'
import type { PaginationType } from '@/types/PaginationType'
import { buildQueryUrl } from '@/utils/queryUtils'

const SEVERITY_API = `${API_PREFIX}/severities`

const severityApi = {
    getSeverity: (id: number) => auth.get(`${SEVERITY_API}/${id}`).then(response => response.data),
    getSeverities: (params?: Partial<PaginationType>) =>
        auth.get(`${SEVERITY_API}${buildQueryUrl(params)}`).then(response => response.data),
    createSeverity: (severityData: any) =>
        auth.post(SEVERITY_API, severityData).then(response => response.data),
    updateSeverity: (id: number, severityData: any) =>
        auth.put(`${SEVERITY_API}/${id}`, severityData).then(response => response.data),
    deleteTopic: (id: string) =>
        auth.delete(`${SEVERITY_API}/${id}`).then(response => response.data),

    getTopicsWithoutPagination: (id?: number) =>
        auth.get(`${SEVERITY_API}/parent/${id ?? ''}`).then(response => response.data)
}

export default severityApi
