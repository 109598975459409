<script setup lang="ts">
import AutocompleteField from '@/components/Selector/AutocompleteField.vue'
import { ADMIN_ROLES_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { ERoleActions } from '@/store/roleStore/RoleStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { SelectorObject } from '@/types/components/SelectorObject'
import { EGuardNameValues } from '@/types/enum/GuardNameEnum'
import { defaultRoleModel, type RoleModel } from '@/types/models/RoleModel'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import InformationBaseCard from '../../../../components/Cards/InformationBaseCard.vue'
import AdministrationRoleDetailView from '../AdministrationRoleDetailView.vue'

const router = useRouter()

const data = ref<Partial<RoleModel>>({ ...defaultRoleModel })

const updateRole = (value: Partial<RoleModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store
        .dispatch(`${EStoreModules.ROLE}/${ERoleActions.CREATE_ROLE}`, data.value)
        .then(() => router.push(ADMIN_ROLES_ROUTES.baseUrl)) // TODO : Alerte

const options: SelectorObject[] = EGuardNameValues.map(value => ({ value, name: value }))
</script>

<template>
    <AdministrationRoleDetailView
        :data="data"
        :buttonLabel="$t('common.create')"
        :loading="!!store.state.role.isLoading"
        hasPermission
        @click="create"
        @update="updateRole">
        <InformationBaseCard :label="$t('role.guardNameTitle')" class="fit-content">
            <AutocompleteField
                label="Guard name"
                :modelValue="data.guard_name"
                @select="data.guard_name = $event?.value as string"
                :options="options" />
        </InformationBaseCard>
    </AdministrationRoleDetailView>
</template>
