<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import IconItem from '@/components/Icon/IconItem.vue'
import { StackLayout } from '@/components/Layouts'
import { TablePagination } from '@/components/Table'
import TooltipItem from '@/components/Tooltip/TooltipItem.vue'
import TypographyItem from '@/components/Typography/TypographyItem.vue'
import { localFallback } from '@/locales/langUtils'
import deviceApi from '@/services/device.service'
import { EColors } from '@/types/constants/ColorValues'
import { EPrimitive } from '@/types/enum/PrimitivesEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { DeviceHistoryModel } from '@/types/models/DeviceHistoryModel'
import { type PaginationDataType, defaultPaginationDataType } from '@/types/PaginationType'
import { booleanToString } from '@/utils/dataUtils'
import { DATETIME_FORMAT } from '@/utils/dateUtils'
import { getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { isNullOrUndefined } from '@/utils/validateUtils'
import moment from 'moment'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DeviceHistoryFilters from './Filters/DeviceHistoryFilters.vue'

type Props = {
    deviceId?: number
}
const props = defineProps<Props>()
const { t } = useI18n()

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)
const filters = ref<Partial<DeviceHistoryModel>>()
const pagination = ref<PaginationDataType<DeviceHistoryModel[]>>(defaultPaginationDataType)
const getHistory = () => {
    if (!props.deviceId) return
    deviceApi
        .getDeviceHistory(
            props.deviceId,
            {
                sort: getSortByKey(sort.value),
                filters: filters.value,
                per_page: getPageSize() ?? pagination.value?.per_page,
                page: pagination.value?.current_page
            },
            true
        )
        .then(res => (pagination.value = { ...res.data }))
}

const handleChangePageSize = (value: number) => {
    pagination.value = { ...pagination.value, current_page: 1, per_page: value }
    getHistory()
}
const handleChangePageNumber = (value: number) => {
    pagination.value = { ...pagination.value, current_page: value }
    getHistory()
}
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getHistory()
}
const handleChangeFilters = (value: Partial<DeviceHistoryModel>) => {
    filters.value = { ...filters.value, ...value }
    getHistory()
}

const getInputLabel = (item: DeviceHistoryModel) =>
    item?.translation && !Array.isArray(item.translation)
        ? localFallback(item.translation)
        : item?.name ?? item?.name

const getKeyValue = (
    value: string,
    type: EPrimitive | null,
    values?: number[],
    values_labels?: string[]
) => {
    if (!isNullOrUndefined(value)) {
        if (!isNullOrUndefined(type)) {
            if (type === EPrimitive.BOOLEAN) return booleanToString(value === '0')
            if (type === EPrimitive.ENUM) {
                const found = values?.findIndex(item => value?.toString() === item?.toString())
                return !isNullOrUndefined(found) && found !== -1
                    ? values_labels?.[found!]
                    : value?.toString()
            }
            return value?.toString() ?? 'null'
        }
        return value?.toString() ?? 'null'
    }
    return 'null'
}

watch(
    () => props.deviceId,
    (newValue, oldValue) => {
        if (oldValue && newValue !== oldValue) handleChangePageNumber(1)
    },
    { immediate: true }
)
</script>

<template>
    <InformationBaseCard class="full-width" :label="$t('device.detail.history.historyTitle')">
        <StackLayout direction="column" :gap="8" class="full-width">
            <DeviceHistoryFilters
                :filters="filters"
                :sort="sort"
                @changeSortBy="handleChangeSortBy"
                @changeFilters="handleChangeFilters" />
            <TypographyItem v-if="!pagination?.data?.length" :label="$t('common.noHistory')" />
            <StackLayout v-else direction="column" :gap="2" class="full-width">
                <StackLayout
                    direction="column"
                    v-for="(item, index) in pagination?.data"
                    :key="index">
                    <StackLayout :gap="4" class="align-items-center small">
                        <IconItem icon="circle" :type="EColors.WHITE" class="small-icon" />
                        <TooltipItem :label="item.name" class="typography-tooltip" multilined>
                            <StackLayout direction="column">
                                <StackLayout class="align-items-center">
                                    <TypographyItem
                                        :label="getInputLabel(item)"
                                        bold
                                        class="key-name" />
                                    <StackLayout
                                        direction="row"
                                        :gap="4"
                                        class="align-items-center">
                                        <IconItem icon="minus" class="small-icon" />
                                        <TypographyItem
                                            :label="`${t('common.modifiedBy')} ${item.editor_name}`"
                                            class="modified-by" />
                                        <IconItem icon="minus" class="small-icon" />
                                        <TypographyItem
                                            :label="`${t('date.at', {
                                                date: moment(item.updated_at).format(
                                                    DATETIME_FORMAT
                                                )
                                            })}`"
                                            class="modified-by" />
                                    </StackLayout>
                                </StackLayout>

                                <StackLayout direction="row" :gap="4" class="align-items-center">
                                    <TypographyItem :label="`${$t('common.value')} :`" />
                                    <TypographyItem
                                        :label="
                                            getKeyValue(
                                                item.old_value,
                                                item.type,
                                                item.values,
                                                item.values_labels
                                            )
                                        " />
                                    <IconItem
                                        icon="arrow-right"
                                        :type="EColors.WHITE"
                                        class="small-icon" />
                                    <TypographyItem
                                        :label="
                                            getKeyValue(
                                                item.new_value,
                                                item.type,
                                                item.values,
                                                item.values_labels
                                            )
                                        " />
                                </StackLayout>
                            </StackLayout>
                        </TooltipItem>
                    </StackLayout>
                </StackLayout>
            </StackLayout>
            <TablePagination
                :perPage="pagination?.per_page"
                :currentPage="pagination?.current_page"
                :lastPage="pagination?.last_page"
                :total="pagination?.total"
                forcePropsCurrentPage
                @change:pageSize="handleChangePageSize"
                @change:pageNumber="handleChangePageNumber" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.key-name {
    color: $primary;
}
.modified-by {
    color: $primary;
    color: var(--color-text-secondary);
}
.user-name {
    color: var(--color-text-secondary);
}

.typography-tooltip {
    & :deep(.tooltip-content) {
        left: unset;
        transform: unset;
    }
}
</style>
