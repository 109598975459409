<script setup lang="ts">
import InformationBaseCard from '@/components/Cards/InformationBaseCard.vue'
import { EditableStringField } from '@/components/Fields'
import { StackLayout } from '@/components/Layouts'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { ref } from 'vue'

type Props = {
    data: OrganizationModel
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<OrganizationModel>): void
}
defineEmits<Emits>()
const isInEditMode = ref(false)
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <InformationBaseCard
            class="full-width input-information-card"
            :label="$t('organization.informations.general')">
            <StackLayout direction="column" :gap="8" class="full-width">
                <StackLayout direction="column" :gap="8">
                    <EditableStringField
                        :label="$t('organization.model.name')"
                        :value="data.name"
                        :isInEditMode="isInEditMode"
                        @change="name => $emit('change', { name })"
                        required
                        class="full-width" />
                    <EditableStringField
                        :label="$t('organization.model.socialReason')"
                        :value="data.social_reason"
                        :isInEditMode="isInEditMode"
                        @change="social_reason => $emit('change', { social_reason })"
                        required
                        class="full-width" />
                </StackLayout>
            </StackLayout>
        </InformationBaseCard>
    </StackLayout>
</template>
