export type PasswordValidation = {
    hasMinLength: boolean
    hasUppercase: boolean
    hasLowercase: boolean
    hasNumber: boolean
    hasSpecial: boolean
    hasNoWhitespace: boolean
    passwordMatches: boolean
}

export const defaultPasswordValidation = {
    hasLowercase: false,
    hasMinLength: false,
    hasNumber: false,
    hasSpecial: false,
    hasUppercase: false,
    hasNoWhitespace: false,
    passwordMatches: false
}
