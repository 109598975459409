<script setup lang="ts">
import { TypographyItem, TypographyWithPrefix } from '@/components/Typography'
import { computed, ref } from 'vue'
import { IconButton } from '../Buttons'

type Props = {
    label?: string
}
const props = defineProps<Props>()

const isShowing = ref(false)
const icon = computed(() => (isShowing.value ? 'eye-slash' : 'eye'))
const currentLabel = computed(() =>
    isShowing.value ? props.label : props.label?.split(/./).join('*')
)

const handleIconClick = () => (isShowing.value = !isShowing.value)
</script>

<template>
    <TypographyWithPrefix v-bind="$attrs">
        <TypographyItem :label="currentLabel" />

        <template #edit>
            <IconButton :icon="icon" class="clickable-icon" @click="handleIconClick" />
        </template>
    </TypographyWithPrefix>
</template>

<style scoped lang="scss">
.clickable-icon {
    cursor: pointer;
    color: $primary;
    border: unset;
}
</style>
