<script setup lang="ts">
import StackLayout from '@/components/Layouts/StackLayout.vue'
import { BaseTable } from '@/components/Table'
import TitleTypography from '@/components/Typography/TitleTypography.vue'
import SocketDetectionsWrapper from '@/socket/SocketDetectionsWrapper.vue'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { SocketDetectionModel } from '@/types/socket/SocketDetectionModel'
import { ref, watch } from 'vue'
import DeviceDetectionsColumns from './DeviceDetectionsColumns.vue'

type Props = {
    deviceId?: number
    noDetectionLink?: boolean
}
const props = defineProps<Props>()

const table = ref()

const deviceDetections = ref<DetectionModel[]>([])
watch(
    () => props.deviceId,
    newValue => (newValue ? (deviceDetections.value = []) : undefined)
)

const handleDetectionsUpdate = (value: SocketDetectionModel) =>
    (deviceDetections.value = [
        ...(value.device_detections
            .find(item => item.device_id === props.deviceId)
            ?.detections.filter(
                item =>
                    !deviceDetections.value.find(
                        detection => detection.timestamp === item.timestamp
                    )
            ) ?? []),
        ...deviceDetections.value
    ])
</script>

<template>
    <SocketDetectionsWrapper
        v-if="!!deviceId"
        :device_ids="[deviceId]"
        @update="handleDetectionsUpdate" />
    <StackLayout direction="column" :gap="8">
        <TitleTypography
            :title="$t('device.detail.detections.liveDetections')"
            class="title-x-large bold" />
        <BaseTable v-bind:ref="table" :data="deviceDetections">
            <template #columns>
                <DeviceDetectionsColumns :noDetectionLink="noDetectionLink" />
            </template>
        </BaseTable>
    </StackLayout>
</template>
