<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    name: string
}
const props = defineProps<Props>()

const stringToColor = (string: string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}
const stringAvatar = computed(() => `${props.name.split(' ')[0][0]}`)
const stringColor = computed(() => stringToColor(props.name))
</script>

<template>
    <div class="image is-48x48 colored-avatar flex align-items-center place-content-center">
        {{ stringAvatar }}
    </div>
</template>

<style scoped lang="scss">
.colored-avatar {
    background-color: v-bind(stringColor);
    border-radius: 48px;
}
</style>
