<script setup lang="ts">
import { StackLayout } from '@/components/Layouts'
import OrganizationView from '@/components/Organization/OrganizationView.vue'
import {
    ORGANIZATIONS_APIS_HREF,
    ORGANIZATIONS_CUSTOMERS_HREF,
    ORGANIZATIONS_ROLES_HREF,
    ORGANIZATIONS_USERS_HREF,
    ORGANIZATIONS_USERS_ROUTES
} from '@/router/routing/organizationRouting/organizationRoutes'
import store from '@/store'
import { EOrganizationActions } from '@/store/organizationStore/OrganizationStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { AddressModel } from '@/types/models/AddressModel'
import { defaultOrganizationModel, type OrganizationModel } from '@/types/models/OrganizationModel'
import {
    EOrganizationsApisTabPerm,
    EOrganizationsCustomersTabPerm,
    EOrganizationsPerm,
    EOrganizationsRolesTabPerm,
    EOrganizationsUsersTabPerm
} from '@/types/permissions/OrganizationsPermissionsEnum'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import OrganizationAddress from './Informations/OrganizationAddress.vue'
import OrganizationInformations from './Informations/OrganizationInformations.vue'

const router = useRouter()

const organizationStore = computed(() => store.state.organization)

const data = ref<OrganizationModel>({ ...defaultOrganizationModel })

const getOrganization = () =>
    store
        .dispatch(`${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_MY_ORGANIZATION}`)
        .then(res => (data.value = res))
onMounted(() => getOrganization())

const goToCreate = () => router.push(ORGANIZATIONS_USERS_ROUTES.createUrl)
const goToDetail = (userId: number) => router.push(ORGANIZATIONS_USERS_ROUTES.buildUrl(userId))

const handleAddressChange = (value: Partial<AddressModel>) =>
    (data.value = { ...data.value, address: { ...data.value.address, ...value } })

const handleDataChange = (value: Partial<OrganizationModel>) =>
    (data.value = { ...data.value, ...value })
</script>

<template>
    <OrganizationView
        :data="data"
        :isLoading="organizationStore.isLoading"
        @goToDetail="goToDetail"
        @goToCreate="goToCreate"
        :informationsPermission="EOrganizationsPerm.ORGANIZATIONS_VIEW"
        :usersViewPermission="EOrganizationsUsersTabPerm"
        :usersViewButtonsHref="ORGANIZATIONS_USERS_HREF"
        :customersViewPermission="EOrganizationsCustomersTabPerm"
        :customersViewButtonsHref="ORGANIZATIONS_CUSTOMERS_HREF"
        :rolesViewPermission="EOrganizationsRolesTabPerm"
        :rolesViewButtonsHref="ORGANIZATIONS_ROLES_HREF"
        :apisViewPermission="EOrganizationsApisTabPerm"
        :apisViewButtonsHref="ORGANIZATIONS_APIS_HREF"
        showRoles>
        <template #informations>
            <StackLayout direction="row" :gap="8" class="full-width" isResponsiveSmallScreen>
                <OrganizationInformations
                    :data="data"
                    @change="handleDataChange"
                    class="full-width" />
                <OrganizationAddress
                    :address="data.address"
                    @change="handleAddressChange"
                    class="full-width" />
            </StackLayout>
        </template>
    </OrganizationView>
</template>
