import type { RoleLocales } from '@/types/lang/locales'

export const roleEn: RoleLocales = {
    api: {
        error: {
            createRole: 'An error occured while creating the role {name}',
            deleteRole: 'An error occured while deleting the role {name}',
            fetchRole: 'An error occured while fetching the role',
            fetchRoles: 'An error occured while fetching the roles',
            updateRole: 'An error occured while updating the role {name}'
        },
        success: {
            createRole: 'Role {name} successfully created !',
            deleteRole: 'Role {name} successfully deleted !',
            fetchRole: 'Role successfully fetched !',
            fetchRoles: 'Roles successfully fetched !',
            updateRole: 'Role {name} successfully edited !'
        }
    },
    buttons: {
        createRole: 'Create role'
    },
    filters: {
        name: 'Name'
    },
    model: {
        name: 'Name',
        guardName: 'Guard name',
        permissions: 'Permissions'
    },
    table: {
        id: 'ID',
        name: 'Name'
    },
    informationsTitle: 'Information',
    permissionsTitle: 'Permissions',
    guardNameTitle: 'Guard name',
    noGuardSelected: 'No permission found. Select a guard.'
}
