import { EColors, type Colors } from '@/types/constants/ColorValues'

export type NotificationState = {
    type: Colors
    message: string
}

export enum ENotificationActions {
    CLEAR = 'clear',
    ERROR = EColors.DANGER,
    INFO = EColors.INFO,
    LIGHT = EColors.LIGHT,
    PRIMARY = EColors.PRIMARY,
    SUCCESS = EColors.SUCCESS,
    WARNING = EColors.WARNING,
    QUEUE_ITEM = 'queueItem'
}

export enum ENotificationMutations {
    CLEAR = 'clear',
    ERROR = EColors.DANGER,
    INFO = EColors.INFO,
    LIGHT = EColors.LIGHT,
    PRIMARY = EColors.PRIMARY,
    SUCCESS = EColors.SUCCESS,
    WARNING = EColors.WARNING,
    ADD_TO_QUEUE = 'addToQueue',
    REMOVE_FROM_QUEUE = 'removeFromQueue'
}
