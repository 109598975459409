<script setup lang="ts">
import { ref } from 'vue'

type Props = {
    label?: string | number
    bold?: boolean
}
defineProps<Props>()
const typographyRef = ref<HTMLSpanElement | null>(null)

defineExpose({ typographyRef })
</script>

<template>
    <span ref="typographyRef" tabindex="0" :class="`text ${bold ? 'bold' : ''}`" v-bind="$attrs">
        {{ label }}
    </span>
</template>
